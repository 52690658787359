import { onlyNumbers, useForm, useValidations } from '@data-c/hooks'
import { Button, ButtonContainer } from '@data-c/ui'

import { Box, Popover, Stack, TextField } from '@mui/material'
import { useUserContext } from 'components/Contexts/UserContext'
import InfoHelper from 'components/Helpers/InfoHelper'
import TimeVendasTransporter from 'components/Transporters/TimeVendasTranporter'
import useMixProdutos, {
  MixProdutoBonificacaoModel,
} from 'hooks/queries/useMixProdutos'
import { PopOverOutputProps } from 'hooks/usePopover'

import * as yup from 'yup'
const schema = yup.object().shape({
  quantidadeMinima: yup.number().required('Informe uma quantidade mínima'),
})

export interface FormProps extends PopOverOutputProps {
  mixProdutoUuid: string
}

export default function Form(props: FormProps) {
  const { isOpen, close, anchorEl, mixProdutoUuid } = props
  const { data, changeValue } = useForm<MixProdutoBonificacaoModel>({
    quantidadeMinima: 1,
    mixProdutoUuid,
  })
  const { setValidationErrors, validationProps } = useValidations()
  const { useAdicionarBonificacao } = useMixProdutos()
  const { mutateAsync, isLoading } = useAdicionarBonificacao()

  const { temPermissao } = useUserContext()

  async function handleSave() {
    setValidationErrors(null)
    schema
      .validate(data, { abortEarly: false })
      .then(async () => {
        const { quantidadeMinima, representanteUuid } = data
        await mutateAsync({
          quantidadeMinima,
          representanteUuid,
          mixProdutoUuid,
        })

        close()
      })
      .catch((err) => {
        setValidationErrors(err)
      })
  }

  return (
    <Popover
      open={isOpen}
      onClose={close}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      disableRestoreFocus={true}
    >
      <Stack spacing={2} sx={{ m: 2, minWidth: '320px' }}>
        <Stack direction="row" gap={1}>
          <TextField
            label="Quantidade Mínima"
            autoFocus
            name="quantidadeMinima"
            value={data.quantidadeMinima || ''}
            onChange={(e) => {
              const v = e.target.value
              let v1 = onlyNumbers(v)
              changeValue('quantidadeMinima', v1)
            }}
            {...validationProps('quantidadeMinima')}
          />
          <InfoHelper>
            Informe a quantidade mínima de produtos deste mix que o cliente deve
            comprar para ganhar a bonificação.
          </InfoHelper>
        </Stack>

        <Stack direction="row" gap={1}>
          <Box sx={{ width: '100%' }}>
            <TimeVendasTransporter
              fullWidth
              disabled={temPermissao('clientes.read') === 'pessoal'}
              name="representanteUuid"
              label="Vendedor"
              value={data?.representante || null}
              onChange={(representante) => {
                changeValue('representante', representante)
                changeValue(
                  'representanteUuid',
                  representante ? representante.uuid : null,
                )
              }}
              InputLabelProps={{ shrink: true }}
            />
          </Box>
          <InfoHelper>
            Indicando um vendedor, a bonificação será gerada em nome dele. Caso
            contrário, a bonificação será gerada para o vendedor que originou o
            pedido.
          </InfoHelper>
        </Stack>
        <ButtonContainer>
          <Button onClick={() => close()}>Cancelar</Button>
          <Button
            isLoading={isLoading}
            variant="contained"
            onClick={handleSave}
          >
            Salvar
          </Button>
        </ButtonContainer>
      </Stack>
    </Popover>
  )
}
