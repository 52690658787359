import { SignalCellularAlt } from '@mui/icons-material'
import {
  LinearProgress,
  List,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
} from '@mui/material'
import { useUserContext } from 'components/Contexts/UserContext'
import EvolucaoVendas from 'components/Indicadores/EvolucaoVendas'
import MetaVendas from 'components/Indicadores/MetaVendas'
import TopClientes from 'components/Indicadores/TopClientes'
import TopVendedores from 'components/Indicadores/TopVendedores'
import TotalPedidos from 'components/Indicadores/TotalPedidos'
import Surface from 'components/Surface'
import useIndicador from 'hooks/queries/useIndicador'
import { ReactNode, useMemo } from 'react'

export const indicadoresMap: Record<
  string,
  {
    nome: string
    componente: ReactNode
    segmento: string
  }
> = {
  VENDAS: {
    nome: 'Vendas',
    componente: <TotalPedidos />,
    segmento: 'Negociações',
    // icon: <ShoppingBasket />,
  },
  EVOLUCAO_VENDAS: {
    nome: 'Evolução de Vendas',
    componente: <EvolucaoVendas />,
    segmento: 'Negociações',
    // icon: <ShoppingBasket />,
  },
  META_VENDAS: {
    nome: 'Meta de Vendas',
    componente: <MetaVendas />,
    segmento: 'Negociações',
  },
  TOP_CLIENTES: {
    nome: 'Top 10 Clientes',
    componente: <TopClientes />,
    segmento: 'Negociações',
  },
  TOP_VENDEDORES: {
    nome: 'Top 10 Vendedores',
    componente: <TopVendedores />,
    segmento: 'Negociações',
  },
}

interface IndicadoresProps {
  painelUuid: string
  close: () => void
  isOpen: boolean
  anchorEl: HTMLElement | null
  shouldCloseOnCreate?: boolean
}

export default function IndicadoresPopover(props: IndicadoresProps) {
  const { painelUuid, close, isOpen, anchorEl, shouldCloseOnCreate } = props

  const { useCreateIndicador, useQueryObterIndicadores } = useIndicador()
  const { mutateAsync: createIndicador } = useCreateIndicador()
  const { data: indicadores, isLoading: isLoadingIndicadores } =
    useQueryObterIndicadores(painelUuid)

  const { temPermissao } = useUserContext()

  const isVendedor = temPermissao('negociacoes.read') === 'pessoal'

  const indicadoresFiltradosPorSegmento = useMemo(() => {
    const nomeIndicadores = indicadores?.map((i) => i.nome)
    const indicadoresRestantes = Object.keys(indicadoresMap).filter(
      (key) =>
        !nomeIndicadores?.includes(key) &&
        (!isVendedor || key !== 'TOP_VENDEDORES'),
    )
    const agrupados: Record<string, any[]> = {}

    indicadoresRestantes.forEach((key) => {
      const segmento = indicadoresMap[key].segmento
      if (!agrupados[segmento]) {
        agrupados[segmento] = []
      }
      agrupados[segmento].push(key)
    })

    return agrupados
  }, [indicadores, isVendedor])

  async function handleCriarIndicador(indicador: string) {
    if (painelUuid && indicador) {
      await createIndicador({
        nome: indicador || '',
        painelUuid,
      })
      if (shouldCloseOnCreate) close()
    }
  }

  if (isLoadingIndicadores) return <LinearProgress />

  return (
    <Popover
      open={isOpen}
      anchorEl={anchorEl}
      anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      onClose={close}
    >
      <Surface elevation={3} sx={{ width: 240, p: 1 }}>
        {Object.entries(indicadoresFiltradosPorSegmento).length > 0 ? (
          <List>
            {Object.entries(indicadoresFiltradosPorSegmento).map(
              ([segmento, indicadoresKeys]) => (
                <div key={segmento}>
                  <Typography
                    sx={{
                      p: 1,
                      fontWeight: 'bold',
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                    }}
                  >
                    {segmento}
                  </Typography>
                  {indicadoresKeys.map((key) => (
                    <MenuItem
                      key={key}
                      onClick={() => handleCriarIndicador(key)}
                      sx={{
                        pl: 3,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                      }}
                    >
                      <SignalCellularAlt />
                      <ListItemText primary={indicadoresMap[key].nome} />
                    </MenuItem>
                  ))}
                </div>
              ),
            )}
          </List>
        ) : (
          <Typography sx={{ p: 2, textAlign: 'center' }}>
            Nenhum indicador disponível
          </Typography>
        )}
      </Surface>
    </Popover>
  )
}
