import { useState } from 'react'

export default function useForm<T>(data: T) {
  const [_data, setData] = useState<T>(data)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const type = e.target.type
    const name = e.target.name

    let value = type === 'text' ? e.target.value : e.target.checked

    switch (type) {
      case 'text':
        value = e.target.value
        break
      case 'checkbox':
        value = e.target.checked
        break
      default:
        value = e.target.value
    }

    setData((oldState) => ({ ...oldState, [name]: value }))
  }

  const changeValue = (name: string, value: any) => {
    setData((oldState) => ({ ...oldState, [name]: value }))
  }

  return { data: _data, setData, handleChange, changeValue }
}
