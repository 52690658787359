import { ButtonContainer } from '@data-c/ui'

import Table from './components/Table'
import Form from './components/Form'
import Filter from './components/Filter'
import { FilterProvider } from '@data-c/providers'
import { filtersItens } from 'hooks/queries/useTabelaPreco'
import Surface from 'components/Surface'

interface TabPrecosProps {
  tabelaPrecoUuid: string
}

export default function TabPrecos(props: TabPrecosProps) {
  const { tabelaPrecoUuid } = props

  return (
    <FilterProvider filterValues={filtersItens}>
      <ButtonContainer sx={{ mb: 2 }}>
        <Filter />
        <Form tabelaPrecoUuid={tabelaPrecoUuid} />
      </ButtonContainer>
      <Surface>
        <Table tabelaPrecoUuid={tabelaPrecoUuid} />
      </Surface>
    </FilterProvider>
  )
}
