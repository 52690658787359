import { SyntheticEvent, useState } from 'react'

import { FilterProvider, FormProvider } from '@data-c/providers'
import { ButtonContainer, PageTitle } from '@data-c/ui'

import { Container, Tab, Tabs } from '@mui/material'

import useVendedores, {
  VendedorFilters1,
  filters,
} from 'hooks/queries/useVendedores'

import Delete from 'components/Delete'
import TabPanel from 'components/TabPanel'

import Table from './components/Table'
import Filter from './components/Filter'
import Form from './components/Form'
import TabChavesDeAcesso from './components/TabChavesDeAcesso'
import Content from 'components/Layouts/Content'
import HelpButton from 'components/HelpButton'

export default function Vendedores() {
  const [appliedFilters, setFilters] = useState<VendedorFilters1>(
    {} as VendedorFilters1,
  )
  const [value, setValue] = useState(0)
  function handleChange(_: SyntheticEvent, newValue: number) {
    setValue(newValue)
  }

  const { useDelete } = useVendedores()
  const { mutateAsync: handleDelete } = useDelete()

  return (
    <Container>
      <FormProvider initialFormValues={{ ativo: true }}>
        <PageTitle title="Vendedores">
          <ButtonContainer>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="tabs vendedores"
            >
              <Tab label="Cadastro" value={0} />
              <Tab label="Chaves de Acesso" value={1} />
            </Tabs>
            <HelpButton link="https://datacsistemashelp.zendesk.com/hc/pt-br/articles/28565697149460-Cadastro-de-Vendedores" />
          </ButtonContainer>
        </PageTitle>

        <Content>
          <TabPanel value={value} index={0}>
            <ButtonContainer sx={{ mb: 2 }}>
              <FilterProvider filterValues={filters}>
                <Filter
                  onApplyFilters={(_appliedFilters) => {
                    setFilters(_appliedFilters)
                  }}
                />
              </FilterProvider>
              <Delete onDelete={handleDelete} />
              <Form view="dialog" />
            </ButtonContainer>

            <Table filters={appliedFilters} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <TabChavesDeAcesso />
          </TabPanel>
        </Content>
      </FormProvider>
    </Container>
  )
}
