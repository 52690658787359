import { ItemPedidoModel } from 'hooks/queries/usePedidos'
// import { useState } from 'react'

export function calcularItemPedido(
  item: ItemPedidoModel,
  tipoCalculo:
    | 'precoUnitarioLiquido'
    | 'precoUnitarioIpi'
    | 'default' = 'default',
  // calcularImpostos: Boolean = false,
): ItemPedidoModel {
  item.precoTotalBruto = item.quantidade * item.precoDeTabela
  /**
   * Se a alteração é o preço unitário líquido, encontra o percentual do desconto
   * Senão calcula o preço unitário líquido utilizando o percentual do desconto
   */
  if (tipoCalculo === 'precoUnitarioLiquido') {
    let auxPercentualDesconto =
      100 - (item.precoUnitarioLiquido / item.precoDeTabela) * 100
    item.percentualDesconto = auxPercentualDesconto
  } else {
    if (item.percentualDesconto !== 0) {
      let auxValorDesconto =
        (item.precoDeTabela * item.percentualDesconto) / 100
      item.precoUnitarioLiquido = item.precoDeTabela - auxValorDesconto
    }
  }

  /**
   * Se alterou o percentual do IPI deve achar o novo valor do IPI
   * Senão deve achar o percentual do
   */
  if (tipoCalculo === 'precoUnitarioIpi') {
    let auxPercentualIpi =
      (item.precoUnitarioIpi / item.precoUnitarioLiquido) * 100
    item.percentualIpi = auxPercentualIpi
  } else {
    let auxValorIpi = (item.precoUnitarioLiquido * item.percentualIpi) / 100
    item.precoUnitarioIpi =
      item.precoUnitarioLiquido - (item.precoUnitarioLiquido - auxValorIpi)
  }

  item.precoTotalIpi = item.quantidade * item.precoUnitarioIpi
  item.precoTotalLiquido = item.quantidade * item.precoUnitarioLiquido
  item.precoTotalComImposto = item.precoTotalLiquido + item.precoTotalIpi

  return item
}

export function useCalcularItemPedido(
  item: ItemPedidoModel,
  tipoCalculo:
    | 'precoUnitarioLiquido'
    | 'precoUnitarioIpi'
    | 'default' = 'default',
): ItemPedidoModel {
  return calcularItemPedido(item, tipoCalculo)
}
