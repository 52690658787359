import { Container } from '@mui/material'

import { FilterProvider, FormProvider } from '@data-c/providers'
import { ButtonContainer, PageTitle } from '@data-c/ui'

import useTabelaPreco from 'hooks/queries/useTabelaPreco'

import Delete from 'components/Delete'
import Form from './components/Form'
import Table from './components/Table'
import Filter from './components/Filter'
import { filters } from 'hooks/queries/useTabelaPreco'
import Content from 'components/Layouts/Content'

export default function TabelasPrecos() {
  const { useDelete } = useTabelaPreco()
  const { mutateAsync: handleDelete } = useDelete()

  return (
    <Container>
      <FormProvider initialFormValues={{ ativo: true }}>
        <FilterProvider filterValues={filters}>
          <PageTitle title="Tabela de Preços">
            <ButtonContainer>
              <Filter />
              <Form />
            </ButtonContainer>
          </PageTitle>
          <Content>
            <Table />
          </Content>
        </FilterProvider>
        <Delete onDelete={handleDelete} />
      </FormProvider>
    </Container>
  )
}
