import {
  Box,
  Divider,
  Typography,
  MenuItem,
  Popover,
  Stack,
  Avatar,
  useTheme,
  Button,
  // useTheme,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import {
  ArrowDropDown,
  Logout,
  VpnKey,
  Badge,
  Check,
  HelpOutline,
} from '@mui/icons-material'

import usePopOver from 'hooks/usePopover'
import { Empresa, useCredentials, useDialog } from '@data-c/hooks'
import credentialsConfig from 'configs/credentials'
import { useUserContext } from 'components/Contexts/UserContext'
import useNotification from 'hooks/useNotifications'
import { AlterarSenha } from '@data-c/ui'
import React, { useMemo } from 'react'
import Surface from 'components/Surface'
import { useQueryClient } from '@tanstack/react-query'
import { formatToCPFOrCNPJ } from 'brazilian-values'

export default function AccountPopover() {
  const notification = useNotification()
  const queryClient = useQueryClient()
  const theme = useTheme()
  const navigate = useNavigate()
  const { user } = useUserContext()
  const { logout, userLogged, selecionarLicenca, token } =
    useCredentials(credentialsConfig)

  const {
    open: openMenu,
    close: closeMenu,
    isOpen: isOpenMenu,
    anchorEl,
  } = usePopOver()

  const {
    isOpen: isOpenAlterarSenha,
    openDialog: openAlterarSenha,
    closeDialog: closeAlterarSenha,
  } = useDialog()

  const empresas = useMemo(() => {
    if (userLogged) {
      return userLogged.permissoes['app-fdv']?.empresas || []
    }
    return []
  }, [userLogged])

  function handleOpen(event: any) {
    openMenu(event)
  }

  function handleLogout() {
    logout()
    window.location.href = '/'
    // dispatch({ type: 'USER_LOGOUT' })
  }

  function handleOpenAlterarSenha() {
    openAlterarSenha()
  }

  async function handleAlterarLicenca(e: Empresa) {
    try {
      await selecionarLicenca(token || undefined, e.uuid)
      queryClient.clear()
      queryClient.invalidateQueries()
      window.location.reload()
    } catch (err) {}
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', alginItems: 'center' }}>
        <Button
          variant="text"
          sx={{
            // p: 0,
            color: (theme) => theme.palette.grey[500],
            // fontSize: '11pt',
          }}
          onClick={handleOpen}
        >
          {userLogged
            ? `${userLogged.empresa.nome} - ${userLogged.name}`
            : 'Usuário do Sistema'}
          <ArrowDropDown />
        </Button>
      </Box>

      <Popover
        open={isOpenMenu}
        onClose={closeMenu}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        sx={{
          '& .MuiPopover-paper': {
            // background: theme.palette.grey[100],
          },
        }}
      >
        <Stack
          sx={{ m: 2, minWidth: '300px' }}
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          <Avatar
            alt="photoURL"
            sx={{
              width: '64px',
              height: '64px',
              border: `solid 2px ${
                user?.isadmin ? theme.palette.grey[100] : 'transparent'
              }`,
            }}
          />
          <Stack justifyContent="center" alignItems="center" spacing={0}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
              Olá, {userLogged ? `${userLogged.name}` : 'Usuário do Sistema'}!
            </Typography>
            <Typography variant="body2">
              {userLogged ? userLogged.email : '---'}
            </Typography>
          </Stack>

          <Stack justifyContent="center" alignItems="center" spacing={0}>
            <Typography variant="body1">
              {userLogged ? userLogged.empresa.nome : '---'}
            </Typography>
            <Typography variant="body2">
              {formatToCPFOrCNPJ(userLogged?.empresa?.documento || '')}
            </Typography>
          </Stack>
        </Stack>

        {empresas.length > 0 && (
          <React.Fragment>
            <Divider />
            <Surface
              sx={{ m: 1, maxHeight: '300px', overflow: 'auto' }}
              elevation={0}
            >
              {empresas.map((e) => {
                return (
                  <MenuItem onClick={() => handleAlterarLicenca(e)}>
                    <Stack direction="row" gap={1} alignItems="center">
                      {userLogged?.empresaSelecionada === e.uuid && <Check />}
                      <Stack>
                        <Typography variant="body2">
                          {formatToCPFOrCNPJ(e.documento)}
                        </Typography>
                        <Typography variant="body2" color="#000">
                          {e.nome}
                        </Typography>
                      </Stack>
                    </Stack>
                  </MenuItem>
                )
              })}
            </Surface>
          </React.Fragment>
        )}

        <Divider />

        <MenuItem
          onClick={(e) => {
            e.preventDefault()
            window.open(
              'https://datacsistemashelp.zendesk.com/hc/pt-br/categories/360001609791-For%C3%A7a-de-Vendas',
              '_blank',
              'noreferrer',
            )
          }}
          sx={{ m: 1 }}
        >
          <Stack direction="row" gap={1}>
            <HelpOutline />
            Manual do Sistema
          </Stack>
        </MenuItem>

        <MenuItem onClick={handleOpenAlterarSenha} sx={{ m: 1 }}>
          <Stack direction="row" gap={1}>
            <VpnKey />
            Alterar Senha
          </Stack>
        </MenuItem>

        {user?.isadmin && (
          <MenuItem onClick={() => navigate('/contas')} sx={{ m: 1 }}>
            <Stack direction="row" gap={1}>
              <Badge />
              Usuários do Sistema
            </Stack>
          </MenuItem>
        )}
        <Divider />
        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          <Stack direction="row" gap={1}>
            <Logout />
            Sair
          </Stack>
        </MenuItem>
      </Popover>

      <AlterarSenha
        credentialsConfig={credentialsConfig}
        isOpen={isOpenAlterarSenha}
        onClose={closeAlterarSenha}
        onFailed={(err: any) => {
          notification.notifyException(err)
        }}
        onSuccess={() => {
          notification.notifySuccess('Sua senha foi alterada com sucesso')
          closeAlterarSenha()
        }}
      />

      {/* <AlterarFotoPerfil
        isOpen={isOpenAlterarFotoPerfil}
        anchorEl={anchorEl}
        onClose={closeAlterarFotoPerfil}
      /> */}
    </Box>
  )
}
