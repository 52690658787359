import { ReactNode } from 'react'
import { Box, BoxProps } from '@mui/material'

interface TabPanelProps extends BoxProps {
  children?: ReactNode
  index: number
  value: number
}

export default function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...others } = props
  if (value !== index) return null
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
      }}
      {...others}
    >
      {value === index && children}
    </Box>
  )
}
