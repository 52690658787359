import { Container } from '@mui/material'

import { FormProvider } from '@data-c/providers'
import { ButtonContainer, PageTitle } from '@data-c/ui'

import useFatorPrecificacao from 'hooks/queries/useFatorPrecificacao'

import Delete from 'components/Delete'
import Table from './components/Table'
import Form from './components/Form'
import Content from 'components/Layouts/Content'

export default function FatorPrecificacao() {
  const { useDelete } = useFatorPrecificacao()
  const { mutateAsync: handleDelete } = useDelete()

  return (
    <Container>
      <FormProvider>
        <PageTitle title="Fator de Precificação">
          <ButtonContainer>
            <Form />
          </ButtonContainer>
        </PageTitle>
        <Content>
          <Table />
        </Content>
        <Delete onDelete={handleDelete} />
      </FormProvider>
    </Container>
  )
}
