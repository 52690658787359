import api from 'services/api'
import BaseModel from 'interfaces/BaseModel'
import { TimeVendasModel } from './useTimeVendas'
import useNotification from 'hooks/useNotifications'
import {
  useMutation,
  useQueryClient,
  useQuery as useTQuery,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'
import ErrorInterface from 'interfaces/ErrorInterface'

export interface AtividadeComentarios extends BaseModel {
  comentario: string
  autorUuid?: string
  autor?: TimeVendasModel
  atividadeUuid?: string
  pedidoUuid?: string
  dataAsRelative?: string
  comentarioAntigo?: Array<{
    updateAt: string
    comentario: string
  }>
}

export interface ObterComentarioQueryParams {
  pedidoUuid?: string
  atividadeUuid?: string
}

export async function adicionarComentario(
  data: AtividadeComentarios,
): Promise<AtividadeComentarios> {
  const response = await api.post<AtividadeComentarios>(`/comentarios`, data)
  return response.data
}

export async function updateComentario(
  data: AtividadeComentarios,
): Promise<AtividadeComentarios> {
  const response = await api.put(`/comentarios/${data?.uuid}`, data)

  return response.data
}

export async function deletarComentario(
  data: AtividadeComentarios,
): Promise<AtividadeComentarios> {
  await api.delete(`/comentarios/${data?.uuid}`)
  return data
}

async function obterComentarios(params: ObterComentarioQueryParams) {
  const response = await api.get(`/comentarios`, { params })
  return response.data || []
}

async function updateComentarioLidoEm(
  params: ObterComentarioQueryParams,
): Promise<any> {
  const response = await api.put(`/comentarios/lidoem`, params)

  return response.data
}

export default function useComentarios() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  function useAdicionarComentario() {
    return useMutation<AtividadeComentarios, AxiosError, AtividadeComentarios>(
      (data) => adicionarComentario(data),
      {
        onSuccess(_: AtividadeComentarios) {
          queryClient.invalidateQueries(['COMENTARIOS'])
        },
        onError(error) {
          notifications.notifyException(error)
        },
      },
    )
  }

  function useObterComentarios(params: ObterComentarioQueryParams) {
    return useTQuery<AtividadeComentarios[], AxiosError<ErrorInterface>>(
      ['COMENTARIOS', params],
      () => {
        return obterComentarios(params)
      },
    )
  }

  function useUpdateComentario() {
    const notifications = useNotification()
    const queryClient = useQueryClient()
    return useMutation<AtividadeComentarios, AxiosError, AtividadeComentarios>(
      (data: AtividadeComentarios) => updateComentario(data),
      {
        onSuccess(_: AtividadeComentarios) {
          queryClient.invalidateQueries(['COMENTARIOS'])
        },
        onError(error) {
          notifications.notifyException(error)
        },
      },
    )
  }

  function useDeletarComentario() {
    const notifications = useNotification()
    const queryClient = useQueryClient()
    return useMutation<AtividadeComentarios, AxiosError, AtividadeComentarios>(
      (data: AtividadeComentarios) => deletarComentario(data),
      {
        onSuccess(_: AtividadeComentarios) {
          queryClient.invalidateQueries(['COMENTARIOS'])
        },
        onError(error) {
          notifications.notifyException(error)
        },
      },
    )
  }

  function useUpdateComentarioLidoEm() {
    // const queryClient = useQueryClient()
    const notifications = useNotification()

    return useMutation<any, AxiosError, ObterComentarioQueryParams>(
      (params: ObterComentarioQueryParams) => updateComentarioLidoEm(params),
      {
        onSuccess(_: any) {
          // queryClient.invalidateQueries(['ATIVIDADE'])
        },
        onError(error) {
          notifications.notifyException(error)
        },
      },
    )
  }

  return {
    useAdicionarComentario,
    useObterComentarios,
    useUpdateComentario,
    useDeletarComentario,
    useUpdateComentarioLidoEm,
  }
}
