import {
  Box,
  LinearProgressProps,
  LinearProgress,
  Typography,
} from '@mui/material'

interface PercentageLinearProgress extends LinearProgressProps {
  progressValue: number
}

export default function PercentageLinearProgress(
  props: PercentageLinearProgress,
) {
  const { progressValue } = props

  function formatPercentage(p: number) {
    if (p > 100) return 100

    return parseFloat(p ? p.toFixed(2) : '0')
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <LinearProgress
        sx={{ flex: 1, height: '10px', borderRadius: '50px' }}
        variant="determinate"
        value={formatPercentage(progressValue)}
        {...props}
      />

      <Box
        sx={{
          width: '55px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography sx={{ width: '100%' }} variant="caption">
          {`${progressValue ? formatPercentage(progressValue) + '%' : '---'}`}
        </Typography>
      </Box>
    </Box>
  )
}
