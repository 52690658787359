import { useEffect, useMemo, useState } from 'react'

import { Box, Grid } from '@mui/material'
import { MUIDataTableColumnDef } from 'mui-datatables'

import { DataTable, Flag, FormContainer } from '@data-c/ui'
import { usePagination } from '@data-c/hooks'
import { useFormApi, useFormData } from '@data-c/providers'

import SearchTextField from 'components/SearchTextField'
import AutocompleteClasseProdutos from 'components/AutoCompleteClasseProdutos'
import AutoCompleteFamiliaProdutos from 'components/AutoCompleteFamiliaProdutos'
import AutoCompleteGrupoProdutos from 'components/AutoCompleteGrupoProdutos'
import AutoCompleteLinhaProdutos from 'components/AutoCompleteLinhaProdutos'

import useProdutos, { ProdutoModel } from 'hooks/queries/useProdutos'
import { ClasseProdutosModel } from 'hooks/queries/useClasseProdutos'
import { FamiliaProdutosModel } from 'hooks/queries/useFamiliaProdutos'
import { GrupoProdutosModel } from 'hooks/queries/useGrupoProdutos'
import { LinhaProdutosModel } from 'hooks/queries/useLinhaProdutos'
import {
  MixProdutosModel,
  useVincularProduto,
} from 'hooks/queries/useMixProdutos'

function temProdutosSelecionados(
  indexProdutosSelecionados: Array<string | undefined>,
): boolean {
  return indexProdutosSelecionados.length > 0
}

interface FormVincularProdutosProps {
  mixProduto: MixProdutosModel
}

const defaultFilter = {
  field: '',
  value: '',
}

export default function FormVincularProdutos(props: FormVincularProdutosProps) {
  const { mixProduto } = props

  const [query, setQuery] = useState('')
  const [filter, setFilter] = useState(defaultFilter)
  const [produtosNaoVinculados, setProdutosNaoVinculados] = useState<
    ProdutoModel[]
  >([])
  const [indexProdutosSelecionados, setIndexProdutosSelecionados] = useState<
    number[]
  >([])

  const params = {
    nome: query,
    [filter.field]: filter.value,
  }

  const { isOpen } = useFormData()
  const { toggleSubmit, closeForm } = useFormApi()
  const { mutateAsync: vincularProdutos } = useVincularProduto()
  const { pagination, changePageSize, changePage } = usePagination()
  const { useQuery } = useProdutos()
  const {
    data: produtos,
    isLoading,
    isFetching,
    error,
  } = useQuery({
    queryParams: params,
    pagination,
  })

  useEffect(() => {
    if (isOpen === false) {
      setIndexProdutosSelecionados([])
      setFilter(defaultFilter)
    }

    const produtosVinculadosIds = mixProduto?.produtos?.map(
      (produto) => produto.uuid,
    )
    const _produtosNãoVinculados =
      produtos?.data?.filter(
        (produto) => !produtosVinculadosIds?.includes(produto.uuid),
      ) || []

    setProdutosNaoVinculados(_produtosNãoVinculados)
  }, [produtos, mixProduto, isOpen])

  const formattedData = produtosNaoVinculados.map((item) => {
    return {
      ...item,
      produtocls: item?.produtoClasse?.nome || '',
      produtofml: item?.familiaProduto?.nome || '',
      produtolnh: item?.linhaProduto?.nome || '',
      produtogrp: item?.grupoProduto?.nome || '',
    }
  })

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'codigo',
        label: 'Código',
        options: {
          setCellProps: () => ({
            style: {
              width: '96px',
            },
          }),
        },
      },
      {
        name: 'nome',
        label: 'Nome',
      },
      {
        name: 'produtolnh',
        label: 'Linha',
      },
      {
        name: 'produtogrp',
        label: 'Grupo',
      },
      {
        name: 'produtofml',
        label: 'Família',
      },
      {
        name: 'produtocls',
        label: 'Classe',
      },
      {
        name: 'ativo',
        label: 'Ativo',
        options: {
          setCellProps: () => ({
            style: {
              width: '60px',
              textAlign: 'center',
            },
          }),
          customBodyRender: (value) => {
            return <Flag isFlagged={value === 1} type="success" />
          },
        },
      },
    ],
    [],
  )

  function handleSearch(_query: string) {
    setQuery(_query)
  }

  async function handleClickVincularProdutos() {
    const produtosSelecionadosIds =
      indexProdutosSelecionados.map(
        (index) => produtosNaoVinculados[index].uuid,
      ) || []

    if (mixProduto.uuid && temProdutosSelecionados(produtosSelecionadosIds)) {
      toggleSubmit(true)
      try {
        await vincularProdutos({
          uuid: mixProduto.uuid,
          produtosSelecionadosIds,
        })
        closeForm()
      } finally {
        toggleSubmit(false)
      }
    }
  }

  return (
    <FormContainer
      onSubmitForm={handleClickVincularProdutos}
      confirmButtonLabel="Salvar"
      triggerButtonLabel="Vincular"
      dialogProps={{ title: 'Vincular Produtos', maxWidth: 'lg' }}
    >
      <Grid container spacing={1}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <SearchTextField
            placeholder="Digite o código ou nome do produto para pesquisar"
            onSearch={handleSearch}
          />
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
          <AutoCompleteLinhaProdutos
            onChange={(_, value: LinhaProdutosModel | null) => {
              const produtolnh = value?.uuid || ''
              setFilter({ field: 'produtolnh', value: produtolnh })
            }}
          />
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
          <AutoCompleteGrupoProdutos
            onChange={(_, value: GrupoProdutosModel | null) => {
              const produtogrp = value?.uuid || ''
              setFilter({ field: 'produtogrp', value: produtogrp })
            }}
          />
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
          <AutoCompleteFamiliaProdutos
            onChange={(_, value: FamiliaProdutosModel | null) => {
              const produtofml = value?.uuid || ''
              setFilter({ field: 'produtofml', value: produtofml })
            }}
          />
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
          <AutocompleteClasseProdutos
            onChange={(_, value: ClasseProdutosModel | null) => {
              const produtocls = value?.uuid || ''
              setFilter({ field: 'produtocls', value: produtocls })
            }}
          />
        </Grid>
      </Grid>
      <Box sx={{ mt: 1, position: 'relative' }}>
        <DataTable
          error={error?.message}
          columns={columns}
          data={formattedData || []}
          pagination={produtos?.pagination}
          isLoading={isLoading}
          isFetching={isFetching}
          changePage={changePage}
          changePageSize={changePageSize}
          options={{
            rowsPerPageOptions: [15, 50, 100],
            rowsPerPage: 15,
            selectableRows: 'multiple',
            selectableRowsOnClick: true,
            rowsSelected: indexProdutosSelecionados,
            onRowSelectionChange: (_, __, rowsSelecteds: any) =>
              setIndexProdutosSelecionados(rowsSelecteds),
            selectToolbarPlacement: 'none',
          }}
        />
      </Box>
    </FormContainer>
  )
}
