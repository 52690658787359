import api from 'services/mio-auth'
import {
  useMutation,
  useQueryClient,
  useQuery as useTQuery,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'
import useNotification from 'hooks/useNotifications'
import { UsuarioModel as FdvUsuarioModel } from 'hooks/queries/useUsuario'
export interface Configs {
  fdv_empresa_id: number
  fdv_vendedor_uuid: string
  fdv_vendedor_codigo: string
}

export interface UsuarioModel {
  email: string | null
  name: string | null
  uuid: string
  documento?: string | null
  permissao: {
    configs: Configs | null
    uuid?: string
  }
  usuarioFdv?: FdvUsuarioModel
}

interface QueryParamsInterface {
  plainQuery?: string
}

async function obterUsuarios(
  empresaUuid: string,
  params: QueryParamsInterface,
  appId: string = 'app-fdv',
) {
  const { plainQuery } = params
  const response = await api.get(
    `api/assinatura/${appId}/${empresaUuid}/usuarios`,
    {
      params: plainQuery,
    },
  )
  return response.data
}

export function useObterUsuarios(
  empresaUuid: string,
  queryParams: QueryParamsInterface,
  appId: string = 'app-fdv',
) {
  return useTQuery<Array<UsuarioModel>, AxiosError>(
    ['MIO-USUARIOS', queryParams],
    () => {
      return obterUsuarios(empresaUuid, queryParams, appId)
    },
  )
}

async function cadastrarUsuario(
  empresaUuid: string,
  data: UsuarioModel,
  appId: string = 'app-fdv',
) {
  const response = await api.post(
    `api/assinatura/${appId}/${empresaUuid}/usuario`,
    data,
  )
  return response.data
}

interface CadastrarUsuarioInputProps {
  usuario: UsuarioModel
  empresaUuid: string
  appId?: string
}
export function useCadastrarUsuario() {
  const queryClient = useQueryClient()
  return useMutation<UsuarioModel, AxiosError, CadastrarUsuarioInputProps>(
    (data) => cadastrarUsuario(data.empresaUuid, data.usuario, data.appId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['MIO-USUARIOS'])
      },
      onError: () => {},
    },
  )
}

async function vincularUsuario(
  empresaUuid: string,
  data: UsuarioModel,
  appId: string = 'app-fdv',
) {
  const response = await api.post(
    `api/assinatura/${appId}/${empresaUuid}/vincular`,
    data,
  )
  return response.data
}

async function desvincularUsuario(
  empresaUuid: string,
  data: UsuarioModel,
  appId: string = 'app-fdv',
) {
  const response = await api.post(
    `api/assinatura/${appId}/${empresaUuid}/desvincular`,
    data,
  )
  return response.data
}

export function useVincularUsuario() {
  const queryClient = useQueryClient()
  return useMutation<UsuarioModel, AxiosError, CadastrarUsuarioInputProps>(
    (data) => vincularUsuario(data.empresaUuid, data.usuario),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['MIO-USUARIOS'])
      },
      onError: () => {},
    },
  )
}

export function useDesvincularUsuario() {
  const queryClient = useQueryClient()
  const notifications = useNotification()
  return useMutation<any, AxiosError, CadastrarUsuarioInputProps>(
    (data) => desvincularUsuario(data.empresaUuid, data.usuario),
    {
      onSuccess: () => {
        notifications.notifySuccess('Permissão do usuário excluída com sucesso')
        queryClient.invalidateQueries(['MIO-USUARIOS'])
      },
      onError: (e) => {
        notifications.notifyException(e)
      },
    },
  )
}
