import { useState } from 'react'

import { FilterProvider, FormProvider } from '@data-c/providers'
import { Button, ButtonContainer, Dialog } from '@data-c/ui'
import { useDialog } from '@data-c/hooks'

import useChavesDeAcesso, {
  ChavesDeAcessoFilters1,
  filters,
} from 'hooks/queries/useChavesDeAcesso'

import Delete from 'components/Delete'

import Filter from './components/Filter'
import Table from './components/Table'
import TableVendedoresSemChave from './components/TableVendedoresSemChave'
import Surface from 'components/Surface'

export default function TabChavesDeAcesso() {
  const [appliedFilters, setFilters] = useState<ChavesDeAcessoFilters1>(
    {} as ChavesDeAcessoFilters1,
  )

  const { useDelete } = useChavesDeAcesso()
  const { mutateAsync: handleDelete } = useDelete()

  const {
    isOpen: isOpenChaveDeAcesso,
    openDialog: openChaveDeAcesso,
    closeDialog: closeChaveDeAcesso,
  } = useDialog()

  return (
    <>
      <FormProvider>
        <ButtonContainer sx={{ mb: 2 }}>
          <FilterProvider filterValues={filters}>
            <Filter
              onApplyFilters={(_appliedFilters) => {
                setFilters(_appliedFilters)
              }}
            />
          </FilterProvider>
          <Button variant="contained" onClick={openChaveDeAcesso}>
            Adicionar
          </Button>
        </ButtonContainer>

        {/* <Alert sx={{ mb: 2 }} severity="warning">
          <AlertTitle>ATENÇÃO - FUNCIONALIDADE OBSOLETA</AlertTitle>O USO DA
          CHAVE DE ACESSO SERÁ DESCONTINUADO EM <strong>01/03/2023</strong>{' '}
          <Link
            target="_blank"
            rel="noopener"
            href="https://datac-wiki.notion.site/e6588820fa6d42e7a0368c9c838ec6dc"
            variant="body2"
            underline="hover"
          >
            Clique para saber mais
          </Link>
        </Alert> */}

        <Surface>
          <Table filters={appliedFilters} />
        </Surface>
        <Delete onDelete={handleDelete} />
      </FormProvider>

      <Dialog open={isOpenChaveDeAcesso} onClose={closeChaveDeAcesso} actions>
        <TableVendedoresSemChave onClose={closeChaveDeAcesso} />
      </Dialog>
    </>
  )
}
