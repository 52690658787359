import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import useCrud, { generateEntityKey } from 'hooks/useCrud'
import api from 'services/api'
import { ProdutoModel } from './useProdutos'
import { AxiosError } from 'axios'
import useNotification from 'hooks/useNotifications'
import { HttpResponseInterface } from '@data-c/hooks'
import { TimeVendasModel } from './useTimeVendas'

const entityName = 'Mix de Produtos'

export interface MixProdutosModel {
  uuid: string
  codigo?: string
  nome: string
  ativo?: boolean
  produtos?: ProdutoModel[]
}

export interface MixProdutoDescontoModel {
  uuid?: string
  promomixUuid: string
  quantidadeMinima: number
  pdesconto: number
  preco: number
}

export interface MixProdutoBonificacaoModel {
  uuid?: string
  mixProdutoUuid: string
  quantidadeMinima: number
  representante?: TimeVendasModel | null
  representanteUuid?: string | null
  bonificacoes?: Array<{
    nome: string
    codigo: string
    quantidadeBonificacao: number
  }>
}

export interface ProdutoBonificacaoModel {
  produto?: ProdutoModel | null
  produtoUuid?: string | null
  quantidade?: number
  regraUuid?: string
}

export async function vincularProdutos(
  uuid: string,
  produtosSelecionadosIds: Array<string>,
): Promise<MixProdutosModel> {
  const response = await api.put(
    `/administrador/mix-produtos/${uuid}/vincular-produtos`,
    produtosSelecionadosIds,
    { params: { uuid } },
  )
  return response.data
}

export async function desvincularProdutos(
  uuid: string,
  produtosSelecionadosIds: Array<string>,
): Promise<MixProdutosModel> {
  const response = await api.put(
    `/administrador/mix-produtos/${uuid}/desvincular-produtos`,
    produtosSelecionadosIds,
    { params: { uuid } },
  )

  return response.data
}

interface VincularProdutosInputProps {
  uuid: string
  produtosSelecionadosIds: Array<string>
}

export function useVincularProduto() {
  const notifications = useNotification()
  const queryClient = useQueryClient()
  return useMutation<MixProdutosModel, AxiosError, VincularProdutosInputProps>(
    (data: VincularProdutosInputProps) =>
      vincularProdutos(data.uuid, data.produtosSelecionadosIds),
    {
      onSuccess(_: MixProdutosModel) {
        notifications.notifySuccess('Produtos vinculados com sucesso')
        const entityKey = generateEntityKey(entityName)
        queryClient.invalidateQueries([entityKey])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export function useDesvincularProduto() {
  const notifications = useNotification()
  const queryClient = useQueryClient()
  return useMutation<MixProdutosModel, AxiosError, VincularProdutosInputProps>(
    (data: VincularProdutosInputProps) =>
      desvincularProdutos(data.uuid, data.produtosSelecionadosIds),
    {
      onSuccess(_: MixProdutosModel) {
        notifications.notifySuccess('Produtos desvinculados com sucesso')
        const entityKey = generateEntityKey(entityName)
        queryClient.invalidateQueries([entityKey])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export async function obterDescontos(
  mixProutoUuid: string,
): Promise<HttpResponseInterface<MixProdutoDescontoModel>> {
  const response = await api.get(
    `/administrador/mix-produtos/${mixProutoUuid}/desconto`,
  )

  const { data, meta: pagination } = response.data
  return { data, pagination }
}

export function useQueryObterDescontos(mixProdutoUuid: string) {
  return useQuery<HttpResponseInterface<MixProdutoDescontoModel>, AxiosError>(
    ['MIX_PRODUTO_DESCONTO', mixProdutoUuid],
    () => {
      return obterDescontos(mixProdutoUuid)
    },
  )
}

export async function obterBonificacaoPorId(
  mixProutoUuid: string,
  id: string,
): Promise<MixProdutoBonificacaoModel> {
  const response = await api.get(
    `/administrador/mix-produtos/${mixProutoUuid}/bonificacao/${id}`,
  )
  return response.data
}

export function useQueryObterBonificacaoPorId(
  mixProdutoUuid: string,
  id: string,
) {
  return useQuery<MixProdutoBonificacaoModel, AxiosError>(
    ['BONIFICACAO', mixProdutoUuid, id],
    () => {
      return obterBonificacaoPorId(mixProdutoUuid, id)
    },
  )
}

export async function adicionarDescontos(
  data: MixProdutoDescontoModel,
): Promise<MixProdutoDescontoModel> {
  const response = await api.post(
    `/administrador/mix-produtos/${data?.promomixUuid}/desconto`,
    data,
  )
  return response.data
}

export async function adicionarBonificacao(
  data: MixProdutoBonificacaoModel,
): Promise<MixProdutoBonificacaoModel> {
  const { mixProdutoUuid, ...nData } = data
  const response = await api.post(
    `/administrador/mix-produtos/${mixProdutoUuid}/bonificacao`,
    nData,
  )
  return response.data
}

export async function obterBonificacoes(
  mixProutoUuid: string,
): Promise<HttpResponseInterface<MixProdutoBonificacaoModel>> {
  const response = await api.get(
    `/administrador/mix-produtos/${mixProutoUuid}/bonificacao`,
  )

  const { data, meta: pagination } = response.data
  return { data, pagination }
}

export function useQueryObterBonificacoes(mixProdutoUuid: string) {
  return useQuery<
    HttpResponseInterface<MixProdutoBonificacaoModel>,
    AxiosError
  >(['MIX_PRODUTO_BONIFICACAO', mixProdutoUuid], () => {
    return obterBonificacoes(mixProdutoUuid)
  })
}

export function useAdicionarDescontos() {
  const notifications = useNotification()
  const queryClient = useQueryClient()
  return useMutation<
    MixProdutoDescontoModel,
    AxiosError,
    MixProdutoDescontoModel
  >((data: MixProdutoDescontoModel) => adicionarDescontos(data), {
    onSuccess(_: MixProdutoDescontoModel) {
      notifications.notifySuccess('Desconto incluído com sucesso!')
      queryClient.invalidateQueries(['MIX_PRODUTO_DESCONTO'])
    },
    onError(error) {
      notifications.notifyException(error)
    },
  })
}

export function useAdicionarBonificacao() {
  const notifications = useNotification()
  const queryClient = useQueryClient()
  return useMutation<
    MixProdutoBonificacaoModel,
    AxiosError,
    MixProdutoBonificacaoModel
  >((data: MixProdutoBonificacaoModel) => adicionarBonificacao(data), {
    onSuccess(_: MixProdutoBonificacaoModel) {
      notifications.notifySuccess('Bonificação incluída com sucesso!')
      queryClient.invalidateQueries(['MIX_PRODUTO_BONIFICACAO'])
    },
    onError(error) {
      notifications.notifyException(error)
    },
  })
}

export function useAdicionarProdutoBonificacao() {
  const notifications = useNotification()
  const queryClient = useQueryClient()
  return useMutation<
    ProdutoBonificacaoModel,
    AxiosError,
    ProdutoBonificacaoModel
  >((data: ProdutoBonificacaoModel) => adicionarProdutoBonificao(data), {
    onSuccess(_: ProdutoBonificacaoModel) {
      notifications.notifySuccess(
        'Produto de bonificação incluído com sucesso!',
      )
      queryClient.invalidateQueries(['BONIFICACAO'])
    },
    onError(error) {
      notifications.notifyException(error)
    },
  })
}

export async function adicionarProdutoBonificao(
  data: ProdutoBonificacaoModel,
): Promise<ProdutoBonificacaoModel> {
  const { regraUuid, ...nData } = data
  const response = await api.post(
    `administrador/mix-produtos/bonificacao/${regraUuid}/produto`,
    nData,
  )
  return response.data
}

export async function removerDescontos(
  data: MixProdutoDescontoModel,
): Promise<MixProdutoDescontoModel> {
  await api.delete(`administrador/mix-produtos/desconto/${data?.uuid}`)
  return data
}

export function useRemoverDescontos(tipo: 'DESCONTO' | 'BONIFICACAO') {
  const notifications = useNotification()
  const queryClient = useQueryClient()
  return useMutation<any, AxiosError, MixProdutoDescontoModel>(
    (data: MixProdutoDescontoModel) => removerDescontos(data),
    {
      onSuccess(_: MixProdutoDescontoModel) {
        const key =
          tipo === 'DESCONTO'
            ? 'MIX_PRODUTO_DESCONTO'
            : 'MIX_PRODUTO_BONIFICACAO'
        const word =
          tipo === 'DESCONTO' ? 'Desconto excluído' : 'Bonificação excluída'
        notifications.notifySuccess(`${word} com sucesso!`)
        queryClient.invalidateQueries([key])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export async function removerProdutoBonificacao(
  regraUuid: string,
  produtoUuid: string,
): Promise<void> {
  await api.delete(
    `/administrador/mix-produtos/bonificacao/${regraUuid}/produto/${produtoUuid}`,
  )
}

export function useRemoverProdutoBonificacao() {
  const notifications = useNotification()
  const queryClient = useQueryClient()
  return useMutation<
    any,
    AxiosError,
    { regraUuid: string; produtoUuid: string }
  >(
    (data: { regraUuid: string; produtoUuid: string }) => {
      return removerProdutoBonificacao(data.regraUuid, data.produtoUuid)
    },
    {
      onSuccess() {
        notifications.notifySuccess(
          `Produto de bonificação excluído com sucesso!`,
        )
        queryClient.invalidateQueries(['BONIFICACAO'])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export default function useMixProdutos() {
  return {
    ...useCrud<MixProdutosModel, AxiosError>(
      'administrador/mix-produtos',
      entityName,
    ),
    useVincularProduto,
    useDesvincularProduto,
    vincularProdutos,
    useQueryObterDescontos,
    useRemoverDescontos,
    useAdicionarDescontos,
    useAdicionarBonificacao,
    useQueryObterBonificacoes,
    useAdicionarProdutoBonificacao,
    useQueryObterBonificacaoPorId,
    useRemoverProdutoBonificacao,
  }
}
