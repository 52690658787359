import { useFilterApi, useFilterData } from '@data-c/providers'
import { FilterContainer } from '@data-c/ui'
import {
  Stack,
  TextField,
  MenuItem,
  Autocomplete,
  CircularProgress,
} from '@mui/material'
import DateRangePicker, { DateRange } from 'components/Inputs/DateRangePicker'
import TimeVendasTransporter from 'components/Transporters/TimeVendasTranporter'
import useEtiqueta from 'hooks/queries/useEtiqueta'
import { DateTime } from 'luxon'
import { useMemo } from 'react'

export default function Filter() {
  const { changeFilterValue } = useFilterApi()

  const { filterValues } = useFilterData()

  const { useQueryObterEtiquetas } = useEtiqueta()
  const { data: etiquetas, isLoading: isLoadingEtiquetas } =
    useQueryObterEtiquetas()

  const formattedEtiquetas = useMemo(() => {
    return etiquetas?.map((e) => {
      return {
        label: e.nome,
        value: e.uuid,
      }
    })
  }, [etiquetas])

  return (
    <FilterContainer
      disableds={[
        'percentualA',
        'percentualB',
        'percentualC',
        'situacaoPedido',
        'dateRange',
        'metrica',
      ]}
      title="Parâmetros"
      triggerButtonProps={{ variant: 'outlined' }}
      renderLabel={(filterLabel, filterValue) => {
        if (filterLabel === 'Vendedor') {
          return `${filterLabel} - ${filterValue?.nome || ''}`
        }

        if (filterLabel === 'Situação') {
          return `Situação - ${
            filterValue === 'emissao' ? 'Faturados' : 'Em Processamento'
          }`
        }

        if (filterLabel.startsWith('Percentual')) {
          return `${filterLabel.split(' ')[1]} - ${filterValue}%`
        }

        if (filterLabel === 'Período') {
          const dateRange = filterValue as DateRange
          const formattedFirstDate = DateTime.fromFormat(
            dateRange?.firstDate || '',
            'yyyy-MM-dd',
          ).toFormat('dd/MM/yyyy')

          const formattedSecondDate = DateTime.fromFormat(
            dateRange?.secondDate || '',
            'yyyy-MM-dd',
          ).toFormat('dd/MM/yyyy')

          if (dateRange.tipo === 'day') {
            return `${filterLabel} - ${formattedFirstDate}`
          }

          return `${filterLabel} - de ${formattedFirstDate} até ${formattedSecondDate}`
        }

        if (filterLabel === 'Métrica') {
          return `Calcular por - ${
            filterValue === 'total' ? 'Total' : 'Quantidade'
          }`
        }

        if (filterLabel === 'Etiqueta') {
          const label = filterValue.length === 1 ? 'Etiqueta' : 'Etiquetas'
          let values = ''
          if (Array.isArray(filterValue)) {
            values = filterValue.map((f: any) => f.label).toString()
          }
          return `${label} - ${values}`
        }

        return `${filterLabel} - ${filterValue}`
      }}
    >
      <Stack spacing={1.5}>
        <DateRangePicker
          onChange={(value) => {
            changeFilterValue('dateRange', value)
          }}
          value={filterValues?.dateRange?.value}
        />
        <TextField
          select
          label="Situação do Pedido"
          value={filterValues?.situacaoPedido?.value || ''}
          onChange={(e) => changeFilterValue('situacaoPedido', e.target.value)}
        >
          <MenuItem value="emissao">Faturados</MenuItem>
          <MenuItem value="venda">Em processamento</MenuItem>
        </TextField>

        <TextField
          select
          label="Calcular por"
          value={filterValues?.metrica?.value || ''}
          onChange={(e) => changeFilterValue('metrica', e.target.value)}
        >
          <MenuItem value="total">Total</MenuItem>
          <MenuItem value="quantidade">Quantidade</MenuItem>
        </TextField>

        <TextField
          type="number"
          label="A"
          value={filterValues?.percentualA?.value || ''}
          onChange={(e) => changeFilterValue('percentualA', e.target.value)}
        />
        <TextField
          type="number"
          label="B"
          value={filterValues?.percentualB?.value || ''}
          onChange={(e) => changeFilterValue('percentualB', e.target.value)}
        />
        <TextField
          type="number"
          label="C"
          value={filterValues?.percentualC?.value || ''}
          onChange={(e) => changeFilterValue('percentualC', e.target.value)}
        />
        <TimeVendasTransporter
          name="representanteUuid"
          label={filterValues?.representante?.label}
          value={filterValues?.representante?.value || null}
          onChange={(representante) => {
            changeFilterValue('representante', representante)
          }}
          InputLabelProps={{ shrink: true }}
        />
        <Autocomplete
          multiple
          options={formattedEtiquetas || []}
          getOptionLabel={(option) => option?.label}
          value={filterValues?.etiquetaKeyValue?.value || []}
          disableCloseOnSelect
          onChange={(_: any, value: any) =>
            changeFilterValue('etiquetaKeyValue', value)
          }
          renderInput={(params) => <TextField {...params} label="Etiquetas" />}
          loading={isLoadingEtiquetas}
          loadingText={<CircularProgress />}
        />
      </Stack>
    </FilterContainer>
  )
}
