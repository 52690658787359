import useCrmAtividades, {
  CrmAtividadesFilters,
  CrmAtividadesFilters1,
  CrmAtividadesModel,
  TipoAtividade,
} from 'hooks/queries/useCrmAtividades'
import { useFormApi } from '@data-c/providers'
import { useFilterData } from '@data-c/providers'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { findData, usePagination } from '@data-c/hooks'
import { Checkbox } from '@data-c/ui'
import {
  List as MuiList,
  ListItemButtonProps,
  ListItemButton as MUIListItemButton,
  Stack,
  Typography,
  styled,
  useTheme,
  Container,
  Box,
} from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import useNotification from 'hooks/useNotifications'
import { SortProps } from 'interfaces/SortProps'
import TipoAtividadeIcon from 'components/TipoAtividadeIcon'
import LoadingStatus from 'components/Displays/LoadingStatus'
import Undo from 'components/Undo'

const ListItemButton = styled(MUIListItemButton)<ListItemButtonProps>(() => ({
  '&.MuiListItemButton-root': {
    minHeight: '112px',
    marginTop: '12px',
    background: '#fff',
    borderRadius: '4px',
  },
}))

interface ListProps {
  atividades: Array<CrmAtividadesModel>
  onClick: (event: 'edit' | 'delete', data: CrmAtividadesModel) => void
  onConcluir: (atividade: CrmAtividadesModel) => void
}

function List(props: ListProps) {
  const theme = useTheme()

  const { atividades, onClick, onConcluir } = props

  function obterCorDaFonte(status: string) {
    if (status === 'ATRASADA') {
      return theme.palette.error.main
    }
    if (status === 'AGORA') {
      return theme.palette.success.main
    }
    return theme.palette.grey['500']
  }

  return (
    <MuiList sx={{ m: 0 }}>
      {atividades.map((a) => (
        <ListItemButton
          key={a.uuid}
          onClick={() => {
            onClick('edit', a)
          }}
        >
          <Stack
            direction="row"
            display="flex"
            // justifyContent="center"
            alignItems="center"
            width="100%"
            spacing={2}
          >
            <TipoAtividadeIcon
              tipoAtividade={a.tipoAtividade || TipoAtividade.TODAS}
            />
            <Stack width="100%">
              <Typography
                color={theme.palette.grey[600]}
                fontWeight="bold"
                fontSize="12pt"
                variant="subtitle1"
              >
                {a.assunto}
              </Typography>
              {a?.leadAsText && (
                <Typography
                  variant="body1"
                  fontSize="11pt"
                  color={theme.palette.grey['600']}
                >
                  {a.leadAsText}
                </Typography>
              )}
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  variant="body2"
                  fontSize="10pt"
                  color={obterCorDaFonte(a?.status || '')}
                >
                  {`${a.dataHoraInicioAsBrazilianDate} (Duração ${a.duracao})`}
                </Typography>
                <Checkbox
                  label=""
                  checked={Boolean(a.concluido)}
                  onClick={(e) => {
                    e.stopPropagation()
                    onConcluir(a)
                  }}
                />
              </Stack>
            </Stack>
          </Stack>
        </ListItemButton>
      ))}
    </MuiList>
  )
}

export default function Table() {
  const { pagination } = usePagination()
  const { useQuery, useAtualizarConcluido, readById } = useCrmAtividades()
  const [sort] = useState<SortProps>({
    column: 'dataHoraInicioAsBrazilianDate',
    direction: 'desc',
  })
  const [searchParams] = useSearchParams()
  const notifications = useNotification()
  const { appliedValues, searchId } = useFilterData<
    CrmAtividadesFilters,
    CrmAtividadesFilters1
  >()
  const { lead, responsavel, pedido, dateRange, ...rest } = appliedValues || {}
  const nFilters: CrmAtividadesFilters1 = {
    ...rest,
    responsavelUuid: responsavel?.uuid,
    leadUuid: lead?.uuid,
    pedidoUuid: pedido?.uuid,
    dataInicio: dateRange?.firstDate,
    dataFim: dateRange?.secondDate,
  }

  const { mutateAsync: atualizarConcluido } = useAtualizarConcluido()

  const { data, isLoading, isFetching, error } = useQuery({
    pagination,
    sort,
    queryParams: nFilters,
    requestId: searchId,
  })

  const nData = useMemo(() => {
    const agora: Array<CrmAtividadesModel> = []
    const atrasadas: Array<CrmAtividadesModel> = []
    const planejadas: Array<CrmAtividadesModel> = []

    data?.data.forEach((atividade) => {
      switch (atividade.status) {
        case 'AGORA':
          agora.push(atividade)
          break
        case 'PLANEJADA':
          planejadas.push(atividade)
          break
        case 'ATRASADA':
          atrasadas.push(atividade)
          break
      }
    })

    return {
      agora,
      planejadas,
      atrasadas,
    }
  }, [data])

  const { openForm, openConfirm } = useFormApi()
  useEffect(() => {
    async function obterAtividadePorId(uuid: string) {
      try {
        const response = await readById<CrmAtividadesModel>(uuid)
        openForm(response)
      } catch (err) {
        notifications.notifyException(err)
      }
    }

    const id = searchParams.get('id')
    if (id) {
      const _data = findData<CrmAtividadesModel>(data?.data || [], id)
      if (_data) {
        openForm(_data)
        return
      }
      obterAtividadePorId(id)
    }
  }, [searchParams, data])

  const handleClickItem = useCallback(
    (event: 'edit' | 'delete', data: CrmAtividadesModel) => {
      switch (event) {
        case 'edit':
          openForm(data)
          break
        case 'delete':
          openConfirm(data)
          break
      }
    },
    [openForm, openConfirm],
  )

  async function handleConcluir(atividade: CrmAtividadesModel) {
    atividade.concluido = !atividade.concluido
    const toastId = notifications.notifyLoading('Concluindo a atividade')
    try {
      await atualizarConcluido(atividade)
      // notifications.updateNotify(toastId, 'success', 'Atividade concluída')
      if (atividade.concluido) {
        notifications.updateNotify(
          toastId,
          'default',
          <Undo
            onUndo={() => {
              handleConcluir(atividade)
            }}
            message="Atividade concluída"
          />,
        )
      } else {
        notifications.updateNotify(
          toastId,
          'success',
          'Atividade desmarcada como concluída',
        )
      }
    } catch (e) {
      notifications.updateNotify(
        toastId,
        'error',
        notifications.extractAxiosError(e) || 'Ocorreu um erro desconhecido',
      )
      console.log('Error', e)
    }
  }

  const atividades =
    data?.data?.map((d) => {
      return {
        ...d,
        // tipoAtividade: tipoAtividadeMap[d?.tipoAtividade || 'Todas'],
      }
    }) || []

  return (
    <>
      <LoadingStatus
        isLoading={isLoading || isFetching}
        isLinear={true}
        error={error?.message}
      />
      <Container>
        {nData.atrasadas.length > 0 && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="h4">Atrasadas</Typography>
            <List
              atividades={nData.atrasadas}
              onClick={handleClickItem}
              onConcluir={handleConcluir}
            />
          </Box>
        )}
        {nData.agora.length > 0 && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="h4">Agora</Typography>
            <List
              atividades={nData.agora}
              onClick={handleClickItem}
              onConcluir={handleConcluir}
            />
          </Box>
        )}

        {nData.planejadas.length > 0 && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="h4">Planejadas</Typography>
            <List
              atividades={nData.planejadas}
              onClick={handleClickItem}
              onConcluir={handleConcluir}
            />
          </Box>
        )}

        {nFilters.situacao === 'CONCLUIDA' && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="h4">Concluídas</Typography>
            <List
              atividades={atividades}
              onClick={handleClickItem}
              onConcluir={handleConcluir}
            />
          </Box>
        )}
      </Container>
    </>
  )
}
