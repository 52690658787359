import { useFilterData } from '@data-c/providers'
import { Box, LinearProgress, Typography } from '@mui/material'
import useGerenteComercial, {
  GerenteComercialFilters,
  GerenteComercialFilters1,
} from 'hooks/queries/useGerenteComercial'
import Chart from './components/Chart'

export default function TopVendedores() {
  const { appliedValues, searchId } = useFilterData<
    GerenteComercialFilters,
    GerenteComercialFilters1
  >()
  const { representante, cliente, dateRange, ...rest } = appliedValues || {}
  const nFilters: GerenteComercialFilters1 = {
    ...rest,
    representanteUuid: representante?.uuid,
    clienteUuid: cliente?.uuid,
    dataInicial: dateRange?.firstDate,
    dataFinal: dateRange?.secondDate,
  }

  const { useQueryObterTopVendedores } = useGerenteComercial()
  const { data: topVendedores, isLoading: isLoadingTopVendedores } =
    useQueryObterTopVendedores({
      queryParams: nFilters,
      requestId: searchId,
      pagination: { page: 1, pageSize: 9999 },
    })

  if (isLoadingTopVendedores) return <LinearProgress />

  return (
    <Box>
      {topVendedores && topVendedores.data?.length > 0 ? (
        <Chart data={topVendedores?.data || []} />
      ) : (
        <Typography
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 20,
            fontSize: '13pt',
          }}
        >
          Não há dados suficientes para exibir o gráfico.
        </Typography>
      )}
    </Box>
  )
}
