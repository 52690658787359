import { findData } from '@data-c/hooks'
import { DataTable } from '@data-c/ui'
import { Box, Button } from '@mui/material'
import Surface from 'components/Surface'
import { ImportarExcelInterface } from 'hooks/queries/useImportarEntidade'
import { MUIDataTableColumnDef } from 'mui-datatables'
import { useMemo } from 'react'

export interface ResultadoProps {
  excelProps: ImportarExcelInterface
  onConfirm: () => void
}

export default function Resultado(props: ResultadoProps) {
  const { excelProps, onConfirm } = props
  const { data } = excelProps

  const formattedData = useMemo(() => {
    return excelProps.data.data.map((d) => {
      return {
        ...d,
        cidade: d.cidade ? d.cidade : 'Cidade não encontrada',
        vendedor: data.config.vendedor.nome
          ? data.config.vendedor.nome
          : 'Não vinculado',
      }
    })
  }, [excelProps])

  const columns = useMemo((): MUIDataTableColumnDef[] => {
    if (!excelProps) return []
    return [
      ...excelProps.mapeamento.map((m) => {
        const destino = findData(excelProps.destinos, m.destino, 'key')
        return {
          name: destino?.key || '',
          label: destino?.value || '',
        }
      }),
      {
        name: 'vendedor',
        label: 'Vendedor',
      },
    ]
  }, [excelProps])

  return (
    <Surface>
      <Box sx={{ display: 'flex', justifyContent: 'end' }}>
        <Button variant="contained" sx={{ mb: 2 }} onClick={() => onConfirm()}>
          Importar outro arquivo
        </Button>
      </Box>
      <DataTable columns={columns} data={formattedData || []} />
    </Surface>
  )
}
