import { Stack, Typography } from '@mui/material'

export default function Descricao() {
  return (
    <Stack>
      <Typography variant="body1">
        Lamentamos que essa negociação não tenha ido pra frente, mas faz parte
        do negócio!
      </Typography>
      <br />
      <Typography variant="body1">
        Deixe registrado as razões pelas quais seu cliente não fechou negócio
        com você para posteriormente trabalhar estratégias que minimizem as suas
        perdas.
      </Typography>
      <br />
      <Typography fontWeight="bold" variant="body1">
        Vamos lá!!!
      </Typography>
    </Stack>
  )
}
