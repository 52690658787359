import { useFilterData } from '@data-c/providers'
import {
  OportunidadesPerdidasFilter,
  OportunidadesPerdidasFilter1,
  useQueryRelatorioOportunidadesPerdidasPorPedido,
} from 'hooks/queries/useRelatorioOportunidadesPerdidas'
import Surface from 'components/Surface'
import { DataTable, DataTableOptions as Options } from '@data-c/ui'
import { useCallback, useMemo, useState } from 'react'
import {
  SortProps,
  findData,
  formatCurrency,
  usePagination,
} from '@data-c/hooks'
import { MUIDataTableColumnDef } from 'mui-datatables'
import { PedidoModel } from 'hooks/queries/usePedidos'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import usePopOver from 'hooks/usePopover'
import { Box, Popper } from '@mui/material'

export default function TabDetalhado() {
  const {
    isOpen,
    anchorEl,
    open,
    close,
    data: popoverData,
  } = usePopOver<string>()
  const [sort, setSort] = useState<SortProps>({
    column: 'venda',
    direction: 'desc',
  })
  const { pagination, changePageSize, changePage } = usePagination()
  const { appliedValues, searchId } = useFilterData<
    OportunidadesPerdidasFilter,
    OportunidadesPerdidasFilter1
  >()

  const { representante, cliente, dateRange, ...rest } = appliedValues || {}
  const nFilters: OportunidadesPerdidasFilter1 = {
    ...rest,
    representanteUuid: representante?.uuid,
    clienteUuid: cliente?.uuid,
    dataInicial: dateRange?.firstDate,
    dataFinal: dateRange?.secondDate,
  }

  const { data, isLoading, isFetching, error } =
    useQueryRelatorioOportunidadesPerdidasPorPedido(
      {
        queryParams: nFilters,
        requestId: searchId,
        pagination,
      },
      sort,
    )

  const navigate = useNavigate()

  const handleClickItem = useCallback(
    (event: 'edit', data: PedidoModel) => {
      switch (event) {
        case 'edit':
          navigate(`/pedidos/pedido/${data.uuid}`)
          break
      }
    },
    [navigate],
  )

  const pedidos = useMemo(() => {
    if (!data?.data) return []
    return data.data.map((pedido: PedidoModel) => ({
      ...pedido,
      representante: `${pedido.representante?.codigo} - ${pedido.representante?.nome}`,
      cliente: `${pedido.cliente?.codigo || ''} - ${pedido.cliente?.nome}`,
      venda: pedido.vendaAsBrazilianDate,
      totalLiquido: pedido.totalLiquidoAsBrazilianCurrency,
      totalComImposto: formatCurrency(pedido?.totalComImposto || ''),
      pesoTotal: `${new Intl.NumberFormat('pt-BR', {
        minimumFractionDigits: 3,
      }).format(pedido?.pesoTotal || 0)}Kg`,
      motivoPerda:
        pedido.motivoPerda?.descricao || 'Nenhum motivo foi reportado',
    }))
  }, [data])

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'codigo',
        label: 'Código',
        options: {
          setCellProps: () => {
            return {
              style: {
                width: '96px',
              },
            }
          },
        },
      },
      {
        name: 'venda',
        label: 'Data',
        options: {
          setCellProps: () => {
            return {
              style: {
                width: '96px',
              },
            }
          },
        },
      },
      {
        name: 'representante',
        label: 'Vendedor',
      },
      {
        name: 'cliente',
        label: 'Cliente',
      },
      {
        name: 'motivoPerda',
        label: 'Motivo da Perda',
      },
      {
        name: 'observacaoMotivoPerda',
        label: 'Observação',
        options: {
          customBodyRender: (value) => {
            const truncated = _.truncate(value, {
              length: 30,
            })
            if (value) {
              return (
                <Box
                  aria-owns={
                    Boolean(anchorEl) ? 'mouse-over-popover' : undefined
                  }
                  aria-haspopup="true"
                  onMouseEnter={(e) => {
                    open(e, value)
                  }}
                  onMouseLeave={() => {
                    close()
                  }}
                >
                  {truncated}
                </Box>
              )
            }
            return null
          },
        },
      },
      {
        name: 'totalLiquido',
        label: `Total`,
        options: {
          setCellProps: () => {
            return {
              style: {
                textAlign: 'right',
              },
            }
          },
        },
      },
      {
        name: 'uuid',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          setCellProps: () => {
            return {
              style: {
                width: '100px',
                textAlign: 'center',
              },
            }
          },
          customBodyRender: (value) => {
            const _data = findData<PedidoModel>(data?.data || [], value)
            return (
              <Options
                value={_data}
                onClick={handleClickItem}
                displayDeleteButton={false}
              />
            )
          },
        },
      },
    ],
    [data?.data, handleClickItem, anchorEl],
  )

  return (
    <Surface>
      <DataTable
        error={error?.message}
        pagination={data?.pagination}
        changePage={changePage}
        changePageSize={changePageSize}
        columns={columns}
        data={pedidos || []}
        isLoading={isLoading}
        isFetching={isFetching}
        options={{
          sort: true,
          sortOrder: {
            name: sort.column,
            direction: sort.direction,
          },
          rowsPerPageOptions: [15, 50, 100],
          rowsPerPage: 15,
        }}
        changeSort={(column, direction) => {
          setSort({ column, direction: direction as 'asc' | 'desc' })
        }}
      />
      <Popper sx={{ maxWidth: 300 }} open={isOpen} anchorEl={anchorEl}>
        <Surface elevation={2}>{popoverData}</Surface>
      </Popper>
    </Surface>
  )
}
