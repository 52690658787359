import { useState } from 'react'
import { Transporter, TransporterProps } from '@data-c/ui'

import MixProdutosTable from 'pages/MixProdutos/components/Table'
import useMixProdutos, { MixProdutosModel } from 'hooks/queries/useMixProdutos'

type MixProdutosTransporterProps = {} & Omit<
  TransporterProps<MixProdutosModel>,
  'table' | 'form' | 'onSearch' | 'renderValue' | 'options'
>

export default function MixProdutosTransporter(
  props: MixProdutosTransporterProps,
) {
  const { value, ...rest } = props
  const [query, setQuery] = useState<string | null>(null)

  const { useQuery } = useMixProdutos()
  const { data, isLoading } = useQuery({
    queryParams: {
      plainQuery: query,
      ativo: true,
    },
    pagination: {
      page: 1,
      pageSize: 100,
    },
  })

  function handleSearch(q: string) {
    setQuery(q)
  }

  return (
    <Transporter
      onSearch={handleSearch}
      options={data?.data || []}
      isLoading={isLoading}
      table={MixProdutosTable}
      label="Mix"
      renderValue={(value) => {
        return `${value?.codigo || ''} - ${value?.nome || ''}`
      }}
      value={value}
      {...rest}
    />
  )
}
