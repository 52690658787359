import { HttpRequestInterface, HttpResponseInterface } from '@data-c/hooks'
import { AxiosError } from 'axios'
import {
  useMutation,
  useQueryClient,
  useQuery as useTQuery,
} from '@tanstack/react-query'
import ErrorInterface from 'interfaces/ErrorInterface'
import api from 'services/api'
import useNotification from 'hooks/useNotifications'
import HooksResultInterface from 'interfaces/CrudResultInterface'

export enum EntidadesEnum {
  COTACAO = 'cotacao',
  ATIVIDADE = 'crm_atividade',
  COMENTARIO_PEDIDO = 'comentario_pedido',
  COMENTARIO_ATIVIDADE = 'comentario_atividade',
}

export enum LembreteTipoEnum {
  MINUTOS = 'minutos',
  HORAS = 'horas',
  DIAS = 'dias',
  SEMANAS = 'semanas',
}

export enum LembreteDataEnum {
  INICIO = 'inicio',
  FIM = 'fim',
}

export interface AppNotificationsModel {
  id?: number
  uuid: string
  titulo?: string
  descricao: string
  lido: boolean
  timeAsRelative: string
  destinatarioUuid?: string
  entidadeUuid?: string
  entidade?: EntidadesEnum
  lembreteTempo?: number
  lembreteTipo?: LembreteTipoEnum
  lembreteData?: LembreteDataEnum
  enviarEm?: string
  lembrarEmAsRelative?: string
  notificadoWeb?: boolean
}

export async function obterNotificacoesPorDestinatario(
  destinatarioUuid: string,
  params: HttpRequestInterface<unknown>,
): Promise<HttpResponseInterface<AppNotificationsModel>> {
  const { pagination: _pagination, queryParams } = params
  const response = await api.get(
    `notifications/destinatario/${destinatarioUuid}`,
    {
      params: queryParams,
      headers: {
        'DC-Page': _pagination.page,
        'DC-PageSize': _pagination.pageSize,
        // 'DC-SortName': 'nome',
        // 'DC-SortDirection': 'ASC',
      },
    },
  )

  const { data, meta: pagination } = response.data
  return { data, pagination }
}

function useObterNotificacoesPorDestinatario(
  destinatarioUuid: string,
  params: HttpRequestInterface<unknown>,
) {
  return useTQuery<
    HttpResponseInterface<AppNotificationsModel> | null,
    AxiosError<ErrorInterface>
  >(['NOTIFICACOES', destinatarioUuid, params], () => {
    if (!destinatarioUuid) return null
    return obterNotificacoesPorDestinatario(destinatarioUuid, params)
  })
}

export async function updateNotificationStatus(
  notificacao: AppNotificationsModel,
): Promise<AppNotificationsModel> {
  const data = {
    lido: notificacao.lido,
  }

  const response = await api.put(`notifications/${notificacao.uuid}`, data)

  return response.data
}

export function useUpdateNotificationStatus() {
  const notifications = useNotification()
  const queryClient = useQueryClient()
  return useMutation<AppNotificationsModel, AxiosError, AppNotificationsModel>(
    (notificacao) => updateNotificationStatus(notificacao),
    {
      onSuccess(_: AppNotificationsModel) {
        queryClient.invalidateQueries(['NOTIFICACOES'])
      },
      onError() {
        notifications.notifyError(
          'Ocorreu um erro ao tentar processar a requisição',
        )
      },
    },
  )
}

export function useDeleteNotification() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  async function deleteNotification(
    notificacao: AppNotificationsModel,
  ): Promise<AppNotificationsModel> {
    await api.delete(`notifications/${notificacao.uuid}`)
    return notificacao
  }

  return useMutation<AppNotificationsModel, AxiosError, AppNotificationsModel>(
    deleteNotification,
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(['NOTIFICACOES'])
        // notifications.notifySuccess('O item do pedido foi excluído com sucesso')
      },
      onError: (err) => {
        notifications.notifyException(err)
      },
    },
  )
}

export async function marcarTodasNotificacoesComoLidas(): Promise<
  AppNotificationsModel[]
> {
  const response = await api.put('notifications/marcar-todas-como-lidas')

  return response.data
}

export function useMarcarTodasNotificacoesComoLidas() {
  const notifications = useNotification()
  const queryClient = useQueryClient()
  return useMutation<AppNotificationsModel[], AxiosError>(
    () => marcarTodasNotificacoesComoLidas(),
    {
      onSuccess() {
        queryClient.invalidateQueries(['NOTIFICACOES'])
      },
      onError() {
        notifications.notifyError(
          'Ocorreu um erro ao tentar processar a requisição',
        )
      },
    },
  )
}

export async function createOrUpdateLembrete(
  notificacao: AppNotificationsModel,
): Promise<HooksResultInterface<AppNotificationsModel>> {
  if (notificacao?.id) {
    const response = await api.put(
      `/crm/atividades/${notificacao.entidadeUuid}/lembrete/${notificacao.uuid}`,
      {
        lembreteTempo: notificacao.lembreteTempo,
        lembreteTipo: notificacao.lembreteTipo,
        lembreteData: notificacao.lembreteData,
      },
    )
    return { data: response.data, operation: 'update' }
  } else {
    const response = await api.post(
      `/crm/atividades/${notificacao.entidadeUuid}/lembrete`,
      notificacao,
    )

    return { data: response.data, operation: 'create' }
  }
}

export function useCreateOrUpdateLembrete() {
  const notifications = useNotification()
  const queryClient = useQueryClient()
  return useMutation<
    HooksResultInterface<AppNotificationsModel>,
    AxiosError,
    AppNotificationsModel
  >((notificacao) => createOrUpdateLembrete(notificacao), {
    onSuccess(result) {
      const { data, operation } = result
      queryClient.invalidateQueries(['LEMBRETE_ATIVIDADE', data.entidadeUuid])

      notifications.notifySuccess(
        `Lembrete ${
          operation === 'create' ? 'incluído' : 'alterado'
        } com sucesso`,
      )
    },
    onError() {
      notifications.notifyError(
        'Ocorreu um erro ao tentar processar a requisição',
      )
    },
  })
}

async function obterLembretesPorAtividade(atividadeUuid: string) {
  const response = await api.get(`/crm/atividades/${atividadeUuid}/lembrete`)

  return response.data
}

export function useQueryLembretesPorAtividade(atividadeUuid: string) {
  return useTQuery<
    Array<AppNotificationsModel> | null,
    AxiosError<ErrorInterface>
  >(['LEMBRETE_ATIVIDADE', atividadeUuid], () => {
    return obterLembretesPorAtividade(atividadeUuid)
  })
}

export function useDeleteLembrete() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  async function deleteLembrete(notificacaoUuid: string) {
    await api.delete(`/crm/lembrete/${notificacaoUuid}/`)
  }

  return useMutation<void, AxiosError, string>(deleteLembrete, {
    onSuccess: async () => {
      queryClient.invalidateQueries(['LEMBRETE_ATIVIDADE'])
      notifications.notifySuccess('O lembrete foi excluído com sucesso')
    },
    onError: (err) => {
      notifications.notifyException(err)
    },
  })
}

export default function useAppNotifications() {
  return {
    useObterNotificacoesPorDestinatario,
    useUpdateNotificationStatus,
    useMarcarTodasNotificacoesComoLidas,
    useDeleteNotification,
    useCreateOrUpdateLembrete,
  }
}
