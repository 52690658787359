import { forwardRef, InputHTMLAttributes, useImperativeHandle } from 'react'
import {
  CircularProgress,
  InputAdornment,
  InputProps,
  TextFieldProps,
} from '@mui/material'
import useConsultaCep, { CepModel } from 'hooks/useConsultaCep'

import MaskedTextField from 'components/MaskedTextField'
import { onlyNumbers } from '@data-c/hooks'

interface CEPTextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  inputProps?: TextFieldProps
  InputProps?: InputProps
  value: string
  onFindCep?: (cep: CepModel) => void
}

export interface CepForwardRefProps {
  consultarCep: (cep: string) => Promise<CepModel | null | undefined>
}

const CEPTextField = forwardRef<CepForwardRefProps, CEPTextFieldProps>(
  (props, ref) => {
    const { value, onFindCep, ...rest } = props

    const mask = '99999-999'

    const { consultarCep: _consultarCep, isLoading } = useConsultaCep()

    useImperativeHandle(ref, () => ({
      consultarCep,
    }))

    async function consultarCep(cepVal: string) {
      const cep = await _consultarCep(cepVal)
      if (onFindCep && cep) {
        onFindCep(cep)
      }
      return cep
    }

    function handleConsultarCep(cep: string) {
      if (!consultarCep) return
      if (value.length === 8) consultarCep(cep)
    }

    return (
      <MaskedTextField
        mask={mask}
        onBlur={(e) => {
          const v = e.target.value || ''
          const cep = onlyNumbers(v)
          if (cep) handleConsultarCep(cep)
        }}
        InputProps={{
          inputRef: ref,
          endAdornment: (
            <InputAdornment position="end">
              {isLoading ? <CircularProgress size={16} /> : <></>}
            </InputAdornment>
          ),
        }}
        value={value}
        {...rest}
      />
    )
  },
)

export default CEPTextField
