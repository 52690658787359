import { useEffect, useMemo, useState } from 'react'

import { Grid } from '@mui/material'
import { MUIDataTableColumnDef } from 'mui-datatables'

import { DataTable, Flag, FormContainer } from '@data-c/ui'
import { usePagination } from '@data-c/hooks'
import { useFormApi, useFormData } from '@data-c/providers'

import SearchTextField from 'components/SearchTextField'
import usePessoa, { EntidadeEnum } from 'hooks/queries/usePessoa'
import { useUserContext } from 'components/Contexts/UserContext'

interface FormProps {
  pessoaUuid: string
  titulo: string
  entidade: EntidadeEnum
  situacaoPessoa?: 'CLIENTE' | 'VENDEDOR'
}

interface ItemProps {
  uuid: string
}

function temItensSelecionados(
  indexItensSelecionados: Array<string | undefined>,
): boolean {
  return indexItensSelecionados.length > 0
}

export default function Form<T extends ItemProps>(props: FormProps) {
  const { pessoaUuid, titulo, entidade, situacaoPessoa } = props

  const [query, setQuery] = useState('')
  const [indexItensSelecionados, setIndexItensSelecionados] = useState<
    number[]
  >([])

  const { temPermissao } = useUserContext()
  const { isOpen } = useFormData()
  const { toggleSubmit, closeForm } = useFormApi()
  const { pagination, changePage, changePageSize } = usePagination()
  const { useQueryObterEntidadesNaoVinculadas, useVincularEntidades } =
    usePessoa()
  const {
    data: entidadesNaoVinculadas,
    isLoading,
    isFetching,
    error,
  } = useQueryObterEntidadesNaoVinculadas<T>({
    plainQuery: query,
    pessoaUuid,
    entidade,
    pagination,
  })
  const { mutateAsync: vincularEntidades } = useVincularEntidades<T>(titulo)

  useEffect(() => {
    if (isOpen === false) {
      setIndexItensSelecionados([])
    }
  }, [isOpen])

  const formattedData = useMemo(
    () =>
      entidadesNaoVinculadas?.data?.map((item) => {
        return {
          ...item,
        }
      }),
    [entidadesNaoVinculadas],
  )

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'codigo',
        label: 'Código',
        options: {
          setCellProps() {
            return { width: '96px' }
          },
        },
      },
      {
        name: 'nome',
        label: 'Nome',
      },
      {
        name: 'ativo',
        label: 'Ativo',
        options: {
          setCellProps: () => ({
            style: {
              width: '60px',
              textAlign: 'center',
            },
          }),
          customBodyRender: (value) => {
            return <Flag isFlagged={value === 1} type="success" />
          },
        },
      },
    ],
    [],
  )

  function handleSearch(_query: string) {
    setQuery(_query)
  }

  async function handleClickVincularEntidades() {
    const itensSelecionadosIds =
      indexItensSelecionados.map(
        (index) => entidadesNaoVinculadas?.data[index]?.uuid || '',
      ) || []
    if (pessoaUuid && temItensSelecionados(itensSelecionadosIds)) {
      toggleSubmit(true)
      try {
        await vincularEntidades({
          pessoaUuid,
          itensSelecionadosIds,
          entidade,
        })
        closeForm()
      } finally {
        toggleSubmit(false)
      }
    }
  }

  const temPermissaoParaVincular =
    situacaoPessoa === 'CLIENTE'
      ? temPermissao('clientes.alter') !== 'sem_acesso'
      : temPermissao('vendedores.alter') !== 'sem_acesso'

  return (
    <>
      {temPermissaoParaVincular && (
        <FormContainer
          onSubmitForm={handleClickVincularEntidades}
          triggerButtonLabel="Vincular"
          confirmButtonLabel="Vincular"
          dialogProps={{ title: titulo, maxWidth: 'md' }}
        >
          <Grid container spacing={2}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <SearchTextField
                placeholder="Pesquisar"
                onSearch={handleSearch}
              />
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <DataTable
                error={error?.message}
                isLoading={isLoading}
                isFetching={isFetching}
                columns={columns}
                data={formattedData || []}
                changePage={changePage}
                changePageSize={changePageSize}
                pagination={entidadesNaoVinculadas?.pagination}
                options={{
                  rowsPerPageOptions: [15, 50, 100],
                  rowsPerPage: 15,
                  selectableRows: 'multiple',
                  selectableRowsOnClick: true,
                  rowsSelected: indexItensSelecionados,
                  onRowSelectionChange: (_, __, rowsSelecteds: any) =>
                    setIndexItensSelecionados(rowsSelecteds),
                  selectToolbarPlacement: 'none',
                }}
              />
            </Grid>
          </Grid>
        </FormContainer>
      )}
    </>
  )
}
