import { useFilterApi, useFilterData } from '@data-c/providers'
import { Checkbox, FilterContainer } from '@data-c/ui'
import { Stack, TextField } from '@mui/material'
import {
  FunilVendaFilter,
  FunilVendaFilter1,
} from 'hooks/queries/useFunilVenda'

export default function Filter() {
  const { onChangeFilterValue } = useFilterApi()
  const { filterValues } = useFilterData<FunilVendaFilter, FunilVendaFilter1>()

  return (
    <FilterContainer
      title="Pesquisar"
      triggerButtonProps={{ variant: 'outlined' }}
      renderLabel={(filterLabel: string, filterValue: any) => {
        if (filterLabel === 'Ativo') {
          return 'Apenas ativos'
        }
        return `${filterLabel} - ${filterValue}`
      }}
    >
      <Stack spacing={2}>
        <TextField
          name="nome"
          value={filterValues?.nome?.value}
          label={filterValues?.nome?.label}
          onChange={onChangeFilterValue}
        />
        <Checkbox
          name="ativo"
          label="Somente ativos"
          checked={filterValues?.ativo?.value}
          onChange={onChangeFilterValue}
        />
      </Stack>
    </FilterContainer>
  )
}
