import { FilterProvider, useFormApi } from '@data-c/providers'

import { useUserContext } from 'components/Contexts/UserContext'
import Table from './components/Table'
import { filters, PedidoModel } from 'hooks/queries/usePedidos'
import Form from '../Form'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
export default function TabTableView() {
  const { temPermissao } = useUserContext()
  const navigate = useNavigate()
  const { openForm } = useFormApi<PedidoModel>()
  const podeCriarPedido = temPermissao('negociacoes.create') != 'sem_acesso'

  return (
    <>
      {podeCriarPedido && (
        <Form
          fazDepoisDeIniciarOPedido={(pedido: PedidoModel) => {
            navigate(`/pedidos/pedido/${pedido.uuid}`)
          }}
        />
      )}
      <FilterProvider
        filterValues={filters}
        storagePath="@fdv-filters-pedidos-tabela"
      >
        <Table onAdicionarClick={openForm} />
      </FilterProvider>
    </>
  )
}
