import { IconProps } from '@mui/material'
import { CSSObject, styled, Theme } from '@mui/material/styles'
import IconDown from './IconDown'

interface IconExpandableProps extends IconProps {
  open: boolean
}

const transformToogleIcon = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create(['transform'], {
    duration: 500,
  }),
})

const ToogleMenuIcon = styled(IconDown, {
  shouldForwardProp: (prop) => prop !== 'open',
})<IconExpandableProps>(({ theme, open }) => ({
  //   color: theme.palette.primary.dark,
  ...transformToogleIcon(theme),
  ...(open && {
    transform: 'rotate(-180deg)',
  }),
  ...(!open && {
    transform: 'rotate(0)',
  }),
}))

interface IconExpandableProps {
  open: boolean
}
export default function IconExpandable(props: IconExpandableProps) {
  const { open } = props
  return <ToogleMenuIcon fontSize="small" open={open} />
}
