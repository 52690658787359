import { CSSProperties, ReactNode, useEffect, useState } from 'react'
import { Stack, Typography, Box, Collapse, IconButton } from '@mui/material'
import { ExpandMore, ExpandLess } from '@mui/icons-material'

interface CardConfigProps {
  title: string
  description: ReactNode
  children?: ReactNode
  component?: ReactNode
  collapse?: boolean
  collapsable?: boolean
  isSubCard?: boolean
  customSx?: CSSProperties
  disabledPadding?: boolean
}

export default function CardConfig(props: CardConfigProps) {
  const {
    customSx,
    title,
    description,
    children,
    collapse,
    component,
    collapsable,
    disabledPadding = false,
  } = props

  const [isOpen, setOpen] = useState<boolean>(false)

  useEffect(() => {
    setOpen(Boolean(collapse))
  }, [collapse])

  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        borderRadius: '4px',
        padding: disabledPadding === false ? 2 : 0,
        ...customSx,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: 1,
        }}
      >
        <Stack sx={{ gap: 1 }}>
          <Typography
            component="h2"
            variant={!disabledPadding || children ? 'h4' : 'h5'}
            sx={{
              fontWeight: '600',
              color: (theme) => theme.palette.grey[600],
            }}
          >
            {title}
          </Typography>
          <Typography variant="body2">{description}</Typography>
        </Stack>

        <Stack sx={{ width: '200px', alignItems: 'flex-end' }}>
          {component}
          {collapsable && (
            <IconButton
              onClick={() => {
                setOpen(!isOpen)
              }}
            >
              {isOpen ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          )}
        </Stack>
      </Box>

      <Collapse in={isOpen}>
        <Stack spacing={2} marginLeft={2}>
          {children}
        </Stack>
      </Collapse>
    </Box>
  )
}
