import { Box, Grid, Switch, TextField } from '@mui/material'
import { LoadingButton as Button } from '@mui/lab'

import FloatButtonContainer from 'components/FloatButtonContainer'

import { ConfiguracoesModel, useSubmit } from 'hooks/queries/useConfiguracoes'
import useForm from 'hooks/useForm'

import CardConfig from './components/CardConfig'
import DropDownUsaTabelaPreco from './components/DropDownUsaTabelaPreco'
import DropDownUsaCondicaoPagamento from './components/DropDownUsaCondicaoPagamento'
import DropDownUsaEstoque from './components/DropDownUsaEstoque'
import DropDownUsaFormaPagamento from './components/DropDownUsaFormaPagamento'

interface FormProps {
  data: ConfiguracoesModel | undefined
}

export default function Form(props: FormProps) {
  const { data: dataProps } = props

  const { data, handleChange, changeValue } = useForm<
    ConfiguracoesModel | undefined
  >(dataProps)

  const { mutateAsync: salvarAlteracoes, isLoading } = useSubmit()

  function handleSubmit() {
    if (data) {
      salvarAlteracoes(data)
    }
  }

  return (
    <Box sx={{ marginBottom: 6.5 }}>
      <Grid component="main" container spacing={2}>
        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
          <CardConfig
            title="Duplicata"
            description="Representa o número de dias de tolerância após o vencimento de uma duplicata "
          >
            <TextField
              name="usa_duplicata"
              value={data?.usa_duplicata || ''}
              onChange={(e) => {
                const value = e.target.value
                const parsedValue = parseInt(value)

                changeValue('usa_duplicata', parsedValue || null)
              }}
            />
          </CardConfig>
        </Grid>
        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
          <CardConfig
            title="Quantidade por Volume"
            description="Habilita o lançamento de quantidade por volume nos itens do pedido"
          >
            <Switch
              name="usa_qt_volume"
              checked={Boolean(data?.usa_qt_volume)}
              onChange={handleChange}
            />
          </CardConfig>
        </Grid>
        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
          <CardConfig
            title="Fator de Comissão"
            description="Habilita a ferramenta de calculo dos itens através do fator de comissão"
          >
            <Switch
              name="usa_fator"
              checked={Boolean(data?.usa_fator)}
              onChange={handleChange}
            />
          </CardConfig>
        </Grid>
        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
          <CardConfig
            title="Tabela de Preços"
            description="Define como as tabelas de preços serão habilitadas para cada tipo de usuário vendedor e/ou cliente"
          >
            <DropDownUsaTabelaPreco
              name="usa_tabpreco"
              value={data?.usa_tabpreco || ''}
              onChange={handleChange}
            />
          </CardConfig>
        </Grid>
        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
          <CardConfig
            title="Condição de pagamento"
            description="Define como as condições de pagamentos serão habilitadas para cada tipo de usuário (vendedor e/ou cliente)"
          >
            <DropDownUsaCondicaoPagamento
              name="filtra_condicaopagamento"
              value={data?.filtra_condicaopagamento || ''}
              onChange={handleChange}
            />
          </CardConfig>
        </Grid>
        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
          <CardConfig
            title="Envia e-mail da cópia do pedido"
            description="Quando habilitado o sistema envia a cópia do pedido assim que o pedido é fechado e recebido na web."
          >
            <Switch
              name="envia_email"
              checked={Boolean(data?.envia_email)}
              onChange={handleChange}
            />
          </CardConfig>
        </Grid>
        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
          <CardConfig
            title="Desconto do Item"
            description="Habilita a manipulação de desconto nos itens do pedido"
          >
            <Switch
              name="edita_desconto"
              checked={Boolean(data?.edita_desconto)}
              onChange={handleChange}
            />
          </CardConfig>
        </Grid>
        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
          <CardConfig
            title="Desconto do Pedido"
            description="Habilita a manipulação de desconto do pedido"
          >
            <Switch
              name="edita_desconto_pedido"
              checked={Boolean(data?.edita_desconto_pedido)}
              onChange={handleChange}
            />
          </CardConfig>
        </Grid>
        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
          <CardConfig
            title="Preço Unitário"
            description="Habilita a manipulação de preço unitário do item"
          >
            <Switch
              name="edita_unitario"
              checked={Boolean(data?.edita_unitario)}
              onChange={handleChange}
            />
          </CardConfig>
        </Grid>
        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
          <CardConfig
            title="Forma de Pagamento"
            description="Define como as formas de pagamento serão habilitadas para cada tipo de usuário vendedor e/ou cliente"
          >
            <DropDownUsaFormaPagamento
              name="usa_formapgto"
              value={data?.usa_formapgto || ''}
              onChange={handleChange}
            />
          </CardConfig>
        </Grid>
        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
          <CardConfig
            title="Estoque"
            description="Habilita a visualização da quantidade em estoque dos produtos."
          >
            <DropDownUsaEstoque
              name="usa_estoque"
              value={data?.usa_estoque || 0}
              onChange={handleChange}
            />
          </CardConfig>
        </Grid>
        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
          <CardConfig
            title="Limite de Crédito"
            description="Habilita a validação no limite de crédito no pedido"
          >
            <Switch
              name="usa_limite_credito"
              checked={Boolean(data?.usa_limite_credito)}
              onChange={handleChange}
            />
          </CardConfig>
        </Grid>
        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
          <CardConfig
            title="Desconto Agregado"
            description="Habilita a utilização do desconto agregado"
          >
            <Switch
              name="usa_desconto_agregado"
              checked={Boolean(data?.usa_desconto_agregado)}
              onChange={handleChange}
            />
          </CardConfig>
        </Grid>
        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
          <CardConfig
            title="Crédito e Débito"
            description="Habilita a utilização do crédito e débito"
          >
            <Switch
              name="usa_credito_debito"
              checked={Boolean(data?.usa_credito_debito)}
              onChange={handleChange}
            />
          </CardConfig>
        </Grid>
        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
          <CardConfig
            title="Promoção/Desconto Progressiva"
            description="Habilita a utilização da promoção/desconto progressivo"
          >
            <Switch
              name="usa_promocao_progressiva"
              checked={Boolean(data?.usa_promocao_progressiva)}
              onChange={handleChange}
            />
          </CardConfig>
        </Grid>
        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
          <CardConfig
            title="Casas Decimais"
            description="Se habilitado formata os valores com 4 casas decimais"
          >
            <Switch
              name="usa_milesimal"
              checked={Boolean(data?.usa_milesimal)}
              onChange={handleChange}
            />
          </CardConfig>
        </Grid>
        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
          <CardConfig
            title="Bloqueio de venda de itens sem quantidade em estoque"
            description="Habilita a o bloqueio de venda de itens sem quantidade em estoque"
          >
            <Switch
              name="bloqueia_estoque"
              checked={Boolean(data?.bloqueia_estoque)}
              onChange={handleChange}
            />
          </CardConfig>
        </Grid>
      </Grid>

      <FloatButtonContainer>
        <Button loading={isLoading} variant="contained" onClick={handleSubmit}>
          Salvar Alterações
        </Button>
      </FloatButtonContainer>
    </Box>
  )
}
