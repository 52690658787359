import {
  PlainQueryProps,
  HttpRequestInterface,
  HttpResponseInterface,
} from '@data-c/hooks'
import useCrud from 'hooks/useCrud'
import api from 'services/api'
import { AxiosError } from 'axios'
import CategoriaInterface from 'interfaces/CategoriaInterface'

export interface FamiliaProdutosModel
  extends PlainQueryProps,
    CategoriaInterface {}

export default function useFamiliaProdutos() {
  return useCrud<FamiliaProdutosModel, AxiosError>(
    'administrador/familia-produto',
    'Família de Produtos',
    'female',
  )
}

export async function obterFamiliaProdutos<T>(
  params: HttpRequestInterface<T>,
): Promise<HttpResponseInterface<FamiliaProdutosModel>> {
  const { pagination: _pagination, queryParams } = params

  const response = await api.get('administrador/familia-produto', {
    params: queryParams,
    headers: {
      'DC-Page': _pagination.page,
      'DC-PageSize': _pagination.pageSize,
    },
  })

  const { data, meta: pagination } = response.data

  return { data, pagination }
}

export async function obterFamiliaProdutosPorId(
  id?: string,
): Promise<FamiliaProdutosModel> {
  const response = await api.get(`administrador/familia-produto/${id}`)
  return response.data
}
