import { Paper } from '@mui/material'
import { VendedorModel } from 'hooks/queries/useVendedores'
import Form from 'pages/Vendedores/components/Form'

interface TabCadastroVendedorProps {
  vendedor?: VendedorModel
}

export default function TabCadastroVendedor(props: TabCadastroVendedorProps) {
  return (
    <Paper sx={{ p: 2 }} elevation={0}>
      <Form view="plain" data={props?.vendedor} />
    </Paper>
  )
}
