import useCrud from 'hooks/useCrud'
import api from 'services/api'

export interface CidadeModel {
  uuid: string
  nome: string
  uf: string
}

export async function obterCidadesPorUf(
  uf: string,
): Promise<Array<CidadeModel>> {
  const response = await api.get<Array<CidadeModel>>(
    `/administrador/cidades/uf/${uf}`,
  )
  return response.data
}

export default function useCidades() {
  return {
    ...useCrud<CidadeModel>('administrador/cidades', 'Cidade', 'female'),
    obterCidadesPorUf,
  }
}
