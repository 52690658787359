import { ButtonContainer } from '@data-c/ui'
import { Box } from '@mui/material'

import Table from './components/Table'
import Form from './components/Form'

interface TabVendedoresProps {
  clienteUuid: string
}

export default function TabVendedores(props: TabVendedoresProps) {
  const { clienteUuid } = props

  return (
    <Box>
      <ButtonContainer sx={{ mb: 2 }}>
        <Form clienteUuid={clienteUuid} />
      </ButtonContainer>
      <Table clienteUuid={clienteUuid} />
    </Box>
  )
}
