import { Box, Stack } from '@mui/material'
import CardConfig from 'components/CardConfig'
// import Leads from './components/Leads'
import Atividades from './components/Atividades'
import MotivosPerda from './components/MotivosPerda'
import FunilDeVenda from './components/FunilDeVendas'
export default function TabCrm() {
  return (
    <Box sx={{ mt: 2 }}>
      <Stack spacing={2}>
        <CardConfig
          title="Atividades"
          collapsable
          description={
            <div>
              Define os níveis de permissão que o usuário tem nas operações do
              cadastro de atividades
            </div>
          }
        >
          <Atividades />
        </CardConfig>

        <CardConfig
          title="Motivo de Perda"
          collapsable
          description={
            <div>
              Define os níveis de permissão que o usuário tem nas operações do
              cadastro de motivo de perda
            </div>
          }
        >
          <MotivosPerda />
        </CardConfig>

        <CardConfig
          title="Funil de Vendas"
          collapsable
          description={
            <div>
              Define os níveis de permissão que o usuário tem nas operações do
              cadastro do funil de vendas
            </div>
          }
        >
          <FunilDeVenda />
        </CardConfig>
      </Stack>
    </Box>
  )
}
