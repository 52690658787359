import { Checkbox } from '@data-c/ui'
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material'

export interface SelectableItem {
  uuid: string
  descricaoProduto: string
  quantidade: number
  total: string
  selecionado: boolean
}

export interface SelecionarItensProps {
  itens: Array<SelectableItem>
  onSelect: (value: SelectableItem) => void
}

export default function SelecionarItens(props: SelecionarItensProps) {
  const { itens, onSelect } = props

  return (
    <div>
      <List>
        {itens?.map((item) => {
          return (
            <ListItem key={item.uuid}>
              <ListItemButton dense onClick={() => onSelect(item)}>
                <ListItemIcon>
                  <Checkbox
                    label=""
                    edge="start"
                    checked={item.selecionado}
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText
                  primary={item.descricaoProduto}
                  secondary={
                    <Stack
                      sx={{ width: '100%' }}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Box>{`Quantidade: ${item.quantidade}`}</Box>
                      <Typography variant="body2" fontWeight="bold">
                        {item.total}
                      </Typography>
                    </Stack>
                  }
                />
              </ListItemButton>
            </ListItem>
          )
        })}
      </List>
    </div>
  )
}
