import { Stack, TextField } from '@mui/material'

import { useFilterApi, useFilterData } from '@data-c/providers'
import { FilterContainer } from '@data-c/ui'

import {
  CondicaoPagamentoFilters,
  CondicaoPagamentoFilters1,
} from 'hooks/queries/useCondicaoPagamento'
import FilterProps from 'interfaces/FilterProps'

export default function Filter({
  onApplyFilters,
}: FilterProps<CondicaoPagamentoFilters1>) {
  const { onChangeFilterValue } = useFilterApi()
  const { filterValues } = useFilterData<
    CondicaoPagamentoFilters,
    CondicaoPagamentoFilters1
  >()
  return (
    <FilterContainer
      title="Pesquisar por Condição de Pagamento"
      triggerButtonProps={{ variant: 'outlined' }}
      onApplyFilters={(f) => onApplyFilters(f)}
    >
      <Stack spacing={1.5}>
        <TextField
          name="codigo"
          value={filterValues?.codigo?.value}
          label={filterValues?.codigo?.label}
          onChange={onChangeFilterValue}
        />
        <TextField
          name="nome"
          value={filterValues?.nome?.value}
          label={filterValues?.nome?.label}
          onChange={onChangeFilterValue}
        />
      </Stack>
    </FilterContainer>
  )
}
