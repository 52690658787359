import { Info } from '@mui/icons-material'
import { IconButton, Popover, Stack, Typography } from '@mui/material'
import usePopOver from 'hooks/usePopover'
import { ReactNode } from 'react'

export interface InfoHelperProps {
  children: ReactNode | string
}

export default function InfoHelper(props: InfoHelperProps) {
  const { isOpen, close, open, anchorEl } = usePopOver()
  const { children } = props
  return (
    <>
      <IconButton onClick={open}>
        <Info color="primary" />
      </IconButton>
      <Popover
        open={isOpen}
        onClose={close}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Stack sx={{ m: 2, minWidth: '220px', maxWidth: '320px' }}>
          {typeof children === 'string' && (
            <Typography variant="body2">{children}</Typography>
          )}
          {typeof children !== 'string' && children}
        </Stack>
      </Popover>
    </>
  )
}
