import { Container, Tab, Tabs } from '@mui/material'

import { ButtonContainer, PageTitle } from '@data-c/ui'
import { FilterProvider, FormProvider } from '@data-c/providers'
import { useDeletePedido } from 'hooks/queries/usePedidos'
import { filters } from 'hooks/queries/usePedidos'
import Delete from 'components/Delete'
import Pipeline from './components/Pipeline'
import useTabs from 'hooks/useTabs'
import TabPanel from 'components/TabPanel'
import { SyntheticEvent, useEffect } from 'react'
import { usePreserveNavigationData } from 'contexts/PreserveNavigationDataContext'
import Content from 'components/Layouts/Content'
import TabTableView from './components/TabTableView'

export default function Pedidos() {
  const { mutateAsync: handleDelete } = useDeletePedido()
  const { setValue, value } = useTabs()
  const { data, setData } = usePreserveNavigationData<number>()

  useEffect(() => {
    setValue(data || 0)
  }, [data])

  function handleChangeTab(_: SyntheticEvent, newValue: number) {
    setData(newValue)
  }
  return (
    <Container>
      <FormProvider>
        <PageTitle title="Negociações">
          <ButtonContainer>
            <Tabs value={value} onChange={handleChangeTab}>
              <Tab label="Tabela" value={0} />
              <Tab label="Funil" value={1} />
            </Tabs>
          </ButtonContainer>
        </PageTitle>

        <Content>
          <TabPanel value={value} index={0}>
            <TabTableView />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <FilterProvider
              filterValues={filters}
              storagePath="@fdv-filters-pedidos-funil"
            >
              <Pipeline />
            </FilterProvider>
          </TabPanel>
          <Delete onDelete={handleDelete} />
        </Content>
      </FormProvider>
    </Container>
  )
}
