import { Button, ButtonContainer, PageTitle } from '@data-c/ui'
import { Container } from '@mui/material'
import AddFirst from 'components/Displays/AddFirst'
import useFunilVenda, { FunilVendaModel } from 'hooks/queries/useFunilVenda'
import { useEffect, useState } from 'react'
import Funil from './components/Funil'
import Surface from 'components/Surface'
import DropDownFunilVendas from 'components/Inputs/DropDownFunilVendas'
import Content from 'components/Layouts/Content'

export default function CrmFunilVenda() {
  const { useSubmit } = useFunilVenda()
  const [funilSelecionado, setFunilSelecionado] = useState<
    FunilVendaModel | undefined
  >()
  const { useQuery } = useFunilVenda()
  const { data, isLoading } = useQuery({
    pagination: {
      pageSize: 999,
      page: 1,
    },
  })
  const { mutateAsync: submitFunil, isLoading: isSubmitting } = useSubmit()
  const hasFunil = Array.isArray(data?.data) && data?.data?.length > 0

  useEffect(() => {
    if (hasFunil && !funilSelecionado) {
      setFunilSelecionado(data?.data[0])
    }
  }, [hasFunil, data])

  async function criarFunil() {
    try {
      const created = await submitFunil({ nome: 'Novo Funil de Vendas' })
      setFunilSelecionado(created.data)
    } catch (err) {
      console.log('ERR', err)
    }
  }

  async function atualizarNomeFunil(nome?: string) {
    try {
      if (nome) await submitFunil({ ...funilSelecionado, nome })
    } catch (err) {
      console.log('ERR', err)
    }
  }

  function handleChange(value?: FunilVendaModel) {
    setFunilSelecionado(value)
  }

  return (
    <Container>
      <PageTitle
        title={funilSelecionado?.nome || ''}
        onUpdate={atualizarNomeFunil}
      >
        <ButtonContainer>
          <Surface sx={{ width: '300px' }}>
            <DropDownFunilVendas
              label="Funil de Vendas"
              value={funilSelecionado?.uuid || ''}
              onChange={handleChange}
            />
          </Surface>
          <Button variant="contained" onClick={() => criarFunil()}>
            Adicionar
          </Button>
        </ButtonContainer>
      </PageTitle>

      {/* {isLoading && <Loading isLoading={isLoading} />} */}

      <Content>
        {!hasFunil && !isLoading && (
          <AddFirst
            isLoading={isSubmitting}
            buttonLabel="Adicionar meu primeiro funil de vendas"
            primaryMessage="Adicione o seu primeiro funil de vendas"
            secondaryMessage={
              <div style={{ textAlign: 'center' }}>
                Descubra como um funil de vendas pode simplificar e otimizar seu
                processo de vendas,
                <br />
                ajudando você a converter leads em clientes de forma mais
                eficiente e lucrativa.
              </div>
            }
            onClick={() => {
              criarFunil()
            }}
          />
        )}

        {funilSelecionado?.uuid && (
          <Funil funilUuid={funilSelecionado?.uuid || ''} />
        )}
      </Content>
    </Container>
  )
}
