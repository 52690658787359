import { FormControlLabel, Popover, Radio, Stack } from '@mui/material'

import { Button, ButtonContainer, CurrencyTextField } from '@data-c/ui'

import useValidations from 'hooks/useValidations'

import * as yup from 'yup'
import useMixProdutos, {
  MixProdutoDescontoModel,
} from 'hooks/queries/useMixProdutos'
import { PopOverOutputProps } from 'hooks/usePopover'
import { onlyNumbers, useForm } from '@data-c/hooks'
import TextField from 'components/Inputs/TextField'
import RadioGroup from 'components/RadioGroup'
import { useEffect, useState } from 'react'

const schema = yup.object().shape({
  quantidadeMinima: yup.number().required('Informe uma quantidade mínima'),
  pdesconto: yup
    .number()
    .required('Informe um % desconto')
    .when('$tipoDesconto', (tipoDesconto, schema) => {
      if (tipoDesconto === 'percentual') {
        return schema.min(0.01, 'Informe um valor maior do que zero')
      }
      return schema
    }),
  preco: yup
    .number()
    .required('Informe o preço')
    .when('$tipoDesconto', (tipoDesconto, schema) => {
      if (tipoDesconto === 'preco') {
        return schema.min(0.01, 'Informe um valor maior do que zero')
      }
      return schema
    }),
})

interface FormProps extends PopOverOutputProps {
  mixProdutoUuid: string
}

export default function Form(props: FormProps) {
  const { isOpen, close, anchorEl, mixProdutoUuid } = props
  const [tipoDesconto, setTipoDesconto] = useState<'percentual' | 'preco'>(
    'percentual',
  )
  const [tipoJaEstaDefinido, setTipoJaEstaDefinido] = useState<boolean>(false)

  const { data, changeValue, setData } = useForm<MixProdutoDescontoModel>({
    quantidadeMinima: 1,
    promomixUuid: mixProdutoUuid,
    pdesconto: 0,
    preco: 0,
  })

  const { useQueryObterDescontos } = useMixProdutos()
  const { data: mixes } = useQueryObterDescontos(mixProdutoUuid)

  useEffect(() => {
    if (Array.isArray(mixes?.data) && mixes.data.length > 0) {
      const { pdesconto, preco } = mixes.data[0]
      setTipoJaEstaDefinido(true)
      if (pdesconto > 0) {
        setTipoDesconto('percentual')
      } else if (preco > 0) {
        setTipoDesconto('preco')
      }
    }
  }, [mixes, isOpen])

  const { useAdicionarDescontos } = useMixProdutos()
  const { mutateAsync, isLoading } = useAdicionarDescontos()
  const { setValidationErrors, validationProps } = useValidations()

  function handleSubmitForm() {
    setValidationErrors(null)
    schema
      .validate(data, {
        abortEarly: false,
        context: {
          tipoDesconto,
        },
      })
      .then(async () => {
        await mutateAsync({ ...data, promomixUuid: mixProdutoUuid })
        setData((prev) => ({
          ...prev,
          pdesconto: 0,
          preco: 0,
        }))
        close()
      })
      .catch((err) => {
        setValidationErrors(err)
      })
  }

  return (
    <Popover
      disableRestoreFocus={true}
      open={isOpen}
      onClose={close}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <Stack spacing={2} sx={{ m: 2, minWidth: '220px' }}>
        <fieldset
          style={{
            border: '1px solid #cfcfcf',
            borderRadius: '4px',
          }}
        >
          <legend
            style={{
              color: '#808080',
              fontSize: '0.63rem',
              paddingLeft: '4px',
              paddingRight: '16px',
            }}
          >
            Desconto por:
          </legend>

          <RadioGroup
            row
            name="percentual"
            value={tipoDesconto}
            onChange={(e) => {
              const tipo = e.target.value as 'percentual' | 'preco'
              setTipoDesconto(tipo)
            }}
            sx={{
              '.MuiFormControlLabel-label': {
                fontSize: '10pt',
              },
            }}
          >
            <FormControlLabel
              value="percentual"
              control={<Radio disabled={tipoJaEstaDefinido} />}
              label="Percentual"
              labelPlacement="end"
            />
            <FormControlLabel
              value="preco"
              control={<Radio size="small" disabled={tipoJaEstaDefinido} />}
              label="Preço"
              labelPlacement="end"
            />
          </RadioGroup>
        </fieldset>

        <TextField
          autoFocus
          inputProps={{
            style: {
              textAlign: 'right',
            },
          }}
          name="quantidadeMinima"
          label="Quantidde Mínima"
          value={data?.quantidadeMinima || ''}
          onChange={(e) => {
            const v = e.target.value
            let v1 = onlyNumbers(v)
            changeValue('quantidadeMinima', v1)
          }}
          {...validationProps('quantidadeMinima')}
        />

        {tipoDesconto === 'percentual' ? (
          <CurrencyTextField
            required
            name="pdesconto"
            label="Desconto(%)"
            value={data?.pdesconto || ''}
            onChange={(_: any, value: string) =>
              changeValue('pdesconto', value)
            }
            {...validationProps('pdesconto')}
          />
        ) : (
          <CurrencyTextField
            required
            name="preco"
            label="Preço"
            value={data?.preco || ''}
            onChange={(_: any, value: string) => changeValue('preco', value)}
            {...validationProps('preco')}
          />
        )}

        <ButtonContainer>
          <Button onClick={() => close()}>Cancelar</Button>
          <Button
            isLoading={isLoading}
            variant="contained"
            onClick={handleSubmitForm}
          >
            Salvar
          </Button>
        </ButtonContainer>
      </Stack>
    </Popover>
  )
}
