import { useMemo } from 'react'

import { CurrencyCellStyle, DataTable, Surface } from '@data-c/ui'
import { usePagination } from '@data-c/hooks'

import { MUIDataTableColumnDef } from 'mui-datatables'

import useClientes, {
  ClientesModel,
  DuplicataFilter,
  DuplicataFilter1,
} from 'hooks/queries/useClientes'
import { DateTime } from 'luxon'
import { Typography } from '@mui/material'
import { useFilterData } from '@data-c/providers'

export interface DuplicatasTable {
  pessoaUuid: string
}

export default function DuplicatasTable(props: DuplicatasTable) {
  const { pessoaUuid } = props

  const { changePageSize, changePage, pagination } = usePagination()
  const { appliedValues, searchId } = useFilterData<
    DuplicataFilter,
    DuplicataFilter1
  >()
  const { useQueryObterDuplicatas, useQueryByUuid } = useClientes()

  const { dateRange, tipoData, ...rest } = appliedValues || {}
  const nFilters = {
    ...rest,
    dataInicial: dateRange?.firstDate || '',
    dataFinal: dateRange?.secondDate || '',
    tipoData: tipoData || 'vencimento',
    clienteUuid: pessoaUuid,
  }

  const {
    data: duplicatas,
    isLoading,
    isFetching,
    error,
  } = useQueryObterDuplicatas({
    pagination,
    queryParams: nFilters,
    requestId: searchId,
  })
  const { data: cliente } = useQueryByUuid<ClientesModel>(pessoaUuid)

  const formattedData = useMemo(
    () =>
      duplicatas?.data?.map((item) => {
        return {
          ...item,
        }
      }),
    [duplicatas],
  )

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'documento',
        label: 'Núm. Documento',
      },
      {
        name: 'emissaoAsBrazilianDate',
        label: 'Emissão',
        options: {
          setCellProps: () => ({
            style: {
              width: '120px',
              textAlign: 'center',
            },
          }),
        },
      },
      {
        name: 'vencimentoAsBrazilianDate',
        label: 'Vencimento',
        options: {
          setCellProps: () => ({
            style: {
              width: '120px',
              textAlign: 'center',
            },
          }),
        },
      },
      {
        name: 'pagamentoAsBrazilianDate',
        label: 'Pagamento',
        options: {
          setCellProps: () => ({
            style: {
              width: '120px',
              textAlign: 'center',
            },
          }),
        },
      },
      {
        name: 'totalAsBrazilianCurrency',
        label: 'Total',
        options: {
          setCellProps: CurrencyCellStyle,
        },
      },
    ],
    [],
  )

  return (
    <Surface elevation={0}>
      {cliente?.dataLimiteIgnorarInadimplencia && (
        <Typography sx={{ marginLeft: '15px' }}>
          A inadimplência deste cliente será desconsiderada até{' '}
          {DateTime.fromISO(cliente?.dataLimiteIgnorarInadimplencia).toFormat(
            'dd/MM/yyyy',
          )}{' '}
          às{' '}
          {DateTime.fromISO(cliente?.dataLimiteIgnorarInadimplencia).toFormat(
            'HH:mm',
          )}
          .
        </Typography>
      )}
      <DataTable
        surface={true}
        error={error?.message}
        isLoading={isLoading}
        isFetching={isFetching}
        columns={columns}
        data={formattedData || []}
        pagination={duplicatas?.pagination}
        changePage={changePage}
        changePageSize={changePageSize}
        options={{
          rowsPerPageOptions: [15, 50, 100],
          rowsPerPage: 15,
        }}
      />
    </Surface>
  )
}
