import { FormProvider } from '@data-c/providers'
import { ButtonContainer, PageTitle } from '@data-c/ui'
import { Container } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Form from './components/Form'
import Table from './components/Table'
import useRole from 'hooks/queries/useRole'
import Delete from 'components/Delete'
import Content from 'components/Layouts/Content'

export default function Roles() {
  const navigate = useNavigate()
  const { useDelete } = useRole()
  const { mutateAsync: handleDelete } = useDelete()
  return (
    <Container>
      <FormProvider>
        <PageTitle title="Permissões" onBack={() => navigate(-1)}>
          <ButtonContainer>
            <Form />
          </ButtonContainer>
        </PageTitle>
        <Content>
          <Table />
        </Content>
        <Delete onDelete={handleDelete} />
      </FormProvider>
    </Container>
  )
}
