import api from 'services/api'
import { useQuery as useTQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import useCrud from 'hooks/useCrud'
import { RoleModel } from './useRole'
import { FilterOptions } from '@data-c/providers'
import { FunilVendaModel } from './useFunilVenda'

export interface UsuarioModel {
  uuid: string
  codigo: string
  nome: string
  isadmin: boolean
  isvendedor: boolean
  role: RoleModel
  roleUuid?: string
  funilDeVenda?: FunilVendaModel
}

export interface UsuarioFilter {
  email?: FilterOptions<string>
  nome?: FilterOptions<string>
}

export interface UsuarioFilter1 {
  email?: string
  nome?: string
}

export const filters: UsuarioFilter = {
  email: {
    label: 'E-mail',
  },
  nome: {
    label: 'Nome',
  },
}

export async function obterUsuarioLogado(): Promise<UsuarioModel> {
  const response = await api.get<UsuarioModel>(`/administrador/usuarios`)
  return response.data
}

async function obterUsuariosPorUuids(
  uuids: Array<string>,
): Promise<Array<UsuarioModel>> {
  const response = await api.post(`/administrador/usuarios-por-uuids`, uuids)
  return response.data
}

const useQuery = () => {
  return useTQuery<UsuarioModel, AxiosError>(
    ['USUARIO_LOGADO'],
    () => {
      return obterUsuarioLogado()
    },
    {
      staleTime: 10 * 60 * 1000,
    },
  )
}

const useQueryObterUsuariosPorUuid = (uuids: Array<string>) => {
  return useTQuery<Array<UsuarioModel>, AxiosError>(
    ['USUARIOS', uuids],
    () => {
      return obterUsuariosPorUuids(uuids)
    },
    {
      staleTime: 0,
    },
  )
}

export default function useUsuario() {
  const uCrud = useCrud<UsuarioModel, AxiosError>(
    'administrador/usuarios',
    'Usuário',
    'male',
  )
  return {
    ...uCrud,
    obterUsuarioLogado,
    useQuery,
    obterUsuariosPorUuids,
    useQueryObterUsuariosPorUuid,
  }
}
