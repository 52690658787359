import { useCredentials, useForm } from '@data-c/hooks'
import { Button, ButtonContainer } from '@data-c/ui'
import {
  Alert,
  Box,
  Container,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import Error from 'components/Displays/Error'
import DocumentoTextField, {
  TIPO_DOCUMENTO,
} from 'components/DocumentoTextField'
import {
  useObterUsuarioPorEmpresaCodigo,
  sinalizarUsuarioMigrado,
} from 'hooks/queries/useMigrateAccount'
import { Configs, useCadastrarUsuario } from 'hooks/queries/useMioAuth'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import useValidations from 'hooks/useValidations'
import * as yup from 'yup'
import useNotification from 'hooks/useNotifications'
import WebAppInterface from 'interfaces/WebAppInterface'
import credentials from 'configs/credentials'

const schema = yup.object().shape({
  email: yup.string().email().nullable(true).required('Informe o email'),
  nome: yup.string().nullable(true).required('Informe o Nome'),
  cpf: yup.string().nullable(true).required('Informe o CPF'),
})

interface CreateAccountInputProps {
  uuid: string
  email: string
  nome: string
  cpf: string
  configs: Configs
}

declare const android: WebAppInterface
export default function MigrarConta() {
  const { chave } = useParams()
  const notifications = useNotification()
  // const navigate = useNavigate()
  const [isSubmitting, setSubmitting] = useState<boolean>(false)
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false)
  const { setValidationErrors, validationProps } = useValidations()
  const {
    data: formData,
    handleChange,
    setData,
  } = useForm<CreateAccountInputProps>({} as CreateAccountInputProps)
  const { mutateAsync: cadastrarUsuario } = useCadastrarUsuario()
  const chaveSplited = chave?.split('-') || []
  const empresaId = parseInt(chaveSplited[0])
  const vendedorCodigo = chaveSplited[1]
  const { data, isLoading, error } = useObterUsuarioPorEmpresaCodigo(
    empresaId,
    vendedorCodigo,
  )
  const { isAuthenticated } = useCredentials(credentials)

  function handleClick(resultCode?: number) {
    android.back(resultCode)
  }

  useEffect(() => {
    if (data) {
      const cnpjcpf =
        typeof data.cnpjcpf === 'string' && data.cnpjcpf.length === 1
          ? data.cnpjcpf
          : ''

      setData({
        uuid: data.uuid,
        nome: data.nome,
        email: data.email,
        cpf: cnpjcpf,
        configs: {
          fdv_empresa_id: empresaId || -1,
          fdv_vendedor_codigo: vendedorCodigo,
          fdv_vendedor_uuid: data.uuid,
        },
      })
    }
  }, [data])

  if (Boolean(error)) {
    return <Error />
  }

  if (!data?.empresaModel?.mioEmpresaUuid && !isLoading) {
    return (
      <Error
        title="Empresa indisponível"
        message={
          <p>
            Sua empresa ainda não está habilitada para realizar a migração das
            contas!
          </p>
        }
      />
    )
  }

  const handleSubmit = async () => {
    setValidationErrors(null)
    schema
      .validate(formData, { abortEarly: false })
      .then(async () => {
        setSubmitting(true)
        try {
          await cadastrarUsuario({
            empresaUuid: data?.empresaModel.mioEmpresaUuid || '',
            usuario: {
              email: formData.email,
              name: formData.nome,
              uuid: formData.uuid || '',
              documento: formData.cpf || '',
              permissao: {
                configs: { ...formData.configs },
              },
            },
          })
          await sinalizarUsuarioMigrado(empresaId, vendedorCodigo)
          setShowSuccessMessage(true)
        } catch (e) {
          notifications.notifyException(e)
        }
      })
      .catch((err) => {
        setValidationErrors(err)
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  if (isAuthenticated) {
    return (
      <Box sx={{ height: '100vh' }}>
        <Container
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Alert
            variant="outlined"
            severity="success"
            action={
              <Button
                variant="contained"
                color="success"
                onClick={() => android.back()}
              >
                Continuar
              </Button>
            }
          >
            Você está logado na sua conta
          </Alert>
        </Container>
      </Box>
    )
  }
  const emailValidation = validationProps('email')
  return (
    <Box sx={{ height: '100vh' }}>
      <Container
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Paper sx={{ p: 2 }}>
          <Stack spacing={2}>
            <Typography
              sx={{
                fontWeight: 'bold',
                pb: 2,
              }}
              variant="h1"
            >
              Crie sua conta
              <br /> Data C
            </Typography>

            {isLoading && 'Carregando...'}

            {!showSuccessMessage && !data?.migracaoMio && !isLoading && (
              <>
                <DocumentoTextField
                  disabled={showSuccessMessage}
                  inputProps={{
                    label: 'CPF',
                  }}
                  tipoDocumento={TIPO_DOCUMENTO.CPF}
                  name="cpf"
                  onChange={handleChange}
                  value={formData?.cpf || ''}
                  {...validationProps('cpf')}
                />
                <TextField
                  disabled={showSuccessMessage}
                  label="Nome"
                  name="nome"
                  onChange={handleChange}
                  value={formData?.nome || ''}
                  {...validationProps('nome')}
                />
                <TextField
                  disabled={showSuccessMessage}
                  label="E-mail"
                  name="email"
                  onChange={handleChange}
                  value={formData?.email || ''}
                  helperText={
                    emailValidation.error
                      ? emailValidation.helperText
                      : 'Informe um e-mail que você consegue acessar, sua senha de acesso será enviada para ele.'
                  }
                  error={emailValidation.error}
                />
                <Button
                  disabled={showSuccessMessage}
                  isLoading={isSubmitting}
                  variant="contained"
                  onClick={handleSubmit}
                >
                  Criar minha conta
                </Button>
              </>
            )}

            {showSuccessMessage && !Boolean(data?.migracaoMio) && (
              <Alert
                variant="outlined"
                severity="success"
                action={
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() => handleClick(99)}
                  >
                    Voltar para o app
                  </Button>
                }
              >
                Sua conta Data C foi criada com sucesso, <br /> Enviamos um
                e-mail para <b>{formData.email}</b> com as informações de
                acesso.
              </Alert>
            )}

            {Boolean(data?.migracaoMio) && (
              <Alert
                variant="outlined"
                severity="info"
                action={
                  <ButtonContainer>
                    <Button
                      variant="contained"
                      color="success"
                      onClick={() => handleClick(98)}
                    >
                      Voltar
                    </Button>
                  </ButtonContainer>
                }
              >
                Você já possui uma conta Data C
              </Alert>
            )}
          </Stack>
        </Paper>
      </Container>
    </Box>
  )
}
