import { CurrencyCellStyle, DataTable } from '@data-c/ui'
import Surface from 'components/Surface'
import { ClientesModel } from 'hooks/queries/useClientes'
import useRelatorioFichaCliente from 'hooks/queries/useRelatorioFichaCliente'
import { SortProps } from 'interfaces/SortProps'
import { MUIDataTableColumnDef } from 'mui-datatables'
import { useMemo, useState } from 'react'

export interface TableProps {
  cliente: ClientesModel | null
  dataInicio: string | null
}

export default function Table(props: TableProps) {
  const { cliente, dataInicio } = props
  const [sort, setSort] = useState<SortProps>({
    column: 'nome',
    direction: 'asc',
  })
  const { useQueryObterRelatorioFichaCliente } = useRelatorioFichaCliente()

  const { data, isLoading, isFetching, error } =
    useQueryObterRelatorioFichaCliente(
      {
        clienteUuid: cliente?.uuid || '',
        dataInicio: dataInicio || null,
      },
      sort,
    )

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'codigo',
        label: 'Código',
        options: {
          setCellProps() {
            return { width: '96px' }
          },
        },
      },
      {
        name: 'nome',
        label: 'Nome',
      },
      {
        name: 'ultimaCompra',
        label: 'Última Compra',
        options: {
          setCellProps() {
            return { width: '125px' }
          },
        },
      },
      {
        name: 'quantidadeItensComprados',
        label: 'Qtd. Total Comprado',
        options: {
          setCellProps() {
            return { width: '96px' }
          },
        },
      },
      {
        name: 'quantidadeRecorrenteCompra',
        label: 'Qtd. Vezes Comprado',
        options: {
          setCellProps() {
            return { width: '96px' }
          },
        },
      },
      {
        name: 'menorPreco',
        label: 'Menor Preço',
        options: {
          setCellProps: CurrencyCellStyle,
        },
      },
      {
        name: 'maiorPreco',
        label: 'Maior Preço',
        options: {
          setCellProps: CurrencyCellStyle,
        },
      },
      {
        name: 'ultimoPreco',
        label: 'Último Preço',
        options: {
          setCellProps: CurrencyCellStyle,
        },
      },
    ],
    [data],
  )

  return (
    <Surface>
      <DataTable
        error={error?.message}
        columns={columns}
        data={data || []}
        isLoading={isLoading}
        isFetching={isFetching}
        options={{
          sort: true,
          sortOrder: {
            name: sort.column,
            direction: sort.direction,
          },
        }}
        changeSort={(column, direction) => {
          setSort({ column, direction: direction as 'asc' | 'desc' })
        }}
      />
    </Surface>
  )
}
