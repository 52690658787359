import { FilterOptions } from '@data-c/providers'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import api from 'services/api'
import { ClientesModel } from './useClientes'
import { TimeVendasModel } from './useTimeVendas'
import { DateRange } from 'components/Inputs/DateRangePicker'
import { DateTime } from 'luxon'
import { HttpRequestInterface } from '@data-c/hooks'

interface TotalPedidosModel {
  totalPeriodoAtual: number
  totalPeriodoAnterior: number
  percentual: number
}

interface EvolucaoVendasModel {
  total: number
  mediaMensal: number
  crescimentoMedioPorMes: {
    valor: number
    percentual: number
  }
  data: Array<{
    total: number
    mes: string
  }>
}

interface MetaVendasModel {
  totalMetaVendas: number
}

interface TopClientesModel {
  data: Array<{
    nome: string
    total: number
  }>
}

export interface GerenteComercialFilters {
  dataInicial: FilterOptions<string>
  dataFinal: FilterOptions<string>
  situacao: FilterOptions<string>
  cliente: FilterOptions<ClientesModel>
  clienteUuid: FilterOptions<string>
  representanteUuid: FilterOptions<string>
  representante: FilterOptions<TimeVendasModel>
  dateRange?: FilterOptions<DateRange>
}

export interface GerenteComercialFilters1 {
  plainQuery?: string | null
  dataInicial?: string | null
  dataFinal?: string | null
  tipoPeriodo?: string | null
  dateRange?: DateRange
  situacao?: string
  cliente?: ClientesModel
  clienteUuid?: string
  representante?: TimeVendasModel
  representanteUuid?: string
}

export const filters: GerenteComercialFilters = {
  dataInicial: {
    label: 'Data Inicial',
  },
  dataFinal: {
    label: 'Data Final',
  },
  situacao: {
    value: 'emissao',
    label: 'Situação',
  },
  cliente: {
    label: 'Cliente',
  },
  clienteUuid: {
    label: 'Cliente',
  },
  representante: {
    label: 'Vendedor',
  },
  representanteUuid: {
    value: '',
    label: 'Vendedor',
  },
  dateRange: {
    label: 'Período',
    value: {
      tipo: 'month',
      firstDate: DateTime.now().startOf('month').toFormat('yyyy-MM-dd'),
      secondDate: DateTime.now().endOf('day').toFormat('yyyy-MM-dd'),
    },
  },
}

export async function obterTotalPedidos(
  params: HttpRequestInterface<GerenteComercialFilters1>,
): Promise<TotalPedidosModel> {
  const { queryParams } = params
  const response = await api.get<TotalPedidosModel>(
    'gerente-comercial/pedido/total',
    {
      params: queryParams,
    },
  )
  return response.data
}

export function useQueryObterTotalPedidos(
  params: HttpRequestInterface<GerenteComercialFilters1>,
) {
  return useQuery<TotalPedidosModel, AxiosError>(
    ['TOTAL_PEDIDOS', params],
    () => {
      return obterTotalPedidos(params)
    },
  )
}

export async function obterEvolucaoVendas(
  params: HttpRequestInterface<GerenteComercialFilters1>,
): Promise<EvolucaoVendasModel> {
  const { queryParams } = params
  const response = await api.get<EvolucaoVendasModel>(
    'gerente-comercial/pedido/evolucao-vendas',
    {
      params: queryParams,
    },
  )
  return response.data
}

export function useQueryObterEvolucaoVendas(
  params: HttpRequestInterface<GerenteComercialFilters1>,
) {
  return useQuery<EvolucaoVendasModel, AxiosError>(
    ['EVOLUCAO_VENDAS', params],
    () => {
      return obterEvolucaoVendas(params)
    },
  )
}

export async function obterMetaVendas(
  params: HttpRequestInterface<GerenteComercialFilters1>,
): Promise<MetaVendasModel> {
  const { queryParams } = params
  const response = await api.get<MetaVendasModel>(
    'gerente-comercial/pedido/meta-vendas',
    {
      params: queryParams,
    },
  )
  return response.data
}

export function useQueryObterMetaVendas(
  params: HttpRequestInterface<GerenteComercialFilters1>,
) {
  return useQuery<MetaVendasModel, AxiosError>(['META_VENDAS', params], () => {
    return obterMetaVendas(params)
  })
}

export async function obterTopClientes(
  params: HttpRequestInterface<GerenteComercialFilters1>,
): Promise<TopClientesModel> {
  const { queryParams } = params
  const response = await api.get<TopClientesModel>(
    'gerente-comercial/pedido/top-clientes',
    {
      params: queryParams,
    },
  )
  return response.data
}

export function useQueryObterTopClientes(
  params: HttpRequestInterface<GerenteComercialFilters1>,
) {
  return useQuery<TopClientesModel, AxiosError>(
    ['TOP_CLIENTES', params],
    () => {
      return obterTopClientes(params)
    },
  )
}

export async function obterTopVendedores(
  params: HttpRequestInterface<GerenteComercialFilters1>,
): Promise<TopClientesModel> {
  const { queryParams } = params
  const response = await api.get<TopClientesModel>(
    'gerente-comercial/pedido/top-vendedores',
    {
      params: queryParams,
    },
  )
  return response.data
}

export function useQueryObterTopVendedores(
  params: HttpRequestInterface<GerenteComercialFilters1>,
) {
  return useQuery<TopClientesModel, AxiosError>(
    ['TOP_VENDEDORES', params],
    () => {
      return obterTopVendedores(params)
    },
  )
}

export default function useGerenteComercial() {
  return {
    // ...useCrud<IndicadorPedidoModel, AxiosError>(
    //   'administrador/forma-pagamento',
    //   'Forma de Pagamento',
    //   'female',
    // ),
    useQueryObterTotalPedidos,
    useQueryObterEvolucaoVendas,
    useQueryObterMetaVendas,
    useQueryObterTopClientes,
    useQueryObterTopVendedores,
  }
}
