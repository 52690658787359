import { useMemo } from 'react'

import { MUIDataTableColumnDef } from 'mui-datatables'

import { CurrencyCellStyle, DataTable } from '@data-c/ui'
import { formatCurrency, usePagination } from '@data-c/hooks'
import useVendedores, { VendedorModel } from 'hooks/queries/useVendedores'

export interface TableProps {
  vendedor: VendedorModel
}

export default function Table(props: TableProps) {
  const { vendedor } = props

  const { changePageSize, changePage, pagination } = usePagination()
  const { useQueryObterLogMetaVendaPorVendedorId } = useVendedores()
  const { data, isLoading, error } = useQueryObterLogMetaVendaPorVendedorId({
    vendedorUuid: vendedor.uuid,
    pagination,
  })

  const logsMetaVenda = data?.data.map((logMetaVenda) => {
    return {
      ...logMetaVenda,
      // nome: logMetaVenda.vendedor.usual,
      metaVendaFormatada: formatCurrency(logMetaVenda.metaVenda),
      dataFormatada: new Intl.DateTimeFormat('pt-BR', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      }).format(new Date(logMetaVenda.data)),
    }
  })

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'dataFormatada',
        label: 'Data Início',
      },
      {
        name: 'metaVendaFormatada',
        label: 'Meta de Venda',
        options: {
          setCellProps: CurrencyCellStyle,
        },
      },
    ],
    [],
  )

  return (
    <DataTable
      error={error?.message}
      isLoading={isLoading}
      columns={columns}
      data={logsMetaVenda || []}
      pagination={data?.pagination}
      changePage={changePage}
      changePageSize={changePageSize}
      options={{
        rowsPerPageOptions: [15, 50, 100],
        rowsPerPage: 15,
      }}
    />
  )
}
