import useCrud from 'hooks/useCrud'
import { AxiosError } from 'axios'
import BaseModel from 'interfaces/BaseModel'

export interface MotivosDePerdaModel extends BaseModel {
  descricao: string
  ativo: boolean
  informarObservacao: boolean
}

export default function useMotivosDePerda() {
  return useCrud<MotivosDePerdaModel, AxiosError>(
    '/crm/motivos-de-perda',
    'Motivos de Perda',
    'male',
  )
}
