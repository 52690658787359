import {
  DataTable,
  Flag,
  OptionStyles,
  DataTableOptions as Options,
} from '@data-c/ui'
import { MUIDataTableColumnDef } from 'mui-datatables'
import { formatToCPFOrCNPJ } from 'brazilian-values'
import { useFormApi } from '@data-c/providers'
import { useMemo, useCallback } from 'react'
import {
  TransportableDataTableProps,
  usePagination,
  findData,
} from '@data-c/hooks'
import useClientes, { ClientesModel } from 'hooks/queries/useClientes'

interface TableProps extends TransportableDataTableProps<ClientesModel> {
  vendedorUuid?: string
}

export default function Table(props: TableProps) {
  const { query, vendedorUuid, onTransport, enableTransporter } = props
  const { pagination, changePageSize, changePage } = usePagination()
  const { useObterClientes } = useClientes()

  const { data, isLoading, isFetching, error } = useObterClientes(
    {
      queryParams: {
        plainQuery: query,
        ativo: true,
        vendedorUuid,
      },
      pagination,
    },
    vendedorUuid,
  )

  const { openForm, openConfirm } = useFormApi()

  const clientes = useMemo(() => {
    return data?.data?.map((cliente) => {
      return {
        ...cliente,
        cnpjcpf: formatToCPFOrCNPJ(cliente.cnpjcpf),
      }
    })
  }, [data?.data])

  const handleClickItem = useCallback(
    (event: 'transport' | 'edit', data: ClientesModel) => {
      if (event === 'transport') {
        onTransport && onTransport(data)
      }
      if (event === 'edit') {
        openForm(data)
      }
    },
    [openForm, openConfirm, onTransport],
  )

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'codigo',
        label: 'Código',
        options: {
          setCellProps() {
            return { width: '96px' }
          },
        },
      },
      {
        name: 'cnpjcpf',
        label: 'CNPJ/CPF',
      },
      {
        name: 'nome',
        label: 'Razão Social',
      },
      {
        name: 'usual',
        label: 'Nome Fantasia',
      },
      {
        name: 'ativo',
        label: 'Ativo',
        options: {
          setCellProps: () => ({
            style: {
              width: '60px',
              textAlign: 'center',
            },
          }),
          customBodyRender: (value) => {
            return <Flag isFlagged={value === 1} type="success" />
          },
        },
      },
      {
        name: 'uuid',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          setCellProps: OptionStyles,
          customBodyRender: (value) => {
            const _data = findData(data?.data || [], value, 'uuid')
            return (
              <Options
                displayTransporterButton={enableTransporter}
                displayDeleteButton={false}
                displayUpdateButton={true}
                value={_data}
                onClick={handleClickItem}
              />
            )
          },
        },
      },
    ],
    [data?.data, handleClickItem, enableTransporter],
  )

  return (
    <DataTable
      error={error?.response?.data?.message}
      pagination={data?.pagination}
      changePage={changePage}
      changePageSize={changePageSize}
      columns={columns}
      data={clientes || []}
      isLoading={isLoading}
      isFetching={isFetching}
      options={{
        rowsPerPageOptions: [15, 50, 100],
        rowsPerPage: 15,
      }}
    />
  )
}
