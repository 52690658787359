import { Alert, Stack } from '@mui/material'
import Table from './components/Table'
import { ClientesModel } from 'hooks/queries/useClientes'
import { Button } from '@data-c/ui'
import useDialog from 'hooks/useDialog'
import MioAuthForm from './components/MioAuthForm'
import MioAuthAccount from './components/MioAuthAccount'

export interface TabPontosProps {
  cliente: ClientesModel
}

export default function TabPontos(props: TabPontosProps) {
  const { cliente } = props
  const { isOpen, openDialog, closeDialog } = useDialog()
  return (
    <Stack spacing={2}>
      {Boolean(cliente.migracaoMio) && (
        <MioAuthAccount clienteUuid={cliente?.uuid || ''} />
      )}
      {!Boolean(cliente.migracaoMio) && (
        <Stack>
          <Alert
            severity="warning"
            action={
              <Button onClick={openDialog} variant="contained" color="primary">
                Cadastrar agora
              </Button>
            }
          >
            <Stack>
              Seu cliente ainda não possui cadastro no programa de fidelidade.
              <br />
              Realize o cadastro agora para que ele aproveite todos os
              benefícios disponíveis!
            </Stack>
          </Alert>
        </Stack>
      )}
      <Table clienteUuid={cliente?.uuid || ''} />

      <MioAuthForm cliente={cliente} open={isOpen} onClose={closeDialog} />
    </Stack>
  )
}
