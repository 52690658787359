import { Button, ButtonContainer, PageTitle } from '@data-c/ui'
import { Container } from '@mui/material'
import Table from './components/Table'
import { FilterProvider, FormProvider } from '@data-c/providers'
import Form from './components/Form'
import { useDesvincularUsuario } from 'hooks/queries/useMioAuth'
import Delete from 'components/Delete'
// import HelpButton from 'components/HelpButton'
import { useNavigate } from 'react-router-dom'
import Content from 'components/Layouts/Content'
import { filters } from 'hooks/queries/useUsuario'
import Filter from './components/Filter'

export default function ContasDeUsuarios() {
  const { mutateAsync: handleDelete } = useDesvincularUsuario()
  const navigate = useNavigate()

  return (
    <Container>
      <FormProvider>
        <FilterProvider filterValues={filters}>
          <PageTitle title="Usuários do Sistema">
            <ButtonContainer>
              <Button onClick={() => navigate('/permissoes')}>
                Permissões
              </Button>
              <Filter />
              <Form />
              {/* <HelpButton link="https://ember-health-873.notion.site/Vincular-conta-de-usu-rios-Data-C-72ee8865cad54b0c8fef7e7a2a7d2a0f?pvs=4" /> */}
            </ButtonContainer>
          </PageTitle>
          <Content>
            <Table />
          </Content>
        </FilterProvider>
        <Delete onDelete={handleDelete} />
      </FormProvider>
    </Container>
  )
}
