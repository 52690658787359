import useCrud from 'hooks/useCrud'
import { AxiosError } from 'axios'
import api from 'services/api'
import { ErrorInterface } from '@data-c/hooks'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import useNotification from 'hooks/useNotifications'

export interface PainelDesempenhoModel {
  uuid?: string
  nome: string
  usuarioUuid?: string
}

export async function obterPaineisDesempenho(): Promise<
  PainelDesempenhoModel[]
> {
  const response = await api.get<PainelDesempenhoModel[]>(
    'administrador/painel-desempenho',
  )
  return response.data
}

export function useQueryObterPaineisDesempenho() {
  return useQuery<
    PainelDesempenhoModel[],
    AxiosError<ErrorInterface, ErrorInterface>
  >(['PAINEL_DESEMPENHO'], () => {
    return obterPaineisDesempenho()
  })
}

export async function createPainelDesempenho(
  data: PainelDesempenhoModel,
): Promise<PainelDesempenhoModel> {
  const response = await api.post(`administrador/painel-desempenho`, data)
  return response.data
}

export function useCreatePainelDesempenho() {
  const queryClient = useQueryClient()
  const notifications = useNotification()

  return useMutation<PainelDesempenhoModel, AxiosError, PainelDesempenhoModel>(
    (data: PainelDesempenhoModel) => createPainelDesempenho(data),
    {
      onSuccess() {
        notifications.notifySuccess(`Painel de Desempenho criado com sucesso`)
        queryClient.invalidateQueries(['PAINEL_DESEMPENHO'])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export async function updatePainelDesempenho(
  id: string,
  data: string,
): Promise<PainelDesempenhoModel> {
  const response = await api.put(`administrador/painel-desempenho/${id}`, {
    data,
  })
  return response.data
}

export function useUpdatePainelDesempenho() {
  const queryClient = useQueryClient()
  const notifications = useNotification()

  return useMutation<PainelDesempenhoModel, AxiosError, PainelDesempenhoModel>(
    (data: PainelDesempenhoModel) =>
      updatePainelDesempenho(data.uuid || '', data.nome),
    {
      onSuccess() {
        notifications.notifySuccess(`Painel de Desempenho alterado com sucesso`)
        queryClient.invalidateQueries(['PAINEL_DESEMPENHO'])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export async function deletePainelDesempenho(
  id: string,
): Promise<PainelDesempenhoModel> {
  const response = await api.delete(`administrador/painel-desempenho/${id}`)
  return response.data
}

export function useDeletePainelDesempenho() {
  const queryClient = useQueryClient()
  const notifications = useNotification()

  return useMutation<PainelDesempenhoModel, AxiosError, string>(
    (data: string) => deletePainelDesempenho(data),
    {
      onSuccess() {
        notifications.notifySuccess(`Painel de Desempenho excluído com sucesso`)
        queryClient.invalidateQueries(['PAINEL_DESEMPENHO'])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export default function usePainelDesempenho() {
  return {
    ...useCrud<PainelDesempenhoModel, AxiosError>(
      'administrador/painel-desempenho',
      'Painel de Desempenho',
      'male',
    ),
    useQueryObterPaineisDesempenho,
    useUpdatePainelDesempenho,
    useDeletePainelDesempenho,
    useCreatePainelDesempenho,
  }
}
