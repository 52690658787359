import { useState } from 'react'
import { PlainQueryProps } from '@data-c/hooks'
import { Transporter, TransporterProps } from '@data-c/ui'
import useProdutos, { ProdutoModel } from 'hooks/queries/useProdutos'
import ProdutoForm from 'pages/Produtos/components/Form'
import ProdutoTable from 'pages/Produtos/components/Table'

type ProdutoTransporterProps = {} & Omit<
  TransporterProps<ProdutoModel>,
  'table' | 'form' | 'onSearch' | 'renderValue' | 'options'
>

export default function ProdutoTransporter(props: ProdutoTransporterProps) {
  const { value, ...rest } = props
  const [query, setQuery] = useState<string | null>()
  const { useQuery } = useProdutos()

  const { data, isLoading } = useQuery<PlainQueryProps>({
    queryParams: {
      plainQuery: query,
    },
    pagination: {
      page: 1,
      pageSize: 100,
    },
  })

  function handleSearch(q: string | null) {
    setQuery(q)
  }

  return (
    <Transporter
      onSearch={handleSearch}
      options={data?.data || []}
      isLoading={isLoading}
      table={ProdutoTable}
      label="Produto"
      renderValue={(value) => {
        return `${value?.codigo || ''} - ${value.nome}`
      }}
      form={ProdutoForm}
      value={value}
      {...rest}
    />
  )
}
