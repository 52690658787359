import { Paper, PaperProps } from '@mui/material'
// import { forwardRef } from 'react'

// const Surface = forwardRef<HTMLDivElement, PaperProps>(function Surface(
//   props: PaperProps,
//   ref,
// ) {
//   return <Paper ref={ref} sx={{ p: 2 }} elevation={0} {...props} />
// })

// export default Surface

export default function Surface(props: PaperProps) {
  return <Paper sx={{ p: 2 }} elevation={0} {...props} />
}
