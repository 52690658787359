import {
  DataTable,
  OptionStyles,
  DataTableOptions as Options,
  Flag,
} from '@data-c/ui'
import { MUIDataTableColumnDef } from 'mui-datatables'
import useFormaPagamento, {
  FormaPagamentoFilter,
  FormaPagamentoFilter1,
  FormaPagamentoModel,
} from 'hooks/queries/useFormaPagamento'

import { useFilterData, useFormApi } from '@data-c/providers'
import { useMemo, useCallback } from 'react'
import { usePagination } from '@data-c/hooks'
import Surface from 'components/Surface'
import { useNavigate } from 'react-router-dom'

export default function Table() {
  const { pagination, changePageSize, changePage } = usePagination()
  const { useQuery } = useFormaPagamento()
  const { appliedValues } = useFilterData<
    FormaPagamentoFilter,
    FormaPagamentoFilter1
  >()
  const { data, isLoading, isFetching, error } = useQuery({
    queryParams: appliedValues,
    pagination,
  })
  const { openConfirm } = useFormApi()
  const navigate = useNavigate()
  const findData = useCallback(
    (value: string) => {
      return data?.data.find((d: FormaPagamentoModel) => d.uuid === value)
    },
    [data],
  )

  const handleClickItem = useCallback(
    (event: 'edit' | 'delete', data: FormaPagamentoModel) => {
      switch (event) {
        case 'edit':
          navigate(`/forma-pagamento/forma-pagamento/${data?.uuid}`)
          break
        case 'delete':
          openConfirm(data)
          break
      }
    },
    [openConfirm],
  )

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'codigo',
        label: 'Código',
        options: {
          setCellProps: () => ({
            style: {
              width: '96px',
            },
          }),
        },
      },
      {
        name: 'nome',
        label: 'Nome',
      },
      {
        name: 'ativo',
        label: 'Ativo',
        options: {
          setCellProps: () => ({
            style: {
              width: '60px',
              'text-align': 'center',
            },
          }),
          customBodyRender: (value) => {
            return <Flag isFlagged={value === 1} type="success" />
          },
        },
      },
      {
        name: 'uuid',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          setCellProps: OptionStyles,
          customBodyRender: (value) => {
            const _data = findData(value)
            return (
              <Options
                displayUpdateButton={true}
                value={_data}
                onClick={handleClickItem}
              />
            )
          },
        },
      },
    ],
    [findData, handleClickItem],
  )

  return (
    <Surface>
      <DataTable
        error={error?.message}
        columns={columns}
        data={data?.data || []}
        pagination={data?.pagination}
        isLoading={isLoading}
        isFetching={isFetching}
        changePage={changePage}
        changePageSize={changePageSize}
        options={{
          rowsPerPageOptions: [15, 50, 100],
          rowsPerPage: 15,
        }}
      />
    </Surface>
  )
}
