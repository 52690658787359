import {
  ListItem,
  ListItemButton as MuiListItem,
  ListItemIcon,
  styled,
  ListItemText,
  Popper,
  Icon as MuiIcon,
} from '@mui/material'
import { MenuItemProps } from './MenuItem'
import Icon from '../Icon'
import _ from 'lodash'
import React, { ReactNode, useState } from 'react'
import { Surface } from '@data-c/ui'

const ListItemButton = styled(
  MuiListItem,
  {},
)(({ theme }) => ({
  cursor: 'pointer',
  //   transition: '0.2s ease-in-out',
  padding: `${theme.spacing(1)} ${theme.spacing(0)} ${theme.spacing(
    0.25,
  )} ${theme.spacing(0)}`,
  minHeight: '32px',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItem: 'center',
  alignContent: 'center',
  '&:hover': {
    backgroundColor: 'rgba(89, 195, 224, 15%);',
    '& .MuiTypography-root': {
      color: theme.palette.primary.main,
      transition: '0.2s ease-in-out',
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.main,
      transition: '0.2s ease-in-out',
    },
    '& .MuiIcon-root': {
      color: theme.palette.primary.main,
      transition: '0.2s ease-in-out',
    },
  },
  '& .MuiIcon-root': {
    color: theme.palette.grey[500],
    fontSize: '20px',
  },
  '& .MuiSvgIcon-root': {
    fontSize: '0.955rem',
  },
  '& .MuiTypography-root': {
    // fontWeight: 'bolder',
    fontSize: '0.805rem',
    color: theme.palette.text.primary,
  },
  '& .MuiListItemIcon-root': {
    minWidth: '0px',
  },
}))

interface OpenedStateProps {
  element: HTMLElement
  id: string
}

function cloneMenuItems(
  childrenProp: React.ReactNode,
  open: boolean,
): React.ReactNode {
  return React.Children.map(childrenProp, (child) => {
    if (!React.isValidElement<MenuItemProps>(child)) {
      return child
    }

    const newChildren = child.props?.children
      ? cloneMenuItems(child.props.children, open)
      : null

    return React.cloneElement(child, {
      open: open,
      children: newChildren,
    })
  })
}

export default function MinMenuItem(props: MenuItemProps) {
  const {
    icon,
    label,
    link,
    id,
    selected = false,
    children: childrenProps,
    onClick,
  } = props
  const [opened, setOpened] = useState<OpenedStateProps | null>(null)

  function handleClick() {
    if (onClick && link) {
      onClick(link)
    }
  }

  function handleOpen(event: React.MouseEvent<HTMLElement>, id: string) {
    setOpened({
      element: event.currentTarget,
      id,
    })
  }

  function handleClose() {
    setOpened(null)
  }

  const newChildren = cloneMenuItems(childrenProps, true)

  function renderIcon(_icon: ReactNode) {
    if (typeof icon === 'string') {
      return <MuiIcon>{icon}</MuiIcon>
    }
    return _icon ? _icon : <Icon.Circle />
  }

  return (
    <ListItem disableGutters disablePadding>
      <ListItemButton
        selected={selected}
        onMouseEnter={(e: React.MouseEvent<HTMLElement>) => {
          if (!link) {
            handleOpen(e, id)
          }
        }}
        onMouseLeave={() => {
          if (!link) {
            handleClose()
          }
        }}
        disableGutters
        onClick={(e) => {
          e.stopPropagation()
          handleClick()
        }}
      >
        <ListItemIcon>{renderIcon(icon)}</ListItemIcon>
        {<ListItemText primary={_.truncate(label, { length: 12 })} />}
        {opened?.id === id && (
          <Popper
            sx={{
              zIndex: 999999,
            }}
            anchorEl={opened?.element}
            open={Boolean(opened?.element)}
            placement="right-start"
          >
            <Surface
              elevation={1}
              sx={{
                position: 'relative',
                '&::before': {
                  content: '""', // Vazio, pois background será o ícone
                  position: 'absolute',
                  left: -8,
                  width: '1.2rem', // Largura do ícone
                  height: '1.2rem', // Altura do ícone
                  backgroundImage: `url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiM4MDgwODAiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBjbGFzcz0ibHVjaWRlIGx1Y2lkZS1waW4tc2FmZXR5LW9wZW4iPjxwYXRoIGQ9Ik0yMC44IDMuMmMtMS42LTEuNi00LjEtMS42LTUuNyAwTDEyLjMgNlMxNSA5IDE4IDZjLTMgMyAwIDUuNyAwIDUuN2wyLjgtMi44YzEuNi0xLjYgMS42LTQuMiAwLTUuNyIvPjxwYXRoIGQ9Im03LjEgMjEuMSAxMC4zLTEwLjIiLz48Y2lyY2xlIGN4PSI1IiBjeT0iMTkiIHI9IjMiLz48cGF0aCBkPSJNOSAycy00LjEgOS41LTYuNzU1IDE1LjgiLz48L3N2Zz4=")`, // SVG codificado como data URI
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  // transform: 'rotate(180deg)',
                  transition: 'transform 0.2s ease-in-out',
                },
                p: 1,
                width: (theme) => theme.mixins.menu?.width,
                //   borderTop: (theme) => `1px solid ${theme.palette.grey[400]}`,
                //   borderRight: (theme) => `1px solid ${theme.palette.grey[400]}`,
                //   borderBottom: (theme) => `1px solid ${theme.palette.grey[400]}`,
              }}
            >
              {newChildren}
            </Surface>
          </Popper>
        )}
      </ListItemButton>
    </ListItem>
  )
}
