import { formatCurrency } from '@data-c/hooks'
import { Surface, theme } from '@data-c/ui'
import { DateTime } from 'luxon'
import { useMemo } from 'react'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Line,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts'

const CustomTooltip = ({ payload, label }: TooltipProps<any, any>) => {
  if (payload && payload.length) {
    const { value } = payload[0]
    return (
      <Surface>
        <p>{`Mês: ${DateTime.fromISO(label + '-01').toFormat('MM/yy')}`}</p>
        <p>{`Total: R$ ${formatCurrency(value)}`}</p>
      </Surface>
    )
  }
  return null
}

const CustomYAxisTick = (props: any) => {
  const { x, y, payload } = props

  const formatValue = (value: number) => {
    if (value >= 1_000_000_000) {
      return `R$ ${(value / 1_000_000_000).toFixed(1)}B`
    }
    if (value >= 1_000_000) {
      return `R$ ${(value / 1_000_000).toFixed(1)}M`
    }
    if (value >= 1_000) {
      return `R$ ${value / 1_000}K`
    }
    return `R$ ${formatCurrency(value.toFixed(2))}`
  }

  return (
    <text x={x} y={y} dy={0} textAnchor="end" width={60} fill="#666">
      {formatValue(payload.value)}
    </text>
  )
}

interface ChartProps {
  data: Array<{ total: number; mes: string }>
}

export default function Chart(props: ChartProps) {
  const { data } = props

  const formattedData = useMemo(() => {
    return data.map((d) => {
      return {
        ...d,
        total: d.total,
      }
    })
  }, [data])

  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        data={formattedData}
        margin={{
          top: 60,
          right: 30,
          left: 30,
          bottom: 12,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="mes"
          tickFormatter={(value) => {
            return DateTime.fromISO(value + '-01').toFormat('MM/yy')
          }}
          axisLine={false}
          tickLine={false}
          tickMargin={15}
          // angle={-30}
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          tickMargin={15}
          tick={<CustomYAxisTick />}
        />
        <Tooltip content={<CustomTooltip />} />
        {/* <Legend /> */}
        <Bar dataKey="total" fill={theme.palette.primary.main} radius={5} />
        <Line
          type="monotone"
          dataKey="total"
          stroke={theme.palette.primary.main}
        />
      </BarChart>
    </ResponsiveContainer>
  )
}
