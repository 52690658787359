import { useFilterApi, useFilterData } from '@data-c/providers'
import { Checkbox, FilterContainer, theme } from '@data-c/ui'
import { MenuItem, Stack, TextField } from '@mui/material'
import {
  ItemTabelaPrecoFilter,
  ItemTabelaPrecoFilter1,
} from 'hooks/queries/useTabelaPreco'
import { useQuery } from 'hooks/queries/useConfiguracoes'
import ClasseProdutoTransporter from 'components/Transporters/ClasseProdutoTransporter'
import { ClasseProdutosModel } from 'hooks/queries/useClasseProdutos'
import FamiliaProdutoTransporter from 'components/Transporters/FamiliaProdutoTransporter'
import { FamiliaProdutosModel } from 'hooks/queries/useFamiliaProdutos'
import GrupoProdutoTransporter from 'components/Transporters/GrupoProdutoTransporter'
import { GrupoProdutosModel } from 'hooks/queries/useGrupoProdutos'
import LinhaProdutoTransporter from 'components/Transporters/LinhaProdutoTransporter'
import { LinhaProdutosModel } from 'hooks/queries/useLinhaProdutos'
export default function Filter() {
  const { onChangeFilterValue, changeFilterValue } = useFilterApi()
  const { data: configuracoes } = useQuery()
  const { filterValues } = useFilterData<
    ItemTabelaPrecoFilter,
    ItemTabelaPrecoFilter1
  >()

  return (
    <FilterContainer
      title="Pesquisar por produtos da tabela"
      triggerButtonProps={{ variant: 'outlined' }}
      disableds={['categoriaKey']}
      renderLabel={(filterLabel: string, filterValue: any) => {
        if (filterLabel === 'qtdMinima') {
          return 'Apenas grade fechada'
        }
        if (filterLabel === 'codigoNomeCor') {
          return `Cor - ${filterValue}`
        }
        if (filterLabel === 'codigoNomeGrade') {
          return `Grade - ${filterValue}`
        }
        if (filterLabel === 'Categoria') {
          return `${filterLabel} - ${filterValue?.nome || ''}`
        }
        if (filterLabel === 'Tipo de Categoria') {
          return `${filterLabel} - ${
            (filterValue as string).toUpperCase() || ''
          }`
        }
        return `${filterLabel} - ${filterValue}`
      }}
    >
      <Stack spacing={2}>
        <TextField
          autoFocus
          name="codigoProduto"
          value={filterValues?.codigoProduto?.value}
          label={filterValues?.codigoProduto?.label}
          onChange={onChangeFilterValue}
        />
        <TextField
          name="nomeProduto"
          value={filterValues?.nomeProduto?.value}
          label={filterValues?.nomeProduto?.label}
          onChange={onChangeFilterValue}
        />
        {configuracoes?.modulo_pedido === 'COR' ||
          (configuracoes?.modulo_pedido === 'GRADE' && (
            <TextField
              name="codigoNomeCor"
              value={filterValues?.codigoNomeCor?.value}
              label={'Variação 1'}
              onChange={onChangeFilterValue}
              placeholder="Código ou nome da variação 1"
            />
          ))}

        {configuracoes?.modulo_pedido === 'GRADE' && (
          <TextField
            name="codigoNomeGrade"
            value={filterValues?.codigoNomeGrade?.value}
            label={'Variação 2'}
            onChange={onChangeFilterValue}
            placeholder="Código ou nome da variação 2"
          />
        )}

        {Boolean(configuracoes?.usa_grade_fechada) && (
          <Checkbox
            name="apenasQuantidadeMinima"
            label="Apenas grade fechada"
            checked={filterValues?.apenasQuantidadeMinima?.value}
            onChange={onChangeFilterValue}
          />
        )}

        <Stack
          sx={{
            gap: 2,
            border: `solid 1px ${theme.palette.primary.main}`,
            padding: 2,
            borderRadius: '4px',
          }}
        >
          <TextField
            select
            name="tipoCategoria"
            label={filterValues?.categoriaKey?.label}
            value={filterValues?.categoriaKey?.value || 'nenhuma'}
            onChange={(e) => {
              const key = e.target.value
              changeFilterValue('categoriaKey', key)
            }}
          >
            <MenuItem value="nenhuma">Todas</MenuItem>
            <MenuItem value="classe">Classe</MenuItem>
            <MenuItem value="familia">Família</MenuItem>
            <MenuItem value="grupo">Grupo</MenuItem>
            <MenuItem value="linha">Linha</MenuItem>
          </TextField>

          {filterValues?.categoriaKey?.value === 'classe' && (
            <ClasseProdutoTransporter
              value={filterValues?.categoriaValue?.value || null}
              onChange={(value: ClasseProdutosModel | null) => {
                changeFilterValue('categoriaValue', value)
              }}
            />
          )}

          {filterValues?.categoriaKey?.value === 'familia' && (
            <FamiliaProdutoTransporter
              value={filterValues?.categoriaValue?.value || null}
              onChange={(value: FamiliaProdutosModel | null) => {
                changeFilterValue('categoriaValue', value)
              }}
            />
          )}

          {filterValues?.categoriaKey?.value === 'grupo' && (
            <GrupoProdutoTransporter
              value={filterValues?.categoriaValue?.value || null}
              onChange={(value: GrupoProdutosModel | null) => {
                changeFilterValue('categoriaValue', value)
              }}
            />
          )}

          {filterValues?.categoriaKey?.value === 'linha' && (
            <LinhaProdutoTransporter
              value={filterValues?.categoriaValue?.value || null}
              onChange={(value: LinhaProdutosModel | null) => {
                changeFilterValue('categoriaValue', value)
              }}
            />
          )}
        </Stack>

        <Checkbox
          name="apenasQuantidadeMinima"
          label="Apenas grade fechada"
          checked={filterValues?.apenasQuantidadeMinima?.value}
          onChange={onChangeFilterValue}
        />
      </Stack>
    </FilterContainer>
  )
}
