import { usePagination } from '@data-c/hooks'
import { useFormApi } from '@data-c/providers'
import { DataTable, FormContainer } from '@data-c/ui'
import { Box, Grid } from '@mui/material'
import SearchTextField from 'components/SearchTextField'
import useFormaPagamento, {
  FormaPagamentoModel,
} from 'hooks/queries/useFormaPagamento'
import { MUIDataTableColumnDef } from 'mui-datatables'
import { useMemo, useState } from 'react'

interface FormProps {
  formaPagamento: FormaPagamentoModel
}

export default function Form(props: FormProps) {
  const { formaPagamento } = props
  const [selecionados, setSelecionados] = useState<number[]>([])
  const [query, setQuery] = useState('')
  const { toggleSubmit, closeForm } = useFormApi()
  const { pagination, changePage, changePageSize } = usePagination()
  const { useQueryObterCondicoesNaoVinculadas, useVincularCondicoes } =
    useFormaPagamento()
  const { mutateAsync: vincularCondicoes } = useVincularCondicoes()
  const {
    data: condicoesNaoVinculadas,
    isLoading,
    isFetching,
    error,
  } = useQueryObterCondicoesNaoVinculadas({
    plainQuery: query,
    formaPagamentoUuid: formaPagamento.uuid,
    pagination,
  })

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'codigo',
        label: 'Código',
        options: {
          setCellProps() {
            return { width: '96px' }
          },
        },
      },
      {
        name: 'nome',
        label: 'Nome',
      },
    ],
    [],
  )

  async function handleSubmit() {
    const idsSelecionados =
      selecionados.map(
        (index) => condicoesNaoVinculadas?.data[index]?.uuid || '',
      ) || []

    if (formaPagamento && selecionados.length > 0) {
      toggleSubmit(true)
      try {
        await vincularCondicoes({
          formaPagamentoUuid: formaPagamento.uuid,
          idsSelecionados,
        })
        setSelecionados([])
        closeForm()
      } finally {
        toggleSubmit(false)
      }
    }
  }

  return (
    <FormContainer
      onSubmitForm={handleSubmit}
      triggerButtonLabel="Vincular"
      confirmButtonLabel="Vincular"
      dialogProps={{ title: 'Condições de Pagamento', maxWidth: 'md' }}
    >
      <Box sx={{ mb: 1 }}>
        <SearchTextField
          placeholder="Digite o códgo ou o nome da condição de pagamento para pesquisar"
          onSearch={(q) => {
            setQuery(q)
          }}
        />
      </Box>
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <DataTable
            error={error?.message}
            isLoading={isLoading}
            isFetching={isFetching}
            columns={columns}
            data={condicoesNaoVinculadas?.data || []}
            changePage={changePage}
            changePageSize={changePageSize}
            pagination={condicoesNaoVinculadas?.pagination}
            options={{
              rowsPerPageOptions: [15, 50, 100],
              rowsPerPage: 15,
              selectableRows: 'multiple',
              selectableRowsOnClick: true,
              rowsSelected: selecionados,
              onRowSelectionChange: (_, __, rowsSelecteds: any) =>
                setSelecionados(rowsSelecteds),
              selectToolbarPlacement: 'none',
            }}
          />
        </Grid>
      </Grid>
    </FormContainer>
  )
}
