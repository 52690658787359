import React, { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import {
  Box,
  Button,
  IconButton,
  LinearProgress,
  Typography,
} from '@mui/material'
import { Delete } from '@mui/icons-material'
import { Surface } from '@data-c/ui'
import useMultipleUpload from 'hooks/useMultipleIpload'

interface PhotoUploaderProps {
  produtoUuid: string
  onUploadCompleted: () => void
}

const PhotoUploader: React.FC<PhotoUploaderProps> = ({
  onUploadCompleted,
  produtoUuid,
}) => {
  const { uploads, uploadFiles } = useMultipleUpload()
  const [selectedFiles, setSelectedFiles] = useState<File[]>([])
  const [previewUrls, setPreviewUrls] = useState<string[]>([])

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setSelectedFiles((prevFiles) => [...prevFiles, ...acceptedFiles])
    setPreviewUrls((prevUrls) => [
      ...prevUrls,
      ...acceptedFiles.map((file) => URL.createObjectURL(file)),
    ])
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { 'image/*': [] },
    multiple: true,
  })

  const handleRemoveImage = (index: number) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index))
    setPreviewUrls((prevUrls) => prevUrls.filter((_, i) => i !== index))
  }

  const handleUpload = async () => {
    if (selectedFiles.length > 0) {
      await uploadFiles(
        `/administrador/produtos/${produtoUuid}/upload-photos`,
        'photos',
        selectedFiles,
      )
      onUploadCompleted()
      setSelectedFiles([])
      setPreviewUrls([])
    }
  }

  return (
    <Surface
      sx={{
        p: 2,
        background: '#fff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box
        {...getRootProps()}
        border={1}
        borderColor={isDragActive ? 'primary.main' : 'grey.400'}
        borderRadius={2}
        p={4}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          alignContent: 'center',
          width: '100%',
          textAlign: 'center',
          //   minHeight: '350px',
          backgroundColor: isDragActive ? 'grey.200' : 'grey.100',
          cursor: 'pointer',
        }}
      >
        <input {...getInputProps()} />
        <Typography variant="body1">
          {isDragActive
            ? 'Solte as fotos aqui...'
            : 'Arraste e solte as fotos ou clique para selecionar'}
        </Typography>

        <Box display="flex" flexWrap="wrap" gap={2} mt={2}>
          {previewUrls.map((url, index) => (
            <Box
              sx={{ cursor: 'default' }}
              onClick={(e) => e.stopPropagation()}
              key={index}
              position="relative"
              display="inline-flex"
              flexDirection="column"
            >
              <img
                src={url}
                alt={`Preview ${index}`}
                style={{
                  width: 100,
                  height: 100,
                  objectFit: 'cover',
                  borderRadius: 8,
                }}
              />
              <IconButton
                onClick={(e) => {
                  e.stopPropagation()
                  handleRemoveImage(index)
                }}
                sx={{
                  position: 'absolute',
                  top: 2,
                  right: 2,
                  backgroundColor: 'white',
                  ':hover': { backgroundColor: 'red', color: 'white' },
                }}
              >
                <Delete fontSize="small" />
              </IconButton>
              {/* Exibir o progresso de upload do arquivo */}
              {uploads[index] && uploads[index].isUploading && (
                <Box mt={1} width="100%">
                  <LinearProgress
                    variant="determinate"
                    value={uploads[index].progress}
                  />
                </Box>
              )}
              {uploads[index] && uploads[index].error && (
                <Typography color="error" variant="caption">
                  {uploads[index].error}
                </Typography>
              )}
            </Box>
          ))}
        </Box>
      </Box>

      <Button
        sx={{ mt: 2 }}
        variant="contained"
        color="primary"
        onClick={handleUpload}
        disabled={selectedFiles.length === 0}
      >
        Upload Fotos
      </Button>
    </Surface>
  )
}

export default PhotoUploader
