import { useState } from 'react'
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  TextField,
} from '@mui/material'

import { usePagination } from '@data-c/hooks'

import {
  ClasseProdutosModel,
  obterClasseProdutos,
} from 'hooks/queries/useClasseProdutos'
import { AutoCompleteProps } from 'hooks/useAutocomplete'
import useNotification from 'hooks/useNotifications'

export default function AutocompleteClasseProdutos(
  props: AutoCompleteProps<ClasseProdutosModel>,
) {
  const { onChange, value } = props

  const [options, setOptions] = useState<ClasseProdutosModel[]>([])
  const [isLoading, setLoading] = useState(false)

  const { pagination } = usePagination()
  const notifications = useNotification()

  async function handleObterClasseProdutos() {
    if (options?.length > 0) return
    setLoading(true)
    try {
      const classeProdutos = await obterClasseProdutos({
        pagination,
        queryParams: { ativo: true },
      })

      setOptions(classeProdutos?.data)
    } catch (error) {
      notifications.notifyException(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Autocomplete
      onOpen={handleObterClasseProdutos}
      disablePortal
      options={options || []}
      onChange={onChange}
      getOptionLabel={(classeProduto: ClasseProdutosModel) => {
        return `${classeProduto.nome}`
      }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField {...params} value={value} label="Classe de Produtos" />
      )}
      loadingText="Carregando..."
      loading={isLoading}
    />
  )
}
