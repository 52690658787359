import { useDialog } from '@data-c/hooks'
import { Stack } from '@mui/material'
import { useUserContext } from 'components/Contexts/UserContext'
import ConfirmDialog from 'components/Displays/ConfirmDialog'
import PhotosList from 'components/PhotoList'
import PhotoUploader from 'components/PhotoUploader'
import useProdutos, { ProdutoModel } from 'hooks/queries/useProdutos'
import { generateEntityKey } from 'hooks/useCrud'
import queryClient from 'services/query-client'
import { makePath } from 'utils/photo-utils'

export interface TabPhotoProps {
  produtoUuid: string
}

export default function TabPhoto({ produtoUuid }: TabPhotoProps) {
  const { useQueryByUuid, useRemoverFoto } = useProdutos()
  const { mutateAsync: removerFoto, isLoading: isRemoving } = useRemoverFoto()
  const { data: produto } = useQueryByUuid<ProdutoModel>(produtoUuid as string)
  const { configuracoes } = useUserContext()
  const { data, isOpen, openDialog, closeDialog } = useDialog<string>()
  function handleUpalod() {
    const key = generateEntityKey('Produto')
    queryClient.invalidateQueries([key])
  }

  function handleRemove(photoUuid: string) {
    openDialog(photoUuid)
  }

  const fotos =
    produto?.fotos.map((p) => {
      const url = makePath(configuracoes?.nome_banco_mobile || '', p.fileName)
      return {
        url,
        uuid: p.uuid,
      }
    }) || []

  return (
    <Stack spacing={2}>
      <PhotoUploader
        onUploadCompleted={handleUpalod}
        produtoUuid={produtoUuid}
      />
      <PhotosList photos={fotos} onRemove={handleRemove} />
      <ConfirmDialog
        isOpen={isOpen}
        isLoading={Boolean(isRemoving)}
        close={closeDialog}
        onConfirm={() => {
          if (data) removerFoto(data)
        }}
        message="Você quer remover essa foto?"
        confirmLabel="Sim"
      />
    </Stack>
  )
}
