import { Button } from '@data-c/ui'
import { Box, Icon, Stack, useTheme } from '@mui/material'
import { forwardRef, ReactNode, useEffect, useState } from 'react'
import {
  DragDropContext,
  Draggable,
  Droppable,
  DroppableProps,
  DropResult,
} from 'react-beautiful-dnd'

interface DragAndDropProps<T> {
  data: T[]
  isLoading: boolean
  renderItem: (data: T) => ReactNode
  onChange: (newItemsOrder: Array<T>) => void
}

interface ItemListProps {
  isDragging?: boolean
  icon?: string
  children: ReactNode
}

interface ContainerProps {
  isDraggingOver?: boolean
  children: ReactNode
}

export const Container = forwardRef((props: ContainerProps, ref: any) => {
  const { children, isDraggingOver, ...rest } = props

  return (
    <Stack
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        padding: 2,
        backgroundColor: isDraggingOver ? 'rgba(89, 195, 224, 8%)' : '',
        transition: 'background-color 0.2s ease',
      }}
      ref={ref}
      {...rest}
    >
      {children}
    </Stack>
  )
})

export const ItemList = forwardRef((props: ItemListProps, ref: any) => {
  const { children, isDragging, icon, ...rest } = props

  const theme = useTheme()

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        padding: 2,
        borderRadius: 3,
        borderLeft: isDragging
          ? `solid 2px ${theme.palette.primary.light}`
          : 'solid 2px transparent',
        boxShadow: isDragging ? '2px 0px 12px -2px rgb(32 32 36 / 30%)' : '',
        backgroundColor: theme.palette.background.default,
        cursor: 'grab',
      }}
      ref={ref}
      {...rest}
    >
      <Icon fontSize="small">{icon}</Icon>
      {children}
    </Box>
  )
})

export const StrictModeDroppable = ({ children, ...props }: DroppableProps) => {
  const [enabled, setEnabled] = useState(false)

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true))

    return () => {
      cancelAnimationFrame(animation)
      setEnabled(false)
    }
  }, [])

  if (!enabled) {
    return null
  }

  return <Droppable {...props}>{children}</Droppable>
}

export default function DragAndDrop<T extends { [key: string]: any }>(props: DragAndDropProps<T>) {
  const { data, renderItem, isLoading, onChange } = props
  const [draggableItems, setDraggableItems] = useState<T[]>([])
  const [newItemsOrder, setNewItemsOrder] = useState<T[]>([])

  const isDataIgual = JSON.stringify(data) === JSON.stringify(draggableItems)

  useEffect(() => {
    setDraggableItems(data)
  }, [data])

  function handleDragEnd(results: DropResult) {
    const items = Array.from(draggableItems)
    const [reorderedItem] = items.splice(results.source.index, 1)

    if (!results.destination) return
    items.splice(results?.destination?.index, 0, reorderedItem)

    const _newItemsOrder: T[] = []
    items.forEach((item, index) => {
      if (item.ordenacao !== data[index].ordenacao) {
        _newItemsOrder.push({
          ...item,
          uuid: item.uuid,
          ordenacao: data[index].ordenacao,
        })
      }
    })

    setDraggableItems(items)
    setNewItemsOrder(_newItemsOrder)
  }

  function handleSave() {
    onChange(newItemsOrder)
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <StrictModeDroppable droppableId="listItem">
        {(provided, snapshot) => (
          <Container
            {...provided.droppableProps}
            ref={provided.innerRef}
            isDraggingOver={snapshot.isDraggingOver}
          >
            {draggableItems?.map((item, index) => {
              return (
                <Draggable key={item.id} draggableId={item?.uuid || ''} index={index}>
                  {(provided, snapshot) => (
                    <ItemList
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                      isDragging={snapshot.isDragging}
                      icon="drag_indicator"
                    >
                      {renderItem(item)}
                    </ItemList>
                  )}
                </Draggable>
              )
            })}
            {provided.placeholder}

            <Button
              variant="contained"
              onClick={handleSave}
              disabled={isDataIgual}
              isLoading={isLoading}
            >
              Salvar Ordenação
            </Button>
          </Container>
        )}
      </StrictModeDroppable>
    </DragDropContext>
  )
}
