import { Box, Switch } from '@mui/material'
import CardConfig from 'components/CardConfig'
import { useRoleContext } from 'components/Contexts/RoleContext'

export default function MotivosPerda() {
  const { role, changeRolePermission } = useRoleContext()
  const { motivos_perda } = role?.permissoes || {}

  return (
    <Box>
      <CardConfig
        title="Acesso (Ver)"
        description={
          <div>
            Define o nível de acesso que o usuário possui para poder ver os
            Motivos de Perda (as regras se aplicam aos relatórios)
          </div>
        }
        component={
          <Switch
            name="motivos_perda.read"
            checked={motivos_perda?.read || ''}
            onChange={changeRolePermission}
            disabled={Boolean(role?.fixa)}
          />
        }
      />
      <CardConfig
        title="Acesso (Criar)"
        description={
          <div>
            Define o nível de acesso que o usuário possui para poder criar os
            Motivos de Perda
          </div>
        }
        component={
          <Switch
            name="motivos_perda.create"
            checked={motivos_perda?.create || ''}
            onChange={changeRolePermission}
            disabled={Boolean(role?.fixa)}
          />
        }
      />
      <CardConfig
        title="Acesso (Alterar)"
        description={
          <div>
            Define o nível de acesso que o usuário possui para poder alterar os
            Motivos de Perda
          </div>
        }
        component={
          <Switch
            name="motivos_perda.alter"
            checked={motivos_perda?.alter || ''}
            onChange={changeRolePermission}
            disabled={Boolean(role?.fixa)}
          />
        }
      />
      <CardConfig
        title="Acesso (Deletar)"
        description={
          <div>
            Define o nível de acesso que o usuário possui para poder deletar os
            Motivos de Perda
          </div>
        }
        component={
          <Switch
            name="motivos_perda.delete"
            checked={motivos_perda?.delete || ''}
            onChange={changeRolePermission}
            disabled={Boolean(role?.fixa)}
          />
        }
      />
    </Box>
  )
}
