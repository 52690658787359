import { Switch } from '@mui/material'
import CardConfig from 'components/CardConfig'
import { useRoleContext } from 'components/Contexts/RoleContext'

export default function ProdutosVisibilidade() {
  const { role, changeRolePermission } = useRoleContext()
  const {
    // produtos_lista,
    produtos_classe,
    produtos_familia,
    produtos_linha,
    produtos_grupo,
    produtos,
  } = role?.permissoes || {}

  return (
    <CardConfig
      isSubCard
      title="Produtos"
      disabledPadding
      description="Define se o usuário tem permissão para acessar o menu Produtos"
      collapse={Boolean(produtos?.visibilidade)}
      component={
        <Switch
          name="produtos.visibilidade"
          checked={Boolean(produtos?.visibilidade)}
          disabled={Boolean(role?.fixa)}
          onChange={changeRolePermission}
        />
      }
    >
      <CardConfig
        title="Classe de Produtos"
        description="Define se o usuário tem permissão para acessar o menu Classe de Produtos"
        disabledPadding
        component={
          <Switch
            name="produtos_classe.visibilidade"
            checked={Boolean(produtos_classe?.visibilidade)}
            disabled={Boolean(role?.fixa)}
            onChange={changeRolePermission}
          />
        }
      />
      <CardConfig
        title="Família de Produtos"
        description="Define se o usuário tem permissão para acessar o menu Família de Produtos"
        disabledPadding
        component={
          <Switch
            name="produtos_familia.visibilidade"
            checked={Boolean(produtos_familia?.visibilidade)}
            disabled={Boolean(role?.fixa)}
            onChange={changeRolePermission}
          />
        }
      />
      <CardConfig
        title="Linha de Produtos"
        description="Define se o usuário tem permissão para acessar o menu Linha de Produtos"
        disabledPadding
        component={
          <Switch
            name="produtos_linha.visibilidade"
            checked={Boolean(produtos_linha?.visibilidade)}
            disabled={Boolean(role?.fixa)}
            onChange={changeRolePermission}
          />
        }
      />
      <CardConfig
        title="Grupo de Produtos"
        description="Define se o usuário tem permissão para acessar o menu Grupo de Produtos"
        disabledPadding
        component={
          <Switch
            name="produtos_grupo.visibilidade"
            checked={Boolean(produtos_grupo?.visibilidade)}
            disabled={Boolean(role?.fixa)}
            onChange={changeRolePermission}
          />
        }
      />
    </CardConfig>
  )
}
