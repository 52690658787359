import { formatCurrency } from '@data-c/hooks'
import { Tooltip } from '@data-c/ui'
import { Box, Stack, Typography, useTheme } from '@mui/material'
import { calcPercentage } from 'utils/math-utils'

interface IndicadorLimiteCreditoProps {
  data: {
    valorDuplicatasEmAberto: number
    valorPedidosFechadosEAnalise: number
    valorPedidoAtual: number
    limiteDeCredito: number
  }
}

function CustomToolTip(props: {
  titulo: string
  color: string
  valor: number
  width: number
}) {
  const { titulo, color, valor, width } = props

  return (
    <Tooltip
      title={<Detalhes label={titulo} value={formatCurrency(valor)} />}
      arrow
    >
      <Box
        sx={{
          backgroundColor: color,
          width: `${width}%`,
          height: '100%',
          fontSize: 13,
          textAlign: 'center',
          fontWeight: 'bold',
        }}
      >
        {width > 30 && formatCurrency(valor || 0)}
      </Box>
    </Tooltip>
  )
}

function Detalhes({ label, value }: { label: string; value: string }) {
  return (
    <Stack>
      <Typography variant="body2">{label}</Typography>
      <Typography variant="body1" fontWeight="bold" sx={{ textAlign: 'right' }}>
        {value}
      </Typography>
    </Stack>
  )
}

export default function IndicadorLimiteCredito(
  props: IndicadorLimiteCreditoProps,
) {
  const { data } = props

  const {
    limiteDeCredito,
    valorDuplicatasEmAberto,
    valorPedidoAtual,
    valorPedidosFechadosEAnalise,
  } = data

  const theme = useTheme()
  const valorTotalPedidos = valorPedidoAtual + valorPedidosFechadosEAnalise

  const percentualDuplicatasEmAberto = calcPercentage(
    valorDuplicatasEmAberto,
    limiteDeCredito,
  )
  const percentualPedidos = calcPercentage(valorTotalPedidos, limiteDeCredito)
  const percentualTotal = percentualDuplicatasEmAberto + percentualPedidos

  const valorTotal = valorDuplicatasEmAberto + valorTotalPedidos
  const limiteRestante = limiteDeCredito - valorTotal
  {
    /* <CustomToolTip titulo='' valor={} color={} width={} /> */
  }
  return (
    <Box
      sx={{
        display: 'flex',
        height: 20,
        width: '100%',
        borderRadius: 8,
        overflow: 'hidden',
      }}
    >
      <CustomToolTip
        titulo="Duplicatas em aberto"
        valor={valorDuplicatasEmAberto}
        color={theme.palette.error.light}
        width={percentualDuplicatasEmAberto}
      />

      <CustomToolTip
        titulo="Pedidos"
        valor={valorTotalPedidos}
        color={theme.palette.warning.light}
        width={percentualPedidos}
      />

      <CustomToolTip
        titulo="Limite Restante"
        valor={limiteRestante}
        color={theme.palette.success.light}
        width={Math.min(100 - percentualTotal, 100)}
      />
    </Box>
  )
}
