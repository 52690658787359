import {
  AppBar as MuiAppBar,
  IconButton,
  Toolbar,
  Typography,
  useTheme,
  styled,
} from '@mui/material'
import { ArrowBack } from '@mui/icons-material'
import { ReactNode } from 'react'

export interface AppBarProps {
  title: string
  onBack?: () => void
  children?: ReactNode
}
const Offset = styled('div')(({ theme }) => theme.mixins.toolbar)
export default function AppBar(props: AppBarProps) {
  const { title, onBack, children } = props
  const theme = useTheme()
  return (
    <>
      <MuiAppBar
        position="fixed"
        sx={{
          width: '100%',
          background: 'white',
          boxShadow: 'none',
        }}
      >
        <Toolbar>
          {onBack && (
            <IconButton
              sx={{
                color: theme.palette.text.primary,
              }}
              size="medium"
              onClick={() => onBack()}
            >
              <ArrowBack fontSize="inherit" />
            </IconButton>
          )}

          <Typography
            fontSize="17pt"
            variant="h3"
            component="div"
            sx={{ flexGrow: 1, ml: 1 }}
          >
            {title}
          </Typography>
          {children}
        </Toolbar>
      </MuiAppBar>
      <Offset />
    </>
  )
}
