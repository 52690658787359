import {
  DataTable,
  OptionStyles,
  DataTableOptions as Options,
  Flag,
} from '@data-c/ui'
import { MUIDataTableColumnDef } from 'mui-datatables'
import { CrmAtividadesModel } from 'hooks/queries/useCrmAtividades'

import { useFormApi } from '@data-c/providers'
import { useMemo, useCallback } from 'react'
import { findData } from '@data-c/hooks'

interface TableProps {
  data: CrmAtividadesModel[]
  isLoading?: boolean
  isFetching?: boolean
  error?: string
}

export default function Table(props: TableProps) {
  const { data, isFetching, isLoading, error } = props
  const { openForm, openConfirm } = useFormApi()

  const handleClickItem = useCallback(
    (event: 'edit' | 'delete', data: CrmAtividadesModel) => {
      switch (event) {
        case 'edit':
          openForm(data)
          break
        case 'delete':
          openConfirm(data)
          break
      }
    },
    [openForm, openConfirm],
  )

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'tipoAtividade',
        label: 'Atividade',
        options: {
          setCellProps: () => ({
            style: {
              width: '96px',
            },
          }),
        },
      },
      {
        name: 'assunto',
        label: 'Assunto',
      },
      {
        name: 'atribuidoParaAsText',
        label: 'Atribuído para',
      },
      {
        name: 'leadAsText',
        label: 'Cliente / Lead',
      },
      {
        name: 'pedidoAsText',
        label: 'Negociação',
      },
      {
        name: 'dataHoraInicioAsBrazilianDate',
        label: 'Início',
      },
      {
        name: 'dataHoraFimAsBrazilianDate',
        label: 'Fim',
      },
      {
        name: 'duracao',
        label: 'Duração',
      },
      {
        name: 'concluido',
        label: 'Concluído',
        options: {
          setCellProps: () => ({
            style: {
              width: '60px',
              textAlign: 'center',
            },
          }),
          customBodyRender: (value) => {
            return <Flag isFlagged={value} type="success" />
          },
        },
      },
      {
        name: 'uuid',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          setCellProps: OptionStyles,
          customBodyRender: (value) => {
            const _data = findData<CrmAtividadesModel>(data || [], value)
            return (
              <Options
                displayUpdateButton={true}
                value={_data}
                onClick={handleClickItem}
              />
            )
          },
        },
      },
    ],
    [data, handleClickItem],
  )

  return (
    <DataTable
      error={error}
      columns={columns}
      data={data || []}
      isLoading={isLoading}
      isFetching={isFetching}
    />
  )
}
