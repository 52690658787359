import { Stack, StackProps } from '@mui/material'

export interface HeaderContentProps extends StackProps {}

export default function HeaderContent(props: HeaderContentProps) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      alignContent="center"
      spacing={1}
      {...props}
    />
  )
}
