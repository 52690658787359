import { formatCurrency } from '@data-c/hooks'
import { useFilterData } from '@data-c/providers'
import { LabelValue } from '@data-c/ui'
import { LinearProgress, Paper, Stack, Typography } from '@mui/material'
import { BarPlot } from '@mui/x-charts/BarChart'
import { PiePlot } from '@mui/x-charts/PieChart'
import {
  ChartsItemContentProps,
  ChartsTooltip,
} from '@mui/x-charts/ChartsTooltip'
import { ChartsXAxis } from '@mui/x-charts/ChartsXAxis'
import { ChartsYAxis } from '@mui/x-charts/ChartsYAxis'
import { ResponsiveChartContainer } from '@mui/x-charts/ResponsiveChartContainer'
import {
  OportunidadePerdidaPorMotivoDataResponse,
  OportunidadesPerdidasFilter,
  OportunidadesPerdidasFilter1,
  useQueryRelatorioOportunidadesPerdidasPorMotivo,
} from 'hooks/queries/useRelatorioOportunidadesPerdidas'
import _ from 'lodash'
import Divider from 'components/Divider'
import { Circle } from '@mui/icons-material'
import EmptyData from 'components/Displays/EmptyData'

function CustomTooltip(props: {
  tooltipProps: ChartsItemContentProps
  data: Array<OportunidadePerdidaPorMotivoDataResponse>
}) {
  const { data } = props
  const { dataIndex } = props.tooltipProps.itemData
  const { color } = props.tooltipProps.series
  const item = data[dataIndex || 0]
  return (
    <Paper sx={{ minWidth: 300, maxWidth: 450, pb: 2, pt: 1.5 }}>
      <Stack
        direction="row"
        spacing={0.5}
        justifyContent="flex-start"
        alignItems="center"
        ml={1}
      >
        <Circle sx={{ fontSize: '11pt', color: color }} />
        <Typography sx={{ pl: 1, pr: 1, mb: 1 }} variant="subtitle1">
          {item.descricao}
        </Typography>
      </Stack>
      <Divider />
      <Stack
        mt={2}
        direction="row"
        alignContent="center"
        justifyContent="space-around"
      >
        <LabelValue label="Quantidade">{item.total}</LabelValue>
        <LabelValue label="Valor">{formatCurrency(item.totalPerda)}</LabelValue>
      </Stack>
    </Paper>
  )
}

export default function TabChart() {
  const { appliedValues, searchId } = useFilterData<
    OportunidadesPerdidasFilter,
    OportunidadesPerdidasFilter1
  >()

  const { representante, cliente, dateRange, ...rest } = appliedValues || {}
  const nFilters: OportunidadesPerdidasFilter1 = {
    ...rest,
    representanteUuid: representante?.uuid,
    clienteUuid: cliente?.uuid,
    dataInicial: dateRange?.firstDate,
    dataFinal: dateRange?.secondDate,
  }

  const { data, isLoading, error } =
    useQueryRelatorioOportunidadesPerdidasPorMotivo({
      queryParams: nFilters,
      requestId: searchId,
      pagination: {
        page: 1,
        pageSize: 999,
      },
    })

  const maiorLabel = data?.reduce((prev, cur) => {
    if (cur.descricao.length > prev.length) return cur.descricao
    return prev
  }, '')

  function calcularLarguraTexto(texto: string) {
    var elementoTemporario = document.createElement('span')
    elementoTemporario.style.font = '14px sans-serif, Roboto, Poppins'
    elementoTemporario.textContent = texto
    elementoTemporario.style.position = 'absolute'
    elementoTemporario.style.left = '-9999px'
    document.body.appendChild(elementoTemporario)
    var largura = elementoTemporario.offsetWidth
    document.body.removeChild(elementoTemporario)
    return largura
  }

  const totalizador = data?.reduce(
    (prev, cur) => {
      const quantidadeTotal = prev.quantidadeTotal + cur.total
      const valorTotal = prev.valorTotal + cur.totalPerda
      return {
        quantidadeTotal,
        valorTotal,
      }
    },
    {
      quantidadeTotal: 0,
      valorTotal: 0,
    },
  ) || {
    quantidadeTotal: 0,
    valorTotal: 0,
  }

  // const dataSeries = data?.map((d) => d.total)

  if (isLoading) return <LinearProgress />
  if (Array.isArray(data) && data.length === 0)
    return (
      <EmptyData
        error={
          Boolean(!isLoading && !error)
            ? 'Não conseguimos gerar o relatório. Por favor, ajuste os filtros de busca e tente novamente.'
            : undefined
        }
      />
    )
  return (
    <Paper sx={{ p: 2 }}>
      <Typography variant="subtitle1">
        Oportunidades perdidas por motivo
      </Typography>
      <ResponsiveChartContainer
        margin={{
          left: calcularLarguraTexto(maiorLabel || ''),
        }}
        height={300}
        dataset={data as []}
        xAxis={[
          {
            label: 'Quantidade',
            labelStyle: { fontWeight: 'bold' },
          },
        ]}
        yAxis={[
          {
            scaleType: 'band',
            dataKey: 'descricao',
          },
        ]}
        series={[
          {
            dataKey: 'total',
            type: 'bar',
            layout: 'horizontal',
          },
          // {
          //   // dataKey: 'total',
          //   data: data.map(d => d.total)
          //   type: 'pie',
          // },
        ]}
      >
        <BarPlot />
        <PiePlot />
        <ChartsYAxis />
        <ChartsXAxis />
        <ChartsTooltip
          trigger="item"
          slots={{
            itemContent: (props: ChartsItemContentProps) => (
              <CustomTooltip tooltipProps={props} data={data || []} />
            ),
          }}
        />
        {/* <ChartsGrid horizontal={true} vertical={true} /> */}
        {/* <ChartsXAxis label="X axis" position="bottom" axisId="x-axis-id" /> */}
      </ResponsiveChartContainer>
      <Stack
        mt={2}
        direction="row"
        alignContent="center"
        justifyContent="space-around"
      >
        <LabelValue label="Quantidade Total">
          {totalizador.quantidadeTotal}
        </LabelValue>
        <LabelValue label="Valor Total">
          {formatCurrency(totalizador.valorTotal)}
        </LabelValue>
      </Stack>
    </Paper>
  )
}
