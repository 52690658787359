import { Button } from '@data-c/ui'
import { Box, ButtonProps, Stack, Typography } from '@mui/material'
import image from 'assets/images/empty-data.svg'
import { ReactNode } from 'react'
export interface AddFirstProps {
  buttonProps?: ButtonProps
  isLoading?: boolean
  buttonLabel?: string
  primaryMessage: string
  secondaryMessage?: ReactNode | string
  onClick: React.MouseEventHandler<HTMLButtonElement>
}

export default function AddFirst(props: AddFirstProps) {
  const {
    buttonProps,
    buttonLabel,
    primaryMessage,
    secondaryMessage,
    isLoading,
    onClick,
  } = props
  return (
    <Box height="100%">
      <Stack
        border="1px solid #c0c0c0"
        borderRadius={4}
        spacing={2}
        direction="column"
        alignContent="center"
        alignItems="center"
        padding={2}
      >
        <img src={image} alt="empty data" width={256} />
        <Typography variant="h4">{primaryMessage}</Typography>

        {secondaryMessage && (
          <Typography variant="body2">{secondaryMessage}</Typography>
        )}

        <Button
          isLoading={isLoading}
          variant="contained"
          {...buttonProps}
          onClick={onClick}
        >
          {buttonLabel || 'Adicionar'}
        </Button>
      </Stack>
    </Box>
  )
}
