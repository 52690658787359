import { Stack } from '@mui/material'

import { Button, CurrencyTextField } from '@data-c/ui'
import { isValidNumber, useForm } from '@data-c/hooks'

import useValidations from 'hooks/useValidations'
import useVendedores, { VendedorModel } from 'hooks/queries/useVendedores'

import * as yup from 'yup'

const transform = (_: any, val: any) =>
  isValidNumber(val) ? Number(val) : null

const schema = yup.object().shape({
  metaVenda: yup
    .number()
    .nullable(true)
    .required('Informe a meta de venda')
    .moreThan(0, 'A meta de Venda deve ser maior do que R$0,00')
    .when('$metaVendaAtual', (metaVendaAtual, schema) => {
      return schema.notOneOf(
        [metaVendaAtual],
        'A meta de venda deve ser diferente da atual',
      )
    })
    .transform(transform),
})

interface FormProps {
  vendedor: VendedorModel
}

export default function Form(props: FormProps) {
  const { vendedor } = props

  const { data, changeValue } = useForm({
    metaVenda: vendedor.metaVenda || 0,
  })

  const { setValidationErrors, validationProps } = useValidations()
  const { useAtualizarMetaVenda } = useVendedores()
  const { mutateAsync: atualizarMetaVenda, isLoading } = useAtualizarMetaVenda()

  function handleSubmitForm() {
    setValidationErrors(null)
    schema
      .validate(data, {
        abortEarly: false,
        context: { metaVendaAtual: vendedor.metaVenda },
      })
      .then(async () => {
        const { metaVenda } = data
        await atualizarMetaVenda({
          metaVenda,
          vendedorUuid: vendedor.uuid || '',
        })
      })
      .catch((err) => {
        setValidationErrors(err)
      })
  }

  return (
    <Stack gap={1.5}>
      <CurrencyTextField
        name="metaVenda"
        label="Meta de venda"
        value={data.metaVenda}
        onChange={(_: any, value: string) => {
          const parsedMetaVenda = parseFloat(value)
          changeValue('metaVenda', parsedMetaVenda)
        }}
        {...validationProps('metaVenda')}
      />
      <Button
        variant="contained"
        onClick={handleSubmitForm}
        isLoading={isLoading}
      >
        Salvar
      </Button>
    </Stack>
  )
}
