import { useNotifications } from '@data-c/hooks'
import { DataTable } from '@data-c/ui'
import { DateTime } from 'luxon'
import { SortProps } from 'interfaces/SortProps'
import usePositivacaoClientes, {
  PositivacaoClientesFilters,
  PositivacaoClientesFilters1,
} from 'hooks/queries/usePositivacaoClientes'

import { useFilterData } from '@data-c/providers'
import { formatCurrency } from '@data-c/hooks'
import { Flag } from '@data-c/ui'
import { useMemo, useState } from 'react'
import { MUIDataTableColumnDef } from 'mui-datatables'
import { Grid, useTheme } from '@mui/material'
import CardValue from 'components/Indicadores/CardValue'

export default function TableClientes() {
  const { extractAxiosError } = useNotifications()
  const theme = useTheme()
  const [sort, setSort] = useState<SortProps>({
    column: 'totalPeriodo',
    direction: 'desc',
  })
  const { appliedValues, searchId } = useFilterData<
    PositivacaoClientesFilters,
    PositivacaoClientesFilters1
  >()

  const { representante, categoriaValue, ...rest } = appliedValues || {}

  const nFilters: PositivacaoClientesFilters1 = {
    ...rest,
    representanteUuid: representante?.uuid,
    categoriaUuid: categoriaValue?.uuid,
  }

  const { useQueryObterRelatorioPositivacaoClientes } = usePositivacaoClientes()
  const { data, isLoading, isFetching, error } =
    useQueryObterRelatorioPositivacaoClientes(nFilters, searchId, sort)

  const dataFormatada = useMemo(
    () =>
      data?.data?.map((item) => {
        return {
          ...item,
          totalDesdeInicioPeriodo: formatCurrency(item.totalDesdeInicioPeriodo),
          totalPeriodo: formatCurrency(item.totalPeriodo),
        }
      }),
    [data],
  )

  const inicioPeriodo = DateTime.fromSQL(data?.inicioPeriodo!)
    .setLocale('pt-BR')
    .toLocaleString()

  const periodoInicioAtual = `${DateTime.fromSQL(data?.dataInicio!).toFormat(
    'dd/LL/yyyy',
  )}`

  const periodoAtual = `${DateTime.fromSQL(data?.dataFim!).toFormat(
    'dd/LL/yyyy',
  )}`

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'codigo',
        label: 'Código',
        options: {
          setCellProps() {
            return { width: '96px' }
          },
        },
      },
      {
        name: 'nome',
        label: 'Nome',
      },
      {
        name: 'totalDesdeInicioPeriodo',
        label: `Total (desde ${inicioPeriodo})`,
        options: {
          setCellProps: () => ({
            style: {
              width: '256px',
              'text-align': 'right',
            },
          }),
        },
      },
      {
        name: 'totalPeriodo',
        label: `Total período (${periodoInicioAtual} até ${periodoAtual})`,
        options: {
          setCellProps: () => ({
            style: {
              width: '256px',
              'text-align': 'right',
            },
          }),
        },
      },
      {
        name: 'estaAtivo',
        label: 'Ativo',
        options: {
          sort: false,
          setCellProps: () => ({
            style: {
              width: '60px',
              'text-align': 'center',
            },
          }),
          customBodyRender: (value) => {
            return <Flag isFlagged={true} type={value ? 'success' : 'error'} />
          },
        },
      },
    ],
    [data, inicioPeriodo, periodoAtual],
  )

  return (
    <>
      <Grid container spacing={2} sx={{ mt: 1, mb: 1 }}>
        <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
          <CardValue
            elevation={2}
            label="Clientes Inativos"
            value={data?.clientesInativos || 0}
            color={theme.palette.error.light}
          />
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
          <CardValue
            elevation={2}
            label="Clientes Ativos"
            value={data?.clientesAtivos || 0}
            color={theme.palette.success.light}
          />
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
          <CardValue
            elevation={2}
            label="Total Clientes"
            value={(data?.clientesAtivos || 0) + (data?.clientesInativos || 0)}
            color={theme.palette.primary.light}
          />
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
          <CardValue
            elevation={2}
            label="Ativação"
            value={`${formatCurrency(data?.percentualPositivacao || 0)}%`}
            color={theme.palette.info.light}
          />
        </Grid>
      </Grid>
      <DataTable
        error={extractAxiosError(error)}
        columns={columns}
        data={dataFormatada || []}
        isLoading={isLoading}
        isFetching={isFetching}
        options={{
          sort: true,
          sortOrder: {
            name: sort.column,
            direction: sort.direction,
          },
        }}
        changeSort={(column, direction) => {
          setSort({ column, direction: direction as 'asc' | 'desc' })
        }}
      />
    </>
  )
}
