import { Box, Collapse, Stack, Typography, useTheme } from '@mui/material'
import { AtividadeComentarios } from 'hooks/queries/useComentarios'
import { useState } from 'react'

interface ItemHeaderProps {
  data: AtividadeComentarios
}

export default function ItemHeader(props: ItemHeaderProps) {
  const { data } = props

  const [isCollapse, setCollapse] = useState<boolean>(false)

  const theme = useTheme()
  const comentarioFoiEditado =
    data?.comentarioAntigo && data?.comentarioAntigo?.length > 0

  function handleCollapse() {
    setCollapse(!isCollapse)
  }

  return (
    <Box>
      <Typography
        variant="body1"
        sx={{
          textTransform: 'capitalize',
        }}
        fontWeight="bold"
      >
        {data?.autor?.nome?.toLowerCase() || ''}
      </Typography>
      {comentarioFoiEditado ? (
        <Box>
          <Typography
            variant="body2"
            onClick={handleCollapse}
            sx={{
              marginBottom: 1,
              color: theme.palette.primary.dark,
              cursor: 'pointer',
              ':hover': {
                textDecoration: 'underline',
              },
            }}
          >
            {data?.dataAsRelative || ''}
          </Typography>

          <Collapse in={isCollapse}>
            <Stack gap={2}>
              {data.comentarioAntigo?.map((item) => (
                <Box
                  sx={{
                    backgroundColor: theme.palette.background.paper,
                    padding: 1,
                    borderRadius: 2,
                    borderLeft: `solid 2px ${theme.palette.primary.light}`,
                  }}
                >
                  <Typography variant="body2">{item.comentario}</Typography>
                  <Typography variant="caption">{item.updateAt}</Typography>
                </Box>
              ))}
            </Stack>
          </Collapse>
        </Box>
      ) : (
        <Typography variant="body2">{data?.dataAsRelative || ''}</Typography>
      )}
    </Box>
  )
}
