import {
  Box,
  ButtonProps,
  InputAdornment,
  TextField,
  CheckboxProps,
} from '@mui/material'
import { Button, Checkbox } from '@data-c/ui'

interface InternalButtonProps extends ButtonProps {
  label: string
}

interface CheckboxButtonProps extends CheckboxProps {
  isLoading?: boolean
  buttonProps: InternalButtonProps
}

export default function CheckboxButton(props: CheckboxButtonProps) {
  const { isLoading, buttonProps, checked, ...rest } = props
  const { label, ...restButtonProps } = buttonProps
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        borderRadius: '4px',
      }}
    >
      <TextField
        sx={{
          width: '300px',
          '& .MuiInputBase-input': { paddingY: '4px' },
          background: '#fff',
        }}
        value={checked ? 'Ativo' : 'Inativo'}
        inputProps={{ maxLength: 11 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="end">
              <Checkbox label="" checked={checked} {...rest} />
            </InputAdornment>
          ),
        }}
        // {...rest}
      />

      <Button
        sx={{
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          minWidth: '160px',
          marginLeft: '-158px',
        }}
        variant="contained"
        isLoading={isLoading}
        {...restButtonProps}
      >
        {label || 'Ok'}
      </Button>
    </Box>
  )
}
