import { useState } from 'react'
import { PlainQueryProps } from '@data-c/hooks'
import { Transporter, TransporterProps } from '@data-c/ui'
import useLinhaProdutos, {
  LinhaProdutosModel,
} from 'hooks/queries/useLinhaProdutos'
import LinhaProdutoForm from 'pages/LinhaProdutos/components/Form'
import LinhaProdutoTable from 'pages/LinhaProdutos/components/Table'

type LinhaProdutoTransporterProps = {} & Omit<
  TransporterProps<LinhaProdutosModel>,
  'table' | 'form' | 'onSearch' | 'renderValue' | 'options'
>

export default function LinhaProdutoTransporter(
  props: LinhaProdutoTransporterProps,
) {
  const { value, ...rest } = props
  const [query, setQuery] = useState<string | null>()
  const { useQuery } = useLinhaProdutos()

  const { data, isLoading } = useQuery<PlainQueryProps>({
    queryParams: {
      plainQuery: query,
    },
    pagination: {
      page: 1,
      pageSize: 100,
    },
  })

  function handleSearch(q: string | null) {
    setQuery(q)
  }

  return (
    <Transporter
      onSearch={handleSearch}
      options={data?.data || []}
      isLoading={isLoading}
      table={LinhaProdutoTable}
      label="Linha"
      dialogProps={{ title: 'Linha' }}
      renderValue={(value) => `${value?.codigo || ''} - ${value.nome}`}
      form={LinhaProdutoForm}
      value={value}
      {...rest}
    />
  )
}
