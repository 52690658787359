import useCrud from 'hooks/useCrud'
import { AxiosError } from 'axios'
import api from 'services/api'
import { ErrorInterface } from '@data-c/hooks'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import useNotification from 'hooks/useNotifications'

interface PropriedadesLayoutModel {
  x: number
  y: number
  largura: number
  altura: number
}

export interface IndicadorModel {
  uuid?: string
  nome: string
  painelUuid?: string
  propriedadesLayout?: PropriedadesLayoutModel
}

export async function updatePropriedadesLayout(
  data: PropriedadesLayoutModel[],
): Promise<PropriedadesLayoutModel[]> {
  const response = await api.post(
    `administrador/indicador/propriedades-layout`,
    { data },
  )
  return response.data
}

export function useUpdatePropriedadesLayout() {
  const queryClient = useQueryClient()
  const notifications = useNotification()

  return useMutation<
    PropriedadesLayoutModel[],
    AxiosError,
    PropriedadesLayoutModel[]
  >((data: PropriedadesLayoutModel[]) => updatePropriedadesLayout(data), {
    onSuccess() {
      queryClient.invalidateQueries(['INDICADORES'])
    },
    onError(error) {
      notifications.notifyException(error)
    },
  })
}

export async function deleteIndicador(id: string): Promise<IndicadorModel> {
  const response = await api.delete(`administrador/indicador/${id}`)
  return response.data
}

export function useDeleteIndicador() {
  const queryClient = useQueryClient()
  const notifications = useNotification()

  return useMutation<IndicadorModel, AxiosError, string>(
    (data: string) => deleteIndicador(data),
    {
      onSuccess() {
        notifications.notifySuccess(`Indicador excluído com sucesso`)
        queryClient.invalidateQueries(['INDICADORES'])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export async function createIndicador(
  data: IndicadorModel,
): Promise<IndicadorModel> {
  const response = await api.post(`administrador/indicador`, data)
  return response.data
}

export function useCreateIndicador() {
  const queryClient = useQueryClient()
  const notifications = useNotification()

  return useMutation<IndicadorModel, AxiosError, IndicadorModel>(
    (data: IndicadorModel) => createIndicador(data),
    {
      onSuccess() {
        notifications.notifySuccess(`Indicador criado com sucesso`)
        queryClient.invalidateQueries(['INDICADORES'])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export async function obterIndicadores(id: string): Promise<IndicadorModel[]> {
  const response = await api.get<IndicadorModel[]>(
    `administrador/indicador/${id}`,
  )
  return response.data
}

export function useQueryObterIndicadores(id: string) {
  return useQuery<IndicadorModel[], AxiosError<ErrorInterface, ErrorInterface>>(
    ['INDICADORES', id],
    () => {
      return obterIndicadores(id)
    },
  )
}

export default function useIndicador() {
  return {
    ...useCrud<IndicadorModel, AxiosError>(
      'administrador/indicador',
      'INDICADORES',
      'male',
    ),
    useQueryObterIndicadores,
    useUpdatePropriedadesLayout,
    useDeleteIndicador,
    useCreateIndicador,
  }
}
