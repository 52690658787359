import { useEffect, useRef, useState } from 'react'

import { Box, Stack, TextField, Typography } from '@mui/material'
import { Button, ButtonContainer, theme } from '@data-c/ui'
import { useForm } from '@data-c/hooks'

import useComentarios, {
  AtividadeComentarios,
} from 'hooks/queries/useComentarios'
import useValidations from 'hooks/useValidations'

import { useUserContext } from 'components/Contexts/UserContext'

import ItemHeader from './components/ItemHeader'
import ItemOptions from './components/ItemOptions'

import * as yup from 'yup'

const schema = yup.object().shape({
  comentario: yup.string().required('Digite um comentário!'),
})

interface ItemProps {
  comentario: AtividadeComentarios
}

export default function Item(props: ItemProps) {
  const { comentario } = props
  const { user } = useUserContext()
  const { useDeletarComentario, useUpdateComentario } = useComentarios()
  const comentarioRef = useRef<HTMLInputElement>(null)
  const { data, setData, handleChange } = useForm<AtividadeComentarios>(
    {} as AtividadeComentarios,
  )

  useEffect(() => {
    setData(comentario)
  }, [comentario])

  const [mouseIn, setMouseIn] = useState<boolean>(false)
  const [isEditing, setEditing] = useState<boolean>(false)

  const { mutateAsync: deletarComentario, isLoading: isDeleting } =
    useDeletarComentario()
  const { mutateAsync: updateComentario, isLoading } = useUpdateComentario()
  const { setValidationErrors, validationProps } = useValidations()

  useEffect(() => {
    if (isEditing && comentarioRef.current) {
      const comentarioInput = comentarioRef.current
      const comentarioLength = comentarioInput.value.length
      comentarioInput.setSelectionRange(comentarioLength, comentarioLength)
      comentarioInput.focus()
    }
  }, [comentarioRef, isEditing])

  function handleUpdateComentario() {
    setValidationErrors(null)
    schema
      .validate(data, { abortEarly: false })
      .then(async () => {
        await updateComentario(data)
        handleEditarComentario()
      })
      .catch((err) => {
        setValidationErrors(err)
      })
  }

  async function handleDeletarComentario() {
    await deletarComentario(comentario)
  }

  function handleEditarComentario() {
    setEditing(!isEditing)
    setMouseIn(false)
    setData(comentario)
  }

  const podeEditar = user?.uuid === comentario.autorUuid
  return (
    <Box
      sx={{
        display: 'flex',
        // alignItems: podeEditar ? 'flex-end' : 'flex-start',
        // alignItems: 'flex-start',
        flexDirection: 'column',
      }}
    >
      <Stack
        sx={{
          width: '100%',
          position: 'relative',
          backgroundColor: podeEditar
            ? 'rgba(89, 195, 224, 15%);'
            : theme.palette.grey[200],
          padding: 2,
          borderRadius: 1.5,
          boxShadow: '1px 1px 2px -1px rgb(32 32 36 / 30%)',
          marginBottom: 2,
          gap: 2,
        }}
        onMouseEnter={() => {
          if (isEditing) return
          setMouseIn(true)
        }}
        onMouseLeave={() => {
          setMouseIn(false)
        }}
      >
        <ItemHeader data={data} />

        {isEditing ? (
          <Stack mt={1} gap={2}>
            <TextField
              label="Comentário"
              name="comentario"
              fullWidth
              multiline
              value={data?.comentario || ''}
              onChange={handleChange}
              {...validationProps('comentario')}
              inputRef={comentarioRef}
            />

            <ButtonContainer>
              <Button color="error" onClick={handleEditarComentario}>
                cancelar
              </Button>
              <Button
                variant="contained"
                onClick={handleUpdateComentario}
                isLoading={isLoading}
              >
                Salvar
              </Button>
            </ButtonContainer>
          </Stack>
        ) : (
          <Typography
            fontSize="14px"
            variant="body1"
            sx={{
              whiteSpace: 'pre-wrap',
            }}
          >
            {data?.comentario}
          </Typography>
        )}

        {mouseIn && podeEditar && (
          <ItemOptions
            onDelete={handleDeletarComentario}
            onEdit={handleEditarComentario}
            isDeleting={isDeleting}
          />
        )}
      </Stack>
    </Box>
  )
}
