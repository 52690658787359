import { useEffect } from 'react'
import { Checkbox, FormControlLabel, Grid } from '@mui/material'

import { useFormApi, useFormData } from '@data-c/providers'
import { FormContainer } from '@data-c/ui'

import useValidations from 'hooks/useValidations'
import useProdutos, { ProdutoModel } from 'hooks/queries/useProdutos'

import * as yup from 'yup'
import LinhaProdutoTransporter from 'components/Transporters/LinhaProdutoTransporter'
import GrupoProdutoTransporter from 'components/Transporters/GrupoProdutoTransporter'
import FamiliaProdutoTransporter from 'components/Transporters/FamiliaProdutoTransporter'
import ClasseProdutoTransporter from 'components/Transporters/ClasseProdutoTransporter'
import DropDownTipoVariacao from 'components/Inputs/DropDownTipoVariacao'

import { LinhaProdutosModel } from 'hooks/queries/useLinhaProdutos'
import { GrupoProdutosModel } from 'hooks/queries/useGrupoProdutos'
import { FamiliaProdutosModel } from 'hooks/queries/useFamiliaProdutos'
import { ClasseProdutosModel } from 'hooks/queries/useClasseProdutos'
import FormProps from 'interfaces/FormProps'
import AutocompleteUnidades from 'components/AutocompleteUnidades'
import TextField from 'components/Inputs/TextField'

const schema = yup.object().shape({
  nome: yup.string().required('Informe o Nome'),
})

export default function Form(props: FormProps<ProdutoModel>) {
  const { data: produto, view = 'dialog' } = props

  const { formValues: data } = useFormData<ProdutoModel>()
  const {
    toggleSubmit,
    closeForm,
    onChangeFormValue,
    changeValues,
    changeValue,
  } = useFormApi<ProdutoModel>()

  useEffect(() => {
    if (produto) {
      changeValues(produto)
    }
  }, [])

  const { useSubmit } = useProdutos()
  const { mutateAsync } = useSubmit()
  const { setValidationErrors, validationProps } = useValidations()

  function handleSubmitForm() {
    setValidationErrors(null)
    schema
      .validate(data, { abortEarly: false })
      .then(async () => {
        toggleSubmit(true)

        const newData = {
          ...data,
          ativo: data?.ativo || false,
          fracionado: data?.fracionado || false,
        }

        await mutateAsync(newData)
        closeForm()
      })
      .catch((err) => {
        setValidationErrors(err)
      })
      .finally(() => toggleSubmit(false))
  }

  return (
    <FormContainer
      view={view}
      onSubmitForm={handleSubmitForm}
      dialogProps={{
        maxWidth: 'md',
        title: 'Cadastro de Produto',
        disableRestoreFocus: true,
      }}
    >
      <Grid>
        <Grid container spacing={2}>
          <Grid item xl={2} lg={2} md={2} sm={2} xs={12}>
            <TextField
              name="codigo"
              label="Código"
              value={data?.codigo || ''}
              disabled
            />
          </Grid>
          <Grid item xl={7} lg={7} md={7} sm={8} xs={12}>
            <TextField
              autoFocus
              name="nome"
              label="Nome"
              required
              inputProps={{ maxLength: 120 }}
              value={data?.nome || ''}
              onChange={onChangeFormValue}
              {...validationProps('nome')}
            />
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={2} xs={12}>
            <AutocompleteUnidades
              value={data?.unidade || null}
              onChange={(_, value: string | null) => {
                changeValue('unidade', value)
              }}
              name="unidade"
            />
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <TextField
              name="descricao"
              label="Descrição"
              rows={4}
              multiline
              value={data?.descricao || ''}
              onChange={onChangeFormValue}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <LinhaProdutoTransporter
              value={data?.linhaProduto || null}
              onChange={(value: LinhaProdutosModel | null) => {
                changeValues({
                  ...data,
                  produtolnh: value?.uuid || null,
                  linhaProduto: value || null,
                })
              }}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <GrupoProdutoTransporter
              value={data?.grupoProduto || null}
              onChange={(value: GrupoProdutosModel | null) => {
                changeValues({
                  ...data,
                  produtogrp: value?.uuid || null,
                  grupoProduto: value || null,
                })
              }}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <FamiliaProdutoTransporter
              value={data?.familiaProduto || null}
              onChange={(value: FamiliaProdutosModel | null) => {
                changeValues({
                  ...data,
                  produtofml: value?.uuid || null,
                  familiaProduto: value || null,
                })
              }}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <ClasseProdutoTransporter
              value={data?.produtoClasse || null}
              onChange={(value: ClasseProdutosModel | null) => {
                changeValues({
                  ...data,
                  produtocls: value?.uuid || null,
                  produtoClasse: value || null,
                })
              }}
            />
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
            <TextField
              name="pesobruto"
              label="Peso Bruto"
              value={data?.pesobruto || ''}
              onChange={onChangeFormValue}
            />
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
            <TextField
              name="pesoliquido"
              label="Peso Líquido"
              value={data?.pesoliquido || ''}
              onChange={onChangeFormValue}
            />
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
            <DropDownTipoVariacao
              name="tipoVariacao"
              label="Variação"
              value={data?.tipoVariacao || ''}
              onChange={(e) => {
                const tipoVariacao = e.target.value
                changeValue('tipoVariacao', tipoVariacao)
              }}
            />
          </Grid>
          <Grid item xl={2} lg={2} md={2} sm={2} xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  name="fracionado"
                  inputProps={{
                    type: 'checkbox',
                  }}
                  size="small"
                  onChange={onChangeFormValue}
                  checked={Boolean(data?.fracionado)}
                />
              }
              label="Fracionado"
            />
          </Grid>
          <Grid item xl={2} lg={2} md={2} sm={2} xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  name="ativo"
                  inputProps={{
                    type: 'checkbox',
                  }}
                  size="small"
                  onChange={onChangeFormValue}
                  checked={Boolean(data?.ativo)}
                />
              }
              label="Ativo"
            />
          </Grid>
        </Grid>
      </Grid>
    </FormContainer>
  )
}
