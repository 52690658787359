import {
  useState,
  InputHTMLAttributes,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react'
import { InputState } from 'react-input-mask'
import {
  CircularProgress,
  InputAdornment,
  InputProps,
  TextFieldProps,
} from '@mui/material'
import MaskedTextField from '../MaskedTextField'
import useConsultaCnpj from 'hooks/useConsultaCnpj'
import { onlyNumbers } from '@data-c/hooks'

export enum TIPO_DOCUMENTO {
  INDEFINIDO = 'INDEFINIDO',
  CPF = 'CPF',
  CNPJ = 'CNPJ',
}

export enum MASCARA {
  CPF = '999.999.999-99',
  INTERMEDIARIA = '999.999.999-999',
  CNPJ = '99.999.999/9999-99',
}

interface DocumentoTextFieldProps
  extends InputHTMLAttributes<HTMLInputElement> {
  inputProps?: TextFieldProps
  InputProps?: InputProps
  value: string
  tipoDocumento?: TIPO_DOCUMENTO
  onDocumentoFound?: (value: any) => void
  consultaCnpj?: boolean
}

export interface ForwardRefProps {
  consultarCnpj: (cnpj: string) => void
}

const DocumentoTextField = forwardRef<ForwardRefProps, DocumentoTextFieldProps>(
  (props, ref) => {
    const { value, tipoDocumento, onDocumentoFound, consultaCnpj, ...rest } =
      props
    const [mask, setMask] = useState(tipoDocumento || MASCARA.CPF)
    const { consultar, isLoading, error } = useConsultaCnpj()

    useImperativeHandle(ref, () => ({
      consultarCnpj,
    }))

    useEffect(() => {
      if (value.length === 14) {
        setMask(MASCARA.CNPJ)
      }
    }, [value])

    async function consultarCnpj(cnpj: string) {
      if (onDocumentoFound) {
        const cadastro = await consultar(onlyNumbers(cnpj) as string)
        onDocumentoFound(cadastro)
      }
    }

    function handleConsultarCnpj(cnpj: string) {
      if (!consultaCnpj) return
      if (value.length === 14) {
        consultarCnpj(cnpj)
      }
    }

    const beforeMaskedValueChange = (
      newState: InputState,
      oldState: InputState,
      userInput: string,
    ) => {
      let selection = newState.selection
      if (userInput && newState.selection && oldState.selection) {
        let newStart = newState.selection.start
        let oldStart = oldState.selection.start

        if (newStart < 13 && mask !== MASCARA.CPF) {
          setMask(MASCARA.CPF)
        }

        if (newStart === 13 && oldStart === 12) {
          setMask(MASCARA.CPF)
        }

        if (newStart === 14 && oldStart === 13) {
          setMask(MASCARA.INTERMEDIARIA)
        }

        if (newStart === 15 && oldStart === 14) {
          setMask(MASCARA.CNPJ)
        }

        if ((newStart === 14 || newStart === 18) && oldStart === 0) {
          setMask(MASCARA.CNPJ)
          if (newState?.selection) {
            newState.selection.end = 18
            newState.selection.start = 18
            newState.value = userInput
          }
        }
      }

      if (tipoDocumento == TIPO_DOCUMENTO.CNPJ) {
        setMask(MASCARA.CNPJ)
      }

      if (tipoDocumento == TIPO_DOCUMENTO.CPF) {
        setMask(MASCARA.CPF)
      }

      const { value: nValue } = newState
      return {
        value: nValue,
        selection,
      }
    }

    return (
      <MaskedTextField
        mask={mask}
        beforeMaskedValueChange={beforeMaskedValueChange}
        value={value || ''}
        onBlur={(e) => {
          const v = e.target.value || ''
          const cnpj = onlyNumbers(v)
          if (cnpj) handleConsultarCnpj(cnpj)
        }}
        InputProps={{
          inputRef: ref,
          error: Boolean(error),
          endAdornment: (
            <InputAdornment position="end">
              {isLoading && <CircularProgress size={16} />}
            </InputAdornment>
          ),
        }}
        {...rest}
      />
    )
  },
)

export default DocumentoTextField
