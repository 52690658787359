import { useFilterApi, useFilterData } from '@data-c/providers'
import { FilterContainer } from '@data-c/ui'
import {
  ClienteFilters,
  ClienteFilters1,
  SituacaoClienteEnum,
} from 'hooks/queries/useClientes'

import { MenuItem, Stack, TextField } from '@mui/material'
import DocumentoTextField from 'components/DocumentoTextField'
import { onlyNumbers } from '@data-c/hooks'
import TimeVendasTransporter from 'components/Transporters/TimeVendasTranporter'
import { useUserContext } from 'components/Contexts/UserContext'
import { useEffect } from 'react'

export default function Filter() {
  const { onChangeFilterValue, changeFilterValue } = useFilterApi()
  const { filterValues, isOpen } = useFilterData<
    ClienteFilters,
    ClienteFilters1
  >()
  const { user, temPermissao } = useUserContext()

  const permissaoParaLerClientes = temPermissao('clientes.read') || 'pessoal'
  useEffect(() => {
    if (permissaoParaLerClientes === 'pessoal' && isOpen) {
      changeFilterValue('representante', user)
    }
  }, [permissaoParaLerClientes, isOpen])

  return (
    <FilterContainer
      title="Pesquisar por clientes / leads"
      triggerButtonProps={{ variant: 'outlined' }}
      renderLabel={(filterLabel: string, filterValue: any) => {
        if (filterLabel === 'Vendedor' || filterLabel === 'Cliente') {
          return `${filterLabel} - ${filterValue?.nome || ''}`
        }
        if (filterLabel === 'Situações') {
          const label = filterValue.length === 1 ? 'Situação' : 'Situações'
          let values = ''
          if (Array.isArray(filterValue)) {
            values = filterValue.map((f: any) => f.label).toString()
          }
          return `${label} - ${values}`
        }
        return `${filterLabel} - ${filterValue}`
      }}
    >
      <Stack spacing={2}>
        <TextField
          name="situacao"
          value={filterValues?.situacao?.value}
          label={filterValues?.situacao?.label}
          onChange={onChangeFilterValue}
          select
        >
          <MenuItem value={SituacaoClienteEnum.TODAS}>
            Clientes e Leads
          </MenuItem>
          <MenuItem value={SituacaoClienteEnum.CLIENTE}>Clientes</MenuItem>
          <MenuItem value={SituacaoClienteEnum.LEAD}>Leads</MenuItem>
        </TextField>
        <TextField
          autoFocus
          InputLabelProps={{ shrink: true }}
          name="plainQuery"
          value={filterValues?.plainQuery?.value}
          label={'Código / Nome'}
          placeholder="Digite código ou nome do cliente"
          onChange={onChangeFilterValue}
        />
        <DocumentoTextField
          name="cnpjcpf"
          required
          value={filterValues?.cnpjcpf?.value || ''}
          onChange={(e) => {
            const cnpjcpf = onlyNumbers(e.target.value)
            changeFilterValue('cnpjcpf', cnpjcpf)
          }}
          inputProps={{ label: filterValues?.cnpjcpf?.label }}
        />
        <TimeVendasTransporter
          disabled={temPermissao('clientes.read') === 'pessoal'}
          name="vendedorUuid"
          label="Vendedor"
          value={filterValues?.representante?.value || null}
          onChange={(representante) => {
            changeFilterValue('representante', representante)
          }}
          placeholder="Selecione um vendedor"
          InputLabelProps={{ shrink: true }}
        />
      </Stack>
    </FilterContainer>
  )
}
