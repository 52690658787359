import { ReactNode } from 'react'
import { Stack, Typography } from '@mui/material'
import CardContainer from 'components/CardContainer'

interface CardProps {
  title: string
  description: string
  children: ReactNode
}

export default function CardConfig(props: CardProps) {
  const { title, description, children } = props

  return (
    <CardContainer>
      <Stack sx={{ gap: 1, flex: '1' }}>
        <Typography
          component="h2"
          variant="h5"
          sx={{ fontWeight: '600', color: (theme) => theme.palette.grey[600] }}
        >
          {title}
        </Typography>
        <Typography variant="body2">{description}</Typography>
      </Stack>

      <Stack sx={{ width: '200px', alignItems: 'flex-end' }}>{children}</Stack>
    </CardContainer>
  )
}
