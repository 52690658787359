import { useCallback, useMemo } from 'react'

import {
  DataTable,
  OptionStyles,
  DataTableOptions as Options,
  Flag,
} from '@data-c/ui'
import { usePagination } from '@data-c/hooks'
import { useFormApi } from '@data-c/providers'

import { formatToCPFOrCNPJ } from 'brazilian-values'
import { MUIDataTableColumnDef } from 'mui-datatables'
import DataTableProps from 'interfaces/DataTableProps'
import useChavesDeAcesso, {
  ChavesDeAcessoFilters1,
  ChavesDeAcessoModel,
} from 'hooks/queries/useChavesDeAcesso'

interface TableProps
  extends DataTableProps<ChavesDeAcessoModel, ChavesDeAcessoFilters1> {}

export default function Table(props: TableProps) {
  const { filters } = props
  const { changePage, changePageSize, pagination } = usePagination()
  const { useQuery } = useChavesDeAcesso()
  const { openConfirm } = useFormApi()
  const { data, isLoading, isFetching, error } = useQuery({
    queryParams: filters,
    pagination,
  })

  const formattedData = data?.data.map((item) => {
    return {
      ...item,
      codigo: item?.vendedor?.codigo,
      nome: item?.vendedor?.usual,
      cnpjcpf: item?.vendedor?.cnpjcpf
        ? formatToCPFOrCNPJ(item?.vendedor?.cnpjcpf)
        : '',
    }
  })

  const findData = useCallback(
    (value: string): ChavesDeAcessoModel | undefined => {
      return data?.data.find((d: ChavesDeAcessoModel) => d.id === value)
    },
    [data],
  )

  const handleClickItem = useCallback(
    (event: 'delete', data: ChavesDeAcessoModel) => {
      if (event === 'delete') {
        openConfirm(data)
      }
    },
    [openConfirm],
  )

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'codigo',
        label: 'Código',
        options: {
          setCellProps() {
            return { width: '96px' }
          },
        },
      },
      {
        name: 'chave',
        label: 'Chave',
      },
      {
        name: 'nome',
        label: 'Nome',
      },
      {
        name: 'cnpjcpf',
        label: 'CNPJ/CPF',
      },
      {
        name: 'ativo',
        label: 'Ativo',
        options: {
          setCellProps: () => ({
            style: {
              width: '60px',
              textAlign: 'center',
            },
          }),
          customBodyRender: (value) => {
            return <Flag isFlagged={value === 1} type="success" />
          },
        },
      },
      {
        name: 'id',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          setCellProps: OptionStyles,
          customBodyRender: (value) => {
            const _data = findData(value)
            return (
              <Options
                displayUpdateButton={false}
                value={_data}
                onClick={handleClickItem}
              />
            )
          },
        },
      },
    ],
    [handleClickItem, findData],
  )

  return (
    <DataTable
      error={error?.message}
      columns={columns}
      data={formattedData || []}
      isLoading={isLoading}
      isFetching={isFetching}
      pagination={data?.pagination}
      changePage={changePage}
      changePageSize={changePageSize}
      options={{
        rowsPerPageOptions: [15, 50, 100],
        rowsPerPage: 15,
      }}
    />
  )
}
