import { Paper, Typography } from '@mui/material'

export interface CardValueProps {
  label: string
  value: string | number
  elevation?: number
  color?: string
}

export default function CardValue(props: CardValueProps) {
  const { label, value, elevation, color } = props
  return (
    <Paper
      sx={{
        backgroundColor: 'background.paper',
        borderRadius: '4px',
        p: 1,
        minHeight: 56,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderLeft: `7px solid ${color}`,
      }}
      elevation={elevation || 0}
    >
      <Typography
        sx={{
          fontWeight: '600',
          color: (theme) => theme.palette.grey[500],
        }}
      >
        {label}
      </Typography>
      <Typography
        sx={{
          textAlign: 'right',
          fontWeight: '600',
          fontSize: '18pt',
          color: (theme) => theme.palette.grey[700],
        }}
      >
        {value}
      </Typography>
    </Paper>
  )
}
