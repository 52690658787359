import { MoreHoriz, Delete, Edit } from '@mui/icons-material'
import {
  Box,
  IconButton,
  MenuItem,
  Popover,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'

import { useDialog } from '@data-c/hooks'
import { Button, ButtonContainer, Dialog } from '@data-c/ui'

import usePopOver from 'hooks/usePopover'

interface ItemOptionsProps {
  isDeleting?: boolean
  onDelete: () => Promise<void>
  onEdit: () => void
}

export default function ItemOptions(props: ItemOptionsProps) {
  const { isDeleting, onDelete, onEdit } = props

  const theme = useTheme()
  const { isOpen: isOpenDialog, openDialog, closeDialog } = useDialog()
  const {
    anchorEl,
    close: closePopOver,
    isOpen: isOpenPopOver,
    open: openPopOver,
  } = usePopOver()

  async function handleEdit() {
    onEdit()
    closePopOver()
  }

  async function handleDelete() {
    closePopOver()
    await onDelete()
    closeDialog()
  }

  return (
    <Box position="absolute" bottom={16} right={16}>
      <ButtonContainer>
        <IconButton
          onClick={(e) => {
            e.stopPropagation()
            openPopOver(e)
          }}
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.background.paper,
            ':hover': {
              backgroundColor: theme.palette.primary.dark,
            },
          }}
        >
          <MoreHoriz />
        </IconButton>
      </ButtonContainer>

      <Popover
        open={isOpenPopOver}
        onClose={closePopOver}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <MenuItem
          sx={{ m: 1 }}
          onClick={(e) => {
            e.stopPropagation()
            handleEdit()
          }}
        >
          <Stack direction="row" gap={1}>
            <Edit color="primary" />
            <Typography variant="body2">Editar comentário</Typography>
          </Stack>
        </MenuItem>
        <MenuItem
          sx={{ m: 1 }}
          onClick={(e) => {
            e.stopPropagation()
            openDialog()
          }}
        >
          <Stack direction="row" gap={1}>
            <Delete color="primary" />
            <Typography variant="body2">Excluir comentário</Typography>
          </Stack>
        </MenuItem>
      </Popover>

      <Dialog
        open={isOpenDialog}
        type="error"
        title="Excluir Comentário Permanentemente?"
        maxWidth="xs"
        actions={
          <ButtonContainer>
            <Button
              onClick={(e) => {
                e.stopPropagation()
                closeDialog()
              }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              color="error"
              isLoading={isDeleting}
              onClick={(e) => {
                e.stopPropagation()
                handleDelete()
              }}
            >
              Excluir
            </Button>
          </ButtonContainer>
        }
      >
        Você não poderá recuperar este comentário após excluí-lo!
      </Dialog>
    </Box>
  )
}
