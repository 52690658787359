import { Surface } from '@data-c/ui'
import Form from 'pages/FormaPagamentos/components/Form'
import { FormaPagamentoModel } from 'hooks/queries/useFormaPagamento'

export interface TabCadastroProps {
  formaPagamento: FormaPagamentoModel
}

export default function TabCadastro(props: TabCadastroProps) {
  return (
    <Surface sx={{ p: 2 }} elevation={1}>
      <Form view="plain" data={props.formaPagamento} />
    </Surface>
  )
}
