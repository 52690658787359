import { formatCurrency } from '@data-c/hooks'
import { Surface, theme } from '@data-c/ui'
import { useMemo } from 'react'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts'

const CustomTooltip = ({ payload, label }: TooltipProps<any, any>) => {
  if (payload && payload.length) {
    const { value } = payload[0]
    return (
      <Surface
        style={{
          padding: '5px',
          backgroundColor: '#fff',
          border: '1px solid #ddd',
        }}
      >
        <p>{`Nome: ${label}`}</p>
        <p>{`Total: R$ ${formatCurrency(value)}`}</p>
      </Surface>
    )
  }
  return null
}

const CustomYAxisTick = (props: any) => {
  const { x, y, payload } = props

  return (
    <foreignObject x={x - 180} y={y - 10} width={190} height={20}>
      <div
        style={{
          fontSize: '14px',
          color: '#666',
          textAlign: 'end',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {payload.value}
      </div>
    </foreignObject>
  )
}

interface ChartProps {
  data: Array<{ total: number; nome: string }>
}

export default function Chart(props: ChartProps) {
  const { data } = props

  const formattedData = useMemo(() => {
    return data.map((d) => {
      return {
        ...d,
        total: d.total,
      }
    })
  }, [data])

  return (
    <ResponsiveContainer width="100%" height={700}>
      <BarChart
        data={formattedData}
        margin={{
          top: 20,
          right: 30,
          left: 170,
          bottom: 20,
        }}
        layout="vertical"
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          type="number"
          axisLine={false}
          tickLine={false}
          tickMargin={10}
          tick={false}
        />
        <YAxis
          type="category"
          dataKey="nome"
          axisLine={false}
          tickLine={false}
          tickMargin={20}
          tick={<CustomYAxisTick />}
        >
          <Label
            offset={10}
            position="insideLeft"
            angle={-90}
            style={{ textAnchor: 'middle', fontSize: 14 }}
          />
        </YAxis>
        <Tooltip content={<CustomTooltip />} />
        <Bar dataKey="total" fill={theme.palette.primary.main} radius={5} />
      </BarChart>
    </ResponsiveContainer>
  )
}
