import { PageTitle } from '@data-c/ui'
import { Box, Container, Tab, Tabs } from '@mui/material'
import Loading from 'components/Displays/Loading'
import TabPanel from 'components/TabPanel'
import useRole, { RoleModel } from 'hooks/queries/useRole'
import useTabs from 'hooks/useTabs'
import { useNavigate, useParams } from 'react-router-dom'
import TabNegociacoes from './components/TabNegociacoes'
import RoleProvider from 'components/Contexts/RoleContext'
import TabVisibilidade from './components/TabVisibilidade'
import TabCadastros from './components/TabCadastros'
import TabCrm from './components/TabCrm'
import Content from 'components/Layouts/Content'

const tabMaps = [
  {
    label: 'Visibilidade',
    component: <TabVisibilidade />,
    visible: true,
  },
  {
    label: 'Negociações',
    component: <TabNegociacoes />,
    visible: true,
  },
  {
    label: 'Cadastros',
    component: <TabCadastros />,
    visible: true,
  },
  {
    label: 'CRM',
    component: <TabCrm />,
    visible: true,
  },
  // {
  //   label: 'Configurações',
  //   component: <TabCrm />,
  //   visible: true,
  // },
]

export default function Role() {
  const { id } = useParams()
  const navigate = useNavigate()
  const { useQueryByUuid } = useRole()
  const { handleChange, value } = useTabs()
  const { data, isLoading } = useQueryByUuid<RoleModel>(id || '')

  if (isLoading) {
    return <Loading />
  }

  return (
    <Container>
      <PageTitle
        title={`Permissão - ${data?.nome || ''}`}
        onBack={() => {
          navigate(-1)
        }}
      />

      <Content>
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: '48px',
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            sx={{
              // position: 'absolute',
              width: '100%',
              bgcolor: 'background.paper',
            }}
            scrollButtons={true}
            // allowScrollButtonsMobile
          >
            {tabMaps.map(
              (t, index) =>
                t.visible && (
                  <Tab key={t.label} label={t.label} value={index} />
                ),
            )}
          </Tabs>
        </Box>
        <RoleProvider role={data || {}}>
          {tabMaps.map(
            (t, index) =>
              t.visible && (
                <TabPanel key={t.label} index={index} value={value}>
                  {t.component}
                </TabPanel>
              ),
          )}
        </RoleProvider>
      </Content>
    </Container>
  )
}
