import { ReactNode } from 'react'
import { Box, Popover } from '@mui/material'

interface ToggleViewContainerProps {
  isOpen: boolean
  anchorEl: HTMLElement | null
  children: ReactNode
  close: () => void
}

export default function ToggleViewContainer(props: ToggleViewContainerProps) {
  const { isOpen, anchorEl, close, children } = props

  return (
    <Popover
      open={isOpen}
      onClose={close}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Box sx={{ padding: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
        {children}
      </Box>
    </Popover>
  )
}
