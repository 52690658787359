import { FilterOptions } from '@data-c/providers'
import useCrud from 'hooks/useCrud'
import { AxiosError } from 'axios'
import BaseModel from 'interfaces/BaseModel'
import { TimeVendasModel } from './useTimeVendas'
import { ClientesModel } from './useClientes'
import { PedidoModel } from './usePedidos'
import {
  useMutation,
  useQueryClient,
  useQuery as useTQuery,
} from '@tanstack/react-query'
import api from 'services/api'
import ligacaoIcon from 'assets/images/ic_ligar.svg'
import reuniaoIcon from 'assets/images/ic_reuniao.svg'
import tarefaIcon from 'assets/images/ic_tarefa.svg'
import prazoIcon from 'assets/images/ic_prazo.svg'
import emailIcon from 'assets/images/ic_email.svg'
import eventoIcon from 'assets/images/ic_evento.svg'
import { AppNotificationsModel } from './useAppNotifications'
import { AtividadeComentarios } from './useAtividadeComentarios'
import useNotification from 'hooks/useNotifications'
import { DateRange } from 'components/Inputs/DateRangePicker'
import { DateTime } from 'luxon'

export enum TipoAtividade {
  TODAS = 'TODAS',
  LIGAR = 'LIGAR',
  REUNIAO = 'REUNIAO',
  TAREFA = 'TAREFA',
  PRAZO = 'PRAZO',
  EMAIL = 'EMAIL',
  ALMOCO = 'ALMOCO',
}

export enum SituacaoAtividade {
  TODAS = 'TODAS',
  CONCLUIDA = 'CONCLUIDA',
  AGORA = 'AGORA',
  PLANEJADA = 'PLANEJADA',
  ATRASADA = 'ATRASADA',
}

export const tipoAtividadeMap: Record<string, string> = {
  [TipoAtividade.TODAS]: 'Todas',
  [TipoAtividade.LIGAR]: 'Ligação',
  [TipoAtividade.REUNIAO]: 'Reunião',
  [TipoAtividade.TAREFA]: 'Tarefa',
  [TipoAtividade.PRAZO]: 'Prazo',
  [TipoAtividade.EMAIL]: 'E-mail',
  [TipoAtividade.ALMOCO]: 'Evento',
}

export const tipoAtividadeIconMap: Record<string, string> = {
  [TipoAtividade.LIGAR]: ligacaoIcon,
  [TipoAtividade.REUNIAO]: reuniaoIcon,
  [TipoAtividade.TAREFA]: tarefaIcon,
  [TipoAtividade.PRAZO]: prazoIcon,
  [TipoAtividade.EMAIL]: emailIcon,
  [TipoAtividade.ALMOCO]: eventoIcon,
}

export const situacaoAtividadeMap: Record<string, string> = {
  [SituacaoAtividade.TODAS]: 'Todas',
  [SituacaoAtividade.CONCLUIDA]: 'Concluída',
  [SituacaoAtividade.AGORA]: 'Agora',
  [SituacaoAtividade.PLANEJADA]: 'Planejada',
  [SituacaoAtividade.ATRASADA]: 'Atrasada',
}

export interface CrmAtividadesModel extends BaseModel {
  assunto?: string
  dataInicio?: string
  horaInicio?: string
  dataFim?: string
  dataAtividadeFormatted?: string
  horaFim?: string
  statusAtividade?: string
  descricao?: string
  tipoAtividade?: TipoAtividade | null
  responsavelUuid?: string | null
  responsavel?: TimeVendasModel | null
  atribuidoParaAsText?: TimeVendasModel | null
  leadUuid?: string | null
  lead?: ClientesModel | null
  leadAsText?: string | null
  pedidoUuid?: string | null
  pedido?: PedidoModel | null
  // duracao?: Duration | string
  duracao?: string
  concluido?: boolean
  situacao?: string
  dataInicioAsRelative?: string
  tipoAtividadeAsText?: string
  tipoAtividadeIcon?: string
  notificacoes?: Array<AppNotificationsModel>
  comentarios?: Array<AtividadeComentarios>
  comentarioStatus?: {
    totalComentarios: number
    totalNaoLidas: number
  }
  dataHoraInicioAsBrazilianDate?: string
  dataHoraFimAsBrazilianDate?: string
  dataInicioAsBrazilianDate?: string
  dataFimAsBrazilianDate?: string
  status: 'ATRASADA' | 'AGORA' | 'PLANEJADA' | 'SEM_ATIVIDADE' | 'CONCLUIDA'
  diaInteiro?: number
}

export interface CrmAtividadesFilters {
  assunto?: FilterOptions<string | null>
  tipoAtividade?: FilterOptions<string | null>
  lead?: FilterOptions<ClientesModel | null>
  leadUuid?: FilterOptions<string | null>
  responsavel?: FilterOptions<TimeVendasModel | null>
  responsavelUuid?: FilterOptions<string | null>
  dateRange?: FilterOptions<DateRange>
  situacao?: FilterOptions<string>
  pedidoUuid?: FilterOptions<string>
  pedido?: FilterOptions<PedidoModel>
}

export interface CrmAtividadesFilters1 {
  assunto?: string | null
  tipoAtividade?: TipoAtividade | null
  lead?: ClientesModel | null
  responsavel?: TimeVendasModel | null
  leadUuid?: string | null
  responsavelUuid?: string | null
  dataInicio?: string | null
  dataFim?: string | null
  dateRange?: DateRange
  situacao?: string
  pedidoUuid?: string
  pedido?: PedidoModel
}

export const filters: CrmAtividadesFilters = {
  assunto: {
    label: 'Assunto',
  },
  tipoAtividade: {
    label: 'Tipo de Atividade',
    value: TipoAtividade.TODAS,
  },
  lead: {
    label: 'Cliente / Lead',
  },
  leadUuid: {
    label: 'Cliente / Lead',
  },
  responsavel: {
    label: 'Atribuído para',
  },
  responsavelUuid: {
    label: 'Atribuído para',
  },
  dateRange: {
    label: 'Período',
    value: {
      tipo: 'month',
      firstDate: DateTime.now().startOf('day').toFormat('yyyy-MM-dd'),
      secondDate: DateTime.now().endOf('day').toFormat('yyyy-MM-dd'),
    },
  },
  situacao: {
    label: 'Situação',
    value: 'TODAS',
  },
  pedido: {
    label: 'Negociação',
  },
}

export async function obterAtividadePorPedidoId(
  id: string,
): Promise<CrmAtividadesModel[]> {
  const response = await api.get(`crm/atividades/negociacao/${id}`)
  return response.data
}

export function useQueryObterAtividadePorPedidoId(id: string) {
  return useTQuery<CrmAtividadesModel[], AxiosError>(['ATIVIDADE', id], () => {
    return obterAtividadePorPedidoId(id)
  })
}

export async function updateComentarioLidoEm(
  atividadeUuid: string,
): Promise<CrmAtividadesModel> {
  const response = await api.put(
    `/crm/atividades/${atividadeUuid}/comentario-lido-em`,
  )

  return response.data
}

export function useUpdateComentarioLidoEm() {
  const queryClient = useQueryClient()
  const notifications = useNotification()

  return useMutation<CrmAtividadesModel, AxiosError, string>(
    (atividadeUuid: string) => updateComentarioLidoEm(atividadeUuid),
    {
      onSuccess(_: CrmAtividadesModel) {
        queryClient.invalidateQueries(['ATIVIDADE'])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export async function atualizaConcluido(
  atividade: CrmAtividadesModel,
): Promise<CrmAtividadesModel> {
  const response = await api.put(`/crm/atividades/${atividade.uuid}`, atividade)

  return response.data
}

export function useAtualizarConcluido() {
  const queryClient = useQueryClient()
  const notifications = useNotification()

  return useMutation<CrmAtividadesModel, AxiosError, CrmAtividadesModel>(
    (atividade: CrmAtividadesModel) => atualizaConcluido(atividade),
    {
      onSuccess(_: CrmAtividadesModel) {
        queryClient.invalidateQueries(['ATIVIDADE'])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export default function useCrmAtividades() {
  return {
    ...useCrud<CrmAtividadesModel, AxiosError>(
      '/crm/atividades',
      'Atividade',
      'female',
    ),
    useUpdateComentarioLidoEm,
    useAtualizarConcluido,
  }
}
