import { useState } from 'react'

export interface PopOverOutputProps<T = unknown> {
  updateData: React.Dispatch<React.SetStateAction<T | null>>
  isOpen: boolean
  close: () => void
  open: (e: React.MouseEvent<HTMLElement>, data?: T) => void
  anchorEl: HTMLElement | null
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>
  data?: T | null
}

export default function usePopOver<T extends any>(): PopOverOutputProps<T> {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [popoverData, setData] = useState<T | null>(null)

  const isOpen = Boolean(anchorEl)

  function open(event: React.MouseEvent<HTMLElement>, data?: T) {
    setAnchorEl(event.currentTarget)
    if (data) {
      setData(data)
    }
  }

  function close() {
    setAnchorEl(null)
    setData(null)
  }

  return {
    isOpen,
    open,
    close,
    anchorEl,
    setAnchorEl,
    updateData: setData,
    data: popoverData,
  }
}
