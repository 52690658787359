import { useState } from 'react'
import { PlainQueryProps } from '@data-c/hooks'
import { Transporter, TransporterProps } from '@data-c/ui'
import useGrupoProdutos, {
  GrupoProdutosModel,
} from 'hooks/queries/useGrupoProdutos'
import GrupoProdutoForm from 'pages/GrupoProdutos/components/Form'
import GrupoProdutoTable from 'pages/GrupoProdutos/components/Table'

type GrupoProdutoTransporterProps = {} & Omit<
  TransporterProps<GrupoProdutosModel>,
  'table' | 'form' | 'onSearch' | 'renderValue' | 'options'
>

export default function GrupoProdutoTransporter(
  props: GrupoProdutoTransporterProps,
) {
  const { value, ...rest } = props

  const [query, setQuery] = useState<string | null>()
  const { useQuery } = useGrupoProdutos()

  const { data, isLoading } = useQuery<PlainQueryProps>({
    queryParams: {
      plainQuery: query,
    },
    pagination: {
      page: 1,
      pageSize: 100,
    },
  })

  function handleSearch(q: string | null) {
    setQuery(q)
  }

  return (
    <Transporter
      onSearch={handleSearch}
      options={data?.data || []}
      isLoading={isLoading}
      table={GrupoProdutoTable}
      label="Grupo"
      dialogProps={{ title: 'Grupo' }}
      renderValue={(value) => `${value?.codigo || ''} - ${value.nome}`}
      form={GrupoProdutoForm}
      value={value}
      {...rest}
    />
  )
}
