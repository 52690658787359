import { useMemo } from 'react'

import { MUIDataTableColumnDef } from 'mui-datatables'

import { DataTable } from '@data-c/ui'

import { useFilterData } from '@data-c/providers'
import Surface from 'components/Surface'
import useRelatorioPrevisaoEntrega, {
  PrevisaoEntregaFilters,
  PrevisaoEntregaFiltersParams,
} from 'hooks/queries/useRelatorioPrevisaoEntrega'
import { DateTime } from 'luxon'

export default function Table() {
  const { appliedValues, searchId } = useFilterData<
    PrevisaoEntregaFilters,
    PrevisaoEntregaFiltersParams
  >()

  const { representante, cliente, ...rest } = appliedValues || {}
  const nFilters: PrevisaoEntregaFiltersParams = {
    ...rest,
    representanteUuid: representante?.uuid,
    clienteUuid: cliente?.uuid,
  }

  const { useQueryObterRelatorioPrevisaoEntrega } =
    useRelatorioPrevisaoEntrega()
  const { data, isLoading, isFetching, error } =
    useQueryObterRelatorioPrevisaoEntrega({
      queryParams: nFilters,
      requestId: searchId,
      pagination: {
        page: 1,
        pageSize: 999,
      },
    })

  const dataFormatada = useMemo(
    () =>
      data?.map((item) => {
        return {
          ...item,
          dataPrevista:
            item.dataPrevista &&
            DateTime.fromISO(item.dataPrevista).toFormat('dd/MM/yyyy'),
        }
      }),
    [data],
  )

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'numeroPedido',
        label: 'Pedido',
        options: {
          setCellProps() {
            return {
              width: '96px',
            }
          },
        },
      },
      {
        name: 'nomeVendedor',
        label: 'Vendedor',
      },
      {
        name: 'nomeCliente',
        label: 'Cliente',
      },
      {
        name: 'nomeProduto',
        label: 'Produto',
      },
      {
        name: 'dataPrevista',
        label: 'Previsão',
        options: {
          setCellProps() {
            return {
              width: '112px',
              style: {
                textAlign: 'center',
              },
            }
          },
        },
      },
    ],
    [data],
  )

  return (
    <Surface>
      <DataTable
        error={error?.message}
        columns={columns}
        data={dataFormatada || []}
        isLoading={isLoading}
        isFetching={isFetching}
      />
    </Surface>
  )
}
