import { ButtonContainer } from '@data-c/ui'
import { Box } from '@mui/material'
import Form from './components/Form'
import Table from './components/Table'

interface TabClientesProps {
  vendedorUuid: string
}

export default function TabClientes(props: TabClientesProps) {
  const { vendedorUuid } = props

  return (
    <Box>
      <ButtonContainer sx={{ mb: 2 }}>
        <Form vendedorUuid={vendedorUuid} />
      </ButtonContainer>

      <Table vendedorUuid={vendedorUuid} />
    </Box>
  )
}
