import { MenuItem, TextField, TextFieldProps } from '@mui/material'

export enum UsaFormaPagamentoOptions {
  EXIBE_FORMAS_PAGAMENTO_ATIVAS = 1,
  EXIBE_FORMAS_PAGAMENTO_ATRELADAS_VENDEDOR = 2,
  EXIBE_FORMAS_PAGAMENTO_ATRELADAS_CLIENTE_CASO_NAO_HAJA_FORMAS_PAGAMENTO_ATRELADA_CLIENTE_UTILIZA_MODO_2 = 3,
  EXIBE_FORMAS_PAGAMENTO_VENDEDOR = 4,
  EXIBE_FORMAS_PAGAMENTO_CLIENTE_CASO_NAO_HAJA_FORMAS_PAGAMENTO_NO_CLIENTE_EXIBE_A_DO_VENDEDOR = 5,
}

export default function DropDownUsaFormaPagamento(props: TextFieldProps) {
  return (
    <TextField select {...props}>
      <MenuItem value={UsaFormaPagamentoOptions.EXIBE_FORMAS_PAGAMENTO_ATIVAS}>
        Exibe as formas de pagamentos ativas
      </MenuItem>
      <MenuItem
        value={
          UsaFormaPagamentoOptions.EXIBE_FORMAS_PAGAMENTO_ATRELADAS_VENDEDOR
        }
      >
        Exibe as formas de pagamentos atreladas ao vendedor
      </MenuItem>
      <MenuItem
        value={
          UsaFormaPagamentoOptions.EXIBE_FORMAS_PAGAMENTO_ATRELADAS_CLIENTE_CASO_NAO_HAJA_FORMAS_PAGAMENTO_ATRELADA_CLIENTE_UTILIZA_MODO_2
        }
      >
        Exibe as formas de pagamentos atreladas ao cliente, quando não houver
        irá exibir as formas de pagamentos atreladas ao vendedor
      </MenuItem>
      <MenuItem
        value={UsaFormaPagamentoOptions.EXIBE_FORMAS_PAGAMENTO_VENDEDOR}
      >
        Exibe a forma de pagamento do vendedor
      </MenuItem>
      <MenuItem
        value={
          UsaFormaPagamentoOptions.EXIBE_FORMAS_PAGAMENTO_CLIENTE_CASO_NAO_HAJA_FORMAS_PAGAMENTO_NO_CLIENTE_EXIBE_A_DO_VENDEDOR
        }
      >
        Exibe a forma de pagamento do cliente, quando não houver irá exibir a
        forma de pagamento do vendedor
      </MenuItem>
    </TextField>
  )
}
