import { useState, useRef, InputHTMLAttributes } from 'react'
import { InputState } from 'react-input-mask'
import { TextFieldProps } from '@mui/material'
import MaskedTextField from 'components/MaskedTextField'

export enum TIPO_DOCUMENTO {
  INDEFINIDO = 'INDEFINIDO',
  CPF = 'CPF',
  CNPJ = 'CNPJ',
}

export enum MASCARA {
  CPF = '999.999.999-99',
  INTERMEDIARIA = '999.999.999-999',
  CNPJ = '99.999.999/9999-99',
}

interface NumeroInscricaoTextFieldProps
  extends InputHTMLAttributes<HTMLInputElement> {
  inputProps?: TextFieldProps
  value: string
}

export default function CnpjCpfTextField(props: NumeroInscricaoTextFieldProps) {
  const { value, ...rest } = props
  const [mask, setMask] = useState(MASCARA.CPF)

  const textRef = useRef(null)

  const beforeMaskedValueChange = (
    newState: InputState,
    oldState: InputState,
    userInput: string,
  ) => {
    let selection = newState.selection

    if (!userInput) {
      if (oldState.value.length === 14) {
        setMask(MASCARA.CNPJ)
      } else {
        // setMask(MASCARA.CPF)
      }
    }

    if (userInput && newState.selection && oldState.selection) {
      let newStart = newState.selection.start
      let oldStart = oldState.selection.start

      if (newStart < 13 && mask !== MASCARA.CPF) {
        setMask(MASCARA.CPF)
      }

      if (newStart === 13 && oldStart === 12) {
        setMask(MASCARA.CPF)
      }

      if (newStart === 14 && oldStart === 13) {
        setMask(MASCARA.INTERMEDIARIA)
      }

      if (newStart === 15 && oldStart === 14) {
        setMask(MASCARA.CNPJ)
      }
    }
    const { value: nValue } = newState
    return {
      value: nValue,
      selection,
    }
  }

  return (
    <MaskedTextField
      ref={textRef}
      mask={mask}
      beforeMaskedValueChange={beforeMaskedValueChange}
      value={value}
      {...rest}
    />
  )
}
