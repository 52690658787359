import { ButtonContainer } from '@data-c/ui'
import { Box } from '@mui/material'
import Form from '../../../../components/EntityLinkerPessoa/Form'
import Table from '../../../../components/EntityLinkerPessoa/Table'
import { EntidadeEnum } from 'hooks/queries/usePessoa'

interface TabTabelaDePrecosProps {
  pessoaUuid: string
}

export default function TabFormaDePagamento(props: TabTabelaDePrecosProps) {
  return (
    <Box>
      <ButtonContainer sx={{ mb: 2 }}>
        <Form
          pessoaUuid={props.pessoaUuid}
          titulo="Formas de Pagamento"
          entidade={EntidadeEnum.FORMA_PAGAMENTO}
          situacaoPessoa="CLIENTE"
        />
      </ButtonContainer>
      <Table
        pessoaUuid={props.pessoaUuid}
        titulo="Formas de Pagamento"
        entidade={EntidadeEnum.FORMA_PAGAMENTO}
        situacaoPessoa="CLIENTE"
      />
    </Box>
  )
}
