import { Box, Stack } from '@mui/material'
import CardConfig from 'components/CardConfig'
import Clientes from './components/Clientes'
import Vendedores from './components/Vendedores'
import Produtos from './components/Produtos'
import MixProdutos from './components/MixProdutos'
import FatorPrecificacao from './components/FatorPrecificacao'
import TabelaPreco from './components/TabelaPreco'
import FormaPagamento from './components/FormaPagamento'
import CondicaoPagamento from './components/CondicaoPagamento'
import Etiquetas from './components/Etiquetas'

export default function TabCadastros() {
  return (
    <Box sx={{ mt: 2 }}>
      <Stack spacing={2}>
        <CardConfig
          title="Clientes"
          collapsable
          description={
            <div>
              Define os níveis de permissão que o usuário tem nas operações do
              cadastro de clientes
            </div>
          }
        >
          <Clientes />
        </CardConfig>
        <CardConfig
          title="Vendedores"
          collapsable
          description={
            <div>
              Define os níveis de permissão que o usuário tem nas operações do
              cadastro de vendedores
            </div>
          }
        >
          <Vendedores />
        </CardConfig>
        <CardConfig
          title="Tabela de Preços"
          collapsable
          description={
            <div>
              Define os níveis de permissão que o usuário tem nas operações do
              cadastro de tabela de preços
            </div>
          }
        >
          <TabelaPreco />
        </CardConfig>

        <CardConfig
          title="Forma de Pagamento"
          collapsable
          description={
            <div>
              Define os níveis de permissão que o usuário tem nas operações do
              cadastro de forma de pagamento
            </div>
          }
        >
          <FormaPagamento />
        </CardConfig>
        <CardConfig
          title="Condição de Pagamento"
          collapsable
          description={
            <div>
              Define os níveis de permissão que o usuário tem nas operações do
              cadastro de condição de pagamento
            </div>
          }
        >
          <CondicaoPagamento />
        </CardConfig>

        <CardConfig
          title="Produtos"
          collapsable
          description={
            <div>
              Define os níveis de permissão que o usuário tem nas operações do
              cadastro de produtos
            </div>
          }
        >
          <Produtos />
        </CardConfig>

        <CardConfig
          title="Fator de Precificação"
          collapsable
          description={
            <div>
              Define os níveis de permissão que o usuário tem nas operações do
              cadastro de fator de precificação
            </div>
          }
        >
          <FatorPrecificacao />
        </CardConfig>
        <CardConfig
          title="Mix de Produtos"
          collapsable
          description={
            <div>
              Define os níveis de permissão que o usuário tem nas operações do
              cadastro de mix de produtos
            </div>
          }
        >
          <MixProdutos />
        </CardConfig>
        <CardConfig
          title="Etiquetas"
          collapsable
          description={
            <div>
              Define os níveis de permissão que o usuário tem nas operações do
              cadastro de etiquetas
            </div>
          }
        >
          <Etiquetas />
        </CardConfig>
      </Stack>
    </Box>
  )
}
