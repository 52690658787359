import { ButtonContainer, PageTitle } from '@data-c/ui'
import { Container } from '@mui/material'
import { FilterProvider } from '@data-c/providers'
import { filters } from 'hooks/queries/useRelatorioABCProdutos'
import Content from 'components/Layouts/Content'
import Filter from './components/Filter'
import Table from './components/Table'
import HelpButton from 'components/HelpButton'

export default function ABCProdutos() {
  return (
    <Container>
      <FilterProvider filterValues={filters}>
        <PageTitle title="Curva ABC de Produtos">
          <ButtonContainer>
            <Filter />
            <HelpButton link="https://datacsistemashelp.zendesk.com/hc/pt-br/articles/28963057562388-Relat%C3%B3rio-Curva-ABC-de-Produtos" />
          </ButtonContainer>
        </PageTitle>
        <Content>
          <Table />
        </Content>
      </FilterProvider>
    </Container>
  )
}
