import { useFilterApi, useFilterData } from '@data-c/providers'
import { Checkbox, FilterContainer, theme } from '@data-c/ui'

import { MenuItem, Stack, TextField } from '@mui/material'
import ClasseProdutoTransporter from 'components/Transporters/ClasseProdutoTransporter'
import FamiliaProdutoTransporter from 'components/Transporters/FamiliaProdutoTransporter'
import GrupoProdutoTransporter from 'components/Transporters/GrupoProdutoTransporter'
import LinhaProdutoTransporter from 'components/Transporters/LinhaProdutoTransporter'
import { ClasseProdutosModel } from 'hooks/queries/useClasseProdutos'
import { FamiliaProdutosModel } from 'hooks/queries/useFamiliaProdutos'
import { GrupoProdutosModel } from 'hooks/queries/useGrupoProdutos'
import { LinhaProdutosModel } from 'hooks/queries/useLinhaProdutos'
import { ProdutoFilters, ProdutoFilters1 } from 'hooks/queries/useProdutos'
import FilterProps from 'interfaces/FilterProps'

export default function Filter({
  onApplyFilters,
}: FilterProps<ProdutoFilters1>) {
  const { onChangeFilterValue, changeFilterValue } = useFilterApi()
  const { filterValues } = useFilterData<ProdutoFilters, ProdutoFilters1>()
  return (
    <FilterContainer
      title="Pesquisar por produtos"
      disableds={['categoriaKey']}
      triggerButtonProps={{ variant: 'outlined' }}
      onApplyFilters={(f) => onApplyFilters(f)}
      renderLabel={(filterLabel: string, filterValue: any) => {
        if (filterLabel === 'qtdMinima') {
          return 'Apenas grade fechada'
        }
        if (filterLabel === 'codigoNomeCor') {
          return `Cor - ${filterValue}`
        }
        if (filterLabel === 'codigoNomeGrade') {
          return `Grade - ${filterValue}`
        }
        if (filterLabel === 'Categoria') {
          return `${filterLabel} - ${filterValue?.nome || ''}`
        }
        if (filterLabel === 'Tipo de Categoria') {
          return `${filterLabel} - ${
            (filterValue as string).toUpperCase() || ''
          }`
        }
        return `${filterLabel} - ${filterValue}`
      }}
    >
      <Stack spacing={1.5}>
        <TextField
          name="codigo"
          value={filterValues?.codigo?.value}
          label={filterValues?.codigo?.label}
          onChange={onChangeFilterValue}
        />

        <TextField
          name="nome"
          value={filterValues?.nome?.value}
          label={filterValues?.nome?.label}
          onChange={onChangeFilterValue}
        />

        <Stack
          sx={{
            gap: 2,
            border: `solid 1px ${theme.palette.primary.main}`,
            padding: 2,
            borderRadius: '4px',
          }}
        >
          <TextField
            select
            name="tipoCategoria"
            label={filterValues?.categoriaKey?.label}
            value={filterValues?.categoriaKey?.value || 'nenhuma'}
            onChange={(e) => {
              const key = e.target.value
              changeFilterValue('categoriaKey', key)
            }}
          >
            <MenuItem value="nenhuma">Todas</MenuItem>
            <MenuItem value="classe">Classe</MenuItem>
            <MenuItem value="familia">Família</MenuItem>
            <MenuItem value="grupo">Grupo</MenuItem>
            <MenuItem value="linha">Linha</MenuItem>
          </TextField>

          {filterValues?.categoriaKey?.value === 'classe' && (
            <ClasseProdutoTransporter
              value={filterValues?.categoriaValue?.value || null}
              onChange={(value: ClasseProdutosModel | null) => {
                changeFilterValue('categoriaValue', value)
              }}
            />
          )}

          {filterValues?.categoriaKey?.value === 'familia' && (
            <FamiliaProdutoTransporter
              value={filterValues?.categoriaValue?.value || null}
              onChange={(value: FamiliaProdutosModel | null) => {
                changeFilterValue('categoriaValue', value)
              }}
            />
          )}

          {filterValues?.categoriaKey?.value === 'grupo' && (
            <GrupoProdutoTransporter
              value={filterValues?.categoriaValue?.value || null}
              onChange={(value: GrupoProdutosModel | null) => {
                changeFilterValue('categoriaValue', value)
              }}
            />
          )}

          {filterValues?.categoriaKey?.value === 'linha' && (
            <LinhaProdutoTransporter
              value={filterValues?.categoriaValue?.value || null}
              onChange={(value: LinhaProdutosModel | null) => {
                changeFilterValue('categoriaValue', value)
              }}
            />
          )}
        </Stack>

        <Checkbox
          name="apenasQuantidadeMinima"
          label="Apenas grade fechada"
          checked={filterValues?.apenasQuantidadeMinima?.value}
          onChange={onChangeFilterValue}
        />
      </Stack>
    </FilterContainer>
  )
}
