import { useState } from 'react'
import { Transporter, TransporterProps } from '@data-c/ui'

import {
  ItemTabelaPrecoModel,
  useQueryItemTabelaPreco,
} from 'hooks/queries/useTabelaPreco'

import ItensTabelaPrecoTable from 'pages/TabelasPrecos/components/ItensTabelaPrecoTable'

type ItensTabelaPrecoTransporterProps = {
  tabelaPrecoUuid: string
} & Omit<
  TransporterProps<ItemTabelaPrecoModel>,
  'table' | 'form' | 'onSearch' | 'renderValue' | 'options'
>

export default function ItensTabelaPrecoTransporter(
  props: ItensTabelaPrecoTransporterProps,
) {
  const { value, tabelaPrecoUuid, ...rest } = props
  const [query, setQuery] = useState<string | null>(null)

  const { data, isLoading } = useQueryItemTabelaPreco(tabelaPrecoUuid, {
    queryParams: {
      plainQuery: query,
      ativo: true,
    },
    pagination: {
      page: 1,
      pageSize: 100,
    },
  })

  function handleSearch(q: string) {
    setQuery(q)
  }

  return (
    <Transporter
      onSearch={handleSearch}
      options={data?.data || []}
      isLoading={isLoading}
      table={ItensTabelaPrecoTable}
      tableProps={{
        tabelaPrecoUuid,
      }}
      label="Produto"
      renderValue={(value) => {
        return `${value?.produto?.codigo || ''} - ${value?.produto?.nome || ''}`
      }}
      value={value}
      {...rest}
    />
  )
}
