import { createContext, useContext, useEffect, useState } from 'react'
import invariant from 'tiny-invariant'
export interface MenuProps {
  isOpen: boolean
  toggleMenu: () => void
}

export const MenuContext = createContext<MenuProps | null>(null)
const STORAGE_KEY = 'app-fdv:menu'
export function MenuProvider(props: { children: React.ReactNode }) {
  const [isOpen, setOpen] = useState<boolean>(() => {
    const status = window.localStorage.getItem(STORAGE_KEY) || 'true'
    return status ? JSON.parse(status) : []
  })
  const { children } = props

  function toggleMenu() {
    setOpen((p) => !p)
  }

  useEffect(() => {
    window.localStorage.setItem(STORAGE_KEY, JSON.stringify(isOpen))
  }, [isOpen])

  return (
    <MenuContext.Provider
      value={{
        isOpen,
        toggleMenu,
      }}
    >
      {children}
    </MenuContext.Provider>
  )
}

export function useMenu(): MenuProps {
  const value = useContext(MenuContext)
  invariant(value, 'cannot find MenuContext provider')
  return value
}
