import { FormContainer } from '@data-c/ui'
import { useFormApi, useFormData } from '@data-c/providers'

import {
  ClientesModel,
  useLiberarInadimplencia,
} from 'hooks/queries/useClientes'

import { Button, Grid, Typography } from '@mui/material'
import { useUserContext } from 'components/Contexts/UserContext'
import { useEffect } from 'react'
import * as yup from 'yup'
import useValidations from 'hooks/useValidations'
import DatePicker from 'components/DatePicker'
import TimePicker from 'components/TimePicker'
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers'
import { DateTime } from 'luxon'
interface LiberarInadimplenciaProps {
  cliente?: ClientesModel | null
  dataLimiteIgnorarInadimplencia: string
  horaLimiteIgnorarInadimplencia: string
}

const schema = yup.object().shape({
  dataLimiteIgnorarInadimplencia: yup
    .date()
    .min(
      DateTime.now().toFormat('yyyy-MM-dd'),
      'A data deve ser maior ou igual à data atual',
    )
    .typeError('Informe uma data válida')
    .required('Informe a Data'),
  horaLimiteIgnorarInadimplencia: yup
    .string()
    .test(
      'is-after-now',
      'A hora deve ser maior que a hora atual',
      function (value) {
        if (!value) return false
        const horaAtual = DateTime.now().toFormat('HH:mm')
        return (
          DateTime.fromFormat(value, 'HH:mm') >
          DateTime.fromFormat(horaAtual, 'HH:mm')
        )
      },
    )
    .typeError('Informe uma hora válida')
    .required('Informe a Hora'),
})

interface FormProps {
  pessoaUuid: string
}

export default function Form(props: FormProps) {
  const { pessoaUuid } = props

  const { changeValue, toggleSubmit, closeForm } =
    useFormApi<LiberarInadimplenciaProps>()
  const { setValidationErrors, validationProps } = useValidations()
  const { formValues: data, isOpen } = useFormData<LiberarInadimplenciaProps>()
  const { openForm } = useFormApi()

  const { mutateAsync: liberarInadimplencia } = useLiberarInadimplencia()
  const { user, temPermissao } = useUserContext()

  const permissaoParaLerClientes = temPermissao('clientes.read') || 'pessoal'

  useEffect(() => {
    if (permissaoParaLerClientes === 'pessoal' && isOpen) {
      changeValue('representante', user)
    }
  }, [permissaoParaLerClientes, isOpen])

  function handleOpenForm() {
    const curDate = DateTime.now().plus({ hour: 1 })
    const initialData: LiberarInadimplenciaProps = {
      dataLimiteIgnorarInadimplencia: curDate.toFormat('yyyy-MM-dd'),
      horaLimiteIgnorarInadimplencia: curDate.toFormat('HH:mm'),
    }
    openForm(initialData)
  }

  function handleSubmitForm() {
    const newData = {
      dataLimiteIgnorarInadimplencia: data.dataLimiteIgnorarInadimplencia,
      horaLimiteIgnorarInadimplencia: data.horaLimiteIgnorarInadimplencia,
    }

    schema
      .validate(newData, { abortEarly: false })
      .then(async () => {
        toggleSubmit(true)
        await liberarInadimplencia({
          dataLimiteIgnorarInadimplencia: data.dataLimiteIgnorarInadimplencia,
          horaLimiteIgnorarInadimplencia: data.horaLimiteIgnorarInadimplencia,
          clienteUuid: pessoaUuid,
        })
        closeForm()
      })
      .catch((err) => {
        setValidationErrors(err)
      })
      .finally(() => {
        toggleSubmit(false)
      })
  }

  return (
    <FormContainer
      triggerButtonLabel="Liberar Pedido"
      dialogProps={{ title: 'Liberar Pedido' }}
      onSubmitForm={handleSubmitForm}
      confirmButtonLabel="Liberar Pedido"
      triggerButton={
        <Button variant="contained" onClick={handleOpenForm}>
          Liberar Pedido
        </Button>
      }
    >
      <Grid container spacing={2}>
        <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
          <DatePicker
            label="Data"
            onChange={(dataLimiteIgnorarInadimplencia) => {
              changeValue(
                'dataLimiteIgnorarInadimplencia',
                dataLimiteIgnorarInadimplencia,
              )
            }}
            value={data?.dataLimiteIgnorarInadimplencia || null}
            slotProps={{
              textField: {
                name: 'dataLimiteIgnorarInadimplencia',
                ...validationProps('dataLimiteIgnorarInadimplencia'),
              },
            }}
          />
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
          <TimePicker
            viewRenderers={{
              hours: renderTimeViewClock,
              minutes: renderTimeViewClock,
              seconds: renderTimeViewClock,
            }}
            label="Hora"
            format="HH:mm"
            onChange={(horaLimiteIgnorarInadimplencia) => {
              changeValue(
                'horaLimiteIgnorarInadimplencia',
                horaLimiteIgnorarInadimplencia,
              )
            }}
            value={data?.horaLimiteIgnorarInadimplencia || null}
            slotProps={{
              textField: {
                name: 'horaLimiteIgnorarInadimplencia',
                ...validationProps('horaLimiteIgnorarInadimplencia'),
              },
            }}
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Typography variant="body2">
            {`A inadimplência deste cliente será desconsiderada até ${DateTime.fromISO(
              data.dataLimiteIgnorarInadimplencia,
            ).toFormat('dd/MM/yyyy')} às ${data.horaLimiteIgnorarInadimplencia}.
            Durante este período, será possível realizar pedidos normalmente para este cliente, mesmo que existam pendências financeiras.`}
          </Typography>
        </Grid>
      </Grid>
    </FormContainer>
  )
}
