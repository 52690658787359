export function makePath(empresa: string, fileName: string) {
  const envMap = {
    production: 'prod',
    development: 'dev_local',
  }

  const envName =
    envMap[
      (import.meta.env.VITE_ENV as 'development' | 'production') ||
        'development'
    ]
  const awsPath = `${envName}/${empresa}/fotos/${fileName}`
  return `https://datac-fdv3.s3.sa-east-1.amazonaws.com/${awsPath}`
}
