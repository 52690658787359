import { useEffect, useRef } from 'react'

import {
  Box,
  CircularProgress,
  IconButton,
  LinearProgress,
  Stack,
  useTheme,
} from '@mui/material'

import { useForm } from '@data-c/hooks'

import useComentarios from 'hooks/queries/useComentarios'
import useValidations from 'hooks/useValidations'

import Item from './components/Item'
import Error from 'components/Displays/Error'

import * as yup from 'yup'
import queryClient from 'services/query-client'
import { Send } from '@mui/icons-material'
import EmptyData from 'components/Displays/EmptyData'
import TextField from 'components/Inputs/TextField'

const schema = yup.object().shape({
  comentario: yup.string().required('Digite um comentário!'),
})

export interface ComentariosProps {
  atividadeUuid?: string
  pedidoUuid?: string
}

const defaultData = {
  comentario: '',
}

export default function Comentarios(props: ComentariosProps) {
  const { atividadeUuid, pedidoUuid } = props

  const theme = useTheme()
  const bottomRef = useRef<HTMLInputElement>(null)

  const {
    useAdicionarComentario,
    useObterComentarios,
    useUpdateComentarioLidoEm,
  } = useComentarios()
  const { mutateAsync, isLoading } = useAdicionarComentario()
  const {
    data: comentarios,
    isFetching,
    error,
  } = useObterComentarios({ atividadeUuid, pedidoUuid })
  const { mutateAsync: updateComentarioLidoEm } = useUpdateComentarioLidoEm()

  useEffect(() => {
    if (comentarios) {
      updateComentarioLidoEm({ atividadeUuid, pedidoUuid })
      scrollToBottom()
    }
  }, [comentarios])

  const { setValidationErrors, validationProps } = useValidations()
  const { data, handleChange, setData } = useForm(defaultData)

  function handleAdicionarComentario() {
    setValidationErrors(null)
    schema
      .validate(data, { abortEarly: false })
      .then(async () => {
        await mutateAsync({
          atividadeUuid,
          pedidoUuid,
          comentario: data?.comentario,
        })
        setData(defaultData)
        scrollToBottom()
      })
      .catch((err) => {
        setValidationErrors(err)
      })
  }

  function scrollToBottom() {
    if (bottomRef) {
      setTimeout(() => {
        bottomRef?.current?.scrollIntoView({ behavior: 'smooth' })
      }, 1000)
    }
  }

  function handleReload() {
    queryClient.invalidateQueries(['ATIVIDADE'])
  }

  if (error?.message) {
    return <Error onClick={handleReload} />
  }

  return (
    <Stack>
      {isFetching && <LinearProgress />}

      <EmptyData
        error={
          Boolean(!isFetching && !error && Boolean(comentarios?.length === 0))
            ? 'Não há comentários por aqui ainda'
            : undefined
        }
      />

      {comentarios?.map((comentario) => (
        <Item key={comentario.uuid} comentario={comentario} />
      ))}

      <Stack
        direction="row"
        spacing={1}
        sx={{
          borderRadius: '6px',
          backgroundColor: theme.palette.background.paper,
          padding: 2,
        }}
      >
        <TextField
          autoFocus
          label="Comentar"
          name="comentario"
          fullWidth
          multiline
          value={data?.comentario || ''}
          onChange={handleChange}
          {...validationProps('comentario')}
        />

        {isLoading ? (
          <CircularProgress size={16} color="primary" />
        ) : (
          <IconButton color="primary" onClick={handleAdicionarComentario}>
            <Send />
          </IconButton>
        )}
      </Stack>

      <Box ref={bottomRef} />
    </Stack>
  )
}
