import api from 'services/api'
import { useQuery } from '@tanstack/react-query'
import ErrorInterface from 'interfaces/ErrorInterface'
import { AxiosError } from 'axios'
import { FilterOptions } from '@data-c/providers'
import { DateRange } from 'components/Inputs/DateRangePicker'
import { DateTime } from 'luxon'
import { TimeVendasModel } from './useTimeVendas'
import { MixProdutosModel } from './useMixProdutos'
import { EtiquetaInterface } from './useEtiqueta'

export interface ProdutosMixProps {
  codigo: number
  nome: string
  metrica: number
}

export interface RelatorioComparacaoPerformanceProdutosModel {
  percentual: number
  mix1: {
    nomeMix: string
    total: number
    produtos: Array<ProdutosMixProps>
  }
  mix2: {
    nomeMix: string
    total: number
    produtos: Array<ProdutosMixProps>
  }
}

export interface RelatorioComparacaoPerformanceProdutosFilters {
  situacao?: FilterOptions<'venda' | 'emissao'>
  tipoDeCalculo?: FilterOptions<'pesoliquido' | 'totalliquido'>
  representante?: FilterOptions<TimeVendasModel | null>
  representanteUuid?: FilterOptions<string | null>
  dateRange?: FilterOptions<DateRange>
  mix1?: FilterOptions<MixProdutosModel | null>
  mix2?: FilterOptions<MixProdutosModel | null>
  mix1Uuid?: FilterOptions<string | null>
  mix2Uuid?: FilterOptions<string | null>
  dataInicial?: FilterOptions<string | null>
  dataFinal?: FilterOptions<string | null>
  etiqueta?: FilterOptions<Array<string>>
  etiquetaKeyValue?: FilterOptions<Array<EtiquetaInterface>>
}

export interface RelatorioComparacaoPerformanceProdutosFilters1 {
  dateRange?: DateRange
  representante?: TimeVendasModel
  representanteUuid?: string
  situacao?: 'venda' | 'emissao'
  tipoDeCalculo?: 'pesoliquido' | 'totalliquido'
  mix1?: MixProdutosModel
  mix2?: MixProdutosModel
  mix1Uuid?: string
  mix2Uuid?: string
  dataInicial?: string | null
  dataFinal?: string | null
  etiqueta?: Array<string>
  etiquetaKeyValue?: Array<EtiquetaInterface> | []
}

export const filters: RelatorioComparacaoPerformanceProdutosFilters = {
  dataInicial: {
    label: 'Data inicial',
  },
  dataFinal: {
    label: 'Data final',
  },
  situacao: {
    label: 'Situação',
    value: 'emissao',
  },
  representante: {
    label: 'Vendedor',
    value: null,
  },
  representanteUuid: {
    value: '',
    label: 'Vendedor',
  },
  dateRange: {
    label: 'Período',
    value: {
      tipo: 'month',
      firstDate: DateTime.now().startOf('month').toFormat('yyyy-MM-dd'),
      secondDate: DateTime.now().endOf('day').toFormat('yyyy-MM-dd'),
    },
  },
  mix1: {
    label: 'Mix Produtos 1',
    value: null,
  },
  mix2: {
    label: 'Mix Produtos 2',
    value: null,
  },
  mix1Uuid: {
    label: 'Mix2',
    value: null,
  },
  mix2Uuid: {
    label: 'Mix1',
    value: null,
  },
  tipoDeCalculo: {
    label: 'Calcular por',
    value: 'pesoliquido',
  },
  etiqueta: {
    value: [],
    label: '',
  },
  etiquetaKeyValue: {
    value: [],
    label: 'Etiqueta',
  },
}

export async function obterRelatorioComparacaoPerformanceProdutos(
  params?: RelatorioComparacaoPerformanceProdutosFilters1,
): Promise<RelatorioComparacaoPerformanceProdutosModel> {
  const etiquetas = params?.etiquetaKeyValue
    ? params?.etiquetaKeyValue.map((s) => s.value)
    : null
  const nQueryParams = {
    ...params,
    etiqueta: etiquetas,
  }
  const response = await api.get<RelatorioComparacaoPerformanceProdutosModel>(
    `relatorio/mix-vs-mix`,
    {
      params: nQueryParams,
      // headers: {
      //   'DC-SortName': sort?.column,
      //   'DC-SortDirection': sort?.direction,
      // },
    },
  )
  return response.data
}

export function useQueryObterRelatorioComparacaoPerformanceProdutos(
  params?: RelatorioComparacaoPerformanceProdutosFilters1,
  searchId?: string,
) {
  return useQuery<
    RelatorioComparacaoPerformanceProdutosModel,
    AxiosError<ErrorInterface>
  >(['RELATORIO_COMPARACAO_PRODUTOS', params, searchId], () => {
    return obterRelatorioComparacaoPerformanceProdutos(params)
  })
}

export default function useRelatorioComparacaoPerformanceProdutos() {
  return {
    useQueryObterRelatorioComparacaoPerformanceProdutos,
  }
}
