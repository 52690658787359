import { CheckCircle } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import { useEffect, useState } from 'react'

export default function SuccessInline(props: {
  timeout: number
  visible: boolean
}) {
  const { visible } = props
  const [_visible, setVisible] = useState(true)

  useEffect(() => {
    setVisible(visible)
  }, [visible])

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false)
    }, props.timeout)
    return () => clearTimeout(timer)
  }, [props.timeout])

  return _visible ? (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
      <CheckCircle fontSize="small" color="success" />
      <Typography variant="body1" color={(theme) => theme.palette.success.main}>
        Salvo com sucesso
      </Typography>
    </Box>
  ) : null
}
