import { Container } from '@mui/material'

import { FormProvider } from '@data-c/providers'
import { ButtonContainer, PageTitle } from '@data-c/ui'

import useMixProdutos from 'hooks/queries/useMixProdutos'

import Delete from 'components/Delete'
import Form from './components/Form'
import Table from './components/Table'
import Content from 'components/Layouts/Content'
import HelpButton from 'components/HelpButton'

export default function MixProdutos() {
  const { useDelete } = useMixProdutos()
  const { mutateAsync: handleDelete } = useDelete()

  return (
    <Container>
      <FormProvider initialFormValues={{ ativo: true }}>
        <PageTitle title="Mix de Produtos">
          <ButtonContainer>
            <Form />
            <HelpButton link="https://datacsistemashelp.zendesk.com/hc/pt-br/articles/26976721038100-Mix-de-Produtos" />
          </ButtonContainer>
        </PageTitle>
        <Content>
          <Table />
        </Content>
        <Delete onDelete={handleDelete} />
      </FormProvider>
    </Container>
  )
}
