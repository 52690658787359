import { ButtonContainer, Content, PageTitle } from '@data-c/ui'
import { Container, Tab, Tabs } from '@mui/material'
import Loading from 'components/Displays/Loading'
import useFunilVenda, { FunilVendaModel } from 'hooks/queries/useFunilVenda'
import { useNavigate, useParams } from 'react-router-dom'
import useTabs from 'hooks/useTabs'
import TabPanel from 'components/TabPanel'
import { FormProvider } from '@data-c/providers'
import TabCadastro from './components/TabCadastro'
import TabVendedores from './components/TabVendedores'

export default function FunilDeVenda() {
  const { id: funilVendaUuid } = useParams()
  const { useQueryByUuid } = useFunilVenda()
  const { data: funilVenda, isLoading } = useQueryByUuid<FunilVendaModel>(
    funilVendaUuid as string,
  )
  const { handleChange, value } = useTabs()

  const navigate = useNavigate()
  if (isLoading)
    return (
      <Container>
        <Loading />
      </Container>
    )

  return (
    <Container>
      <PageTitle
        title={`Funil de Venda - ${funilVenda?.nome || ''}`}
        onBack={() => navigate(-1)}
      >
        <ButtonContainer>
          <Tabs value={value} onChange={handleChange}>
            <Tab label="Cadastro" value={0} />
            <Tab label="Vendedores" value={1} />
          </Tabs>
        </ButtonContainer>
      </PageTitle>
      <Content>
        <TabPanel value={value} index={0}>
          <FormProvider>
            <TabCadastro funilVenda={funilVenda || ({} as FunilVendaModel)} />
          </FormProvider>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <TabVendedores funilVendaUuid={funilVenda?.uuid || ''} />
        </TabPanel>
      </Content>
    </Container>
  )
}
