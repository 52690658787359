import {
  DataTable,
  OptionStyles,
  DataTableOptions as Options,
} from '@data-c/ui'
import { useFilterData } from '@data-c/providers'
import { MUIDataTableColumnDef } from 'mui-datatables'
import {
  PedidoFilters,
  PedidoFilters1,
  PedidoModel,
  SortProps,
  useQueryObterPedidos,
} from 'hooks/queries/usePedidos'
import { useMemo, useCallback, useState } from 'react'
import {
  TransportableDataTableProps,
  findData,
  usePagination,
} from '@data-c/hooks'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'

interface TableProps extends TransportableDataTableProps<PedidoModel> {
  // clienteUuid?: string
}

export default function Table(props: TableProps) {
  const { onTransport } = props
  const [sort, setSort] = useState<SortProps>({
    column: 'venda',
    direction: 'desc',
  })

  const { pagination, changePageSize, changePage } = usePagination()
  const { appliedValues } = useFilterData<PedidoFilters, PedidoFilters1>()
  const { data, isLoading, isFetching, error } = useQueryObterPedidos(
    {
      queryParams: appliedValues,
      pagination,
    },
    sort,
  )
  const navigate = useNavigate()

  const pedidos = useMemo(() => {
    if (!data?.data) return []
    return data.data.map((pedido: PedidoModel) => ({
      ...pedido,
      representante: `${pedido.representante?.codigo} - ${pedido.representante?.nome}`,
      cliente: `${pedido.cliente?.codigo} - ${pedido.cliente?.nome}`,
      venda: pedido.vendaAsBrazilianDate,
      totalLiquido: pedido.totalLiquidoAsBrazilianCurrency,
    }))
  }, [data])

  const handleClickItem = useCallback(
    (event: 'transport', data: PedidoModel) => {
      switch (event) {
        case 'transport':
          onTransport && onTransport(data)
          break
      }
    },
    [navigate],
  )

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'codigo',
        label: 'Código',
        options: {
          setCellProps() {
            return { width: '96px' }
          },
        },
      },
      {
        name: 'venda',
        label: 'Data',
        options: {
          setCellProps() {
            return { width: '96px' }
          },
        },
      },
      {
        name: 'situacaoAsText',
        label: 'Situação',
        options: {
          sort: false,
        },
      },
      {
        name: 'representante',
        label: 'Vendedor',
      },
      {
        name: 'cliente',
        label: 'Cliente',
      },
      {
        name: 'totalLiquido',
        label: 'Total',
        options: {
          setCellProps: () => ({
            style: {
              textAlign: 'right',
            },
          }),
        },
      },
      {
        name: 'uuid',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          setCellProps: OptionStyles,
          customBodyRender: (value) => {
            const _data = findData<PedidoModel>(data?.data || [], value)
            return (
              <Options
                value={_data}
                onClick={handleClickItem}
                displayTransporterButton={true}
                displayUpdateButton={false}
                displayDeleteButton={false}
              />
            )
          },
        },
      },
    ],
    [data?.data, handleClickItem],
  )

  return (
    <DataTable
      error={error?.message}
      pagination={data?.pagination}
      changePage={changePage}
      changePageSize={changePageSize}
      columns={columns}
      data={pedidos || []}
      isLoading={isLoading}
      isFetching={isFetching}
      options={{
        sort: true,
        sortOrder: {
          name: sort.column,
          direction: sort.direction,
        },
        rowsPerPageOptions: [15, 50, 100],
        rowsPerPage: 15,
      }}
      changeSort={(column, direction) => {
        setSort({ column, direction: direction as 'asc' | 'desc' })
      }}
    />
  )
}
