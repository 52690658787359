import { useFilterApi, useFilterData } from '@data-c/providers'
import { FilterContainer } from '@data-c/ui'
import { MenuItem, Stack, TextField } from '@mui/material'
import DateRangePicker, { DateRange } from 'components/Inputs/DateRangePicker'
import TimeVendasTransporter from 'components/Transporters/TimeVendasTranporter'
import ClientesTransporter from 'components/Transporters/ClientesTransporter'
import { useUserContext } from 'components/Contexts/UserContext'
import { useEffect } from 'react'
import {
  GerenteComercialFilters,
  GerenteComercialFilters1,
} from 'hooks/queries/useGerenteComercial'
import { DateTime } from 'luxon'
export default function Filter() {
  const { changeFilterValue } = useFilterApi()
  const { filterValues, isOpen } = useFilterData<
    GerenteComercialFilters,
    GerenteComercialFilters1
  >()
  const { user, temPermissao } = useUserContext()

  const permissaoParaLerClientes = temPermissao('clientes.read') || 'pessoal'
  useEffect(() => {
    if (permissaoParaLerClientes === 'pessoal' && isOpen) {
      changeFilterValue('representante', user)
    }
  }, [permissaoParaLerClientes, isOpen])

  return (
    <FilterContainer
      title="Pesquisar"
      triggerButtonProps={{ variant: 'outlined' }}
      renderLabel={(filterLabel: string, filterValue: any) => {
        if (filterLabel === 'Situação') {
          return `${filterLabel} - ${
            filterValue === 'emissao' ? 'Faturados' : 'Em Processamento'
          }`
        }
        if (filterLabel === 'Período') {
          const dateRange = filterValue as DateRange
          const formattedFirstDate = DateTime.fromFormat(
            dateRange?.firstDate || '',
            'yyyy-MM-dd',
          ).toFormat('dd/MM/yyyy')

          const formattedSecondDate = DateTime.fromFormat(
            dateRange?.secondDate || '',
            'yyyy-MM-dd',
          ).toFormat('dd/MM/yyyy')

          if (dateRange.tipo === 'day') {
            return `${filterLabel} - ${formattedFirstDate}`
          }

          return `${filterLabel} - de ${formattedFirstDate} até ${formattedSecondDate}`
        }
        if (filterLabel === 'Vendedor' || filterLabel === 'Cliente') {
          return `${filterLabel} - ${filterValue?.nome || ''}`
        }
        return `${filterLabel} - ${filterValue}`
      }}
    >
      <Stack spacing={2}>
        <DateRangePicker
          onChange={(value) => {
            changeFilterValue('dateRange', value)
          }}
          value={filterValues?.dateRange?.value}
        />
        <TextField
          select
          label="Situação"
          value={filterValues?.situacao?.value || ''}
          onChange={(e) => changeFilterValue('situacao', e.target.value)}
        >
          <MenuItem value="emissao">Faturados</MenuItem>
          <MenuItem value="venda">Em processamento</MenuItem>
        </TextField>
        <TimeVendasTransporter
          disabled={temPermissao('clientes.read') === 'pessoal'}
          name="vendedorUuid"
          label="Vendedor"
          value={filterValues?.representante?.value || null}
          onChange={(representante) => {
            changeFilterValue('representante', representante)
          }}
          placeholder="Selecione um vendedor"
          InputLabelProps={{ shrink: true }}
        />
        <ClientesTransporter
          label="Cliente"
          name="clienteUuid"
          value={filterValues?.cliente?.value || null}
          vendedorUuid={filterValues.representante?.value?.uuid || ''}
          onChange={(cliente) => {
            changeFilterValue('cliente', cliente)
          }}
          placeholder="Selecione um cliente"
          InputLabelProps={{ shrink: true }}
        />
      </Stack>
    </FilterContainer>
  )
}
