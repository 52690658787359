import { useMemo, useCallback } from 'react'
import { DataTable, DataTableOptions as Options } from '@data-c/ui'
import { MUIDataTableColumnDef } from 'mui-datatables'
import useRole, { RoleModel } from 'hooks/queries/useRole'
import { PlainQueryProps, findData } from '@data-c/hooks'
import { useNavigate } from 'react-router-dom'
import { useFormApi } from '@data-c/providers'
import Surface from 'components/Surface'

export default function Table() {
  const { openConfirm } = useFormApi()
  const navigate = useNavigate()
  const { useQuery, useCopy } = useRole()
  const { mutateAsync: copy, isLoading: isSubmittingCopy } = useCopy()
  const { data, error, isFetching, isLoading } = useQuery<PlainQueryProps>({
    pagination: {
      page: 1,
      pageSize: 100,
    },
  })

  const roles = useMemo(
    () =>
      data?.data?.map((d) => {
        return {
          ...d,
        }
      }),
    [data],
  )

  const handleClickItem = useCallback(
    (event: 'edit' | 'delete' | 'copy', value: RoleModel) => {
      switch (event) {
        case 'edit':
          navigate(`/permissao/${value.uuid}`)
          break
        case 'delete':
          openConfirm(value)
          break
        case 'copy':
          copy(value?.uuid || '')
          break
      }
    },
    [],
  )

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'nome',
        label: 'Nome',
      },
      {
        name: 'uuid',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          setCellProps: () => ({
            style: {
              width: '106px',
              textAlign: 'left',
            },
          }),
          customBodyRender: (value) => {
            const _data = findData<RoleModel>(roles || [], value, 'uuid')
            return (
              <Options
                displayUpdateButton={true}
                displayDeleteButton={!Boolean(_data?.fixa)}
                extraOptions={[
                  {
                    id: 'copy',
                    icon: 'content_copy',
                    iconButtonProps: { color: 'primary' },
                    visible: true,
                  },
                ]}
                value={_data}
                onClick={handleClickItem}
              />
            )
          },
        },
      },
    ],
    [roles, handleClickItem],
  )

  return (
    <Surface>
      <DataTable
        error={error?.message}
        columns={columns}
        data={roles || []}
        isLoading={isLoading}
        isFetching={isFetching || isSubmittingCopy}
      />
    </Surface>
  )
}
