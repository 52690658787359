import React from 'react'

export default function useDeviceDetect() {
  const [isWebView, setWebView] = React.useState(false)

  React.useEffect(() => {
    const userAgent =
      typeof window.navigator === 'undefined' ? '' : navigator.userAgent
    const webView = Boolean(
      //   userAgent.match(
      //     /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i,
      //   ),
      userAgent.match(/WebViewUserAgent/i),
    )
    setWebView(webView)
  }, [])
  return { isWebView }
}
