import { FilterProvider, FormProvider } from '@data-c/providers'
import { ButtonContainer } from '@data-c/ui'
import useCrmAtividades, { filters } from 'hooks/queries/useCrmAtividades'

import Form from 'pages/CrmAtividades/components/Form'
import Table from './components/Table'
import Filter from 'pages/CrmAtividades/components/Filter'
import Delete from 'components/Delete'
import AppBar from 'components/Mobile/AppBar'
import WebAppInterface from 'interfaces/WebAppInterface'

declare const android: WebAppInterface
export default function CrmAtividades() {
  const { useDelete } = useCrmAtividades()
  const { mutateAsync: handleDelete } = useDelete()

  function handleBack() {
    android.back()
  }

  return (
    <FormProvider>
      <FilterProvider
        storagePath="@fdv-filters-atividades"
        filterValues={filters}
      >
        <AppBar title="Atividade" onBack={handleBack}>
          <ButtonContainer>
            <Filter />
            <Form fullScreen />
          </ButtonContainer>
        </AppBar>
        <Table />
      </FilterProvider>
      <Delete onDelete={handleDelete} />
    </FormProvider>
  )
}
