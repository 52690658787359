import { FilterOptions } from '@data-c/providers'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import ErrorInterface from 'interfaces/ErrorInterface'
import api from 'services/api'
import HttpRequestInterface from 'interfaces/HttpRequestInterface'
import { TimeVendasModel } from './useTimeVendas'
import { ClientesModel } from './useClientes'
import { EtiquetaInterface } from './useEtiqueta'

export interface PrevisaoEntregaFilters {
  representante?: FilterOptions<TimeVendasModel | null>
  representanteUuid?: FilterOptions<string | null>
  cliente?: FilterOptions<ClientesModel | null>
  clienteUuid?: FilterOptions<string | null>
  codigoPedido?: FilterOptions<string | null>
  etiqueta?: FilterOptions<Array<string>>
  etiquetaKeyValue?: FilterOptions<Array<EtiquetaInterface>>
}

export interface PrevisaoEntregaFiltersParams {
  representante?: TimeVendasModel
  representanteUuid?: string
  cliente?: ClientesModel
  clienteUuid?: string
  codigoPedido?: string
  etiqueta?: Array<string>
  etiquetaKeyValue?: Array<EtiquetaInterface> | []
}

export interface PrevisaoEntregaModel {
  numeroPedido: string | number
  nomeProduto: string
  dataPrevista: string
}

export const filters: PrevisaoEntregaFilters = {
  representante: {
    label: 'Vendedor',
    value: null,
  },
  representanteUuid: {
    value: '',
    label: 'Vendedor',
  },
  cliente: {
    label: 'Cliente',
    value: null,
  },
  clienteUuid: {
    value: '',
    label: 'Cliente',
  },
  codigoPedido: {
    value: '',
    label: 'Pedido',
  },
  etiqueta: {
    value: [],
    label: '',
  },
  etiquetaKeyValue: {
    value: [],
    label: 'Etiqueta',
  },
}

export async function obterRelatorioPrevisaoEntrega(
  params: HttpRequestInterface<PrevisaoEntregaFiltersParams>,
): Promise<PrevisaoEntregaModel[]> {
  const { queryParams } = params
  const etiquetas = queryParams?.etiquetaKeyValue
    ? queryParams?.etiquetaKeyValue.map((s) => s.value)
    : null
  const nQueryParams = {
    ...queryParams,
    etiqueta: etiquetas,
  }
  const response = await api.get<PrevisaoEntregaModel[]>(
    `relatorio/previsao-entrega`,
    {
      params: nQueryParams,
    },
  )
  return response.data
}
// requestId: searchId,
export function useQueryObterRelatorioPrevisaoEntrega(
  params: HttpRequestInterface<PrevisaoEntregaFiltersParams>,
) {
  return useQuery<PrevisaoEntregaModel[], AxiosError<ErrorInterface>>(
    ['RELATORIO_PREVISAO_ENTREGA', params],
    () => obterRelatorioPrevisaoEntrega(params),
  )
}

export default function useRelatorioPrevisaoEntrega() {
  return {
    useQueryObterRelatorioPrevisaoEntrega,
  }
}
