import DragDropFile from 'components/DragDropFile'
import Surface from 'components/Surface'
import useUpload from 'hooks/useUpload'
import { useNotifications } from '@data-c/hooks'
import { ImportarExcelInterface } from 'hooks/queries/useImportarEntidade'
import { useState } from 'react'

export interface UploadFileProps {
  onUploadCompleted: (excelUploadResponse: ImportarExcelInterface) => void
}

export default function UploadFile({ onUploadCompleted }: UploadFileProps) {
  const { isUploading, uploadFile } = useUpload()
  const notification = useNotifications()
  const { extractAxiosError } = notification
  const [error, setError] = useState<string>('')

  async function handleDrop(files: File[]) {
    setError('')
    const invalideFiles = files.filter((file) => {
      const extension = file?.name?.split('.')?.pop()?.toLowerCase()
      return extension !== 'xls' && extension !== 'xlsx'
    })
    if (invalideFiles.length > 0) {
      setError(
        'Formato de arquivo não suportado. Por favor, envie um arquivo .xls ou .xlsx.',
      )
      return
    }
    try {
      const response = await uploadFile<ImportarExcelInterface>(
        '/administrador/importar/upload',
        'file',
        files[0],
      )
      if (response?.data) {
        onUploadCompleted(response.data)
      }
    } catch (err) {
      const msg = extractAxiosError(err)
      setError(`Não foi possível processar o arquivo - ${msg}`)
      notification.notifyException(err)
    }
  }

  return (
    <Surface>
      <DragDropFile
        acceptFiles={{
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
            '.xlsx',
          ],
          'application/vnd.ms-excel': ['.xls'],
        }}
        onDropped={handleDrop}
        height={200}
        isLoading={isUploading}
        error={error}
      />
    </Surface>
  )
}
