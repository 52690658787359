import api from 'services/api'
import useDeviceDetect from './useDeviceDetect'

export interface SendLogProps {
  grupo: string
  acao: string
  plataforma?: 'reactjs' | 'webview'
  data?: object
}

export default function useLogs() {
  const { isWebView } = useDeviceDetect()
  async function sendLog(data: SendLogProps) {
    if (!data?.plataforma) {
      isWebView ? (data.plataforma = 'webview') : 'reactjs'
    }
    try {
      await api.post('logs', data)
    } catch (err) {}
  }
  return { sendLog }
}
