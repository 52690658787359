import { useMemo, useCallback } from 'react'
import { MUIDataTableColumnDef } from 'mui-datatables'

import { DataTable, DataTableOptions as Options, Flag } from '@data-c/ui'
import { useFormApi } from '@data-c/providers'
import {
  TransportableDataTableProps,
  findData,
  usePagination,
} from '@data-c/hooks'

import useMotivosDePerda, {
  MotivosDePerdaModel,
} from 'hooks/queries/useMotivosDePerda'
import { useUserContext } from 'components/Contexts/UserContext'

export default function Table(
  props: TransportableDataTableProps<MotivosDePerdaModel>,
) {
  const { query, onTransport, enableTransporter } = props

  const { pagination, changePageSize, changePage } = usePagination()
  const { useQuery } = useMotivosDePerda()
  const { data, isLoading, isFetching, error } = useQuery({
    queryParams: {
      plainQuery: query,
    },
    pagination,
  })
  const { openForm, openConfirm } = useFormApi()
  const { user } = useUserContext()

  const handleClickItem = useCallback(
    (event: 'edit' | 'delete' | 'transport', data: MotivosDePerdaModel) => {
      switch (event) {
        case 'edit':
          openForm(data)
          break
        case 'delete':
          openConfirm(data)
          break
        case 'transport':
          onTransport && onTransport(data)
          break
      }
    },
    [openForm, openConfirm],
  )

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'descricao',
        label: 'Motivo',
      },
      {
        name: 'ativo',
        label: 'Ativo',
        options: {
          setCellProps: () => ({
            style: {
              width: '60px',
              textAlign: 'center',
            },
          }),
          customBodyRender: (value) => {
            return <Flag isFlagged={value === 1} type="success" />
          },
        },
      },
      {
        name: 'uuid',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          //setCellProps: OptionStyles,
          setCellProps: () => ({
            style: {
              width: '110px',
              textAlign: 'center',
            },
          }),
          customBodyRender: (value) => {
            const _data = findData<MotivosDePerdaModel>(data?.data || [], value)
            return (
              <Options
                displayTransporterButton={enableTransporter}
                displayDeleteButton={user?.isadmin || false}
                displayUpdateButton={user?.isadmin || false}
                value={_data}
                onClick={handleClickItem}
              />
            )
          },
        },
      },
    ],
    [data?.data, handleClickItem],
  )

  return (
    <DataTable
      error={error?.message}
      columns={columns}
      data={data?.data || []}
      pagination={data?.pagination}
      isLoading={isLoading}
      isFetching={isFetching}
      changePage={changePage}
      changePageSize={changePageSize}
      options={{
        rowsPerPageOptions: [15, 50, 100],
        rowsPerPage: 15,
      }}
    />
  )
}
