import { RefObject, useEffect, useRef, useState } from 'react'
import { Button, Grid, Popover, Stack, Typography } from '@mui/material'
// import { isCPFOrCNPJ } from 'brazilian-values'
import { onlyNumbers, usePopover } from '@data-c/hooks'

import useClientes, { ClientesModel } from 'hooks/queries/useClientes'
import useValidations from 'hooks/useValidations'

import { ButtonContainer, Checkbox, FormContainer } from '@data-c/ui'
import { useFormApi, useFormData } from '@data-c/providers'

import CEPTextField, { CepForwardRefProps } from 'components/CEPTextField'
import AutoCompleteCidade from 'components/AutoCompleteCidade'
import DropDownUF from 'components/Inputs/DropDownUF'
import InscricaoEstadualTextField from 'components/InscricaoEstadualTextField'

import * as yup from 'yup'
import { CidadeModel } from 'hooks/queries/useCidade'
import { CepModel, consultarCep } from 'hooks/useConsultaCep'
import FormProps from 'interfaces/FormProps'
import DocumentoTextField, {
  ForwardRefProps,
} from 'components/DocumentoTextField'
import { useUserContext } from 'components/Contexts/UserContext'
import Divider from 'components/Divider'
import CondicaoPagamentoTransporter from 'components/Transporters/CondicaoPagamentoTransporter'
import { useParams } from 'react-router-dom'
import TextField from 'components/Inputs/TextField'
import { CnpjRetornoModel } from 'hooks/useConsultaCnpj'
import TelefoneTextField from 'components/TelefoneTextField'
import { formatToCNPJ, isCNPJ } from 'brazilian-values'

function extractDDD(phoneNumber: string) {
  const ddd = phoneNumber.substring(0, 2)
  const number = phoneNumber.substring(2)
  return { ddd, number }
}

const schema = yup.object().shape(
  {
    ie: yup.string().nullable().required('Informe a Inscrição Estadual'),
    nome: yup.string().when('usual', {
      is: (value: string) => !value || value.length === 0,
      then: yup.string().required('Informe a razão social ou o nome fantasia'),
      otherwise: yup.string().nullable(),
    }),
    usual: yup.string().when('nome', {
      is: (value: string) => !value || value.length === 0,
      then: yup.string().required('Informe a razão social ou o nome fantasia'),
      otherwise: yup.string().nullable(),
    }),
  },
  [['nome', 'usual']],
)

export default function Form(props: FormProps<ClientesModel>) {
  const { view = 'dialog', data: clienteFromProps } = props
  const { temPermissao } = useUserContext()
  const documentoRef: RefObject<ForwardRefProps> = useRef<ForwardRefProps>(null)
  const cepRef: RefObject<CepForwardRefProps> = useRef<CepForwardRefProps>(null)

  const {
    open,
    close: closeCnpjcpf,
    isOpen: isOpenCnpjcpf,
    anchorEl,
  } = usePopover()

  const {
    onChangeFormValue,
    changeValue,
    changeValues,
    closeForm,
    toggleSubmit,
  } = useFormApi<ClientesModel>()
  const { formValues, isOpen } = useFormData<ClientesModel>()
  const { useSubmit, useQueryByUuid } = useClientes()
  const { setValidationErrors, validationProps } = useValidations()
  const { id } = useParams()
  const { data: cliente } = useQueryByUuid<ClientesModel>(id || '')
  const { mutateAsync } = useSubmit()

  const [clipboardCnpjValue, setClipboardCnpjValue] = useState<string | null>(
    null,
  )
  const [useClipboard, setUseClipboard] = useState<boolean>(true)

  useEffect(() => {
    async function checkClipboard() {
      if (clipboardCnpjValue) {
        return clearInterval(intervalId)
      }

      if (document.hasFocus() && useClipboard) {
        try {
          const text = await navigator.clipboard.readText()
          if (isCNPJ(text)) {
            setClipboardCnpjValue(text)
          }
        } catch (err) {
          console.error('Failed to read clipboard contents: ', err)
        }
      }
    }

    const intervalId = setInterval(checkClipboard, 1000)
    return () => clearInterval(intervalId)
  }, [clipboardCnpjValue, useClipboard])

  useEffect(() => {
    if (cliente) {
      changeValues(cliente)
    }
  }, [cliente])

  useEffect(() => {
    if (clienteFromProps) {
      changeValues(clienteFromProps)
    }
  }, [clienteFromProps])

  useEffect(() => {
    if (formValues?.cidadeModel?.uf) {
      changeValue('uf', formValues?.cidadeModel?.uf)
    }
  }, [formValues?.cidadeModel?.uf, formValues?.uf, isOpen])

  function handleSubmitForm(formValues: ClientesModel) {
    setValidationErrors(null)
    schema
      .validate(formValues, { abortEarly: false })
      .then(async () => {
        toggleSubmit(true)
        delete formValues?.uf
        await mutateAsync(formValues)
        closeForm()
      })
      .catch((err) => {
        setValidationErrors(err)
      })
      .finally(() => {
        toggleSubmit(false)
      })
  }

  const podeEditar = temPermissao('clientes.alter') !== 'sem_acesso'
  async function handleFocus(e: React.SyntheticEvent<HTMLElement> | undefined) {
    if (clipboardCnpjValue && e) {
      open(e)
    }
  }

  async function handleDocumentoFound(value: CnpjRetornoModel) {
    const { ddd, number } = extractDDD(onlyNumbers(value.telefone || '') || '')

    const cep = await consultarCep(value.cep)

    changeValues({
      ...formValues,
      cnpjcpf: onlyNumbers(value.cnpj as string) || '',
      nome: value.nome,
      usual: value.fantasia,
      numero: value.numero,
      complemento: value.complemento,
      endereco: value.logradouro,
      bairro: value.bairro,
      cep: value.cep,
      email: value.email,
      telefoneAsText: value.telefone,
      ddd: ddd,
      telefone: number,
      uf: value.uf,
      cidadeModel: cep?.cidadeModel,
      cidade: cep?.cidadeModel?.uuid,
    })
  }

  return (
    <FormContainer
      dialogProps={{
        title: 'Cadastro de Clientes',
        maxWidth: 'lg',
        disableRestoreFocus: true,
      }}
      view={view}
      onSubmitForm={handleSubmitForm}
    >
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Divider label="Cadastro" />
        </Grid>

        <Grid item xl={2} lg={2} md={2} sm={3} xs={12}>
          <TextField
            disabled
            name="codigo"
            label="Código"
            value={formValues?.codigo || ''}
            onChange={onChangeFormValue}
          />
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={9} xs={12}>
          <DocumentoTextField
            consultaCnpj={!Boolean(formValues?.nome)}
            ref={documentoRef}
            name="cnpjcpf"
            disabled={Boolean(formValues?.uuid)}
            value={formValues?.cnpjcpf || ''}
            onChange={(e) => {
              const cnpjcpf = onlyNumbers(e.target.value)
              changeValue('cnpjcpf', cnpjcpf)
            }}
            onFocus={handleFocus}
            onDocumentoFound={handleDocumentoFound}
            inputProps={{
              label: 'CPF/CNPJ',
              InputProps: { disabled: !podeEditar },
            }}
            {...validationProps('cnpjcpf')}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <InscricaoEstadualTextField
            name="ie"
            label="Inscrição Estadual"
            value={formValues?.ie || ''}
            onChange={(inscricaoEstadual) => {
              changeValue('ie', inscricaoEstadual)
            }}
            disabled={!podeEditar}
            {...validationProps('ie')}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
          <TextField
            autoFocus
            name="usual"
            label="Nome Fantasia"
            required={!Boolean(formValues?.nome)}
            value={formValues?.usual || ''}
            onChange={onChangeFormValue}
            disabled={!podeEditar}
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <TextField
            name="nome"
            label="Razão Social"
            required={!Boolean(formValues?.usual)}
            value={formValues?.nome || ''}
            inputProps={{ maxLength: 40 }}
            onChange={onChangeFormValue}
            {...validationProps('nome')}
            disabled={!podeEditar}
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Divider label="Local / Contato" />
        </Grid>
        <Grid item xl={2} lg={2} md={2} sm={2} xs={12}>
          <CEPTextField
            ref={cepRef}
            name="cep"
            inputProps={{
              label: 'CEP',
              InputProps: { disabled: !podeEditar },
            }}
            value={formValues?.cep || ''}
            onChange={(e) => {
              const cep = onlyNumbers(e.target.value)
              changeValue('cep', cep)
            }}
            {...validationProps('cep')}
            onFindCep={(cep: CepModel) => {
              if (cep) {
                changeValues({
                  ...formValues,
                  cep: cep.cep,
                  endereco: cep.endereco,
                  bairro: cep.bairro,
                  cidadeModel: cep.cidadeModel,
                  cidade: cep.cidadeModel?.uuid,
                })
              }
            }}
          />
        </Grid>
        <Grid item xl={5} lg={5} md={5} sm={6} xs={12}>
          <DropDownUF
            disabled={!podeEditar}
            name="uf"
            label="Estado"
            value={formValues?.uf || ''}
            onChange={(e) => {
              const uf = e.target.value
              changeValue('uf', uf)
              changeValue('cidade', null)
              changeValue('cidadeModel', null)
            }}
          />
        </Grid>
        <Grid item xl={5} lg={5} md={5} sm={6} xs={12}>
          <AutoCompleteCidade
            name="cidade"
            label="Cidade"
            disabled={!podeEditar}
            value={formValues?.cidadeModel}
            uf={formValues?.uf || null}
            onChange={(_, value: CidadeModel | null) => {
              const cidadeId = value?.uuid || null
              const cidadeModel = value || null
              changeValue('cidade', cidadeId)
              changeValue('cidadeModel', cidadeModel)
            }}
          />
        </Grid>
        <Grid item xl={5} lg={5} md={5} sm={6} xs={6}>
          <TextField
            name="endereco"
            label="Endereço"
            value={formValues?.endereco || ''}
            inputProps={{ maxLength: 40 }}
            onChange={onChangeFormValue}
            disabled={!podeEditar}
          />
        </Grid>
        <Grid item xl={2} lg={2} md={2} sm={2} xs={12}>
          <TextField
            name="numero"
            label="Número"
            value={formValues?.numero || ''}
            onChange={onChangeFormValue}
            inputProps={{ maxLength: 30 }}
            disabled={!podeEditar}
          />
        </Grid>
        <Grid item xl={5} lg={5} md={5} sm={6} xs={12}>
          <TextField
            name="bairro"
            label="Bairro"
            value={formValues?.bairro || ''}
            onChange={onChangeFormValue}
            disabled={!podeEditar}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <TextField
            name="complemento"
            label="Complemento"
            value={formValues?.complemento || ''}
            onChange={onChangeFormValue}
            disabled={!podeEditar}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <TextField
            name="contato"
            label="Contato"
            value={formValues?.contato || ''}
            inputProps={{ maxLength: 30 }}
            onChange={onChangeFormValue}
            disabled={!podeEditar}
          />
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
          <TelefoneTextField
            name="telefoneAsText"
            inputProps={{
              label: 'Telefone',
              InputProps: { disabled: !podeEditar },
            }}
            value={formValues?.telefoneAsText || ''}
            onChange={(e) => {
              const telefone = onlyNumbers(e.target.value)
              const { ddd, number } = extractDDD(telefone || '')
              changeValues({
                ...formValues,
                telefoneAsText: telefone,
                ddd: ddd,
                telefone: number,
              })
            }}
          />
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
          <TelefoneTextField
            name="celularAsText"
            inputProps={{
              label: 'Celular',
              InputProps: { disabled: !podeEditar },
            }}
            value={formValues?.celularAsText || ''}
            onChange={(e) => {
              const celular = onlyNumbers(e.target.value)
              const { ddd, number } = extractDDD(celular || '')
              changeValues({
                ...formValues,
                celularAsText: celular,
                dddcelular: ddd,
                celular: number,
              })
            }}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <TextField
            name="email"
            label="E-mail"
            value={formValues?.email || ''}
            onChange={onChangeFormValue}
            disabled={!podeEditar}
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            name="observacao"
            label="Observação"
            multiline
            // rows={4}
            minRows={4}
            value={formValues?.observacao || ''}
            onChange={onChangeFormValue}
            disabled={!podeEditar}
          />
        </Grid>
        {temPermissao('clientes.visibilidade_observacao_referencia') && (
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <TextField
              name="observacaoReferencia"
              label="Observação Referência"
              multiline
              // rows={4}
              minRows={4}
              value={formValues?.observacaoReferencia || ''}
              onChange={onChangeFormValue}
              disabled={!podeEditar}
            />
          </Grid>
        )}

        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{ textAlign: 'right' }}
        >
          <Checkbox
            disabled={!podeEditar}
            label="Ativo"
            name="ativo"
            onChange={onChangeFormValue}
            checked={Boolean(formValues?.ativo)}
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Divider label="Configurações" />
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <CondicaoPagamentoTransporter
            // clienteUuid={formvalues.uuid}
            disabled={!podeEditar}
            value={formValues?.condicaoPagamento || null}
            onChange={(value) => {
              changeValues({
                ...formValues,
                condicaoPagamento: value || null,
                condicaoPagamentoUuid: value?.uuid || null,
              })
            }}
          />
        </Grid>
      </Grid>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={isOpenCnpjcpf}
        onClose={closeCnpjcpf}
        anchorEl={anchorEl}
        disableRestoreFocus={true}
      >
        <Stack sx={{ minWidth: '320px', m: 2 }} spacing={2}>
          <Typography variant="body1">
            {`Detectamos o CNPJ (${formatToCNPJ(
              clipboardCnpjValue || '',
            )}) na sua área de transferência. Gostaria de usar
            esse CNPJ aqui?`}
          </Typography>
          <ButtonContainer>
            <Button
              onClick={() => {
                closeCnpjcpf()
                setClipboardCnpjValue(null)
                setUseClipboard(false)
              }}
            >
              Não
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                changeValue(
                  'cnpjcpf',
                  onlyNumbers(clipboardCnpjValue as string),
                )
                setClipboardCnpjValue(null)
                setUseClipboard(false)
                closeCnpjcpf()
                if (documentoRef && clipboardCnpjValue) {
                  documentoRef.current?.consultarCnpj(clipboardCnpjValue)
                }
              }}
            >
              Sim
            </Button>
          </ButtonContainer>
        </Stack>
      </Popover>
    </FormContainer>
  )
}

Form.defaultProps = {
  data: {},
}
