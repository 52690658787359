import { Paper } from '@mui/material'
import Comentarios from 'components/Comentarios'
import { PedidoModel } from 'hooks/queries/usePedidos'

export interface TabComentariosProps {
  pedido: PedidoModel
}

export default function TabComentarios(props: TabComentariosProps) {
  return (
    <Paper sx={{ p: 2 }}>
      <Comentarios pedidoUuid={props.pedido.uuid} />
    </Paper>
  )
}
