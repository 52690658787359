import { CircularProgress, LinearProgress, Stack } from '@mui/material'

export interface LoadingProps {
  isLoading?: boolean
  isLinear?: boolean
  error?: string
}

export default function LoadingStatus(props: LoadingProps) {
  const { isLoading, isLinear } = props
  if (!isLoading) return null

  return (
    <Stack spacing={2}>
      {isLinear ? <LinearProgress /> : <CircularProgress />}
    </Stack>
  )
}
