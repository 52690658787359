import { PageTitle } from '@data-c/ui'
import { Container } from '@mui/material'
import Filter from './components/Filter'
import Table from './components/Table'
import { FilterProvider } from '@data-c/providers'
import { filters } from 'hooks/queries/usePositivacaoClientes'
import Content from 'components/Layouts/Content'

export default function AtivacaoClientes() {
  return (
    <Container>
      <FilterProvider filterValues={filters}>
        <PageTitle title="Ativação de Clientes">
          <Filter />
        </PageTitle>
        <Content>
          <Table />
        </Content>
      </FilterProvider>
    </Container>
  )
}
