import { FilterProvider, FormProvider } from '@data-c/providers'
import { Button, ButtonContainer, PageTitle } from '@data-c/ui'

import {
  Box,
  Container,
  IconButton,
  Popover,
  Stack,
  TextField,
} from '@mui/material'

import Content from 'components/Layouts/Content'
import AddFirst from 'components/Displays/AddFirst'
import usePainelDesempenho from 'hooks/queries/usePainelDesempenho'
import Surface from 'components/Surface'
import { useEffect, useState } from 'react'
import usePopOver from 'hooks/usePopover'
import FeedbackSuccessInline from 'components/SuccessInLine'
import PainelDesempenhoContent from './components/PainelDesempenhoContent'
import IndicadoresPopover from './components/PainelDesempenhoContent/components/IndicadoresPopover'
import * as yup from 'yup'
import Filter from './components/Filter'
import { filters } from 'hooks/queries/useGerenteComercial'
import PainelSelector from './components/PainelSelector'
import { Close } from '@mui/icons-material'
import { useValidations } from '@data-c/hooks'

const schema = yup.object().shape({
  nome: yup.string().required('Informe o nome do painel'),
})

export default function PainelDesempenho() {
  const { useQueryObterPaineisDesempenho, useCreatePainelDesempenho } =
    usePainelDesempenho()
  const { data: paineis, isLoading: isLoadingPaineis } =
    useQueryObterPaineisDesempenho()
  const { mutateAsync: createPainel, isLoading: isLoadingCreatePainel } =
    useCreatePainelDesempenho()

  const {
    open: openIndicadores,
    isOpen: isOpenIndicadores,
    anchorEl: anchorElIndicadores,
    close: closeIndicadores,
  } = usePopOver()
  const { open: openCreatePainel, isOpen, close, anchorEl } = usePopOver()
  const { setValidationErrors, validationProps } = useValidations()

  const [isSucess, setIsSucess] = useState<boolean>(false)
  const [hasIndicadores, setHasIndicadores] = useState<boolean>(false)
  const [painelSelecionadoUuid, setPainelSelecionadoUuid] = useState<
    string | null
  >(null)
  const [panelName, setPanelName] = useState<string>('')

  const hasPanel = Array.isArray(paineis) && paineis.length > 0

  useEffect(() => {
    if (isOpen) {
      // setPanelName('Meu Painel de Desempenho')
    }
  }, [isOpen])

  async function handleCreatePainel() {
    setValidationErrors(null)
    schema
      .validate({ nome: panelName }, { abortEarly: false })
      .then(async () => {
        const response = await createPainel({
          nome: panelName || '',
        })
        if (response) setPainelSelecionadoUuid(response.uuid || '')
        setPanelName('')
        close()
      })
      .catch((err) => {
        setValidationErrors(err)
      })
  }

  return (
    <Container>
      <FilterProvider filterValues={filters}>
        <FormProvider>
          <PageTitle title="Painel de Desempenho">
            <ButtonContainer>
              <Filter />
              {hasIndicadores && (
                <Button
                  variant="contained"
                  onClick={(e) => openIndicadores(e)}
                  sx={{ m: 0 }}
                >
                  Adicionar
                </Button>
              )}
            </ButtonContainer>
          </PageTitle>
          <Content>
            {!hasPanel && !isLoadingPaineis ? (
              <Surface>
                <AddFirst
                  isLoading={isLoadingPaineis}
                  buttonLabel="Criar meu primeiro Painel de Desempenho"
                  primaryMessage="Crie o seu Painel de Desempenho"
                  secondaryMessage={
                    <div style={{ textAlign: 'center' }}>
                      Parece que você ainda não tem nenhum Panel de Desempenho
                      criado ainda. Clique no botão abaixo para começar e
                      organizar suas informações de forma eficiente.
                    </div>
                  }
                  onClick={(e) => {
                    openCreatePainel(e)
                  }}
                />
              </Surface>
            ) : (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <PainelSelector
                    painelSelecionadoUuid={painelSelecionadoUuid || ''}
                    onChangePainelSelecionadoUuid={setPainelSelecionadoUuid}
                    onCreatePainel={handleCreatePainel}
                    openPopover={openCreatePainel}
                  />
                  <Box sx={{ display: 'flex', gap: 2, mr: 1 }}>
                    {isSucess && (
                      <FeedbackSuccessInline
                        visible={isSucess}
                        timeout={3000}
                      />
                    )}
                  </Box>
                </Box>
                <PainelDesempenhoContent
                  painelUuid={painelSelecionadoUuid || ''}
                  onSucessUpdatePropriedades={setIsSucess}
                  setHasIndicadores={setHasIndicadores}
                />
              </>
            )}
          </Content>
        </FormProvider>

        <IndicadoresPopover
          painelUuid={painelSelecionadoUuid || ''}
          anchorEl={anchorElIndicadores}
          close={closeIndicadores}
          isOpen={isOpenIndicadores}
        />

        <Popover
          open={isOpen}
          anchorEl={anchorEl}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          onClose={close}
        >
          <Surface elevation={2}>
            <ButtonContainer sx={{ m: 0, p: 0 }}>
              <IconButton onClick={() => close()}>
                <Close />
              </IconButton>
            </ButtonContainer>
            <Stack spacing={2} sx={{ mt: 2, width: '350px' }}>
              <TextField
                label="Nome do Painel"
                name="nome"
                required
                onChange={(e) => setPanelName(e.target.value)}
                value={panelName}
                {...validationProps('nome')}
              />
              <Button
                isLoading={isLoadingCreatePainel}
                variant="contained"
                onClick={handleCreatePainel}
              >
                Adicionar Painel
              </Button>
            </Stack>
          </Surface>
        </Popover>
      </FilterProvider>
    </Container>
  )
}
