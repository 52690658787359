import { Stack } from '@mui/material'
import {
  TimePickerProps,
  TimePicker as MuiDatePicker,
} from '@mui/x-date-pickers/TimePicker'
import { DateTime } from 'luxon'

interface ITimerPickcerProps
  extends Omit<TimePickerProps<DateTime>, 'onChange' | 'value'> {
  onChange: (date: string | null) => void
  value?: string | null
}

export default function TimePicker(props: ITimerPickcerProps) {
  const { value, onChange, ...rest } = props
  function handleChange(date: DateTime | null) {
    if (date?.isValid) {
      onChange(date?.toFormat('HH:mm'))
      return
    }

    if (date === null) {
      onChange(null)
      return
    }
  }

  return (
    <Stack flexDirection="row" alignItems="center" gap={1}>
      <MuiDatePicker
        format="HH:mm"
        value={DateTime.fromFormat(value || '', 'HH:mm')}
        onChange={(v: DateTime | null) => handleChange(v)}
        {...rest}
      />
    </Stack>
  )
}
