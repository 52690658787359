import { useEffect, useMemo, useState } from 'react'

import {
  Alert,
  AlertTitle,
  Box,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Step,
  StepLabel,
  Stepper,
} from '@mui/material'

import { formatCurrency } from '@data-c/hooks'
import { useDialog, useForm } from '@data-c/hooks'
import { Button, ButtonContainer, Dialog } from '@data-c/ui'

import {
  NegociacaoPerdidaRequest,
  PedidoModel,
  useMarcarNegociacaoComoPerdida,
} from 'hooks/queries/usePedidos'

import * as yup from 'yup'
import useValidations from 'hooks/useValidations'
import { Archive } from '@mui/icons-material'
import Descricao from './components/Descricao'
import MotivoPerdaForm from './components/MotivoPerdaForm'
import SelecionarItens, { SelectableItem } from './components/SelecionarItens'
import useNotification from 'hooks/useNotifications'

interface MarcarComoPerdidaFormProps {
  pedido: PedidoModel
  close: () => void
}

const schema = yup.object().shape({
  motivoPerdaUuid: yup.string().ensure().required('Informe o Motivo de Perda'),
  observacaoMotivoPerda: yup
    .string()
    .ensure()
    .when('$informarObservacao', (informarObservacao, schema) => {
      if (informarObservacao) {
        return yup
          .string()
          .ensure()
          .required('Informe a Observação')
          .min(15, 'Descreva a observação usando pelo menos 15 caracteres')
      }
      return schema
    }),
})

export default function MarcarComoPerdidaForm(
  props: MarcarComoPerdidaFormProps,
) {
  const { pedido, close } = props
  const [activeStep, setActiveStep] = useState(0)
  const { setValidationErrors, validationProps } = useValidations()
  const { isOpen, openDialog, closeDialog } = useDialog()
  const { mutateAsync, isLoading } = useMarcarNegociacaoComoPerdida()
  const notifications = useNotification()
  const { data, setData, handleChange } = useForm<PedidoModel>(
    {} as PedidoModel,
  )
  const [itensSelecionaveis, setItensSelecionaveis] = useState<
    Array<SelectableItem>
  >([])
  useEffect(() => {
    setData(pedido)
    const novosItens: Array<SelectableItem> = pedido?.itens.map((i) => {
      return {
        uuid: i.uuid || '',
        descricaoProduto: `#${i.produto?.codigo} - ${i.produto?.nome}`,
        quantidade: i.quantidade,
        total: formatCurrency(i.precoTotalLiquido),
        selecionado: true,
      }
    })
    setItensSelecionaveis(novosItens)
  }, [pedido, setData])

  const quantidadeDeItens = pedido.itens.length

  const quantidadeDeItensSelecionados = useMemo(
    () =>
      itensSelecionaveis.reduce((prev, cur) => {
        return prev + (cur.selecionado ? 1 : 0)
      }, 0),
    [itensSelecionaveis],
  )

  const pluralizar = quantidadeDeItens - quantidadeDeItensSelecionados > 1
  const s = pluralizar ? 's' : ''
  const msg = `${
    quantidadeDeItens - quantidadeDeItensSelecionados
  } produto${s} não est${pluralizar ? 'ão' : 'á'} selecionado${s}`
  const msg2 = `Uma nova negociação será criada com o${s} produto${s} que não est${
    pluralizar ? 'ão' : 'á'
  } selecionado${s}`

  const steps = [
    'Que pena!',
    'Selecione os produtos perdidos',
    'Indique o motivo da perda da negociação',
  ]

  function handleToggle(value: SelectableItem) {
    const novosItems = itensSelecionaveis.map((i) => {
      if (i.uuid === value.uuid) {
        i.selecionado = !i.selecionado
      }
      return i
    })
    setItensSelecionaveis(novosItems)
  }

  function handleSubmitForm() {
    setValidationErrors(null)
    schema
      .validate(data, {
        abortEarly: false,
        context: {
          informarObservacao: Boolean(data?.motivoPerda?.informarObservacao),
        },
      })
      .then(async () => {
        const dataRequest: NegociacaoPerdidaRequest = {
          negociacaoUuid: data?.uuid || '',
          itensSelecionados: itensSelecionaveis,
          motivoPerdaUuid: data?.motivoPerdaUuid || '',
          observacaoMotivoPerda: data?.observacaoMotivoPerda || null,
        }
        const dataResponse = await mutateAsync(dataRequest)

        if (dataResponse.pedido2) {
          notifications.notifySuccess(
            <div>
              A negociação foi marcada como perdida com sucesso.
              <p>
                Uma nova negociação foi gerada com os produtos que não foram
                perdidos.
              </p>
            </div>,
          )
        } else {
          notifications.notifySuccess(`A negociação foi marcada como perdida`)
        }

        closeDialog()
        close()
      })
      .catch((err) => {
        setValidationErrors(err)
      })
  }

  function handleNextStep() {
    if (activeStep === 1) {
      if (quantidadeDeItensSelecionados === 0) return
    }
    if (activeStep === 2) {
      setValidationErrors(null)
      schema
        .validate(data, {
          abortEarly: false,
          context: {
            informarObservacao: Boolean(data?.motivoPerda?.informarObservacao),
          },
        })
        .then(async () => {
          setActiveStep((previousState) => previousState + 1)
        })
        .catch((err) => {
          setValidationErrors(err)
        })
      return
    }

    setActiveStep((previousState) => previousState + 1)
  }

  function handlePreviousStep() {
    setActiveStep((previousState) => previousState - 1)
  }

  return (
    <>
      <MenuItem
        onClick={() => {
          openDialog()
        }}
      >
        <ListItemIcon>
          <Archive />
        </ListItemIcon>
        <ListItemText>Marcar como perdida</ListItemText>
      </MenuItem>

      <Dialog
        title="Marcar como oportunidade perdida"
        open={isOpen}
        onClose={() => {
          closeDialog()
          close()
        }}
        actions={
          <Stack
            sx={{ width: '100%' }}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Box>
              {activeStep > 0 && (
                <Button variant="text" onClick={handlePreviousStep}>
                  Voltar
                </Button>
              )}
            </Box>

            {activeStep < 3 && (
              <Box>
                <Button variant="text" onClick={handleNextStep}>
                  Próximo
                </Button>
              </Box>
            )}

            {activeStep === 3 && (
              <ButtonContainer>
                <Button
                  onClick={() => {
                    closeDialog()
                    close()
                  }}
                  color="error"
                >
                  Cancelar
                </Button>
                <Button
                  variant="contained"
                  isLoading={isLoading}
                  onClick={handleSubmitForm}
                >
                  Marcar como perdida
                </Button>
              </ButtonContainer>
            )}
          </Stack>
        }
      >
        <Grid container spacing={2}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Stepper nonLinear activeStep={activeStep} alternativeLabel>
              {steps.map((label, index) => (
                <Step completed={activeStep > index} key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>

          {quantidadeDeItensSelecionados > 0 &&
            quantidadeDeItensSelecionados < quantidadeDeItens &&
            activeStep === 1 && (
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Alert severity="warning">
                  <AlertTitle>{msg}</AlertTitle>
                  {msg2}
                </Alert>
              </Grid>
            )}

          {quantidadeDeItensSelecionados === 0 && (
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Alert severity="error">
                <AlertTitle>Nenhum produto selecionado</AlertTitle>
                Selecione ao menos um produto para dar perda na negociação
              </Alert>
            </Grid>
          )}

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            {activeStep === 0 && <Descricao />}
            {activeStep === 1 && (
              <SelecionarItens
                itens={itensSelecionaveis || []}
                onSelect={handleToggle}
              />
            )}
            {activeStep === 2 && (
              <MotivoPerdaForm
                validationProps={validationProps}
                setData={setData}
                handleChange={handleChange}
                data={data}
              />
            )}
            {activeStep === 3 && (
              <Stack spacing={2}>
                {quantidadeDeItensSelecionados > 0 &&
                  quantidadeDeItensSelecionados < quantidadeDeItens && (
                    <Alert severity="warning">
                      <AlertTitle>{msg}</AlertTitle>
                      {msg2}
                    </Alert>
                  )}

                <Alert severity="info">
                  Ao marcar como oportunidade perdida você não poderá mais
                  alterar essa negociação.
                </Alert>
              </Stack>
            )}
          </Grid>
        </Grid>
        {/* {quantidadeDeItens} */}
      </Dialog>
    </>
  )
}
