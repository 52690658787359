import { useState } from 'react'
import { Transporter, TransporterProps } from '@data-c/ui'
import {
  FormaPagamentoModel,
  useQueryObterFormaPagamentoPorRegra,
} from 'hooks/queries/useFormaPagamento'
import FormaPagamentoTable from './components/Table'

type FormaPagamentoTransporterProps = {
  clienteUuid?: string
  vendedorUuid?: string
} & Omit<
  TransporterProps<FormaPagamentoModel>,
  'table' | 'form' | 'onSearch' | 'renderValue' | 'options'
>

export default function FormaPagamentoTransporter(
  props: FormaPagamentoTransporterProps,
) {
  const { value, clienteUuid, ...rest } = props

  const [query, setQuery] = useState<string | null>()

  const { data, isLoading } = useQueryObterFormaPagamentoPorRegra({
    plainQuery: query,
    clienteUuid,
    ativo: true,
  })

  function handleSearch(q: string | null) {
    setQuery(q)
  }

  return (
    <Transporter
      onSearch={handleSearch}
      options={data || []}
      isLoading={isLoading}
      table={FormaPagamentoTable}
      label="Forma de Pagamento"
      dialogProps={{ title: 'Forma de Pagamento', maxWidth: 'md' }}
      tableProps={{
        clienteUuid,
      }}
      renderValue={(value) => {
        const dash = value?.codigo || value?.nome ? ' - ' : '  '
        return `${value?.codigo || ''}${dash}${value?.nome || ''}`
      }}
      value={value}
      {...rest}
    />
  )
}
