import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  DataTable,
  OptionStyles,
  DataTableOptions as Options,
  Flag,
} from '@data-c/ui'
import { useFilterData, useFormApi } from '@data-c/providers'
import {
  // TransportableDataTableProps,
  findData,
  usePagination,
} from '@data-c/hooks'

import { formatToCPFOrCNPJ } from 'brazilian-values'

import { MUIDataTableColumnDef } from 'mui-datatables'
import useClientes, {
  ClienteFilters1,
  ClientesModel,
} from 'hooks/queries/useClientes'
import { useUserContext } from 'components/Contexts/UserContext'
import Surface from 'components/Surface'

export default function Table() {
  const { appliedValues, searchId } = useFilterData<ClienteFilters1>()
  const { configuracoes } = useUserContext()
  const { changePage, changePageSize, pagination } = usePagination()
  const { openForm, openConfirm } = useFormApi()
  const { useQuery } = useClientes()

  const { representante, ...rest } = appliedValues || {}
  const nFilters: ClienteFilters1 = {
    ...rest,
    vendedorUuid: representante?.uuid,
  }

  const { data, isLoading, isFetching, error } = useQuery({
    queryParams: nFilters,
    requestId: searchId,
    pagination,
  })
  const navigate = useNavigate()

  const formattedData = useMemo(
    () =>
      data?.data.map((item) => {
        return {
          ...item,
          cnpjcpf: item.cnpjcpf ? formatToCPFOrCNPJ(item.cnpjcpf) : '',
          cidadeNome: `${item.cidadeModel?.nome || ''} -  ${
            item.cidadeModel?.uf || ''
          }`,
        }
      }),
    [data?.data],
  )

  const handleClickItem = useCallback(
    (event: 'edit' | 'delete', data: ClientesModel) => {
      switch (event) {
        case 'edit':
          navigate(`/clientes/cliente/${data?.uuid}`)
          break
        case 'delete':
          openConfirm(data)
          break
      }
    },
    [openForm, openConfirm],
  )

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'codigo',
        label: 'Código',
        options: {
          setCellProps() {
            return { width: '96px' }
          },
        },
      },
      {
        name: 'cnpjcpf',
        label: 'CNPJ/CPF',
      },
      {
        name: 'nome',
        label: 'Razão Social',
      },
      {
        name: 'usual',
        label: 'Nome Fantasia',
      },
      {
        name: 'cidadeNome',
        label: 'Cidade',
      },
      {
        name: 'situacao',
        label: 'Situação',
      },
      {
        name: 'ativo',
        label: 'Ativo',
        options: {
          setCellProps: () => ({
            style: {
              width: '60px',
              textAlign: 'center',
            },
          }),
          customBodyRender: (value) => {
            return <Flag isFlagged={value === 1} type="success" />
          },
        },
      },
      {
        name: 'uuid',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          setCellProps: OptionStyles,
          customBodyRender: (value) => {
            const _data = findData(data?.data || [], value, 'uuid')
            return (
              <Options
                displayUpdateButton={true}
                value={_data}
                onClick={handleClickItem}
              />
            )
          },
        },
      },
    ],
    [configuracoes, handleClickItem, data?.data],
  )

  return (
    <Surface>
      <DataTable
        error={error?.message}
        columns={columns}
        data={formattedData || []}
        pagination={data?.pagination}
        isLoading={isLoading}
        isFetching={isFetching}
        changePage={changePage}
        changePageSize={changePageSize}
        options={{
          rowsPerPageOptions: [15, 50, 100],
          rowsPerPage: 15,
        }}
      />
    </Surface>
  )
}
