import { SvgIcon, SvgIconProps } from '@mui/material'

export default function PrazoIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        id="vector"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          fill="currentColor"
          d="M14.4,6L14,4H5v17h2v-7h5.6l0.4,2h7V6z"
          id="path_0"
        />
      </svg>
    </SvgIcon>
  )
}
