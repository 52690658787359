import { findData } from '@data-c/hooks'
import { Button, ButtonContainer, DataTable, Dialog, Flag } from '@data-c/ui'
import { Box, Popover, Stack, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { useUserContext } from 'components/Contexts/UserContext'
import Surface from 'components/Surface'
import TimeVendasTransporter from 'components/Transporters/TimeVendasTranporter'
import {
  ImportarExcelInterface,
  useFinalImport,
  preview,
} from 'hooks/queries/useImportarEntidade'
import { TimeVendasModel } from 'hooks/queries/useTimeVendas'
import useDialog from 'hooks/useDialog'
import usePopOver from 'hooks/usePopover'
import { MUIDataTableColumnDef } from 'mui-datatables'
import { useEffect, useMemo, useState } from 'react'

export interface PreviewProps {
  excelProps: ImportarExcelInterface
  onBack: () => void
  onImportCompleted: (excelPropsResponse: ImportarExcelInterface) => void
}

export default function Preview(props: PreviewProps) {
  const { excelProps, onBack, onImportCompleted } = props
  const [data, setData] = useState<ImportarExcelInterface>()
  const [isLoading, setLoading] = useState<boolean>()
  const [error, setError] = useState<AxiosError>()

  useEffect(() => {
    async function obterPreview() {
      setLoading(true)
      try {
        const response = await preview(excelProps)
        setData(response)
      } catch (err) {
        setError(err as AxiosError)
      } finally {
        setLoading(false)
      }
    }

    obterPreview()
  }, [excelProps])

  const { mutateAsync: finalImport, isLoading: isLoadingImport } =
    useFinalImport()
  const {
    data: previewData,
    mapeamento,
    destinos,
  } = data || ({} as ImportarExcelInterface)
  const { temPermissao, user } = useUserContext()
  const {
    open: openPopover,
    isOpen: isOpenPopover,
    close: closePopover,
    anchorEl,
  } = usePopOver()
  const {
    isOpen: isOpenDialog,
    closeDialog,
    openDialog,
    data: dataDialog,
  } = useDialog<string[]>()

  const [vendedor, setVendedor] = useState<TimeVendasModel | null>(null)
  const [estaValidado, setEstaValidado] = useState<boolean | null>(null)

  const permissaoParaLer = temPermissao('clientes.read') === 'pessoal'

  useEffect(() => {
    if (previewData?.erros && Object.keys(previewData.erros).length > 0) {
      return setEstaValidado(false)
    }
    return setEstaValidado(true)
  }, [data])

  async function handleSubmit() {
    previewData.config = {
      vendedor: {
        uuid: permissaoParaLer ? user?.uuid : vendedor?.uuid,
        nome: permissaoParaLer ? user?.nome : vendedor?.nome,
      },
    }
    const response = await finalImport(data || ({} as ImportarExcelInterface))
    if (response) {
      onImportCompleted(response)
    }
  }

  const formattedData = useMemo(() => {
    return previewData?.data?.map((d) => {
      return {
        ...d,
        cidade: d.cidade ? d.cidade : 'Cidade Não Encontrada',
      }
    })
  }, [data])

  const columns = useMemo((): MUIDataTableColumnDef[] => {
    if (!mapeamento) return []
    return [
      {
        name: 'validacao',
        label: 'Validação',
        options: {
          setCellProps: () => ({
            style: {
              width: '60px',
              textAlign: 'center',
            },
          }),
          customBodyRender: (_, tableMeta) => {
            const rowIndex = tableMeta.rowIndex
            return previewData.erros &&
              previewData.erros[rowIndex]?.length > 0 ? (
              <Button
                color="error"
                size="small"
                onClick={() =>
                  previewData.erros && openDialog(previewData?.erros[rowIndex])
                }
                variant="text"
              >
                Ver Erro
              </Button>
            ) : (
              <Flag isFlagged type="success" />
            )
          },
        },
      },
      ...mapeamento.map((m) => {
        const destino = findData(destinos, m.destino, 'key')
        return {
          name: destino?.key || '',
          label: destino?.value || '',
        }
      }),
    ]
  }, [data])

  return (
    <Surface>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button variant="contained" sx={{ mb: 2 }} onClick={onBack}>
          Voltar
        </Button>
        <Button
          variant="contained"
          sx={{ mb: 2 }}
          onClick={(e) => (permissaoParaLer ? handleSubmit() : openPopover(e))}
          disabled={!estaValidado}
        >
          Importar
        </Button>
        <Popover
          open={isOpenPopover}
          anchorEl={anchorEl}
          onClose={closePopover}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          <Stack sx={{ m: 2, maxWidth: '360px' }} spacing={2}>
            <Typography variant="body1">
              Escolha um vendedor para vincular aos clientes ou deixe em branco
              para não vincular nenhum.
            </Typography>
            <TimeVendasTransporter
              label="Vendedor"
              onChange={(vendedor: TimeVendasModel | null) => {
                setVendedor(vendedor)
              }}
              value={vendedor}
            />
            <ButtonContainer>
              <Button
                isLoading={isLoadingImport}
                variant="contained"
                onClick={handleSubmit}
              >
                Importar
              </Button>
            </ButtonContainer>
          </Stack>
        </Popover>
      </Box>
      <DataTable
        error={error?.message}
        columns={columns}
        data={formattedData || []}
        isLoading={isLoading}
        isFetching={isLoading}
      />
      <Dialog
        open={isOpenDialog}
        onClose={closeDialog}
        title="Dados Incorretos"
        type="error"
        actions={
          <Button onClick={closeDialog} variant="contained">
            Ok
          </Button>
        }
      >
        {dataDialog?.map((d, index) => (
          <Typography>{`${index + 1} - ${d}`}</Typography>
        ))}
      </Dialog>
    </Surface>
  )
}
