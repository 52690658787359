import { Paper } from '@mui/material'
import { TabelaPrecoModel } from 'hooks/queries/useTabelaPreco'
import Form from 'pages/TabelasPrecos/components/Form'

interface TabCadastroTabelaPrecoProps {
  tabelaPreco: TabelaPrecoModel
}

export default function TabCadastroTabelaPreco(
  props: TabCadastroTabelaPrecoProps,
) {
  const { tabelaPreco } = props

  return (
    <Paper sx={{ p: 2 }} elevation={1}>
      <Form view="plain" data={tabelaPreco} />
    </Paper>
  )
}
