import { ReactNode } from 'react'

import {
  TimelineConnector,
  TimelineContent as MuiTimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab'
import { Typography, useTheme } from '@mui/material'

interface TimelineContentProps {
  leftContent: ReactNode
  icon: string
  completed?: boolean
  title: string
  description: string
  statusAtividade?: string
  onClick: () => void
}

export default function TimelineContent(props: TimelineContentProps) {

  const { leftContent, icon, title, description, onClick, completed, statusAtividade } = props
  
  const theme = useTheme()

  let backgroundColorTimelineDot = ""

  if(!completed) {
    switch(statusAtividade) {
      case "ACONTECENDO":
        backgroundColorTimelineDot = "green"
        break
      case "VENCIDA":
        backgroundColorTimelineDot = "red"
        break
      case "FUTURA":
        backgroundColorTimelineDot = "gray"
        break
    }
  } else {
    backgroundColorTimelineDot = "#000"
  }

  return (
    <TimelineItem
      onClick={onClick}
      sx={{
        borderRadius: 0.5,
        cursor: 'pointer',
        transition: 'ease-in-out 200ms',
        ':hover': { backgroundColor: 'rgba(89, 195, 224, 15%)' },
        minHeight: { xs: '120px', md: '70px' },
      }}
    >
      <TimelineOppositeContent variant="body2">
        {leftContent}
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot
          sx={{
            opacity: completed ? '0.4' : '1',
            backgroundColor: backgroundColorTimelineDot,
          }}
        >
          <img src={icon} width="24px" height="24px" />
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <MuiTimelineContent>
        <Typography variant="h6" fontWeight="bold" component="span">
          {title}
        </Typography>
        <Typography
          variant="body1"
          color={theme.palette.grey[500]}
          component="p"
          sx={{ textDecoration: completed ? 'line-through' : 'none' }}
        >
          {description}
        </Typography>
      </MuiTimelineContent>
    </TimelineItem>
  )
}
