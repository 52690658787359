import axios, { InternalAxiosRequestConfig } from 'axios'

import { getToken } from 'services/credentials'

const api = axios.create({
  baseURL: import.meta.env.VITE_MIO_AUTH_API_URL,
})

api.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
  let conf: InternalAxiosRequestConfig = config
  if (conf.headers === undefined) {
    conf.headers = config.headers
  }

  const token = getToken()
  if (token) {
    conf.headers.Authorization = `Bearer ${token}`
  }

  conf.headers['Content-Type'] = 'application/json;charset=UTF-8'
  conf.headers['Resposta-Compactada'] = 'Nunca'
  conf.headers['Access-Control-Allow-Origin'] = '*'
  conf.headers['Access-Control-Allow-Headers'] =
    'Access-Control-*, Origin, X-Requested-With, Content-Type, Accept'
  conf.headers['Access-Control-Allow-Methods'] =
    'GET, POST, OPTIONS, PUT, PATCH, DELETE'
  conf.headers['Access-Control-Allow-Credentials'] = true
  conf.headers['Allow'] = 'GET, POST, PUT, DELETE, OPTIONS, HEAD'
  return conf
})

export default api
