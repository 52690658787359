import { useState } from 'react'
import { PlainQueryProps } from '@data-c/hooks'
import { Transporter, TransporterProps } from '@data-c/ui'
import useFamiliaProdutos, {
  FamiliaProdutosModel,
} from 'hooks/queries/useFamiliaProdutos'
import FamiliaProdutoForm from 'pages/FamiliaProdutos/components/Form'
import FamiliaProdutoTable from 'pages/FamiliaProdutos/components/Table'

type FamiliaProdutoTransporterProps = {} & Omit<
  TransporterProps<FamiliaProdutosModel>,
  'table' | 'form' | 'onSearch' | 'renderValue' | 'options'
>

export default function FamiliaProdutoTransporter(
  props: FamiliaProdutoTransporterProps,
) {
  const { value, ...rest } = props

  const [query, setQuery] = useState<string | null>()
  const { useQuery } = useFamiliaProdutos()

  const { data, isLoading } = useQuery<PlainQueryProps>({
    queryParams: {
      plainQuery: query,
    },
    pagination: {
      page: 1,
      pageSize: 100,
    },
  })

  function handleSearch(q: string | null) {
    setQuery(q)
  }

  return (
    <Transporter
      onSearch={handleSearch}
      options={data?.data || []}
      isLoading={isLoading}
      table={FamiliaProdutoTable}
      label="Família"
      dialogProps={{ title: 'Família' }}
      renderValue={(value) => `${value?.codigo || ''} - ${value.nome}`}
      form={FamiliaProdutoForm}
      value={value}
      {...rest}
    />
  )
}
