import { Button, DataTable } from '@data-c/ui'
import { Stack } from '@mui/material'
import useFormaPagamento, {
  FormaPagamentoModel,
} from 'hooks/queries/useFormaPagamento'
import { MUIDataTableColumnDef } from 'mui-datatables'
import { useMemo, useState } from 'react'

export default function Table(props: { formaPagamento: FormaPagamentoModel }) {
  const { formaPagamento } = props
  const [selecionados, setSelecionados] = useState<number[]>([])

  const { useQueryCondicoesVinculadas, useDesvincularCondicoes } =
    useFormaPagamento()
  const { data, isFetching, isLoading, error } = useQueryCondicoesVinculadas(
    formaPagamento.uuid,
  )

  const { mutateAsync: desvincularCondicoes, isLoading: isSubmiting } =
    useDesvincularCondicoes()

  const condicoes = data?.map((d) => ({
    uuid: d.uuid,
    codigoCondicao: d.condicaoPagamento.codigo,
    nomeCondicao: d.condicaoPagamento.nome,
  }))

  async function handleDesvincular() {
    try {
      if (Array.isArray(data)) {
        const idsSelecionados = selecionados.map(
          (index) => data[index]?.uuid || '',
        )

        await desvincularCondicoes({
          formaPagamentoUuid: formaPagamento.uuid,
          idsSelecionados,
        })
      }
    } finally {
      setSelecionados([])
    }
  }

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'codigoCondicao',
        label: 'Código',
        options: {
          setCellProps() {
            return { width: '96px' }
          },
        },
      },
      {
        name: 'nomeCondicao',
        label: 'Nome',
        options: {
          sort: false,
        },
      },
    ],
    [data],
  )

  return (
    <DataTable
      error={error?.message}
      isLoading={isLoading}
      isFetching={isFetching}
      columns={columns}
      data={condicoes || []}
      options={{
        rowsPerPageOptions: [15, 50, 100],
        rowsPerPage: 15,
        selectableRows: 'multiple',
        selectableRowsOnClick: true,
        rowsSelected: selecionados,
        onRowSelectionChange: (_, __, rowsSelecteds: any) =>
          setSelecionados(rowsSelecteds),
        customToolbarSelect() {
          return (
            <Stack spacing={1} mr={1}>
              <Button
                color="error"
                onClick={handleDesvincular}
                isLoading={isSubmiting}
              >
                Desvincular Condições
              </Button>
            </Stack>
          )
        },
      }}
    />
  )
}
