import { Grid, TextField } from '@mui/material'

import { useFormApi, useFormData } from '@data-c/providers'
import { Checkbox, FormContainer } from '@data-c/ui'

import useValidations from 'hooks/useValidations'

import * as yup from 'yup'
import useLinhaProdutos, {
  LinhaProdutosModel,
} from 'hooks/queries/useLinhaProdutos'
import FormProps from 'interfaces/FormProps'

const schema = yup.object().shape({
  nome: yup.string().required('Informe o Nome'),
})

export default function Form(props: FormProps<LinhaProdutosModel>) {
  const { formValues: data } = useFormData<LinhaProdutosModel>()
  const { onChangeFormValue, toggleSubmit, closeForm } =
    useFormApi<LinhaProdutosModel>()

  const { useSubmit } = useLinhaProdutos()
  const { mutateAsync } = useSubmit()
  const { setValidationErrors, validationProps } = useValidations()

  function handleSubmitForm() {
    setValidationErrors(null)
    schema
      .validate(data, { abortEarly: false })
      .then(async () => {
        toggleSubmit(true)

        await mutateAsync(data)
        closeForm()
      })
      .catch((err) => {
        setValidationErrors(err)
      })
      .finally(() => toggleSubmit(false))
  }

  return (
    <FormContainer
      onSubmitForm={handleSubmitForm}
      dialogProps={{
        maxWidth: 'sm',
        title: ' Linha de Produtos',
        disableRestoreFocus: true,
      }}
      {...props}
    >
      <Grid container spacing={2}>
        <Grid item xl={2} lg={2} md={2} sm={3} xs={12}>
          <TextField name="codigo" label="Código" disabled />
        </Grid>
        <Grid item xl={10} lg={10} md={10} sm={9} xs={12}>
          <TextField
            autoFocus
            name="nome"
            label="Nome"
            required
            value={data?.nome || ''}
            onChange={onChangeFormValue}
            {...validationProps('nome')}
          />
        </Grid>
        <Grid item xl={2} lg={2} md={2} sm={2} xs={12}>
          <Checkbox
            label="Ativo"
            name="ativo"
            onChange={onChangeFormValue}
            checked={Boolean(data?.ativo)}
          />
        </Grid>
      </Grid>
    </FormContainer>
  )
}
