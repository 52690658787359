import { onlyNumbers } from '@data-c/hooks'
import { useFilterApi, useFilterData } from '@data-c/providers'
import { FilterContainer } from '@data-c/ui'

import { VendedorFilters, VendedorFilters1 } from 'hooks/queries/useVendedores'
import FilterProps from 'interfaces/FilterProps'
import { Stack, TextField } from '@mui/material'
import CnpjCpfTextField from 'components/CnpjCpfTextField'

export default function Filter({
  onApplyFilters,
}: FilterProps<VendedorFilters1>) {
  const { onChangeFilterValue, changeFilterValue } = useFilterApi()
  const { filterValues } = useFilterData<VendedorFilters, VendedorFilters1>()
  return (
    <FilterContainer
      title="Pesquisar por vendedores"
      triggerButtonProps={{ variant: 'outlined' }}
      onApplyFilters={(f) => onApplyFilters(f)}
    >
      <Stack spacing={1.5}>
        <TextField
          name="codigo"
          value={filterValues?.codigo?.value}
          label={filterValues?.codigo?.label}
          onChange={onChangeFilterValue}
        />
        <TextField
          name="usual"
          value={filterValues?.usual?.value}
          label={filterValues?.usual?.label}
          onChange={onChangeFilterValue}
        />
        <CnpjCpfTextField
          name="cnpjcpf"
          autoFocus
          value={filterValues?.cnpjcpf?.value || ''}
          onChange={(e) => {
            const cnpjcpf = onlyNumbers(e.target.value)
            changeFilterValue('cnpjcpf', cnpjcpf)
          }}
          inputProps={{
            label: `${filterValues?.cnpjcpf?.label}`,
          }}
        />
      </Stack>
    </FilterContainer>
  )
}
