import { ReactNode } from 'react'
import FullMenuItem from './FullMenuItem'
import MinMenuItem from './MinMenuItem'

export interface MenuItemProps {
  id: string
  label: string
  items?: MenuItemProps[]
  link?: string
  icon?: ReactNode
  visible?: boolean
  isNew?: boolean
  children?: ReactNode
  open?: boolean
  isFirstLevel?: boolean
  selected?: boolean
  onClick?: (link: string) => void
}

export default function MenuItem(props: MenuItemProps) {
  const { open, isFirstLevel = false, ...rest } = props
  if (open) return <FullMenuItem {...rest} />
  return <MinMenuItem {...rest} />
}
