import { Button, Stack, Typography, useTheme } from '@mui/material'
import Surface from 'components/Surface'
import { ImportarExcelInterface } from 'hooks/queries/useImportarEntidade'
import { useEffect, useRef, useState } from 'react'
import jsPlumb from 'jsplumb'
import { useQuery } from 'hooks/queries/useConfiguracoes'

export interface MapeamentoProps {
  excelProps?: ImportarExcelInterface
  onMapCompleted: (excelProps: ImportarExcelInterface) => void
  onBack: () => void
}

interface ConectableCardProps {
  value: string
  id: string
}

function ConectableCard({ value, id }: ConectableCardProps) {
  return (
    <Surface
      elevation={1}
      id={id}
      sx={{ p: 1, width: '70%', backgroundColor: '#44444411' }}
    >
      {value}
    </Surface>
  )
}

const instance = jsPlumb.jsPlumb.getInstance()

export default function Mapeamento(props: MapeamentoProps) {
  const { excelProps, onMapCompleted, onBack } = props
  const theme = useTheme()
  const { data: configuracoes } = useQuery()
  const [mapeamento, setMapeamento] = useState<
    Array<{ origem: string; destino: string }>
  >([])
  const [previewDisabled, setPreviewDisabled] = useState(true)

  const jsPlumbContainerRef = useRef<HTMLDivElement>(null)
  const resizeObserverRef = useRef<ResizeObserver | null>(null)

  useEffect(() => {
    instance.setContainer(jsPlumbContainerRef.current || '')

    instance.importDefaults({
      Connector: 'Straight',
      Anchors: ['Right', 'Left'],
      Endpoint: 'Dot',
      EndpointStyle: { fill: theme.palette.primary.main, radius: 5 },
      PaintStyle: { stroke: theme.palette.primary.dark, strokeWidth: 2 },
      Overlays: [['Arrow', { width: 10, length: 10, location: 1 }]],
    })
  }, [instance, jsPlumbContainerRef.current])

  useEffect(() => {
    excelProps?.origens.forEach((o) => {
      instance.addEndpoint(o.key, {
        anchor: 'Right',
        isSource: true,
        isTarget: false,
        maxConnections: -1,
      })
    })

    excelProps?.destinos
      .filter((d) => {
        return previewDisabled
          ? configuracoes?.importa_lead
            ? ['nome', 'usual', 'cnpjcpf'].includes(d.key)
            : ['nome', 'usual'].includes(d.key)
          : true
      })
      .forEach((d) => {
        instance.addEndpoint(d.key, {
          anchor: 'Left',
          isSource: false,
          isTarget: true,
          maxConnections: 1,
        })
      })

    instance.bind('connection', (info) => {
      setMapeamento((prev) => {
        const hasConnection = prev.find(
          (m) => m.origem === info.sourceId && m.destino === info.targetId,
        )
        if (hasConnection) return prev
        return [...prev, { origem: info.sourceId, destino: info.targetId }]
      })
    })

    instance.bind('connectionDetached', (info) => {
      setMapeamento((prev) => {
        return prev.filter((m) => {
          return m.origem != info.sourceId && m.destino != info.targetId
        })
      })
    })

    mapeamento.forEach((m) => {
      instance.connect({
        source: m.origem,
        target: m.destino,
      })
    })

    const updateConnections = () => {
      instance.repaintEverything()
    }

    resizeObserverRef.current = new ResizeObserver(updateConnections)
    resizeObserverRef.current?.observe(instance.getContainer())

    // Cleanup quando o componente desmontar
    return () => {
      resizeObserverRef.current?.unobserve(instance.getContainer())
      instance.deleteEveryConnection()
      instance.reset()
    }
  }, [excelProps, previewDisabled, configuracoes])

  useEffect(() => {
    const temCNPJCPF = mapeamento.some((item) => item.destino === 'cnpjcpf')
    const temNomeOuUsual = mapeamento.some(
      (item) => item.destino === 'usual' || item.destino === 'nome',
    )
    const mapeamentoValido = configuracoes?.importa_lead
      ? temCNPJCPF && temNomeOuUsual
      : temNomeOuUsual
    setPreviewDisabled(!mapeamentoValido)
  }, [mapeamento, configuracoes])

  useEffect(() => {
    if (
      Array.isArray(excelProps?.mapeamento) &&
      excelProps.mapeamento.length > 0
    ) {
      setMapeamento(excelProps.mapeamento)
    }
  }, [excelProps])

  function completeMap() {
    if (!excelProps) return
    excelProps.mapeamento = mapeamento
    onMapCompleted(excelProps)
  }

  return (
    <Surface ref={jsPlumbContainerRef} sx={{ position: 'relative', p: 2 }}>
      <Stack direction="row" spacing={1}>
        <Stack flexGrow={1} spacing={1}>
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ width: '70%' }}
          >
            <Button variant="contained" onClick={onBack}>
              Voltar
            </Button>
            <Typography variant="subtitle1">Origem</Typography>
          </Stack>

          {excelProps?.origens.map((origem, index) => {
            return (
              <ConectableCard
                value={origem.value}
                key={`${origem.key}${index}`}
                id={origem.key}
              />
            )
          })}
        </Stack>
        <Stack flexGrow={1} spacing={1} alignItems="flex-end">
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ width: '70%' }}
          >
            <Typography variant="subtitle1">Destino</Typography>
            <Button
              variant="contained"
              onClick={completeMap}
              disabled={previewDisabled}
            >
              Ver prévia
            </Button>
          </Stack>

          {excelProps?.destinos.map((destino, index) => {
            return (
              <ConectableCard
                value={destino.value}
                key={`${destino.key}${index}`}
                id={destino.key}
              />
            )
          })}
        </Stack>
      </Stack>
    </Surface>
  )
}
