import { TransporterProps } from '@data-c/ui'
import { Transporter } from '@data-c/ui'
import useTimeVendas, { TimeVendasModel } from 'hooks/queries/useTimeVendas'
import { useState } from 'react'
import Table from './components/Table'

type UsuariosTransporterProps = {} & Omit<
  TransporterProps<TimeVendasModel>,
  'table' | 'form' | 'onSearch' | 'renderValue' | 'options'
>

export default function TimeVendasTransporter(props: UsuariosTransporterProps) {
  const [query, setQuery] = useState<string | null>()

  const { useQuery } = useTimeVendas()

  const { data, isLoading } = useQuery({
    queryParams: {
      plainQuery: query,
      ativo: true,
    },
    pagination: {
      page: 1,
      pageSize: 100,
    },
  })

  return (
    <Transporter
      dialogProps={{
        title: 'Vendedores',
        maxWidth: 'lg',
      }}
      fullWidth
      onSearch={(q) => setQuery(q)}
      isLoading={isLoading}
      options={data?.data || []}
      renderValue={(value) => {
        return `${value?.codigo || ''} - ${value.nome || value.usual} `
      }}
      table={Table}
      {...props}
    />
  )
}
