import { MenuItem, TextField, TextFieldProps } from '@mui/material'

export default function DropDownCrmSituaco(props: TextFieldProps) {
  return (
    <TextField select {...props}>
      <MenuItem value="TODAS">Todas</MenuItem>
      <MenuItem value="CONCLUIDA">Concluída</MenuItem>
      <MenuItem value="AGORA">Agora</MenuItem>
      <MenuItem value="ATRASADA">Atrasada</MenuItem>
      <MenuItem value="PLANEJADA">Planejada</MenuItem>
    </TextField>
  )
}
