import {
  FormControl,
  FormLabel,
  RadioGroup as MUIRadioGroup,
  RadioGroupProps as MUIRadioGroupProps,
} from '@mui/material'
import { ReactNode } from 'react'

interface RadioGroupProps extends MUIRadioGroupProps {
  label?: string
  children: ReactNode
}

export default function RadioGroup(props: RadioGroupProps) {
  const { children, label, ...rest } = props

  return (
    <FormControl>
      <FormLabel id="controlled-radio-buttons-group">{label}</FormLabel>
      <MUIRadioGroup
        aria-labelledby="controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        {...rest}
      >
        {children}
      </MUIRadioGroup>
    </FormControl>
  )
}
