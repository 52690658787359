import { formatCurrency, useNotifications } from '@data-c/hooks'
import { DataTable } from '@data-c/ui'
import { SortProps } from 'interfaces/SortProps'
import usePositivacaoClientes, {
  PositivacaoClientesFilters,
  PositivacaoClientesFilters1,
} from 'hooks/queries/usePositivacaoClientes'

import { useFilterData } from '@data-c/providers'
import { useMemo, useState } from 'react'
import { MUIDataTableColumnDef } from 'mui-datatables'
import CardValue from 'components/Indicadores/CardValue'
import { Grid, useTheme } from '@mui/material'

export default function TableVendedores() {
  const { extractAxiosError } = useNotifications()
  const theme = useTheme()
  const [sort, setSort] = useState<SortProps>({
    column: 'totalPeriodo',
    direction: 'desc',
  })
  const { appliedValues, searchId } = useFilterData<
    PositivacaoClientesFilters,
    PositivacaoClientesFilters1
  >()

  const { representante, categoriaValue, ...rest } = appliedValues || {}

  const nFilters: PositivacaoClientesFilters1 = {
    ...rest,
    representanteUuid: representante?.uuid,
    categoriaUuid: categoriaValue?.uuid,
  }

  const { useQueryObterRelatorioPositivacaoClientesSintetico } =
    usePositivacaoClientes()
  const { data, isLoading, isFetching, error } =
    useQueryObterRelatorioPositivacaoClientesSintetico(nFilters, searchId, sort)

  const dataFormatada = useMemo(
    () =>
      data?.data?.map((item) => {
        return {
          ...item,
          percentualPositivacao: `${formatCurrency(
            item.percentualPositivacao,
          )}%`,
        }
      }),
    [data],
  )

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'codigoVendedor',
        label: 'Código',
        options: {
          setCellProps() {
            return { width: '96px' }
          },
        },
      },
      {
        name: 'nomeVendedor',
        label: 'Nome',
      },
      {
        name: 'clientesInativos',
        label: `Clientes Inativos`,
        options: {
          setCellProps: () => ({
            style: {
              width: '180px',
              'text-align': 'right',
            },
          }),
        },
      },
      {
        name: 'clientesAtivos',
        label: `Clientes Ativos`,
        options: {
          setCellProps: () => ({
            style: {
              width: '180px',
              'text-align': 'right',
            },
          }),
        },
      },
      {
        name: 'totalClientes',
        label: `Total Clientes`,
        options: {
          setCellProps: () => ({
            style: {
              width: '180px',
              'text-align': 'right',
            },
          }),
        },
      },
      {
        name: 'percentualPositivacao',
        label: `Ativação`,
        options: {
          setCellProps: () => ({
            style: {
              width: '110px',
              'text-align': 'right',
            },
          }),
        },
      },
    ],
    [data],
  )

  return (
    <>
      <Grid container spacing={2} sx={{ mt: 1, mb: 1 }}>
        <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
          <CardValue
            elevation={2}
            label="Clientes Inativos"
            value={data?.clientesInativos || 0}
            color={theme.palette.error.light}
          />
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
          <CardValue
            elevation={2}
            label="Clientes Ativos"
            value={data?.clientesAtivos || 0}
            color={theme.palette.success.light}
          />
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
          <CardValue
            elevation={2}
            label="Total Clientes"
            value={(data?.clientesAtivos || 0) + (data?.clientesInativos || 0)}
            color={theme.palette.primary.light}
          />
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
          <CardValue
            elevation={2}
            label="Ativação"
            value={`${formatCurrency(data?.percentualPositivacao || 0)}%`}
            color={theme.palette.info.light}
          />
        </Grid>
      </Grid>
      <DataTable
        error={extractAxiosError(error)}
        columns={columns}
        data={dataFormatada || []}
        isLoading={isLoading}
        isFetching={isFetching}
        options={{
          sort: true,
          sortOrder: {
            name: sort.column,
            direction: sort.direction,
          },
        }}
        changeSort={(column, direction) => {
          setSort({ column, direction: direction as 'asc' | 'desc' })
        }}
      />
    </>
  )
}
