import { useEffect, useState } from 'react'
import {
  TextField,
  LinearProgress,
  Popover,
  Stack,
  InputAdornment,
  Chip,
  IconButton,
  ListItem,
  List,
  ListItemText,
  ListItemButton,
  Box,
  Typography,
} from '@mui/material'
import useEtiqueta, { EtiquetaModel } from 'hooks/queries/useEtiqueta'
import { useValidations } from '@data-c/hooks'
import * as yup from 'yup'
import Surface from 'components/Surface'
import { Button, ButtonContainer, Dialog } from '@data-c/ui'
import { Delete, Edit, Search } from '@mui/icons-material'
import Divider from 'components/Divider'
import useDialog from 'hooks/useDialog'
import {
  useQueryObterEtiquetasNaoVinculadas,
  useVincularEtiquetas,
} from 'hooks/queries/usePedidos'
import { useUserContext } from 'components/Contexts/UserContext'
import useKeys from 'hooks/useKeys'

interface EtiquetasPedidoProps {
  pedidoUuid: string
  isOpen: boolean
  anchorEl: HTMLElement | null
  onClose: () => void
}

const schema = yup.object().shape({
  nome: yup.string().required('Informe o nome da etiqueta'),
})

export default function EtiquetasPedido(props: EtiquetasPedidoProps) {
  const { pedidoUuid, isOpen, anchorEl, onClose } = props
  const { isEnterKey } = useKeys()
  const { setValidationErrors, validationProps } = useValidations()
  const {
    data: dataDialog,
    isOpen: isOpenDialog,
    closeDialog,
    openDialog,
  } = useDialog<string>()
  const { temPermissao } = useUserContext()

  const { useCreateEtiqueta, useUpdateEtiqueta, useDeleteEtiqueta } =
    useEtiqueta()
  const { mutateAsync: createEtiqueta, isLoading: isLoadingCreateEtiquetas } =
    useCreateEtiqueta()
  const { mutateAsync: updateEtiqueta } = useUpdateEtiqueta()
  const { mutateAsync: deleteEtiqueta, isLoading: isDeletingEtiqueta } =
    useDeleteEtiqueta()
  const {
    mutateAsync: vincularEtiquetas,
    isLoading: isLoadingVincularEtiquetas,
  } = useVincularEtiquetas()
  const {
    data: etiquetasNaoVinculadas,
    isLoading: isLoadingEtiquetasNaoVinculadas,
  } = useQueryObterEtiquetasNaoVinculadas(pedidoUuid)

  const [etiqueta, setEtiqueta] = useState<string | null>(null)
  const [etiquetaSelecionadaUuid, setEtiquetaSelecionadaUuid] = useState<
    string | null
  >(null)
  const [isModifying, setIsModifying] = useState<boolean>(false)
  const [editMode, setEditMode] = useState<'criar' | 'editar' | null>(null)
  const [hoverEtiquetaUuid, setHoverEtiquetaUuid] = useState<string | null>(
    null,
  )
  const [searchInput, setSearchInput] = useState('')

  useEffect(() => {
    if (!isOpen) {
      setIsModifying(false)
    }
  }, [isOpen])

  const handleCreateEtiqueta = () => {
    setValidationErrors(null)
    schema
      .validate({ nome: etiqueta }, { abortEarly: false })
      .then(async () => {
        await createEtiqueta({
          nome: etiqueta || '',
        })
        setIsModifying(false)
        setEtiqueta('')
      })
      .catch((err) => {
        setValidationErrors(err)
      })
  }

  const handleClickAdicionarEtiqueta = () => {
    setIsModifying(true)
    setEditMode('criar')
  }

  const handleClickEditarEtiqueta = (etiqueta: EtiquetaModel) => {
    setEtiquetaSelecionadaUuid(etiqueta.uuid || '')
    setEtiqueta(etiqueta.nome)
    setIsModifying(true)
    setEditMode('editar')
  }

  const handleUpdateEtiqueta = async () => {
    if (etiquetaSelecionadaUuid) {
      setValidationErrors(null)
      schema
        .validate({ nome: etiqueta }, { abortEarly: false })
        .then(async () => {
          await updateEtiqueta({
            nome: etiqueta || '',
            uuid: etiquetaSelecionadaUuid,
          })
          setIsModifying(false)
          setEtiqueta('')
        })
        .catch((err) => {
          setValidationErrors(err)
        })
    }
  }

  const handleDeleteEtiqueta = async () => {
    if (dataDialog) {
      await deleteEtiqueta(dataDialog)
      setIsModifying(false)
      closeDialog()
    }
  }

  const handleVincularEtiquetaPedido = async (etiquetaUuid: string) => {
    if (pedidoUuid && etiquetaUuid && temPermissao('etiquetas.vincular')) {
      await vincularEtiquetas({
        etiquetaUuid,
        pedidoUuid,
      })
    }
  }

  const etiquetasFiltradas = etiquetasNaoVinculadas?.filter((etiqueta) =>
    etiqueta.nome.toLowerCase().includes(searchInput.toLowerCase()),
  )
  const temEtiquetasVinculadas =
    etiquetasNaoVinculadas && etiquetasNaoVinculadas?.length > 0
  return (
    <Popover
      open={isOpen}
      anchorEl={anchorEl}
      anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      onClose={onClose}
      disableRestoreFocus={true}
    >
      {isLoadingEtiquetasNaoVinculadas && <LinearProgress />}
      <Surface elevation={2}>
        {isModifying && (
          <Stack spacing={2} sx={{ mt: 2, width: '300px' }}>
            <TextField
              autoFocus
              label="Etiqueta"
              name="nome"
              required
              onChange={(e) => setEtiqueta(e.target.value)}
              onKeyDown={(e) => {
                console.log('ENTER')
                if (isEnterKey(e)) {
                  editMode === 'criar'
                    ? handleCreateEtiqueta()
                    : handleUpdateEtiqueta()
                }
              }}
              value={etiqueta}
              {...validationProps('nome')}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <ButtonContainer>
                <Button
                  onClick={() => {
                    setIsModifying(false)
                    setEtiqueta('')
                  }}
                  color="error"
                >
                  Cancelar
                </Button>
                <Button
                  isLoading={isLoadingCreateEtiquetas}
                  variant="contained"
                  onClick={
                    editMode === 'criar'
                      ? handleCreateEtiqueta
                      : handleUpdateEtiqueta
                  }
                >
                  Salvar
                </Button>
              </ButtonContainer>
            </Box>
          </Stack>
        )}
        {!isModifying && (
          <Stack spacing={2} sx={{ mt: 2, width: '300px' }}>
            <TextField
              autoFocus
              label="Buscar Etiquetas"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setSearchInput(e.target.value)}
              value={searchInput}
            />
            <Divider />

            {!temEtiquetasVinculadas && (
              <Box>
                <Typography sx={{ textAlign: 'center' }}>
                  Não há etiquetas para exibir
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{ textAlign: 'center', color: 'GrayText', mt: 1 }}
                >
                  Adicione uma nova etiqueta para começar a organizar os seus
                  negócios com eficiência
                </Typography>
              </Box>
            )}
            {temEtiquetasVinculadas && (
              <List
                sx={{
                  width: '100%',
                  maxWidth: 360,
                  bgcolor: 'background.paper',
                }}
              >
                {isLoadingVincularEtiquetas && <LinearProgress />}
                {!isLoadingVincularEtiquetas &&
                  etiquetasFiltradas?.map((etiquetaNaoVinculada) => {
                    return (
                      <ListItem
                        onMouseEnter={() =>
                          setHoverEtiquetaUuid(etiquetaNaoVinculada.uuid || '')
                        }
                        onMouseLeave={() => setHoverEtiquetaUuid(null)}
                        key={etiquetaNaoVinculada.uuid}
                        secondaryAction={
                          hoverEtiquetaUuid === etiquetaNaoVinculada.uuid && (
                            <>
                              {temPermissao('etiquetas.alter') && (
                                <IconButton
                                  edge="end"
                                  onClick={() =>
                                    handleClickEditarEtiqueta(
                                      etiquetaNaoVinculada,
                                    )
                                  }
                                >
                                  <Edit color="primary" />
                                </IconButton>
                              )}
                              {temPermissao('etiquetas.delete') && (
                                <IconButton
                                  onClick={() =>
                                    openDialog(etiquetaNaoVinculada.uuid)
                                  }
                                >
                                  <Delete color="primary" />
                                </IconButton>
                              )}
                            </>
                          )
                        }
                        disablePadding
                      >
                        <ListItemButton
                          sx={{
                            padding: 0.5,
                            ':hover': {
                              backgroundColor: 'transparent',
                            },
                          }}
                          onClick={() =>
                            handleVincularEtiquetaPedido(
                              etiquetaNaoVinculada.uuid || '',
                            )
                          }
                        >
                          <ListItemText
                            id={etiquetaNaoVinculada.uuid}
                            primary={
                              <Chip
                                sx={{
                                  fontWeight: 'bold',
                                  ml: 1,
                                  maxWidth: '85%',
                                }}
                                label={etiquetaNaoVinculada.nome}
                                size="small"
                              />
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                    )
                  })}
              </List>
            )}
            <Divider />
            {temPermissao('etiquetas.create') && (
              <Button onClick={handleClickAdicionarEtiqueta}>
                Adicionar Etiqueta
              </Button>
            )}
          </Stack>
        )}
      </Surface>

      <Dialog
        open={isOpenDialog}
        type="error"
        title="Excluir Registro Permanentemente?"
        maxWidth="xs"
        onClose={() => closeDialog()}
        actions={
          <ButtonContainer>
            <Button
              onClick={(e) => {
                e.stopPropagation()
                closeDialog()
              }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              color="error"
              isLoading={isDeletingEtiqueta}
              onClick={(e) => {
                e.stopPropagation()
                handleDeleteEtiqueta()
              }}
            >
              Excluir
            </Button>
          </ButtonContainer>
        }
      >
        Você não poderá recuperar este registro após excluí-lo!
      </Dialog>
    </Popover>
  )
}
