import { ChangeEvent } from 'react'

import { InputAdornment, TextField } from '@mui/material'
import { Search } from '@mui/icons-material'
import _ from 'lodash'

interface SearchTextFieldProps {
  placeholder?: string
  onSearch: (query: string) => void
}

export default function SearchTextField(props: SearchTextFieldProps) {
  const { onSearch, placeholder } = props

  // const theme = useTheme()

  const debouncedHandleChange = _.debounce((query) => onSearch(query), 500)

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const value = event.target.value
    debouncedHandleChange(value)
  }

  return (
    <TextField
      placeholder={placeholder}
      onChange={handleChange}
      autoFocus
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
      }}
      // sx={{
      //   '& .MuiOutlinedInput-root': {
      //     backgroundColor: theme.palette.background.paper,
      //     '& fieldset': {
      //       borderColor: 'transparent',
      //       borderBottomColor: theme.palette.primary.main,
      //       borderRadius: '4px',
      //     },
      //   },
      // }}
    />
  )
}
