import { Grid, TextField } from '@mui/material'

import { useFormApi, useFormData } from '@data-c/providers'
import { FormContainer, Checkbox } from '@data-c/ui'

import useClasseProdutos, {
  ClasseProdutosModel,
} from 'hooks/queries/useClasseProdutos'
import useValidations from 'hooks/useValidations'

import * as yup from 'yup'

const schema = yup.object().shape({
  nome: yup.string().required('Informe o Nome'),
})

export default function Form() {
  const { onChangeFormValue, toggleSubmit, closeForm } =
    useFormApi<ClasseProdutosModel>()
  const { formValues: data } = useFormData<ClasseProdutosModel>()
  const { setValidationErrors, validationProps } = useValidations()
  const { useSubmit } = useClasseProdutos()
  const { mutateAsync } = useSubmit()

  function handleSubmitForm(formValues: ClasseProdutosModel) {
    setValidationErrors(null)
    schema
      .validate(formValues, { abortEarly: false })
      .then(async () => {
        toggleSubmit(true)

        const newFormValues = {
          ...formValues,
          ativo: data?.ativo || false,
        }

        await mutateAsync(newFormValues)
        toggleSubmit(false)
        closeForm()
      })
      .catch((err) => {
        setValidationErrors(err)
      })
      .finally(() => {
        toggleSubmit(false)
      })
  }

  return (
    <FormContainer
      dialogProps={{
        maxWidth: 'sm',
        title: ' Classe de Produtos',
        disableRestoreFocus: true,
      }}
      onSubmitForm={handleSubmitForm}
    >
      <Grid container spacing={2}>
        <Grid item xl={2} lg={2} md={2} sm={3} xs={12}>
          <TextField
            name="codigo"
            label="Código"
            value={data?.codigo || ''}
            onChange={onChangeFormValue}
            disabled
          />
        </Grid>
        <Grid item xl={10} lg={10} md={10} sm={9} xs={12}>
          <TextField
            autoFocus
            name="nome"
            label="Nome"
            required
            value={data?.nome || ''}
            onChange={onChangeFormValue}
            {...validationProps('nome')}
          />
        </Grid>
        <Grid item xl={2} lg={2} md={2} sm={2} xs={12}>
          <Checkbox
            label="Ativo"
            name="ativo"
            onChange={onChangeFormValue}
            checked={Boolean(data?.ativo)}
          />
        </Grid>
      </Grid>
    </FormContainer>
  )
}
