import { formatCurrency, useForm, useValidations } from '@data-c/hooks'
import { Button, ButtonContainer, Dialog, LabelValue } from '@data-c/ui'
import { Grid } from '@mui/material'
import TextField from 'components/Inputs/TextField'
import MotivosDePerdaTransporter from 'components/Transporters/MotivosDePerdaTransporter'
import { MotivosDePerdaModel } from 'hooks/queries/useMotivosDePerda'
import {
  ItemPedidoModel,
  PerderItemModel,
  usePerderItem,
} from 'hooks/queries/usePedidos'
import { useEffect } from 'react'
import * as yup from 'yup'

const schema = yup.object().shape({
  motivoPerdaUuid: yup.string().ensure().required('Informe o Motivo de Perda'),
  observacaoMotivoPerda: yup
    .string()
    .ensure()
    .when('$informarObservacao', (informarObservacao, schema) => {
      if (informarObservacao) {
        return yup
          .string()
          .ensure()
          .required('Informe a Observação')
          .min(15, 'Descreva a observação usando pelo menos 15 caracteres')
      }
      return schema
    }),
})

export interface PerderItemFormProps {
  isOpen: boolean
  closeDialog: (e?: object, reason?: 'backdropClick' | 'escapeKeyDown') => void
  item?: ItemPedidoModel
}

export default function PerderItemForm(props: PerderItemFormProps) {
  const { isOpen, closeDialog, item } = props
  const { setValidationErrors, validationProps } = useValidations()
  const { data, handleChange, setData } = useForm<PerderItemModel>({
    item,
    motivoPerda: null,
    motivoPerdaUuid: null,
    observacaoMotivoPerda: '',
  })
  const { mutateAsync, isLoading: isSubmitting } = usePerderItem()

  useEffect(() => {
    if (isOpen) setValidationErrors(null)
    if (item) {
      setData({
        motivoPerda: null,
        motivoPerdaUuid: null,
        observacaoMotivoPerda: '',
        item,
      })
    }
  }, [isOpen])

  function handleSubmit() {
    setValidationErrors(null)
    schema
      .validate(data, {
        abortEarly: false,
        context: {
          informarObservacao: Boolean(data?.motivoPerda?.informarObservacao),
        },
      })
      .then(async () => {
        await mutateAsync({
          itemPedidoUuid: data?.item?.uuid || '',
          motivoPerdaUuid: data.motivoPerdaUuid || '',
          observacaoMotivoPerda: data.observacaoMotivoPerda,
        })
        closeDialog()
      })
      .catch((err) => {
        setValidationErrors(err)
      })
  }

  return (
    <Dialog
      actions={
        <ButtonContainer>
          <Button
            onClick={() => {
              closeDialog()
            }}
            color="error"
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            isLoading={isSubmitting}
            onClick={handleSubmit}
          >
            Perder Item
          </Button>
        </ButtonContainer>
      }
      maxWidth="sm"
      title="Perder Item"
      open={isOpen}
      onClose={closeDialog}
    >
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <LabelValue label="Produto">{item?.produto?.nome}</LabelValue>
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <LabelValue label="Quantidade">{item?.quantidade}</LabelValue>
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <LabelValue label="Preço">
            {formatCurrency(item?.precoUnitarioLiquido || 0)}
          </LabelValue>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <MotivosDePerdaTransporter
            name="motivoPerdaUuid"
            value={data?.motivoPerda || null}
            required={Boolean(!data?.observacaoMotivoPerda)}
            onChange={(value: MotivosDePerdaModel | null) => {
              setData({
                ...data,
                motivoPerda: value || null,
                motivoPerdaUuid: value?.uuid || '',
              })
            }}
            {...validationProps('motivoPerdaUuid')}
          />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            required={Boolean(data?.motivoPerda?.informarObservacao)}
            name="observacaoMotivoPerda"
            label="Observação"
            multiline
            rows={4}
            value={data?.observacaoMotivoPerda || ''}
            onChange={handleChange}
            {...validationProps('observacaoMotivoPerda')}
          />
        </Grid>
      </Grid>
    </Dialog>
  )
}
