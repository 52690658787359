import { useState } from 'react'
import {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios'
import mainApi from 'services/api'
import useNotifications from 'hooks/useNotifications'

interface ApiConfigs {
  api: AxiosInstance
  errorHandler: 'callback' | 'notification' | 'state' | 'disabled'
  errorCallback?: any
}

export default function useApi(
  configs: ApiConfigs = {
    errorHandler: 'notification',
    api: mainApi,
  },
) {
  const [isLoading, setLoading] = useState<boolean>(false)
  const [isSubmitting, setSubmitting] = useState<boolean>(false)
  const [isDeleting, setDeleting] = useState<boolean>(false)
  const [error, setError] = useState<string | Error | AxiosError | null>(null)
  //const [data, setData] = useState(null)
  const notifications = useNotifications()

  const { api } = configs

  function beforeRequest() {
    if (configs.errorHandler === 'state') {
      setError(null)
    }
  }

  const get = async (endpoint: string, data: any) => {
    beforeRequest()
    setLoading(true)
    try {
      const response = await api.get(endpoint, data)
      return response
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }

  const post = async <T = any, D = any, R = AxiosResponse<T>>(
    endpoint: string,
    data?: D,
    config?: AxiosRequestConfig<D>,
  ): Promise<R | undefined> => {
    try {
      beforeRequest()
      setSubmitting(true)
      const toReturn: any = await api.post(endpoint, data, config)
      return toReturn
    } catch (err) {
      handleError(err)
    } finally {
      setSubmitting(false)
    }
  }

  const put = async <T = any, D = any, R = AxiosResponse<T>>(
    endpoint: string,
    data?: D,
    config?: AxiosRequestConfig<D>,
  ): Promise<R | undefined> => {
    try {
      beforeRequest()
      setSubmitting(true)
      const toReturn: any = await api.put(endpoint, data, config)
      return toReturn
    } catch (err) {
      handleError(err)
    } finally {
      setSubmitting(false)
    }
  }

  const del = async <T = any, D = any, R = AxiosResponse<T>>(
    endpoint: string,
    config?: AxiosRequestConfig<D>,
  ): Promise<R | undefined> => {
    try {
      beforeRequest()
      setDeleting(true)
      const toReturn: any = await api.delete(endpoint, config)
      return toReturn
    } catch (err) {
      handleError(err)
    } finally {
      setDeleting(false)
    }
  }

  const handleError = (_error: any) => {
    if (configs?.errorHandler === 'disabled') {
      throw _error
    }

    if (configs?.errorHandler === 'callback') {
      configs?.errorCallback(_error)
      return
    }

    if (configs?.errorHandler === 'notification') {
      notifications.notifyException(_error)
      return
    }

    if (configs?.errorHandler === 'state') {
      setError(_error)
    }
  }

  return {
    instance: api,
    post,
    put,
    del,
    get,
    // data,
    isLoading,
    isSubmitting,
    isDeleting,
    error,
  }
}
