import { ReactNode } from 'react'
import { Timeline as MuiTimeline } from '@mui/lab'
import { Paper } from '@mui/material'
import EmptyData from 'components/Displays/EmptyData'

interface TimelineProps<T> {
  data: Array<T>
  render: (item: T) => ReactNode
}

export default function Timeline<T>(props: TimelineProps<T>) {
  const { render, data } = props

  return (
    <Paper sx={{ height: '100%' }}>
      <MuiTimeline
        sx={{
          height: '100%',
          margin: 0,
          padding: 2,
          overflow: 'auto',
        }}
      >
        <EmptyData
          error={
            data.length == 0 ? 'Ainda não há atividades por aqui' : undefined
          }
        />

        {data.map((item) => render(item))}
      </MuiTimeline>
    </Paper>
  )
}
