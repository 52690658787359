import { ReactElement } from 'react'
import { Chip } from '@mui/material'
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined'
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined'

interface StatusAtividade {
  status: 'ATRASADA' | 'AGORA' | 'PLANEJADA' | 'SEM_ATIVIDADE' | 'CONCLUIDA'
  disableBorder?: boolean
}

export default function StatusAtividade(props: StatusAtividade) {
  const { status, disableBorder } = props

  const configsMap: Record<
    string,
    {
      icon: ReactElement
      label: string
      borderColor: string
    }
  > = {
    ATRASADA: {
      icon: <AccessTimeOutlinedIcon color="error" />,
      label: 'Atrasada',
      borderColor: '#F44336',
    },
    AGORA: {
      icon: <AccessTimeOutlinedIcon color="success" />,
      label: 'Agora',
      borderColor: '#4CAF50',
    },
    PLANEJADA: {
      icon: <AccessTimeOutlinedIcon color="disabled" />,
      label: 'Futura',
      borderColor: 'rgba(0, 0, 0, 0.26)',
    },
    CONCLUIDA: {
      icon: <AccessTimeOutlinedIcon />,
      label: 'Concluída',
      borderColor: '#262626',
    },
    SEM_ATIVIDADE: {
      icon: <ReportProblemOutlinedIcon color="warning" />,
      label: 'Sem Atividade',
      borderColor: '#FFCD38',
    },
  }

  return (
    <Chip
      icon={configsMap[status].icon}
      variant="outlined"
      label={configsMap[status].label}
      size="small"
      sx={{
        padding: '3px',
        marginTop: '4px',
        borderColor: disableBorder
          ? 'rgba(0, 0, 0, 0)'
          : configsMap[status].borderColor,
      }}
    />
  )
}
