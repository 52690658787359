import { Popover, Stack } from '@mui/material'

import useValidations from 'hooks/useValidations'
import * as yup from 'yup'
import { Button, ButtonContainer, CurrencyTextField } from '@data-c/ui'
import useTabelaPreco, {
  DescontoAgregadoModel,
} from 'hooks/queries/useTabelaPreco'
import { PopOverOutputProps } from 'hooks/usePopover'
import useForm from 'hooks/useForm'

interface FormProps extends PopOverOutputProps {
  tabelaPrecoUuid?: string
}

export default function Form(props: FormProps) {
  const { isOpen, close, anchorEl, tabelaPrecoUuid } = props
  const { data, changeValue, setData } = useForm<DescontoAgregadoModel>({
    tipoPesoValor: 'valor',
    pdesconto: 0,
    pesoValorMinimo: 0,
    pesoValor: 0,
    uuidTabelaPreco: tabelaPrecoUuid || '',
  })
  /*
    Obter o label do campo de forma dinâmica no yup (pesoValor =  valor label = Valor; pesoValor =  peso label = peso)
  */
  const schema = yup.object().shape({
    pdesconto: yup.number().required('Informe um % de desconto'),
    pesoValorMinimo: yup
      .number()
      .required('Informe um desconto agregado mínimo'),
  })

  const { setValidationErrors, validationProps } = useValidations()

  const { useAdicionarDescontoAgregado } = useTabelaPreco()
  const { mutateAsync, isLoading } = useAdicionarDescontoAgregado()

  function handleSubmitForm() {
    setValidationErrors(null)
    schema
      .validate(data, { abortEarly: false })
      .then(async () => {
        await mutateAsync({ ...data, uuidTabelaPreco: tabelaPrecoUuid || '' })
        close()
        setData({
          tipoPesoValor: 'valor',
          pdesconto: 0,
          pesoValorMinimo: 0,
          pesoValor: 0,
          uuidTabelaPreco: tabelaPrecoUuid || '',
        })
      })
      .catch((err) => {
        setValidationErrors(err)
      })
  }

  return (
    <Popover
      disableRestoreFocus={true}
      open={isOpen}
      onClose={close}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <Stack spacing={2} sx={{ m: 2, minWidth: '220px' }}>
        <CurrencyTextField
          autoFocus
          name="pdesconto"
          label="Desconto(%)"
          required
          value={data?.pdesconto || ''}
          onChange={(_: any, value: string) => changeValue('pdesconto', value)}
          {...validationProps('pdesconto')}
        />

        <CurrencyTextField
          name="pesoValorMinimo"
          label="Valor Mínimo"
          value={data?.pesoValorMinimo || ''}
          onChange={(_: any, value: string) =>
            changeValue('pesoValorMinimo', value)
          }
          {...validationProps('pesoValorMinimo')}
        />

        <ButtonContainer>
          <Button onClick={() => close()}>Cancelar</Button>
          <Button
            isLoading={isLoading}
            variant="contained"
            onClick={handleSubmitForm}
          >
            Salvar
          </Button>
        </ButtonContainer>
      </Stack>
    </Popover>
  )
}

Form.defaultProps = {
  data: {},
}
