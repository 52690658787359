import { useMemo } from 'react'

import { MUIDataTableColumnDef } from 'mui-datatables'

import { CurrencyCellStyle, DataTable } from '@data-c/ui'

import { useFilterApi, useFilterData } from '@data-c/providers'
import Surface from 'components/Surface'
import { Box, Grid } from '@mui/material'
import CardValue from 'components/Indicadores/CardValue'
import useRelatorioComparacaoPerformanceProdutos, {
  RelatorioComparacaoPerformanceProdutosFilters,
  RelatorioComparacaoPerformanceProdutosFilters1,
} from 'hooks/queries/useRelatorioComparacaoPerformanceProdutos'
import { formatCurrency } from '@data-c/hooks'
import AddFirst from 'components/Displays/AddFirst'

export default function Table() {
  const { openFilter } = useFilterApi()

  const { appliedValues, searchId } = useFilterData<
    RelatorioComparacaoPerformanceProdutosFilters,
    RelatorioComparacaoPerformanceProdutosFilters1
  >()
  const { representante, dateRange, mix1, mix2, ...rest } = appliedValues || {}
  const nFilters: RelatorioComparacaoPerformanceProdutosFilters1 = {
    ...rest,
    representanteUuid: representante?.uuid,
    dataInicial: dateRange?.firstDate,
    dataFinal: dateRange?.secondDate,
    mix1Uuid: mix1?.uuid,
    mix2Uuid: mix2?.uuid,
  }
  const { useQueryObterRelatorioComparacaoPerformanceProdutos } =
    useRelatorioComparacaoPerformanceProdutos()
  const { data, isLoading, isFetching, error } =
    useQueryObterRelatorioComparacaoPerformanceProdutos(nFilters, searchId)

  const dataFormatadaMix1 = useMemo(
    () =>
      data?.mix1?.produtos?.map((item) => {
        return {
          ...item,
          codigoProduto: item.codigo,
          nomeProduto: item.nome,
          metrica: formatCurrency(item.metrica),
        }
      }),
    [data],
  )

  const dataFormatadaMix2 = useMemo(
    () =>
      data?.mix2?.produtos?.map((item) => {
        return {
          ...item,
          codigoProduto: item.codigo,
          nomeProduto: item.nome,
          metrica: formatCurrency(item.metrica),
        }
      }),
    [data],
  )

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'codigoProduto',
        label: 'Código',
        options: {
          setCellProps() {
            return { width: '96px' }
          },
        },
      },
      {
        name: 'nomeProduto',
        label: 'Nome',
      },
      {
        name: 'metrica',
        label:
          appliedValues?.tipoDeCalculo === 'pesoliquido' ? 'Peso' : 'Valor',
        options: {
          setCellProps: CurrencyCellStyle,
        },
      },
    ],
    [data],
  )

  return (
    <>
      <Surface>
        {mix1?.uuid && mix2?.uuid ? (
          <>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <CardValue
                elevation={2}
                label="Resultado"
                value={`${formatCurrency(data?.percentual || 0)}%`}
              />
            </Grid>
            <Grid container spacing={2} sx={{ mt: 1, mb: 1 }}>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <Box sx={{ mb: 1 }}>
                  <CardValue
                    elevation={2}
                    label={data?.mix1.nomeMix || ''}
                    value={
                      appliedValues?.tipoDeCalculo === 'pesoliquido'
                        ? `${formatCurrency(data?.mix1.total || 0)}Kg`
                        : `R$${formatCurrency(data?.mix1.total || 0)}`
                    }
                  />
                </Box>
                <DataTable
                  error={error?.response?.data.message}
                  columns={columns}
                  data={dataFormatadaMix1 || []}
                  isLoading={isLoading}
                  isFetching={isFetching}
                />
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <Box sx={{ mb: 1 }}>
                  <CardValue
                    elevation={2}
                    label={data?.mix2.nomeMix || ''}
                    value={
                      appliedValues?.tipoDeCalculo === 'pesoliquido'
                        ? `${formatCurrency(data?.mix2.total || 0)}Kg`
                        : `R$${formatCurrency(data?.mix2.total || 0)}`
                    }
                  />
                </Box>
                <DataTable
                  error={error?.response?.data.message}
                  columns={columns}
                  data={dataFormatadaMix2 || []}
                  isLoading={isLoading}
                  isFetching={isFetching}
                />
              </Grid>
            </Grid>
          </>
        ) : (
          <AddFirst
            buttonLabel="Selecionar Mix"
            primaryMessage="Selecione os mix de produtos"
            secondaryMessage={
              <div style={{ textAlign: 'center' }}>
                Para gerar o relatório, é necessário selecionar o Mix de
                Produtos 1 e 2.
                <br /> Essa ação garante que todas as informações relevantes
                sejam incluídas no relatório final.
              </div>
            }
            onClick={(_) => {
              openFilter()
            }}
          />
        )}
      </Surface>
    </>
  )
}
