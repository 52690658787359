import { useState } from 'react'

export interface DialogStateProps<T> {
  isOpen: boolean
  data?: T
}

interface UseDialogProps<T> {
  isOpen: boolean
  data?: T
  closeDialog: (e?: object, reason?: 'backdropClick' | 'escapeKeyDown') => void
  openDialog: (data?: T) => void
}

interface UseDialogConfigProps {
  backdropClickDisabled: boolean
}

export default function useDialog<T>(configs?: UseDialogConfigProps): UseDialogProps<T> {
  const { backdropClickDisabled } = configs || { backdropClickDisabled: false }

  const [dialog, setDialog] = useState<DialogStateProps<T>>({
    isOpen: false,
    // data: null,
  })

  function closeDialog(_?: object, reason?: 'backdropClick' | 'escapeKeyDown') {
    if (backdropClickDisabled && reason === 'backdropClick') {
      return
    }
    setDialog((oldState) => ({
      ...oldState,
      isOpen: false,
    }))
  }

  function openDialog(data?: T) {
    setDialog(() => ({
      isOpen: true,
      data,
    }))
  }

  return { isOpen: dialog.isOpen, data: dialog.data, closeDialog, openDialog }
}
