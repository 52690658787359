import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import useCrud, { generateEntityKey } from 'hooks/useCrud'
import useNotification from 'hooks/useNotifications'
import api from 'services/api'
import { PedidoModel } from './usePedidos'

export interface EtapaModel {
  uuid?: string
  nome: string
  posicao: number
  negociacoes: Array<PedidoModel>
  funilVendaUuid?: string
}

export async function atualizarPosicaoDaEtapa(
  direction: 'up' | 'down',
  etapaUuid: string,
  funilUuid: string,
): Promise<EtapaModel> {
  const response = await api.put(
    `crm/funil-venda/${funilUuid}/etapa/${etapaUuid}/alterar-posicao`,
    {
      direction,
    },
  )
  return response.data
}

export function useAtualizarPosicaoDaEtapa() {
  const notifications = useNotification()
  const queryClient = useQueryClient()
  return useMutation<
    EtapaModel,
    AxiosError,
    {
      etapaUuid: string
      funilUuid: string
      direction: 'up' | 'down'
    }
  >(
    (data) =>
      atualizarPosicaoDaEtapa(data.direction, data.etapaUuid, data.funilUuid),
    {
      onSuccess(_: EtapaModel) {
        // notifications.notifySuccess(`Posição da etapa alterada`)
        const key = generateEntityKey('Etapa')
        queryClient.invalidateQueries([key])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export async function salvarOrdenacao(
  data: Array<Pick<EtapaModel, 'uuid' | 'posicao'>>,
  funilUuid: string,
): Promise<EtapaModel> {
  const response = await api.put(
    `crm/funil-venda/${funilUuid}/salvar-ordenacao-etapa`,
    data,
  )
  return response.data
}

export function useSalvarOrdenacao() {
  // const notifications = useNotification()
  // const queryClient = useQueryClient()
  return useMutation<
    any,
    AxiosError,
    {
      data: Array<Pick<EtapaModel, 'uuid' | 'posicao'>>
      funilUuid: String
    }
  >((data) => salvarOrdenacao(data.data, data.funilUuid as string), {
    // onSuccess(_: EtapaModel) {
    //   // notifications.notifySuccess(`Posição da etapa alterada`)
    //   // const key = generateEntityKey('Etapa')
    //   // queryClient.invalidateQueries([key])
    // },
    // onError(error) {
    //   // notifications.notifyException(error)
    // },
  })
}

export default function useFunilVendaEtapa(funilUuid: string) {
  return {
    ...useCrud<EtapaModel>(
      `crm/funil-venda/${funilUuid}/etapa`,
      'Etapa',
      'female',
      'uuid',
      {
        disableDeleteNotifications: true,
      },
    ),
    useAtualizarPosicaoDaEtapa,
  }
}
