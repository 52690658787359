import { useMemo, useState } from 'react'

import { Button, DataTable, Flag } from '@data-c/ui'
import { usePagination } from '@data-c/hooks'

import { MUIDataTableColumnDef } from 'mui-datatables'

import { formatToCPFOrCNPJ } from 'brazilian-values'
import useVendedores from 'hooks/queries/useVendedores'
import { SortProps } from 'interfaces/SortProps'
import Surface from 'components/Surface'

export interface TableProps {
  vendedorUuid: string
}

function temClientesSelecionados(
  indexClientesSelecionados: Array<string | undefined>,
): boolean {
  return indexClientesSelecionados.length > 0
}

export default function Table(props: TableProps) {
  const { vendedorUuid } = props

  const [sort, setSort] = useState<SortProps>({
    column: 'usual',
    direction: 'asc',
  })
  const [indexClientesSelecionados, setIndexClientesSelecionados] = useState<
    number[]
  >([])

  const { changePageSize, changePage, pagination } = usePagination()
  const { useQueryObterClientesVinculados, useDesvincularClientes } =
    useVendedores()
  const {
    data: clientesVinculados,
    isLoading,
    isFetching,
    error,
  } = useQueryObterClientesVinculados(
    {
      pagination,
      vendedorUuid,
    },
    sort,
  )
  const {
    mutateAsync: desvincularClientes,
    isLoading: isLoadingDesvincularClientes,
  } = useDesvincularClientes()

  const formattedData = useMemo(
    () =>
      clientesVinculados?.data?.map((clienteVinculado) => {
        return {
          ...clienteVinculado,
          cnpjcpf: clienteVinculado.cnpjcpf
            ? formatToCPFOrCNPJ(clienteVinculado.cnpjcpf)
            : '',
        }
      }),
    [clientesVinculados],
  )

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'codigo',
        label: 'Código',
        options: {
          setCellProps() {
            return { width: '96px' }
          },
        },
      },
      {
        name: 'cnpjcpf',
        label: 'CNPJ/CPF',
        options: {
          sort: false,
        },
      },
      {
        name: 'nome',
        label: 'Razão Social',
      },
      {
        name: 'usual',
        label: 'Nome Fatasia',
      },
      {
        name: 'ativo',
        label: 'Ativo',
        options: {
          setCellProps: () => ({
            style: {
              width: '60px',
              textAlign: 'center',
            },
          }),
          customBodyRender: (value) => {
            return <Flag isFlagged={value === 1} type="success" />
          },
        },
      },
    ],
    [],
  )

  async function handleDesvincularClientes() {
    const clientesSelecionadosIds = indexClientesSelecionados.map(
      (index) => clientesVinculados?.data[index]?.uuid || '',
    )

    if (vendedorUuid && temClientesSelecionados(clientesSelecionadosIds)) {
      try {
        await desvincularClientes({
          vendedorUuid,
          clientesSelecionadosIds,
        })
      } finally {
        setIndexClientesSelecionados([])
      }
    }
  }

  return (
    <Surface>
      <DataTable
        error={error?.message}
        isLoading={isLoading}
        isFetching={isFetching}
        columns={columns}
        data={formattedData || []}
        pagination={clientesVinculados?.pagination}
        changePage={changePage}
        changePageSize={changePageSize}
        options={{
          rowsPerPageOptions: [15, 50, 100],
          rowsPerPage: 15,
          sort: true,
          sortOrder: {
            name: sort.column,
            direction: sort.direction,
          },
          selectableRows: 'multiple',
          selectableRowsOnClick: true,
          rowsSelected: indexClientesSelecionados,
          onRowSelectionChange: (_, __, rowsSelected: any) =>
            setIndexClientesSelecionados(rowsSelected),
          customToolbarSelect() {
            return (
              <Button
                color="error"
                isLoading={isLoadingDesvincularClientes}
                onClick={handleDesvincularClientes}
                sx={{ marginRight: '26px' }}
              >
                Desvincular clientes
              </Button>
            )
          },
        }}
        changeSort={(column, direction) => {
          setSort({ column, direction: direction as 'asc' | 'desc' })
        }}
      />
    </Surface>
  )
}
