import {
  ListItem,
  ListItemButton as MuiListItem,
  styled,
  ListItemText,
  ListItemIcon,
  Collapse,
  List,
  Icon as MuiIcon,
} from '@mui/material'
import { ReactNode, useState } from 'react'
import Icon from '../Icon'
import { MenuItemProps } from './MenuItem'

const ListItemButton = styled(
  MuiListItem,
  {},
)(({ theme }) => ({
  height: '32px',
  cursor: 'pointer',
  gap: theme.spacing(2),
  transition: '0.2s ease-in-out',
  padding: `${theme.spacing(0.25)} ${theme.spacing(2)} ${theme.spacing(
    0.25,
  )} ${theme.spacing(2)}`,
  '&:hover': {
    backgroundColor: 'rgba(89, 195, 224, 15%);',
    '& .MuiTypography-root': {
      color: theme.palette.primary.main,
      transition: '0.2s ease-in-out',
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.main,
      transition: '0.2s ease-in-out',
    },
    '& .MuiIcon-root': {
      color: theme.palette.primary.main,
      transition: '0.2s ease-in-out',
    },
  },
  '& .MuiIcon-root': {
    color: theme.palette.grey[500],
    fontSize: '20px',
  },
  '& .MuiTypography-root': {
    // fontWeight: 'bolder',
    fontSize: '0.805rem',
    color: theme.palette.text.primary,
  },
  '& .MuiSvgIcon-root': {
    fontSize: '0.955rem',
  },
  '& .MuiListItemIcon-root': {
    minWidth: '0px',
  },
}))

export default function FullMenuItem({
  label,
  icon,
  visible = true,
  children: childrenProp,
  selected = false,
  onClick,
  link,
}: MenuItemProps) {
  if (!visible) return null

  const [expanded, setExpanded] = useState(false)
  const isExpandable: boolean = Boolean(childrenProp)

  function handleClick() {
    if (isExpandable) {
      setExpanded((p) => !p)
      return
    }
    if (onClick && link) {
      onClick(link)
    }
  }

  function renderIcon(_icon: ReactNode) {
    if (typeof icon === 'string') {
      return <MuiIcon>{icon}</MuiIcon>
    }
    return _icon ? _icon : <Icon.Circle />
  }

  return (
    <>
      <ListItem disableGutters disablePadding>
        <ListItemButton
          selected={selected}
          disableGutters
          onClick={(e) => {
            e.stopPropagation()
            handleClick()
          }}
        >
          <ListItemIcon>{renderIcon(icon)}</ListItemIcon>
          {<ListItemText primary={label} />}
          {isExpandable && <Icon.Expandable open={expanded} />}
        </ListItemButton>
      </ListItem>
      {isExpandable && (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <List
            sx={{
              '& .MuiListItemButton-root': {
                paddingLeft: 4, // Padding inicial para o primeiro nível
              },
              '&  .MuiList-root > .MuiListItem-root > .MuiListItemButton-root':
                {
                  paddingLeft: 6, // Segundo nível de aninhamento
                },
              '& .MuiList-root > .MuiList-root > .MuiListItemButton-root': {
                paddingLeft: 12, // Terceiro nível de aninhamento
              },
              // Continue adicionando níveis conforme necessário
            }}
            disablePadding
          >
            {childrenProp}
          </List>
        </Collapse>
      )}
    </>
  )
}
