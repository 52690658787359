import { useState } from 'react'

import { findData } from '@data-c/hooks'
import { useFormApi } from '@data-c/providers'
import { Button, ButtonContainer } from '@data-c/ui'

import { Box, Container } from '@mui/material'

import useCrmAtividades, {
  CrmAtividadesModel,
  TipoAtividade,
  tipoAtividadeIconMap,
  tipoAtividadeMap,
  useQueryObterAtividadePorPedidoId,
} from 'hooks/queries/useCrmAtividades'
import { PedidoModel } from 'hooks/queries/usePedidos'

import CrmAtividadesForm from 'pages/CrmAtividades/components/Form'
import CrmAtividadesTable from './components/Table'

import TimelineContent from 'components/Timeline/components/TimelineContent'
import Timeline from 'components/Timeline'
import Loading from 'components/Displays/Loading'
import Delete from 'components/Delete'
import usePopOver from 'hooks/usePopover'
import { TableView, ViewTimeline } from '@mui/icons-material'
import ToggleViewContainer from 'components/ToggleViewContainer'
import ToggleViewButton from 'components/ToggleViewContainer/Components/ToggleViewButton'

interface TabAtividadesProps {
  pedido?: PedidoModel
}

function LeftContent(props: { item: CrmAtividadesModel }) {
  const { item } = props
  return (
    <Box>
      <span>{item.dataAtividadeFormatted}</span>
      <br />
      <span>
        {item.diaInteiro ? 'Dia inteiro' : `${item.horaInicio} - ${item.horaFim}`}
      </span>
    </Box>
  )
}

export default function TabAtividades(props: TabAtividadesProps) {
  const { pedido } = props

  const [viewPage, setViewPage] = useState('timeline')

  const { openForm } = useFormApi()
  const { open, close, anchorEl, isOpen } = usePopOver()
  const { data, isLoading, isFetching, error } =
    useQueryObterAtividadePorPedidoId(pedido?.uuid || '')
  const { useDelete } = useCrmAtividades()
  const { mutateAsync: handleDelete } = useDelete()

  if (isLoading)
    return (
      <Container>
        <Loading />
      </Container>
    )

  function handleOpenEditForm(atividadeUuid: string) {
    const _data = findData<CrmAtividadesModel>(
      data || [],
      atividadeUuid,
      'uuid',
    )
    openForm(_data)
  }

  function changePageView(_viewPage: string) {
    setViewPage(_viewPage)
    close()
  }

  const atividades = Array.isArray(data)
    ? data.map((d) => {
        return {
          ...d,
          tipoAtividadeAsText:
            tipoAtividadeMap[d?.tipoAtividade || TipoAtividade.TODAS],
          tipoAtividadeIcon: tipoAtividadeIconMap[d?.tipoAtividade || ''],
        }
      })
    : ([] as CrmAtividadesModel[])

  return (
    <Box sx={{ height: `calc(100vh - 207px)` }}>
      <ButtonContainer sx={{ mb: 2 }}>
        <Button onClick={open}>Visualização</Button>
        <CrmAtividadesForm pedido={pedido} />
      </ButtonContainer>

      {viewPage === 'timeline' && (
        <Timeline
          data={atividades}
          render={(item: CrmAtividadesModel) => {
            return (
              <TimelineContent
                leftContent={<LeftContent item={item} />}
                icon={item?.tipoAtividadeIcon || ''}
                completed={item.concluido}
                title={item?.tipoAtividadeAsText || ''}
                description={item?.assunto || 'Sem assunto'}
                onClick={() => handleOpenEditForm(item?.uuid || '')}
                statusAtividade={item?.statusAtividade}
              />
            )
          }}
        />
      )}

      {viewPage === 'table' && (
        <CrmAtividadesTable
          data={atividades}
          error={error?.message}
          isLoading={isLoading}
          isFetching={isFetching}
        />
      )}

      <ToggleViewContainer anchorEl={anchorEl} isOpen={isOpen} close={close}>
        <ToggleViewButton
          label="Timeline"
          icon={<ViewTimeline fontSize="medium" />}
          active={viewPage === 'timeline'}
          onClick={() => changePageView('timeline')}
        />

        <ToggleViewButton
          label="Tabela"
          icon={<TableView fontSize="medium" />}
          active={viewPage === 'table'}
          onClick={() => changePageView('table')}
        />
      </ToggleViewContainer>
      <Delete onDelete={handleDelete} />
    </Box>
  )
}
