import { Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

interface DialogObsClienteProps {
  openDialog: boolean
  observacao: string
  setOpenDialog: (isOpen: boolean) => void
  onSubmit: () => void
}

export default function DialogObsCliente(props: DialogObsClienteProps) {
  const { openDialog, observacao, setOpenDialog, onSubmit } = props

  const handleClose = () => {
    setOpenDialog(false)
  }

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      aria-labelledby="observacao-cliente"
      aria-describedby="observacao-cliente"
    >
      <DialogTitle id="observacao-cliente-title">
        <Typography fontSize={18} fontWeight={'bold'}>
          Observação do cliente
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="observacao-cliente-description">
          <Typography>{observacao}</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined" color="error">
          Fechar
        </Button>
        <Button onClick={onSubmit} variant="contained" autoFocus>
          Iniciar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
