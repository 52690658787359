import { Box, Switch } from '@mui/material'
import CardConfig from 'components/CardConfig'
import { useRoleContext } from 'components/Contexts/RoleContext'

export default function Etiquetas() {
  const { role, changeRolePermission } = useRoleContext()
  const { etiquetas } = role?.permissoes || {}

  return (
    <Box>
      <CardConfig
        title="Acesso (Ver)"
        description={
          <div>
            Define o nível de acesso que o usuário possui para poder ver os
            registros (as regras se aplicam aos relatórios)
          </div>
        }
        component={
          <Switch
            name="etiquetas.read"
            checked={etiquetas?.read || ''}
            onChange={changeRolePermission}
            disabled={Boolean(role?.fixa)}
          />
        }
      />
      <CardConfig
        title="Acesso (Criar)"
        description={
          <div>
            Define o nível de acesso que o usuário possui para poder criar o
            registro
          </div>
        }
        component={
          <Switch
            name="etiquetas.create"
            checked={etiquetas?.create || ''}
            onChange={changeRolePermission}
            disabled={Boolean(role?.fixa)}
          />
        }
      />
      <CardConfig
        title="Acesso (Alterar)"
        description={
          <div>
            Define o nível de acesso que o usuário possui para poder alterar os
            registros
          </div>
        }
        component={
          <Switch
            name="etiquetas.alter"
            checked={etiquetas?.alter || ''}
            onChange={changeRolePermission}
            disabled={Boolean(role?.fixa)}
          />
        }
      />
      <CardConfig
        title="Acesso (Deletar)"
        description={
          <div>
            Define o nível de acesso que o usuário possui para poder deletar os
            registros
          </div>
        }
        component={
          <Switch
            name="etiquetas.delete"
            checked={etiquetas?.delete || ''}
            onChange={changeRolePermission}
            disabled={Boolean(role?.fixa)}
          />
        }
      />
      <CardConfig
        title="Vincular"
        description={
          <div>
            Define se o usuário pode realizar o vínculo entre etiquetas e
            negociação
            <strong>
              (O usuário vai poder ver todos as etiquetas cadastradas no
              sistema)
            </strong>
          </div>
        }
        component={
          <Switch
            name="etiquetas.vincular"
            checked={Boolean(etiquetas?.vincular)}
            onChange={changeRolePermission}
            disabled={Boolean(role?.fixa)}
          />
        }
      />
    </Box>
  )
}
