import { Container } from '@mui/material'

import { FilterProvider, FormProvider } from '@data-c/providers'
import { ButtonContainer, PageTitle } from '@data-c/ui'

import Delete from 'components/Delete'
import Form from './components/Form'
import { filters } from 'hooks/queries/useFunilVenda'
import Content from 'components/Layouts/Content'
import useFunilVenda from 'hooks/queries/useFunilVenda'
import Filter from './components/Filter'
import Table from './components/Table'
import { useNavigate } from 'react-router-dom'

export default function FunisDeVendas() {
  const { useDelete } = useFunilVenda()
  const { mutateAsync: handleDelete } = useDelete()
  const navigate = useNavigate()
  return (
    <Container>
      <FormProvider initialFormValues={{ ativo: true }}>
        <FilterProvider filterValues={filters}>
          <PageTitle
            onBack={() => {
              navigate(-1)
            }}
            title="Funil de Vendas"
          >
            <ButtonContainer>
              <Filter />
              <Form />
            </ButtonContainer>
          </PageTitle>
          <Content>
            <Table />
          </Content>
        </FilterProvider>
        <Delete onDelete={handleDelete} />
      </FormProvider>
    </Container>
  )
}
