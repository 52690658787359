import { ButtonContainer, PageTitle } from '@data-c/ui'
import { Box, Container } from '@mui/material'

import ClientesTransporter from 'components/Transporters/ClientesTransporter'
import { useState } from 'react'
import { ClientesModel } from 'hooks/queries/useClientes'
import Table from './components/Table'
import DatePicker from 'components/DatePicker'
import Content from 'components/Layouts/Content'

export default function FichaClientes() {
  const [cliente, setCliente] = useState<ClientesModel | null>(null)
  const [dataInicio, setDataInicio] = useState<string | null>(null)
  return (
    <Container>
      <PageTitle title="Ficha do Cliente">
        <ButtonContainer>
          <Box sx={{ minWidth: '300px' }}>
            <ClientesTransporter
              label="Clientes"
              placeholder="Selecione um cliente para gerar o relatório"
              autoFocus
              value={cliente}
              onChange={(clienteSelecionado) => {
                setCliente(clienteSelecionado)
              }}
            />
          </Box>
          <Box sx={{ width: '156px' }}>
            <DatePicker
              label="Data Início"
              onChange={(dataInicio) => {
                setDataInicio(dataInicio)
              }}
              value={dataInicio || null}
            />
          </Box>
        </ButtonContainer>
      </PageTitle>
      <Content>
        <Table cliente={cliente} dataInicio={dataInicio} />
      </Content>
    </Container>
  )
}
