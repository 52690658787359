import { ReactNode } from 'react'
import { Box } from '@mui/material'

interface CardContainerProps {
  height?: string
  children: ReactNode
}

export default function CardContainer(props: CardContainerProps) {
  const { height, children } = props

  return (
    <Box
      sx={{
        minHeight: !height ? '100%' : height,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 3,
        backgroundColor: 'background.paper',
        padding: 3,
        borderRadius: 3,
      }}
    >
      {children}
    </Box>
  )
}
