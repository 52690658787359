import { ButtonContainer } from '@data-c/ui'
import { Box } from '@mui/material'
import Form from '../../../../components/EntityLinkerPessoa/Form'
import Table from '../../../../components/EntityLinkerPessoa/Table'
import { EntidadeEnum } from 'hooks/queries/usePessoa'

interface TabCondicaoDePagamentoProps {
  pessoaUuid: string
}

export default function TabCondicaoDePagamento(
  props: TabCondicaoDePagamentoProps,
) {
  return (
    <Box>
      <ButtonContainer sx={{ mb: 2 }}>
        <Form
          pessoaUuid={props.pessoaUuid}
          titulo="Condições de Pagamento"
          entidade={EntidadeEnum.CONDICAO_PAGAMENTO}
          situacaoPessoa="CLIENTE"
        />
      </ButtonContainer>
      <Table
        pessoaUuid={props.pessoaUuid}
        titulo="Condições de Pagamento"
        entidade={EntidadeEnum.CONDICAO_PAGAMENTO}
        situacaoPessoa="CLIENTE"
      />
    </Box>
  )
}
