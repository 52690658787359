import {
  DatePickerProps,
  DatePicker as MuiDatePicker,
} from '@mui/x-date-pickers'
import { DateTime } from 'luxon'

interface IDatePickerProps
  extends Omit<DatePickerProps<DateTime>, 'onChange' | 'value'> {
  error?: boolean | string | null
  onChange: (date: string | null) => void
  value?: string | null
  fromFormat?: string
}

export default function DatePicker(props: IDatePickerProps) {
  const { value, error, onChange, fromFormat, format, ...rest } = props

  function handleChange(date: DateTime | null) {
    if (date?.isValid) {
      onChange(date?.toFormat('yyyy-MM-dd'))
      return
    }

    if (date === null) {
      onChange(null)
      return
    }
  }

  return (
    <MuiDatePicker
      format={format || 'dd/MM/yyyy'}
      value={DateTime.fromFormat(value || '', fromFormat || 'yyyy-MM-dd')}
      onChange={(v: DateTime | null) => handleChange(v)}
      slotProps={{
        textField: {
          error: Boolean(error),
        },
      }}
      {...rest}
    />
  )
}
