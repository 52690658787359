import { Box, Switch } from '@mui/material'
import CardConfig from 'components/CardConfig'
import { useRoleContext } from 'components/Contexts/RoleContext'
import DropDownAcessoNegociacoes from 'pages/Role/components/DropDownAcessoNegociacoes'

export default function FunilDeVenda() {
  const { role, changeRolePermission } = useRoleContext()
  const { crm_funil_venda } = role?.permissoes || {}

  return (
    <Box>
      <CardConfig
        title="Acesso (Ver)"
        description={
          <div>
            Define o nível de acesso que o usuário possui para poder ver os
            Funis de Vendas (as regras se aplicam aos relatórios)
          </div>
        }
        component={
          <DropDownAcessoNegociacoes
            name="crm_funil_venda.read"
            value={crm_funil_venda?.read || ''}
            onChange={changeRolePermission}
            disabled={Boolean(role?.fixa)}
          />
        }
      />
      <CardConfig
        title="Acesso (Criar)"
        description={
          <div>
            Define o nível de acesso que o usuário possui para poder criar os
            Funis de Vendas
          </div>
        }
        component={
          <Switch
            name="crm_funil_venda.create"
            checked={crm_funil_venda?.create || ''}
            onChange={changeRolePermission}
            disabled={Boolean(role?.fixa)}
          />
        }
      />
      <CardConfig
        title="Acesso (Alterar)"
        description={
          <div>
            Define o nível de acesso que o usuário possui para poder alterar os
            Funis de Vendas
          </div>
        }
        component={
          <Switch
            name="crm_funil_venda.alter"
            checked={crm_funil_venda?.alter || ''}
            onChange={changeRolePermission}
            disabled={Boolean(role?.fixa)}
          />
        }
      />
      <CardConfig
        title="Acesso (Deletar)"
        description={
          <div>
            Define o nível de acesso que o usuário possui para poder deletar os
            Funis de Vendas
          </div>
        }
        component={
          <Switch
            name="crm_funil_venda.delete"
            checked={crm_funil_venda?.delete || ''}
            onChange={changeRolePermission}
            disabled={Boolean(role?.fixa)}
          />
        }
      />
    </Box>
  )
}
