import { Button, ButtonContainer, Dialog } from '@data-c/ui'
import { Close } from '@mui/icons-material'
import { Box, IconButton, Typography } from '@mui/material'
import Divider from 'components/Divider'
import useIndicador, { IndicadorModel } from 'hooks/queries/useIndicador'
import { indicadoresMap } from '../IndicadoresPopover'
import { useState } from 'react'
import { useDialog } from '@data-c/hooks'

interface IndicadorCardProps {
  indicador: IndicadorModel
  setIsDraggable: (draggable: boolean) => void
}

export default function IndicadorCard(props: IndicadorCardProps) {
  const { indicador, setIsDraggable } = props

  const { closeDialog, isOpen: isOpenDialog, openDialog } = useDialog()

  const { useDeleteIndicador } = useIndicador()
  const { mutateAsync: deleteIndicador, isLoading: isDeleting } =
    useDeleteIndicador()

  const [isMouseEnter, setIsMouseEnter] = useState(false)

  async function handleDeleteIndicador(uuid: string) {
    if (uuid) {
      await deleteIndicador(uuid)
    }
  }

  return (
    <>
      <Box
        onMouseEnter={() => setIsMouseEnter(true)}
        onMouseLeave={() => setIsMouseEnter(false)}
        sx={{ height: '100%' }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{ display: 'flex', gap: 2, alignItems: 'center', height: 30 }}
          >
            <Typography
              sx={{
                fontSize: '12pt',
                fontWeight: 'bolder',
                color: '#666',
                ml: 1,
              }}
            >
              {indicadoresMap[indicador.nome]?.nome}
            </Typography>
          </Box>
          {isMouseEnter && (
            <ButtonContainer>
              <IconButton
                onMouseEnter={() => setIsDraggable(false)}
                onMouseLeave={() => setIsDraggable(true)}
                onClick={(e) => {
                  e.stopPropagation()
                  openDialog()
                }}
              >
                <Close />
              </IconButton>
            </ButtonContainer>
          )}
        </Box>
        <Divider />
        {indicadoresMap[indicador.nome]?.componente}
      </Box>

      <Dialog
        open={isOpenDialog}
        type="error"
        title="Excluir Indicador Permanentemente?"
        maxWidth="xs"
        onClose={() => closeDialog()}
        actions={
          <ButtonContainer>
            <Button
              onClick={(e) => {
                e.stopPropagation()
                closeDialog()
              }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              color="error"
              isLoading={isDeleting}
              onClick={(e) => {
                e.stopPropagation()
                handleDeleteIndicador(indicador?.uuid || '')
              }}
            >
              Excluir
            </Button>
          </ButtonContainer>
        }
      >
        Você não poderá recuperar este indicador após excluí-lo!
      </Dialog>
    </>
  )
}
