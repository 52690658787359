import { Box } from '@mui/material'
import { ReactNode, useEffect } from 'react'
import Fallback from 'components/Fallback'
import * as Sentry from '@sentry/react'
import { useCredentials } from '@data-c/hooks'
import credentialsConfig from 'configs/credentials'
import Unauthorized from 'components/Displays/Unauthorized'
import { useUserContext } from 'components/Contexts/UserContext'
interface MLayoutProps {
  children: ReactNode
  isProtected: boolean
}

const toolBarHeight = 48

export default function MLayout(props: MLayoutProps) {
  const { children, isProtected } = props
  const { isAuthenticated } = useCredentials(credentialsConfig)
  const { loadUser, loadConfiguracoes, isLoading } = useUserContext()

  if (isProtected && !isAuthenticated && !isLoading) {
    return <Unauthorized />
  }

  useEffect(() => {
    if (isAuthenticated) {
      loadUser()
      loadConfiguracoes()
    }
  }, [isAuthenticated])

  return (
    <Box
      sx={{
        height: `calc(100vh - ${toolBarHeight}px)`,
      }}
    >
      <Sentry.ErrorBoundary
        fallback={(errorProps) => <Fallback {...errorProps} />}
      >
        {children}
      </Sentry.ErrorBoundary>
    </Box>
  )
}

MLayout.defaultProps = {
  isProtected: true,
}
