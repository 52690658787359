import { useFilterData } from '@data-c/providers'
import { Box, LinearProgress, Stack, Typography } from '@mui/material'
import useGerenteComercial, {
  GerenteComercialFilters,
  GerenteComercialFilters1,
} from 'hooks/queries/useGerenteComercial'
import Chart from './components/Chart'
import { formatCurrency } from '@data-c/hooks'
import { theme, Tooltip } from '@data-c/ui'
import { Info } from '@mui/icons-material'
import TooltipCrescimentoMedio from './components/TootipCrescimentoMedio'

export default function EvolucaoVendas() {
  const { appliedValues, searchId } = useFilterData<
    GerenteComercialFilters,
    GerenteComercialFilters1
  >()
  const { representante, cliente, dateRange, ...rest } = appliedValues || {}
  const nFilters: GerenteComercialFilters1 = {
    ...rest,
    representanteUuid: representante?.uuid,
    clienteUuid: cliente?.uuid,
    dataInicial: dateRange?.firstDate,
    dataFinal: dateRange?.secondDate,
    tipoPeriodo: dateRange?.tipo,
  }

  const { useQueryObterEvolucaoVendas } = useGerenteComercial()
  const { data: evolucaoVendas, isLoading: isLoadingTotalPedidos } =
    useQueryObterEvolucaoVendas({
      queryParams: nFilters,
      requestId: searchId,
      pagination: { page: 1, pageSize: 9999 },
    })

  if (isLoadingTotalPedidos) return <LinearProgress />

  return (
    <Box>
      <Stack direction="row" gap={5} alignItems="center" sx={{ ml: 1, mt: 2 }}>
        <Typography sx={{ fontSize: '11pt', color: 'gray' }}>
          Valor
          <Typography
            sx={{ fontWeight: 'bold', color: 'gray', fontSize: '15pt' }}
          >
            R$ {formatCurrency(evolucaoVendas?.total || 0)}
          </Typography>
        </Typography>
        <Typography sx={{ fontSize: '11pt', color: 'gray' }}>
          Média por mês
          <Typography
            sx={{ fontWeight: 'bold', color: 'gray', fontSize: '15pt' }}
          >
            R$ {formatCurrency(evolucaoVendas?.mediaMensal || 0)}
          </Typography>
        </Typography>
        {evolucaoVendas?.crescimentoMedioPorMes.percentual &&
        evolucaoVendas?.crescimentoMedioPorMes.percentual !== 0 ? (
          <Typography sx={{ fontSize: '11pt', color: 'gray' }}>
            <Stack direction="row" alignItems="center" gap="3px">
              Crescimento médio por mês
              <Tooltip title={<TooltipCrescimentoMedio />}>
                <Info
                  color="primary"
                  fontSize="small"
                  sx={{ '&:hover': { cursor: 'default' } }}
                />
              </Tooltip>
            </Stack>
            <Typography
              sx={{
                fontWeight: 'bold',
                color:
                  evolucaoVendas?.crescimentoMedioPorMes.valor &&
                  evolucaoVendas?.crescimentoMedioPorMes.valor > 0
                    ? theme.palette.success.main
                    : theme.palette.error.main,
                fontSize: '15pt',
              }}
            >
              R${' '}
              {formatCurrency(
                evolucaoVendas?.crescimentoMedioPorMes.valor || 0,
              )}{' '}
              (
              {formatCurrency(
                evolucaoVendas?.crescimentoMedioPorMes.percentual?.toFixed(2) ||
                  0,
              )}
              %)
            </Typography>
          </Typography>
        ) : null}
      </Stack>
      {evolucaoVendas?.data && evolucaoVendas?.data.length > 0 ? (
        <Chart data={evolucaoVendas?.data || []} />
      ) : (
        <Typography
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 20,
            fontSize: '13pt',
          }}
        >
          Não há dados suficientes para exibir o gráfico.
        </Typography>
      )}
    </Box>
  )
}
