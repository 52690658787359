import { ReactNode } from 'react'
import { Box, Collapse, Grid, Paper, Stack, useTheme } from '@mui/material'

interface FloatDrawerProps {
  action?: ReactNode
  children: ReactNode
  drawerHeader?: ReactNode
  open: boolean
}

export default function FloatDrawer(props: FloatDrawerProps) {
  const { action, children, open, drawerHeader } = props

  const theme = useTheme()

  return (
    <Box
      sx={{
        position: 'absolute',
        bottom: '0',
        left: '0',
        right: '0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        zIndex: { xs: '1', sm: '1' },
      }}
    >
      <Paper
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'fixed',
          backgroundColor: theme.palette.background.paper,
          bottom: '0',
          padding: '8px 0 8px 0',
          left: { xs: '0', sm: '0', md: 'auto', lg: 'auto', xl: 'auto' },
          borderTop: `solid 1px ${theme.palette.grey[300]}`,
        }}
      >
        <Grid container justifyContent="center">
          <Grid item xl={10} lg={9} md={8} sm={9} xs={8}>
            <Stack gap={1}>
              <Box
                sx={{
                  height: '100%',
                  width: '30%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  [theme.breakpoints.down('sm')]: {
                    width: '100%',
                    height: '50%',
                  },
                  marginLeft: 'auto',
                }}
              >
                {drawerHeader}
              </Box>

              {action}
            </Stack>
            <Collapse in={open}>{children}</Collapse>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}
