import { useState } from 'react'
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  TextField,
} from '@mui/material'

import { usePagination } from '@data-c/hooks'

import { AutoCompleteProps } from 'hooks/useAutocomplete'
import {
  LinhaProdutosModel,
  obterLinhaProdutos,
} from 'hooks/queries/useLinhaProdutos'
import useNotification from 'hooks/useNotifications'

export default function AutoCompleteLinhaProdutos(
  props: AutoCompleteProps<LinhaProdutosModel>,
) {
  const { onChange, value } = props

  const [options, setOptions] = useState<LinhaProdutosModel[]>([])
  const [isLoading, setLoading] = useState(false)

  const { pagination } = usePagination()
  const notifications = useNotification()

  async function handleObterLinhaProdutos() {
    if (options?.length > 0) return
    setLoading(true)
    try {
      const linhaProdutos = await obterLinhaProdutos({
        pagination,
        queryParams: { ativo: true },
      })

      setOptions(linhaProdutos?.data)
    } catch (error) {
      notifications.notifyException(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Autocomplete
      disablePortal
      onOpen={handleObterLinhaProdutos}
      options={options || []}
      onChange={onChange}
      getOptionLabel={(linhaProduto: LinhaProdutosModel) => {
        return `${linhaProduto.nome}`
      }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField {...params} value={value} label="Linha de Produtos" />
      )}
      loadingText="Carregando..."
      loading={isLoading}
    />
  )
}
