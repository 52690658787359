import { useMemo, useCallback } from 'react'

import {
  DataTable,
  OptionStyles,
  DataTableOptions as Options,
  CurrencyCellStyle,
} from '@data-c/ui'
import { useFormApi } from '@data-c/providers'

import { MUIDataTableColumnDef } from 'mui-datatables'
import useTabelaPreco, {
  DescontoAgregadoModel,
} from 'hooks/queries/useTabelaPreco'
import { findData, formatCurrency } from '@data-c/hooks'

interface TableProps {
  tabelaPrecoUuid?: string
}

export default function Table(props: TableProps) {
  const { tabelaPrecoUuid } = props
  const { openForm, openConfirm } = useFormApi()
  const { useQueryObterDescontoAgregadoPorTabelaId } = useTabelaPreco()
  const { data, isLoading, isFetching, error } =
    useQueryObterDescontoAgregadoPorTabelaId(tabelaPrecoUuid || '')

  const descontoAgregado = useMemo(() => {
    return data?.data.map((d) => {
      return {
        ...d,
        pdesconto: formatCurrency(d.pdesconto),
        pesoValorMinimo: formatCurrency(d.pesoValorMinimo),
      }
    })
  }, [data?.data])

  const handleClickItem = useCallback(
    (event: 'delete', data: DescontoAgregadoModel) => {
      switch (event) {
        case 'delete':
          openConfirm(data)
          break
      }
    },
    [openForm, openConfirm],
  )

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'pdesconto',
        label: 'Desconto(%)',
        options: {
          setCellProps: CurrencyCellStyle,
        },
      },
      {
        name: 'pesoValorMinimo',
        label: 'Valor Mínimo',
        options: {
          setCellProps: CurrencyCellStyle,
        },
      },
      {
        name: 'uuid',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          setCellProps: OptionStyles,
          customBodyRender: (value) => {
            const _data = findData(data?.data || [], value, 'uuid')
            return (
              <Options
                displayDeleteButton={true}
                displayUpdateButton={false}
                value={_data}
                onClick={handleClickItem}
              />
            )
          },
        },
      },
    ],
    [findData, handleClickItem, data?.data],
  )

  return (
    <DataTable
      error={error?.message}
      columns={columns}
      data={descontoAgregado || []}
      isLoading={isLoading}
      isFetching={isFetching}
      options={{
        pagination: false,
        rowsPerPageOptions: [15, 50, 100],
        rowsPerPage: 15,
      }}
    />
  )
}
