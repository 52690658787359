import { Box, Typography } from '@mui/material'
import { MathJax, MathJaxContext } from 'better-react-mathjax'
const mathJaxConfig = {
  loader: { load: ['input/tex', 'output/chtml'] },
}
export default function TooltipCrescimentoMedio() {
  return (
    <MathJaxContext config={mathJaxConfig}>
      <Box>
        <Typography sx={{ fontSize: '17px', fontWeight: 'bold', mb: 1 }}>
          Fórmula do Crescimento Médio
        </Typography>
        <Typography sx={{ fontSize: '14px', my: 1, textAlign: 'center' }}>
          <MathJax>
            {
              '\\[ \\left( \\frac{valor\\_final}{valor\\_inicial} \\right)^{\\frac{1}{n}} - 1 \\]'
            }
          </MathJax>
        </Typography>
        <Typography sx={{ fontSize: '14px', fontWeight: 'bold', mb: 1 }}>
          O Que Essa Fórmula Faz:
        </Typography>
        <Typography sx={{ fontSize: '14px' }}>
          Ela calcula a <strong>taxa média de crescimento</strong> ao longo de
          um período (como meses ou anos). Você usa o valor inicial e o valor
          final para descobrir o quanto, em média, o valor cresceu ou diminuiu
          por período.
        </Typography>
        <Typography sx={{ fontSize: '14px', fontWeight: 'bold', mt: 1, mb: 1 }}>
          Como Entender:
        </Typography>
        <Typography sx={{ fontSize: '14px' }}>
          <strong>valor_inicial:</strong> O valor total no começo do período.
        </Typography>
        <Typography sx={{ fontSize: '14px', mt: 1 }}>
          <strong>valor_final:</strong> O valor total no fim do período.
        </Typography>
        <Typography sx={{ fontSize: '14px', mt: 1 }}>
          <strong>n:</strong> O número de períodos (como meses) que você está
          analisando.
        </Typography>
        <Typography sx={{ fontSize: '14px', mt: 1 }}>
          Essa fórmula te ajuda a descobrir o crescimento ou redução de forma
          simples, considerando todos os períodos envolvidos.
        </Typography>
      </Box>
    </MathJaxContext>
  )
}
