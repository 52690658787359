import { Box, Stack } from '@mui/material'
import CardConfig from 'components/CardConfig'
import { useRoleContext } from 'components/Contexts/RoleContext'
import DropDownAcessoNegociacoes from '../DropDownAcessoNegociacoes'

export default function TabNegociacoes() {
  const { role, changeRolePermission } = useRoleContext()
  const { negociacoes } = role?.permissoes || {}

  return (
    <Box sx={{ mt: 2 }}>
      <Stack spacing={2}>
        <CardConfig
          title="Acesso (Ver)"
          description={
            <div>
              Define o nível de acesso que o usuário possui para poder ver as
              negociações (as regras se aplicam aos relatórios)
            </div>
          }
          component={
            <DropDownAcessoNegociacoes
              name="negociacoes.read"
              value={negociacoes?.read || ''}
              onChange={changeRolePermission}
              disabled={Boolean(role?.fixa)}
            />
          }
        />
        <CardConfig
          title="Acesso (Criar)"
          description={
            <div>
              Define o nível de acesso que o usuário possui para poder criar as
              negociações
            </div>
          }
          component={
            <DropDownAcessoNegociacoes
              name="negociacoes.create"
              value={negociacoes?.create || ''}
              onChange={changeRolePermission}
              disabled={Boolean(role?.fixa)}
            />
          }
        />
        <CardConfig
          title="Acesso (Alterar)"
          description={
            <div>
              Define o nível de acesso que o usuário possui para poder alteraras
              negociações
            </div>
          }
          component={
            <DropDownAcessoNegociacoes
              name="negociacoes.alter"
              value={negociacoes?.alter || ''}
              onChange={changeRolePermission}
              disabled={Boolean(role?.fixa)}
            />
          }
        />

        <CardConfig
          title="Acesso (Deletar)"
          description={
            <div>
              Define o nível de acesso que o usuário possui para poder deletar
              as negociações
            </div>
          }
          component={
            <DropDownAcessoNegociacoes
              name="negociacoes.delete"
              value={negociacoes?.delete || ''}
              onChange={changeRolePermission}
              disabled={Boolean(role?.fixa)}
            />
          }
        />
      </Stack>
    </Box>
  )
}
