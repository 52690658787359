import { FilterOptions } from '@data-c/providers'
import { AxiosError } from 'axios'
import BaseModel from 'interfaces/BaseModel'
import useCrud from 'hooks/useCrud'
import { VendedorModel } from './useVendedores'

export interface ChavesDeAcessoModel extends BaseModel {
  id?: string
  chave?: string
  vendedor?: VendedorModel
  vendedorUuid: string
}

export interface ChavesDeAcessoFilters {
  codigo: FilterOptions<string>
  nome: FilterOptions<string>
  cnpjcpf: FilterOptions<string>
}

export interface ChavesDeAcessoFilters1 {
  codigo: string
  nome: string
  cnpjCpf: string
}

export const filters: ChavesDeAcessoFilters = {
  codigo: {
    label: 'Código',
  },
  nome: {
    label: 'Nome',
  },
  cnpjcpf: {
    label: 'CPF/CNPJ',
  },
}

export default function useChavesDeAcesso() {
  return useCrud<ChavesDeAcessoModel, AxiosError>(
    'administrador/chaves-de-acesso',
    'Chave de Acesso',
    'female',
    'id',
  )
}
