import { useFilterApi, useFilterData } from '@data-c/providers'
import { FilterContainer } from '@data-c/ui'
import { MenuItem, Stack, TextField } from '@mui/material'
import DateRangePicker, { DateRange } from 'components/Inputs/DateRangePicker'
import { DuplicataFilter, DuplicataFilter1 } from 'hooks/queries/useClientes'
import { DateTime } from 'luxon'
import _ from 'lodash'

export default function Filter() {
  const { changeFilterValue } = useFilterApi()
  const { filterValues } = useFilterData<DuplicataFilter, DuplicataFilter1>()

  return (
    <FilterContainer
      title="Pesquisar"
      triggerButtonProps={{ variant: 'outlined' }}
      renderLabel={(filterLabel: string, filterValue: any) => {
        if (filterLabel === 'Período') {
          const dateRange = filterValue as DateRange
          const formattedFirstDate = DateTime.fromFormat(
            dateRange?.firstDate || '',
            'yyyy-MM-dd',
          ).toFormat('dd/MM/yyyy')

          const formattedSecondDate = DateTime.fromFormat(
            dateRange?.secondDate || '',
            'yyyy-MM-dd',
          ).toFormat('dd/MM/yyyy')

          if (dateRange.tipo === 'day') {
            return `${filterLabel} - ${formattedFirstDate}`
          }

          return `${filterLabel} - de ${formattedFirstDate} até ${formattedSecondDate}`
        }
        return `${filterLabel} - ${_.toUpper(filterValue)}`
      }}
    >
      <Stack spacing={2}>
        <fieldset
          style={{
            border: '1px solid #cfcfcf',
            borderRadius: '4px',
          }}
        >
          <legend
            style={{
              color: '#808080',
              fontSize: '0.7rem',
              paddingLeft: '4px',
              paddingRight: '16px',
            }}
          >
            Período
          </legend>

          <Stack
            sx={{
              mt: 1,
              gap: 2,
              borderRadius: '4px',
            }}
          >
            <TextField
              label="Tipo da Data"
              name="tipoData"
              value={filterValues?.tipoData?.value || 'vencimento'}
              select
              onChange={(e) => {
                const key = e.target.value
                changeFilterValue('tipoData', key)
              }}
            >
              <MenuItem value="vencimento">Vencimento</MenuItem>
              <MenuItem value="pagamento">Pagamento</MenuItem>
              <MenuItem value="emissao">Emissão</MenuItem>
            </TextField>

            <DateRangePicker
              renderFieldSet={false}
              onChange={(value) => {
                changeFilterValue('dateRange', value)
              }}
              value={filterValues?.dateRange?.value}
            />
          </Stack>
        </fieldset>
      </Stack>
    </FilterContainer>
  )
}
