import { Container } from '@mui/material'

// interface HomeProps {
//   pesoValorPadrao: number
// }

export default function Home() {
  return (
    <Container>
      {/* <TextField value={formValues.pesoValorPadrao}></TextField> */}
    </Container>
  )
}
