import { Box, Collapse, Grid, Typography, useTheme } from '@mui/material'
import { ClientesModel } from 'hooks/queries/useClientes'
import { LabelValue } from '@data-c/ui'

import CEPTextField from 'components/CEPTextField'
import AutoCompleteCidade from 'components/AutoCompleteCidade'
import DropDownUF from 'components/Inputs/DropDownUF'
import MaskedTextField from 'components/MaskedTextField'
import InscricaoEstadualTextField from 'components/InscricaoEstadualTextField'

import DocumentoTextField from 'components/DocumentoTextField'

import TextField from 'components/Inputs/TextField'
import React, { useState } from 'react'
interface InfoClienteProps {
  cliente: ClientesModel
}

export default function InfoCliente(props: InfoClienteProps) {
  const { cliente } = props
  const theme = useTheme()
  const [collapse, setCollapse] = useState(false)
  const handleChangeCollapse = () => {
    setCollapse(!collapse)
  }

  return (
    <React.Fragment>
      <LabelValue label="Cliente">
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          {`${cliente.codigo || ''} - ${cliente?.nome || ''}`}{' '}
          <Typography
            fontSize={13}
            onClick={handleChangeCollapse}
            color={theme.palette.primary.dark}
            sx={{ cursor: 'pointer' }}
          >
            {collapse ? 'ver menos' : 'ver mais'}
          </Typography>
        </Box>
      </LabelValue>
      <Typography variant="body2">
        {`${cliente?.cidadeModel?.nome || ''} - ${
          cliente?.cidadeModel?.uf || ''
        }`}{' '}
      </Typography>
      <Collapse in={collapse}>
        <Box
          sx={{
            mt: 2,
            p: 2,
            border: `1px solid rgba(0, 0, 0, 0.38)`,
            borderRadius: '4px',
          }}
        >
          <Grid container spacing={2}>
            <Grid item xl={2} lg={2} md={2} sm={3} xs={12}>
              <TextField
                disabled
                name="codigo"
                label="Código"
                value={cliente.codigo || ''}
              />
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={9} xs={12}>
              <DocumentoTextField
                name="cnpjcpf"
                value={cliente.cnpjcpf || ''}
                InputProps={{
                  disabled: true,
                }}
                inputProps={{
                  label: 'CPF/CNPJ',
                }}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <InscricaoEstadualTextField
                name="ie"
                label="Inscrição Estadual"
                value={cliente.ie || ''}
                disabled={true}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <TextField
                name="nome"
                label="Razão Social"
                required
                value={cliente.nome || ''}
                inputProps={{ maxLength: 40 }}
                disabled={true}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
              <TextField
                name="usual"
                label="Nome Fantasia"
                value={cliente.usual || ''}
              />
            </Grid>

            <Grid item xl={2} lg={2} md={2} sm={2} xs={12}>
              <CEPTextField
                name="cep"
                disabled={true}
                inputProps={{
                  label: 'CEP',
                }}
                InputProps={{
                  disabled: true,
                }}
                value={cliente.cep || ''}
              />
            </Grid>
            <Grid item xl={5} lg={5} md={5} sm={6} xs={12}>
              <DropDownUF
                disabled={true}
                name="uf"
                label="Estado"
                value={cliente.uf || ''}
              />
            </Grid>
            <Grid item xl={5} lg={5} md={5} sm={6} xs={12}>
              <AutoCompleteCidade
                name="cidade"
                label="Cidade"
                disabled={true}
                value={cliente.cidadeModel}
                uf={cliente.uf || null}
              />
            </Grid>
            <Grid item xl={5} lg={5} md={5} sm={6} xs={12}>
              <TextField
                name="bairro"
                label="Bairro"
                value={cliente.bairro || ''}
                disabled={true}
              />
            </Grid>
            <Grid item xl={5} lg={5} md={5} sm={6} xs={6}>
              <TextField
                name="endereco"
                label="Endereço"
                value={cliente.endereco || ''}
                inputProps={{ maxLength: 40 }}
                disabled={true}
              />
            </Grid>
            <Grid item xl={2} lg={2} md={2} sm={2} xs={12}>
              <TextField
                name="numero"
                label="Número"
                value={cliente.numero || ''}
                inputProps={{ maxLength: 30 }}
                disabled={true}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <TextField
                name="complemento"
                label="Complemento"
                value={cliente.complemento || ''}
                disabled={true}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <TextField
                name="contato"
                label="Contato"
                value={cliente.contato || ''}
                inputProps={{ maxLength: 30 }}
                disabled={true}
              />
            </Grid>
            <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
              <MaskedTextField
                name="telefoneAsText"
                mask="(99) 9999-9999"
                inputProps={{
                  label: 'Telefone',
                  InputProps: { disabled: true },
                }}
                value={cliente.telefoneAsText || ''}
              />
            </Grid>
            <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
              <MaskedTextField
                mask="(99) 9 9999-9999"
                name="celularAsText"
                inputProps={{
                  label: 'Celular',
                  InputProps: { disabled: true },
                }}
                value={cliente.celularAsText || ''}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <TextField
                name="email"
                label="E-mail"
                value={cliente.email || ''}
                disabled={true}
              />
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <TextField
                name="observacao"
                label="Observação"
                multiline
                rows={4}
                value={cliente.observacao || ''}
                disabled={true}
              />
            </Grid>
          </Grid>
        </Box>
      </Collapse>
    </React.Fragment>
  )
}
