import { ReactNode, useState } from 'react'
import { Stack, Typography } from '@mui/material'
import { LoadingButton as Button } from '@mui/lab'
import { Replay } from '@mui/icons-material'

import errorImage from 'assets/images/error.svg'

interface ErrorProps {
  onClick?: () => void
  title?: string
  message?: string | ReactNode
}

export default function Error(props: ErrorProps) {
  const { onClick, title, message } = props

  const [isLoading, setLoading] = useState(false)

  function handleReloadPage() {
    setLoading(true)
    window.location.reload()
  }

  function handleClick() {
    if (onClick) {
      onClick()
    } else {
      handleReloadPage()
    }
  }

  return (
    <Stack sx={{ alignItems: 'center', mt: 8, padding: 4, gap: 4 }}>
      <img src={errorImage} alt="Imagem de erro" />

      <Stack sx={{ maxWidth: '554px', textAlign: 'center', gap: 2 }}>
        <Typography variant="h1">{title}</Typography>

        <Typography variant="body2">{message}</Typography>
      </Stack>

      <Button
        variant="contained"
        onClick={handleClick}
        loading={isLoading}
        startIcon={<Replay />}
      >
        Tentar novamente
      </Button>
    </Stack>
  )
}

Error.defaultProps = {
  title: 'Ops! Algo deu errado',
  message: (
    <div>
      Não foi possível processar a sua requisição.
      <br />
      Tente novamente mais tarde ou entre em contato com o nosso suporte para
      mais informações
    </div>
  ),
}
