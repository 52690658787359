import { formatCurrency } from '@data-c/hooks'
import { useFilterData } from '@data-c/providers'
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import { Box, LinearProgress, Typography } from '@mui/material'
import useGerenteComercial, {
  GerenteComercialFilters,
  GerenteComercialFilters1,
} from 'hooks/queries/useGerenteComercial'

export default function TotalPedidos() {
  const { appliedValues, searchId } = useFilterData<
    GerenteComercialFilters,
    GerenteComercialFilters1
  >()
  const { representante, cliente, dateRange, ...rest } = appliedValues || {}
  const nFilters: GerenteComercialFilters1 = {
    ...rest,
    representanteUuid: representante?.uuid,
    clienteUuid: cliente?.uuid,
    dataInicial: dateRange?.firstDate,
    dataFinal: dateRange?.secondDate,
    tipoPeriodo: dateRange?.tipo,
  }

  const { useQueryObterTotalPedidos } = useGerenteComercial()
  const { data: totalPedidos, isLoading: isLoadingTotalPedidos } =
    useQueryObterTotalPedidos({
      queryParams: nFilters,
      requestId: searchId,
      pagination: { page: 1, pageSize: 9999 },
    })

  if (isLoadingTotalPedidos) return <LinearProgress />
  const isPositive = totalPedidos?.percentual && totalPedidos?.percentual > 0
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '80%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
        }}
      >
        <Typography sx={{ fontSize: '9pt', color: '#4d6cb2' }}>
          R$ {formatCurrency(totalPedidos?.totalPeriodoAnterior || 0)}
        </Typography>
        <Typography
          sx={{ fontWeight: 'bold', color: 'gray', fontSize: '15pt' }}
        >
          R$ {formatCurrency(totalPedidos?.totalPeriodoAtual || 0)}
        </Typography>
      </Box>
      {totalPedidos?.totalPeriodoAnterior && totalPedidos?.totalPeriodoAtual ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            textAlign: 'center',
            mt: 0,
          }}
        >
          <Typography
            sx={{
              fontSize: '9pt',
              color: isPositive ? 'green' : 'red',
            }}
          >
            {totalPedidos?.percentual?.toFixed(2)}%
          </Typography>
          {isPositive && <ArrowDropUp color="success" />}
          {!isPositive && <ArrowDropDown color="error" />}
        </Box>
      ) : null}
    </Box>
  )
}
