import { MenuItem, TextField, TextFieldProps } from '@mui/material'

export enum UsaEstoqueOptions {
  DESABILITADO = 0,
  EXIBE_QUANTIDADE_ESTOQUE_TABELA_ESTOQUE = 1,
  EXIBE_QUANTIDADE_ESTOQUE_ESTIMADO = 3,
}

export default function DropDownUsaEstoque(props: TextFieldProps) {
  return (
    <TextField select {...props}>
      <MenuItem value={UsaEstoqueOptions.DESABILITADO}>
        Não considera quantidade em estoque
      </MenuItem>
      <MenuItem
        value={UsaEstoqueOptions.EXIBE_QUANTIDADE_ESTOQUE_TABELA_ESTOQUE}
      >
        Considera apenas a quantidade em estoque
      </MenuItem>
      <MenuItem value={UsaEstoqueOptions.EXIBE_QUANTIDADE_ESTOQUE_ESTIMADO}>
        Considera a quantidade em estoque + quantidade dos itens do pedido em
        venda.
      </MenuItem>
    </TextField>
  )
}
