//String url = "https://www.receitaws.com.br/v1/cnpj/"+cnpjcpf;

import { AxiosError } from 'axios'
import { useState } from 'react'
import api from 'services/cnpj-api'

export interface CnpjRetornoModel {
  cnpj: string
  nome: string
  logradouro: string
  numero: string
  complemento: string
  municipio: string
  bairro: string
  uf: string
  cep: string
  email: string
  telefone: string
  fantasia: string
}

export default function useConsultaCnpj() {
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState<null | AxiosError>(null)

  async function consultar(
    cnpj: string,
  ): Promise<CnpjRetornoModel | undefined> {
    setLoading(true)
    try {
      const response = await api.get<{
        body: CnpjRetornoModel | string
        statusCode: number
      }>(`/consultacnpj`, {
        params: {
          cnpj,
        },
      })

      if (response.status === 200) return response.data.body as CnpjRetornoModel

      throw Error(response.data.body as string)
    } catch (err) {
      setError(err as AxiosError)
    } finally {
      setLoading(false)
    }
  }

  return {
    consultar,
    isLoading,
    error,
  }
}
