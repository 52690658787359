import { PedidoModel } from 'hooks/queries/usePedidos'
import Table from './componentes/Table'

export interface TabPedidosRelacionadosProps {
  pedidosRelacionados: Array<PedidoModel>
}
export default function TabPedidosRelacionados(
  props: TabPedidosRelacionadosProps,
) {
  return <Table data={props.pedidosRelacionados} />
}
