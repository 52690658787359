import { useMemo, useState } from 'react'

import { Button, DataTable, Flag } from '@data-c/ui'
import { usePagination } from '@data-c/hooks'

import { MUIDataTableColumnDef } from 'mui-datatables'

import useClientes from 'hooks/queries/useClientes'
import { formatToCPFOrCNPJ } from 'brazilian-values'
import { SortProps } from 'interfaces/SortProps'

export interface TableProps {
  clienteUuid: string
}

function temVendedoresSelecionados(
  indexVendedoresSelecionados: Array<string | undefined>,
): boolean {
  return indexVendedoresSelecionados.length > 0
}

export default function Table(props: TableProps) {
  const { clienteUuid } = props

  const [sort, setSort] = useState<SortProps>({
    column: 'usual',
    direction: 'asc',
  })
  const [indexVendedoresSelecionados, setIndexVendedoresSelecionados] =
    useState<number[]>([])

  const { changePageSize, changePage, pagination } = usePagination()
  const { useQueryObterVendedoresVinculados, useDesvincularVendedores } =
    useClientes()
  const {
    data: vendedoresVinculados,
    isLoading,
    isFetching,
    error,
  } = useQueryObterVendedoresVinculados(
    {
      pagination,
      clienteUuid,
    },
    sort,
  )
  const {
    mutateAsync: desvincularVendedores,
    isLoading: isLoadingDesvincularVendedores,
  } = useDesvincularVendedores()

  const formattedData = useMemo(
    () =>
      vendedoresVinculados?.data?.map((item) => {
        return {
          ...item,
          cnpjcpf: item.cnpjcpf ? formatToCPFOrCNPJ(item.cnpjcpf) : '',
        }
      }),
    [vendedoresVinculados],
  )

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'codigo',
        label: 'Código',
        options: {
          setCellProps() {
            return { width: '96px' }
          },
        },
      },
      {
        name: 'cnpjcpf',
        label: 'CNPJ/CPF',
        options: {
          sort: false,
        },
      },
      {
        name: 'usual',
        label: 'Nome',
      },
      {
        name: 'ativo',
        label: 'Ativo',
        options: {
          setCellProps: () => ({
            style: {
              width: '60px',
              textAlign: 'center',
            },
          }),
          customBodyRender: (value) => {
            return <Flag isFlagged={value === 1} type="success" />
          },
        },
      },
    ],
    [],
  )

  async function handleClickDesvincularVendedores() {
    const vendedoresSelecionadosIds =
      indexVendedoresSelecionados.map(
        (index) => vendedoresVinculados?.data[index]?.uuid || '',
      ) || []

    if (clienteUuid && temVendedoresSelecionados(vendedoresSelecionadosIds)) {
      try {
        await desvincularVendedores({
          clienteUuid,
          vendedoresSelecionadosIds,
        })
      } finally {
        setIndexVendedoresSelecionados([])
      }
    }
  }

  return (
    <DataTable
      error={error?.message}
      isLoading={isLoading}
      isFetching={isFetching}
      columns={columns}
      data={formattedData || []}
      pagination={vendedoresVinculados?.pagination}
      changePage={changePage}
      changePageSize={changePageSize}
      options={{
        rowsPerPageOptions: [15, 50, 100],
        rowsPerPage: 15,
        selectableRows: 'multiple',
        selectableRowsOnClick: true,
        rowsSelected: indexVendedoresSelecionados,
        sort: true,
        sortOrder: {
          name: sort.column,
          direction: sort.direction,
        },
        onRowSelectionChange: (_, __, rowsSelected: any) =>
          setIndexVendedoresSelecionados(rowsSelected),
        customToolbarSelect() {
          return (
            <Button
              sx={{ marginRight: '26px' }}
              color="error"
              isLoading={isLoadingDesvincularVendedores}
              onClick={handleClickDesvincularVendedores}
            >
              Desvincular Vendedores
            </Button>
          )
        },
      }}
      changeSort={(column, direction) => {
        setSort({ column, direction: direction as 'asc' | 'desc' })
      }}
    />
  )
}
