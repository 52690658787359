import { Box, Typography, Divider as MuiDivider, useTheme } from '@mui/material'

export interface DividerProps {
  label?: string
}
export default function Divider(props: DividerProps) {
  const { label } = props
  const theme = useTheme()
  return (
    <Box sx={{ mb: 0.5, mt: 0.5 }}>
      {label && (
        <Typography variant="h5" sx={{ color: theme.palette.text.primary }}>
          {label}
        </Typography>
      )}
      <MuiDivider />
    </Box>
  )
}
