import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import api from 'services/api'
import { ErrorInterface } from '@data-c/hooks'
import useNotification from 'hooks/useNotifications'

export interface KeyValue {
  key: string
  value: string
}

export interface ImportarExcelInterface {
  fileName: string
  origens: Array<KeyValue>
  destinos: Array<KeyValue>
  mapeamento: Array<{ origem: string; destino: string }>
  data: {
    config: Record<string, any>
    data: any[]
    erros?: Record<string, string[]>
  }
}

// export const tabelaPessoaMap: Record<string, string> = {
//   cnpjcpf: 'CNPJ/CPF',
//   ie: 'Inscrição Estatual',
//   nome: 'Razão Social',
//   cep: 'CEP',
//   endereco: 'Endereço',
//   numero: 'Número',
//   bairro: 'Bairro',
//   complemento: 'Complemento',
//   contato: 'Contato',
//   telefone: 'Telefone',
//   celular: 'Celular',
//   email: 'E-mail',
//   observacao: 'Observação',
//   usual: 'Nome Fantasia',
//   ddd: 'DDD Telefone',
//   dddcelular: 'DDD Celular',
//   cidade: 'Cidade',
// }

export async function preview(
  data: ImportarExcelInterface,
): Promise<ImportarExcelInterface> {
  const response = await api.post<Promise<ImportarExcelInterface>>(
    `administrador/importar/preview`,
    data,
  )
  return response.data
}

export async function obterMapeamentos(): Promise<any[]> {
  const response = await api.get(
    `administrador/importar-entidade/obter-mapeamentos`,
  )
  return response.data
}

export function useQueryObterMapeamentos() {
  return useQuery<any[], AxiosError<ErrorInterface, ErrorInterface>>(
    ['IMPORTAR_ENTIDADE'],
    () => {
      return obterMapeamentos()
    },
  )
}

export function useQueryPreview(data: ImportarExcelInterface) {
  return useQuery<
    ImportarExcelInterface,
    AxiosError<ErrorInterface, ErrorInterface>
  >(['IMPORTAR_ENTIDADE'], () => {
    return preview(data)
  })
}

async function finalImport(
  data: ImportarExcelInterface,
): Promise<ImportarExcelInterface> {
  const response = await api.post(`/administrador/importar/import`, data)
  return response.data
}

export function useFinalImport() {
  const notifications = useNotification()
  const queryClient = useQueryClient()
  return useMutation<
    ImportarExcelInterface,
    AxiosError,
    ImportarExcelInterface
  >((data: ImportarExcelInterface) => finalImport(data), {
    onSuccess() {
      notifications.notifySuccess(`Dados importados com sucesso`)
      queryClient.invalidateQueries(['IMPORTAR_ENTIDADE'])
    },
    onError(error) {
      notifications.notifyException(error)
    },
  })
}

export default function useImportarEntidade() {
  return {
    useQueryObterMapeamentos,
    useQueryPreview,
    useFinalImport,
  }
}
