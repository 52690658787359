import { Stack, Typography } from '@mui/material'
import notifyImage from 'assets/images/notify.svg'

interface EmptyDataProps {
  error?: string
}

export default function EmptyData(props: EmptyDataProps) {
  const { error } = props
  return (
    <>
      {error && (
        <Stack
          padding={4}
          alignItems="center"
          gap={3}
          marginTop="10%"
          justifyContent="center"
        >
          <img src={notifyImage} alt="Imagem de notificação" width="30%" />

          <Typography variant="h5">{error}</Typography>
        </Stack>
      )}
    </>
  )
}
