import { useState } from 'react'

import { Transporter, TransporterProps } from '@data-c/ui'

import useClientes, { ClientesModel } from 'hooks/queries/useClientes'

import Table from './components/Table'
import Form from 'pages/Clientes/components/Form'

type ClientesTransporterProps = {
  vendedorUuid?: string
} & Omit<
  TransporterProps<ClientesModel>,
  'table' | 'form' | 'onSearch' | 'renderValue' | 'options'
>

export default function ClientesTransporter(props: ClientesTransporterProps) {
  const { value, vendedorUuid, ...rest } = props

  const [query, setQuery] = useState<string | null>()

  const { useObterClientes } = useClientes()
  const { data, isLoading, error } = useObterClientes(
    {
      queryParams: {
        plainQuery: query,
        ativo: true,
        vendedorUuid,
      },
      pagination: {
        page: 1,
        pageSize: 100,
      },
    },
    vendedorUuid,
  )

  const clientes = data?.data || []
  function handleSearch(q: string | null) {
    setQuery(q)
  }

  return (
    <Transporter
      dialogProps={{
        title: 'Clientes',
        maxWidth: 'lg',
      }}
      onSearch={handleSearch}
      options={clientes || []}
      isLoading={isLoading}
      table={Table}
      tableProps={{
        vendedorUuid,
      }}
      form={Form}
      label="Clientes"
      renderValue={(value) => {
        return `${value?.codigo || ''} - ${value.nome || value.usual}`
      }}
      value={value}
      error={Boolean(error)}
      helperText={
        Boolean(error) &&
        (error?.response?.data?.message ||
          'Ocorreu um erro ao tentar processar a requisição')
      }
      // helperText={'Ocorreu um erro ao tentar processar a requisição'}
      {...rest}
    />
  )
}
