import React from 'react'
import {
  IconButton,
  IconButtonProps,
  SvgIconProps,
  // Toolbar,
  // Slide,
  // AppBar,
  // Dialog,
} from '@mui/material'
import { HelpOutline } from '@mui/icons-material'
// import { TransitionProps } from '@mui/material/transitions'
// import Close from '@mui/icons-material/Close'
// import { useDialog } from '@data-c/hooks'
// import { ButtonContainer, theme } from '@data-c/ui'

interface HelpButtonProps {
  iconButtonProps?: IconButtonProps
  svgIconProps?: SvgIconProps
  link: string
}

// const Transition = React.forwardRef(function Transition(
//   props: TransitionProps & {
//     children: React.ReactElement
//   },
//   ref: React.Ref<unknown>,
// ) {
//   return <Slide direction="up" ref={ref} {...props} />
// })

export default function HelpButton(props: HelpButtonProps) {
  const { link, svgIconProps, iconButtonProps } = props
  // const { isOpen, openDialog, closeDialog } = useDialog()
  return (
    <React.Fragment>
      <IconButton
        onClick={(e) => {
          e.preventDefault()
          window.open(link, '_blank', 'noreferrer')
        }}
        {...iconButtonProps}
      >
        <HelpOutline color="primary" {...svgIconProps} />
      </IconButton>
      {/* <Dialog
        sx={{
          '& .MuiDialog-paper': {
            position: 'absolute',
            top: theme.mixins.toolbar.height,            
          },
        }}
        fullScreen
        open={isOpen}
        onClose={closeDialog}
        TransitionComponent={Transition}
      >
        <ButtonContainer sx={{ m: 2 }}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={closeDialog}
            aria-label="close"
          >
            <Close />
          </IconButton>
        </ButtonContainer>
        <iframe src={link} width={1000} height={500}></iframe>;
      </Dialog> */}
    </React.Fragment>
  )
}
