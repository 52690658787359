import { useFilterApi, useFilterData } from '@data-c/providers'
import { FilterContainer } from '@data-c/ui'
import { MenuItem, Stack, TextField } from '@mui/material'
import {
  AtivoEnum,
  FormaPagamentoFilter,
  FormaPagamentoFilter1,
} from 'hooks/queries/useFormaPagamento'
const mapAtivos: Record<string, string> = {
  [AtivoEnum.TODOS]: 'Todos',
  [AtivoEnum.ATIVOS]: 'Ativos',
  [AtivoEnum.INATIVOS]: 'Inativos',
}
export default function Filter() {
  const { onChangeFilterValue } = useFilterApi()
  const { filterValues } = useFilterData<
    FormaPagamentoFilter,
    FormaPagamentoFilter1
  >()

  return (
    <FilterContainer
      title="Pesquisar"
      triggerButtonProps={{ variant: 'outlined' }}
      renderLabel={(filterLabel: string, filterValue: any) => {
        if (filterLabel === 'Ativo') {
          return mapAtivos[filterValue]
        }
        return `${filterLabel} - ${filterValue}`
      }}
    >
      <Stack spacing={2}>
        <TextField
          autoFocus
          name="codigo"
          value={filterValues?.codigo?.value}
          label={filterValues?.codigo?.label}
          onChange={onChangeFilterValue}
        />
        <TextField
          name="nome"
          value={filterValues?.nome?.value}
          label={filterValues?.nome?.label}
          onChange={onChangeFilterValue}
        />
        <TextField
          name="ativo"
          onChange={onChangeFilterValue}
          value={filterValues?.ativo?.value || ''}
          label={filterValues?.ativo?.label}
          select
        >
          <MenuItem value={AtivoEnum.TODOS}>Todos</MenuItem>
          <MenuItem value={AtivoEnum.ATIVOS}>Ativos</MenuItem>
          <MenuItem value={AtivoEnum.INATIVOS}>Inativos</MenuItem>
        </TextField>
      </Stack>
    </FilterContainer>
  )
}
