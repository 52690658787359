import React, { useEffect, useState } from 'react'
import {
  Box,
  CircularProgress,
  MenuItem,
  TextField,
  TextFieldProps,
} from '@mui/material'
import useRole from 'hooks/queries/useRole'
import useUsuario, { UsuarioModel } from 'hooks/queries/useUsuario'
import queryClient from 'services/query-client'

type DropDownRoleProps = {
  usuarioUuid?: string
} & TextFieldProps

export default function DropDownRole(props: DropDownRoleProps) {
  const { useQuery } = useRole()
  const { useSubmit } = useUsuario()
  const { usuarioUuid, value, ...rest } = props
  const [_value, setValue] = useState<unknown>()
  const { mutateAsync: salvar, isLoading: isSubmitting } = useSubmit()
  const { data, isLoading } = useQuery({
    pagination: {
      page: 1,
      pageSize: 1000,
    },
  })

  async function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const roleUuid = e.target.value
    const data = {
      uuid: usuarioUuid || '',
      roleUuid: roleUuid || '',
    } as UsuarioModel
    try {
      setValue(roleUuid)
      await salvar(data)
    } catch (e) {
      setValue(value)
    }

    queryClient.invalidateQueries(['USUARIOS'])
  }

  useEffect(() => {
    setValue(value)
  }, [value])

  return (
    <TextField
      select
      onChange={handleChange}
      value={_value || ''}
      InputProps={{
        endAdornment: (isLoading || isSubmitting) && (
          <Box
            sx={{
              mr: 2,
            }}
          >
            <CircularProgress size={16} />
          </Box>
        ),
      }}
      {...rest}
    >
      {data?.data?.map((role) => (
        <MenuItem key={role.uuid} value={role.uuid}>
          {role.nome}
        </MenuItem>
      ))}
    </TextField>
  )
}
