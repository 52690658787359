import { useMemo, useCallback, useState } from 'react'
import {
  DataTable,
  OptionStyles,
  DataTableOptions as Options,
} from '@data-c/ui'
import { MUIDataTableColumnDef } from 'mui-datatables'
import { UsuarioModel, useObterUsuarios } from 'hooks/queries/useMioAuth'
import useUsuario, { UsuarioFilter1 } from 'hooks/queries/useUsuario'
import { SortProps, useCredentials } from '@data-c/hooks'
import credentialsConfig from 'configs/credentials'
import { findData } from '@data-c/hooks'
import { useFilterData, useFormApi } from '@data-c/providers'
import { useNavigate } from 'react-router-dom'
import DropDownRole from 'components/Inputs/DropDownRole'
import { RoleModel } from 'hooks/queries/useRole'
import Surface from 'components/Surface'

export default function Table() {
  const { userLogged } = useCredentials(credentialsConfig)
  const { openConfirm } = useFormApi()
  const { useQueryObterUsuariosPorUuid } = useUsuario()
  const { appliedValues } = useFilterData<UsuarioFilter1>()
  const { data, error, isFetching, isLoading } = useObterUsuarios(
    userLogged?.empresaSelecionada || '',
    {
      plainQuery: appliedValues,
    },
  )
  const navigate = useNavigate()
  const [sort, setSort] = useState<SortProps>({
    column: 'name',
    direction: 'desc',
  })

  const usuariosUuids = useMemo(() => {
    return Array.isArray(data)
      ? data.map((d) => {
          return d.permissao.configs?.fdv_vendedor_uuid || ''
        })
      : []
  }, [data])

  const { data: usuariosfdv, isLoading: carregandoUsuarios } =
    useQueryObterUsuariosPorUuid(usuariosUuids)

  const usuarios = useMemo(() => {
    function filtrarUsuarios(usuarios: UsuarioModel[]) {
      return usuarios.filter((d) => {
        if (appliedValues?.email && appliedValues?.nome) {
          return (
            d.email?.includes(appliedValues?.email) &&
            d.name?.includes(appliedValues?.nome)
          )
        }

        return (
          d.email?.includes(appliedValues?.email) ||
          d.name?.includes(appliedValues?.nome)
        )
      })
    }

    function ordenarUsuarios(
      usuarios: UsuarioModel[],
      fieldName: keyof UsuarioModel,
      direction: string,
    ) {
      const sortDirection = direction === 'desc' ? -1 : 1
      return usuarios.sort((a, b) => {
        const campo1 = a[fieldName] as string
        const campo2 = b[fieldName] as string

        if (campo1!.toLocaleLowerCase() < campo2!.toLocaleLowerCase()) {
          return 1 * sortDirection
        }

        if (campo1!.toLocaleLowerCase() > campo2!.toLocaleLowerCase()) {
          return -1 * sortDirection
        }

        return 0
      })
    }

    let d = data || []

    if (
      appliedValues !== undefined &&
      Object.values(appliedValues).length > 0
    ) {
      d = filtrarUsuarios(d)
    }

    d = ordenarUsuarios(d, sort.column as keyof UsuarioModel, sort.direction)

    return d.map((d) => {
      const uuid = d.permissao.configs?.fdv_vendedor_uuid || ''
      const usuarioFdv = usuariosfdv?.find((u) => uuid === u.uuid) || null

      return {
        usuarioUuid: usuarioFdv?.uuid,
        ...d,
        vinculo: d.permissao.configs?.fdv_vendedor_codigo,
        vendedorUuid: d.permissao.configs?.fdv_vendedor_uuid,
        usuarioVinculado: `${usuarioFdv?.codigo} - ${usuarioFdv?.nome}`,
        permissao: usuarioFdv?.role,
      }
    })
  }, [data, usuariosfdv, appliedValues, sort])

  const handleClickItem = useCallback(
    (event: 'edit' | 'delete', value: UsuarioModel) => {
      switch (event) {
        case 'edit':
          navigate(`/conta/${value.uuid}`, { state: value })
          break
        case 'delete':
          openConfirm({
            usuario: value,
            empresaUuid: value.permissao.uuid,
          })
          break
      }
    },
    [],
  )

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'usuarioUuid',
        options: {
          display: false,
        },
      },
      {
        name: 'email',
        label: 'E-mail',
        options: {
          sort: true,
          setCellProps: () => ({
            style: {
              width: '200px',
            },
          }),
        },
      },
      {
        name: 'name',
        label: 'Nome',
        options: {
          sort: true,
        },
      },
      {
        name: 'usuarioVinculado',
        label: 'Usuário Vinculado',
        options: {
          sort: false,
        },
        // options: {
        //   setCellProps: () => ({
        //     style: {
        //       width: '156px',
        //     },
        //   }),
        // },
      },
      {
        name: 'permissao',
        label: 'Permissão',
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value: RoleModel, { rowData }) => {
            return (
              <DropDownRole
                value={value?.uuid || ''}
                usuarioUuid={rowData[0]}
              />
            )
          },
          setCellProps: () => ({
            style: {
              width: '200px',
              maxWidth: '200px',
            },
          }),
        },
      },
      {
        name: 'uuid',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          setCellProps: OptionStyles,
          customBodyRender: (value) => {
            const _data = findData<UsuarioModel>(data || [], value, 'uuid')
            return (
              <Options
                displayUpdateButton={false}
                value={_data}
                onClick={handleClickItem}
              />
            )
          },
        },
      },
    ],
    [data, handleClickItem],
  )

  return (
    <Surface>
      <DataTable
        error={error?.message}
        columns={columns}
        data={usuarios || []}
        isLoading={isLoading || carregandoUsuarios}
        isFetching={isFetching}
        options={{
          sort: true,
          sortOrder: {
            name: sort.column,
            direction: sort.direction,
          },
        }}
        changeSort={(column, direction) => {
          setSort({ column, direction: direction as 'asc' | 'desc' })
          // ordenaUsuarios(sort.column as keyof UsuarioModel, sort.direction)
        }}
      />
    </Surface>
  )
}
