import { Icon, Stack, Typography, useTheme } from '@mui/material'
import { ReactNode } from 'react'

interface ToggleViewButtonProps {
  label: string
  icon: ReactNode
  active: boolean
  onClick: () => void
}

export default function ToggleViewButton(props: ToggleViewButtonProps) {
  const { label, icon, active, onClick } = props

  const theme = useTheme()

  return (
    <Stack
      sx={{
        width: '80px',
        alignItems: 'center',
        gap: 1,
        padding: 2,
        borderRadius: '8px',
        border: active
          ? `solid 2px ${theme.palette.primary.main}`
          : `solid 1px ${theme.palette.grey[300]}`,
        cursor: 'pointer',
        backgroundColor: active ? 'rgba(89, 195, 224, 8%);' : '',
        ':hover': {
          borderColor: active ? 'none' : theme.palette.grey[600],
        },
      }}
      onClick={onClick}
    >
      <Icon
        sx={{
          color: active ? theme.palette.primary.main : theme.palette.grey[500],
        }}
      >
        {icon}
      </Icon>

      <Typography
        variant="button"
        sx={{
          color: active ? theme.palette.primary.main : theme.palette.grey[500],
        }}
      >
        {label}
      </Typography>
    </Stack>
  )
}
