import { ButtonContainer } from '@data-c/ui'
import { Box } from '@mui/material'
import Form from './components/Form'
import Table from './components/Table'
import { FormProvider } from '@data-c/providers'

interface TabVendedoresProps {
  funilVendaUuid: string
}

export default function TabVendedores(props: TabVendedoresProps) {
  const { funilVendaUuid } = props

  return (
    <Box>
      <ButtonContainer sx={{ mb: 2 }}>
        <FormProvider>
          <Form funilVendaUuid={funilVendaUuid} />
        </FormProvider>
      </ButtonContainer>
      <Table funilVendaUuid={funilVendaUuid} />
    </Box>
  )
}
