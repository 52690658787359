import { useForm } from '@data-c/hooks'
import { Button, ButtonContainer, Dialog, DialogProps } from '@data-c/ui'
import { Alert, Stack, TextField } from '@mui/material'
import Error from 'components/Displays/Error'
import DocumentoTextField, {
  TIPO_DOCUMENTO,
} from 'components/DocumentoTextField'
import { sinalizarUsuarioMigrado } from 'hooks/queries/useMigrateAccount'
import { Configs, useCadastrarUsuario } from 'hooks/queries/useMioAuth'
import { useEffect, useState } from 'react'
import useValidations from 'hooks/useValidations'
import * as yup from 'yup'
import useNotification from 'hooks/useNotifications'
import { ClientesModel } from 'hooks/queries/useClientes'

const schema = yup.object().shape({
  email: yup.string().email().nullable(true).required('Informe o email'),
  nome: yup.string().nullable(true).required('Informe o Nome'),
  cpf: yup.string().nullable(true).required('Informe o CPF'),
})

interface CreateAccountInputProps {
  uuid: string
  email: string
  nome: string
  cpf: string
  configs: Configs
}

export interface MioAuthProps extends DialogProps {
  cliente: ClientesModel
  onClose: () => void
}

export default function MioAuthForm(props: MioAuthProps) {
  const { cliente, open, onClose } = props

  const notifications = useNotification()

  const [isSubmitting, setSubmitting] = useState<boolean>(false)
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false)
  const { setValidationErrors, validationProps } = useValidations()
  const {
    data: formData,
    handleChange,
    setData,
  } = useForm<CreateAccountInputProps>({} as CreateAccountInputProps)
  const { mutateAsync: cadastrarUsuario } = useCadastrarUsuario()

  useEffect(() => {
    if (cliente) {
      const cnpjcpf =
        typeof cliente.cnpjcpf === 'string' && cliente.cnpjcpf.length === 1
          ? cliente.cnpjcpf
          : ''

      setData({
        uuid: cliente?.uuid || '',
        nome: cliente.nome,
        email: cliente?.email || '',
        cpf: cnpjcpf,
        configs: {
          fdv_empresa_id: cliente.empresa || -1,
          fdv_vendedor_codigo: cliente?.codigo?.toString() || '',
          fdv_vendedor_uuid: cliente.uuid || '',
        },
      })
    }
  }, [cliente])

  //   if (Boolean(error)) {
  //     return <Error />
  //   }

  if (!cliente?.empresaModel?.mioEmpresaUuid) {
    return (
      <Error
        title="Empresa indisponível"
        message={
          <p>
            A conta da sua empresa ainda não está habilitada para fazer este
            cadastro.
          </p>
        }
      />
    )
  }

  const handleSubmit = async () => {
    setValidationErrors(null)
    schema
      .validate(formData, { abortEarly: false })
      .then(async () => {
        setSubmitting(true)
        try {
          await cadastrarUsuario({
            empresaUuid: cliente?.empresaModel.mioEmpresaUuid || '',
            usuario: {
              email: formData.email,
              name: formData.nome,
              uuid: formData.uuid || '',
              documento: formData.cpf || '',
              permissao: {
                configs: { ...formData.configs },
              },
            },
            appId: 'app-fdv-mais',
          })
          await sinalizarUsuarioMigrado(
            cliente.empresa,
            cliente?.codigo?.toString() || '',
          )
          setShowSuccessMessage(true)
          onClose()
          notifications.notifySuccess(
            'Conta do cliente criada com sucesso, um e-mail foi enviado com as instruções de acesso',
          )
        } catch (e) {
          notifications.notifyException(e)
        }
      })
      .catch((err) => {
        setValidationErrors(err)
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  const emailValidation = validationProps('email')
  return (
    <Dialog
      open={open}
      onClose={onClose}
      dialogActionsProps={{
        confirmLabel: 'Salvar',
        isLoadingConfirmButton: isSubmitting,
        onConfirm: () => {
          handleSubmit()
        },
      }}
    >
      <Stack spacing={2}>
        {!showSuccessMessage && !cliente?.migracaoMio && (
          <>
            <DocumentoTextField
              disabled={showSuccessMessage}
              inputProps={{
                label: 'CPF ou CNPJ',
              }}
              tipoDocumento={TIPO_DOCUMENTO.INDEFINIDO}
              name="cpf"
              onChange={handleChange}
              value={formData?.cpf || ''}
              {...validationProps('cpf')}
            />
            <TextField
              disabled={showSuccessMessage}
              label="Nome"
              name="nome"
              onChange={handleChange}
              value={formData?.nome || ''}
              {...validationProps('nome')}
            />
            <TextField
              disabled={showSuccessMessage}
              label="E-mail"
              name="email"
              onChange={handleChange}
              value={formData?.email || ''}
              helperText={
                emailValidation.error
                  ? emailValidation.helperText
                  : 'Informe um e-mail que o cliente consegue acessar, a senha de acesso será enviada para ele.'
              }
              error={emailValidation.error}
            />
          </>
        )}

        {Boolean(cliente?.migracaoMio) && (
          <Alert
            variant="outlined"
            severity="info"
            action={
              <ButtonContainer>
                <Button variant="contained" color="success" onClick={() => {}}>
                  Voltar
                </Button>
              </ButtonContainer>
            }
          >
            Você já possui uma conta Data C
          </Alert>
        )}
      </Stack>
    </Dialog>
  )
}
