import { AxiosResponse } from 'axios'
import { useState } from 'react'
import api from 'services/api'

export default function useUpload() {
  const [progress, setUploadPercentage] = useState(0)
  const [isUploading, setIsUploading] = useState<boolean>(false)
  const [error, setError] = useState<string | boolean>(false)

  async function uploadFile<T>(url: string, attrName: string, file: File) {
    const formData = new FormData()
    formData.append(attrName, file)
    setUploadPercentage(0)
    setIsUploading(true)
    setError(false)
    try {
      const response = await api.post<any, AxiosResponse<T>>(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent
          let percentCompleted = Math.round((loaded * 100) / (total || 1))
          setUploadPercentage(percentCompleted)
        },
      })
      setIsUploading(false)
      return response
    } catch (err) {
      setIsUploading(false)
      setError('Erro ao carregar arquivo')
    }
  }

  return { isUploading, progress, error, uploadFile }
}
