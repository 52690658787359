import { Button } from '@data-c/ui'

import { Refresh } from '@mui/icons-material'
import { Box, CircularProgress, Typography } from '@mui/material'

import FeedbackSuccessInline from 'components/SuccessInLine'

interface FeedbackRequestStatusProps {
  value?: any
  isLoading: boolean
  isSuccess: boolean
  error?: boolean | string | null
  helperText?: string | boolean
  onRetry?: (value: any) => void
}

export default function FeedbackRequestStatus(
  props: FeedbackRequestStatusProps,
) {
  const { value, isLoading, isSuccess, error, helperText, onRetry } = props

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        height: '24px',
        padding: 1,
      }}
    >
      {isLoading && (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
          <CircularProgress size={16} color="primary" />
          <Typography variant="body1" color="primary">
            Salvando...
          </Typography>
        </Box>
      )}

      {isSuccess && (
        <FeedbackSuccessInline visible={isSuccess} timeout={3000} />
      )}

      {Boolean(error) && (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
          <Typography variant="body1" color="error">
            {helperText
              ? `${helperText}`
              : 'Não foi possível salvar as alterações mas você pode tentar novamente'}
          </Typography>
          <Button
            variant="text"
            startIcon={<Refresh fontSize="small" />}
            sx={{
              ':hover': {
                backgroundColor: 'transparent',
                color: (theme) => theme.palette.primary.dark,
              },
            }}
            onClick={() => {
              if (onRetry) onRetry(value)
            }}
          >
            Tentar novamente
          </Button>
        </Box>
      )}
    </Box>
  )
}
