import { ReactNode } from 'react'
import { Stack } from '@mui/material'

interface ButtonContainerProps {
  children: ReactNode
}

export default function FloatButtonContainer(props: ButtonContainerProps) {
  const { children } = props

  return (
    <Stack
      sx={{
        width: '100%',
        position: 'fixed',
        bottom: '0px',
        right: '0px',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        padding: (theme) => theme.spacing(1),
        pr: (theme) => theme.spacing(3),
        gap: 2,
        backgroundColor: (theme) => theme.palette.background.default,
      }}
    >
      {children}
    </Stack>
  )
}
