import { Content, PageTitle } from '@data-c/ui'
import { Container, Tab, Tabs } from '@mui/material'
import Loading from 'components/Displays/Loading'
import TabPanel from 'components/TabPanel'
import useFormaPagamento, {
  FormaPagamentoModel,
} from 'hooks/queries/useFormaPagamento'
import useTabs from 'hooks/useTabs'
import { useNavigate, useParams } from 'react-router-dom'
import TabCadastro from './components/TabCadastro'
import TabCondicoesPagamento from './components/TabCondicoesPagamento'
import { FormProvider } from '@data-c/providers'

export default function FormaPagamento() {
  const { id: formaPagamentoUuid } = useParams()
  const { useQueryByUuid } = useFormaPagamento()
  const { data: formaPagamento, isLoading } =
    useQueryByUuid<FormaPagamentoModel>(formaPagamentoUuid as string)

  const { handleChange, value } = useTabs()
  const navigate = useNavigate()
  if (isLoading)
    return (
      <Container>
        <Loading />
      </Container>
    )

  return (
    <Container>
      <PageTitle
        title={`Forma de Pagamento - ${formaPagamento?.nome || ''}`}
        onBack={() => navigate(-1)}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="tabs tabela de preco"
        >
          <Tab label="Cadastro" value={0} />
          <Tab label="Condições de Pgto" value={1} />
        </Tabs>
      </PageTitle>
      <Content>
        <TabPanel value={value} index={0}>
          <FormProvider>
            <TabCadastro
              formaPagamento={formaPagamento || ({} as FormaPagamentoModel)}
            />
          </FormProvider>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <TabCondicoesPagamento
            formaPagamento={formaPagamento || ({} as FormaPagamentoModel)}
          />
        </TabPanel>
      </Content>
    </Container>
  )
}
