import { useCallback, useMemo } from 'react'

import {
  DataTable,
  OptionStyles,
  DataTableOptions as Options,
  Flag,
  // CurrencyCellStyle,
} from '@data-c/ui'
import { useFormApi } from '@data-c/providers'
import { usePagination, formatCurrency, findData } from '@data-c/hooks'

import { MUIDataTableColumnDef } from 'mui-datatables'
import useCondicaoPagamento, {
  CondicaoPagamentoFilters1,
  CondicaoPagamentoModel,
} from 'hooks/queries/useCondicaoPagamento'
import DataTableProps from 'interfaces/DataTableProps'
import Surface from 'components/Surface'

interface TableProps
  extends DataTableProps<CondicaoPagamentoModel, CondicaoPagamentoFilters1> {}

export default function Table(props: TableProps) {
  const { filters } = props

  const { changePage, changePageSize, pagination } = usePagination()
  const { openForm, openConfirm } = useFormApi()
  const { useQuery } = useCondicaoPagamento()
  const { data, isLoading, isFetching, error } = useQuery({
    pagination,
    queryParams: filters,
  })

  const condicoesDePagamento = useMemo(() => {
    return data?.data?.map((item: CondicaoPagamentoModel) => {
      return {
        ...item,
        fatorDesbloqueio: formatCurrency(item?.fatorDesbloqueio || 0),
        fator: formatCurrency(item?.fator || ''),
        descontoMax:
          (item?.descontoMax || -1) >= 0
            ? formatCurrency(item?.descontoMax || '')
            : '',
      }
    })
  }, [data])

  const handleClickItem = useCallback(
    (event: 'edit' | 'delete', data: CondicaoPagamentoModel) => {
      switch (event) {
        case 'edit':
          openForm(data)
          break
        case 'delete':
          openConfirm(data)
          break
      }
    },
    [openForm, openConfirm],
  )

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'codigo',
        label: 'Código',
        options: {
          setCellProps() {
            return { width: '96px' }
          },
        },
      },
      {
        name: 'nome',
        label: 'Nome',
      },
      {
        name: 'condpgtot',
        label: 'Prazo',
      },
      // {
      //   name: 'fatorDesbloqueio',
      //   label: 'Fator Desbloqueio',
      //   options: {
      //     setCellProps: CurrencyCellStyle,
      //   },
      // },
      // {
      //   name: 'fator',
      //   label: 'Fator (%)',
      //   options: {
      //     setCellProps: CurrencyCellStyle,
      //   },
      // },
      // {
      //   name: 'descontoMax',
      //   label: 'Desconto Max (%)',
      //   options: {
      //     setCellProps: CurrencyCellStyle,
      //   },
      // },
      {
        name: 'ativo',
        label: 'Ativo',
        options: {
          setCellProps: () => ({
            style: {
              width: '60px',
              textAlign: 'center',
            },
          }),
          customBodyRender: (value) => {
            return <Flag isFlagged={value === 1} type="success" />
          },
        },
      },
      {
        name: 'uuid',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          setCellProps: OptionStyles,
          customBodyRender: (value) => {
            const _data = findData(data?.data || [], value)
            return (
              <Options
                displayUpdateButton={true}
                value={_data}
                onClick={handleClickItem}
              />
            )
          },
        },
      },
    ],
    [data, handleClickItem],
  )

  return (
    <Surface>
      <DataTable
        error={error?.message}
        columns={columns}
        data={condicoesDePagamento || []}
        pagination={data?.pagination}
        isLoading={isLoading}
        isFetching={isFetching}
        changePage={changePage}
        changePageSize={changePageSize}
        options={{
          rowsPerPageOptions: [15, 50, 100],
          rowsPerPage: 15,
        }}
      />
    </Surface>
  )
}
