import { Paper } from '@mui/material'
import { ProdutoModel } from 'hooks/queries/useProdutos'
import Form from 'pages/Produtos/components/Form'

interface TabCadastroProdutoProps {
  produto: ProdutoModel
}

export default function TabCadastroProduto(props: TabCadastroProdutoProps) {
  const { produto } = props

  return (
    <Paper sx={{ p: 2, mb: 1 }} elevation={0}>
      <Form data={produto} view="plain" />
    </Paper>
  )
}
