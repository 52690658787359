import { createContext, useContext } from 'react'
import invariant from 'tiny-invariant'
import type { CleanupFn } from '@atlaskit/pragmatic-drag-and-drop/types'
import { PedidoModel } from 'hooks/queries/usePedidos'
import { EtapaModel } from 'hooks/queries/useFunilEtapa'

export interface BoardContextProps {
  // getColumns: () => ColumnType[];

  // reorderColumn: (args: { startIndex: number; finishIndex: number }) => void;

  // reorderCard: (args: {
  //   columnId: string;
  //   startIndex: number;
  //   finishIndex: number;
  // }) => void;

  // moveCard: (args: {
  //   startColumnId: string;
  //   finishColumnId: string;
  //   itemIndexInStartColumn: number;
  //   itemIndexInFinishColumn?: number;
  // }) => void;

  removeColumn: (args: { etapa: EtapaModel }) => void

  addNewCard: (args: { pedido: PedidoModel }) => void

  registerCard: (args: {
    cardId: string
    entry: {
      element: HTMLElement
    }
  }) => CleanupFn

  registerColumn: (args: {
    columnId: string
    entry: {
      element: HTMLElement
    }
  }) => CleanupFn

  instanceId: symbol
}

export const BoardContext = createContext<BoardContextProps | null>(null)

export function useBoardContext(): BoardContextProps {
  const value = useContext(BoardContext)
  invariant(value, 'cannot find BoardContext provider')
  return value
}
