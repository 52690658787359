import { Grid, TextField } from '@mui/material'

import useFormaPagamento, {
  FormaPagamentoModel,
} from 'hooks/queries/useFormaPagamento'
import useValidations from 'hooks/useValidations'
import * as yup from 'yup'
import { Checkbox, FormContainer } from '@data-c/ui'
import { useFormApi, useFormData } from '@data-c/providers'
import FormProps from 'interfaces/FormProps'
import { useEffect } from 'react'

export default function Form(props: FormProps<FormaPagamentoModel>) {
  const { data: formaPagamento, view = 'dialog' } = props
  const { onChangeFormValue, changeValues, closeForm, toggleSubmit } =
    useFormApi<FormaPagamentoModel>()

  const { formValues: data } = useFormData<FormaPagamentoModel>()

  useEffect(() => {
    if (formaPagamento) {
      changeValues(formaPagamento)
    }
  }, [])

  const schema = yup.object().shape({
    nome: yup.string().required('Informe o nome'),
  })

  const { setValidationErrors, validationProps } = useValidations()
  const { useSubmit } = useFormaPagamento()
  const { mutateAsync } = useSubmit()

  function handleSubmitForm() {
    setValidationErrors(null)
    schema
      .validate(data, { abortEarly: false })
      .then(async () => {
        toggleSubmit(true)
        await mutateAsync(data)
        closeForm()
      })
      .catch((err) => {
        setValidationErrors(err)
      })
      .finally(() => toggleSubmit(false))
  }

  return (
    <FormContainer
      dialogProps={{ title: 'Forma de Pagamento', disableRestoreFocus: true }}
      onSubmitForm={handleSubmitForm}
      view={view}
    >
      <Grid container spacing={2}>
        <Grid item xl={2} lg={2} md={2} sm={3} xs={12}>
          <TextField
            name="codigo"
            label="Código"
            value={data?.codigo || ''}
            onChange={onChangeFormValue}
            disabled
          />
        </Grid>
        <Grid item xl={10} lg={10} md={10} sm={12} xs={12}>
          <TextField
            autoFocus
            name="nome"
            label="Nome"
            required
            value={data?.nome || ''}
            onChange={onChangeFormValue}
            inputProps={{ maxLength: 40 }}
            {...validationProps('nome')}
          />
        </Grid>
        <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
          <Checkbox
            label="Ativo"
            name="ativo"
            onChange={onChangeFormValue}
            checked={Boolean(data?.ativo)}
          />
        </Grid>
      </Grid>
    </FormContainer>
  )
}

Form.defaultProps = {
  data: {},
}
