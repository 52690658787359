import { Container, Grid, Step, StepLabel, Stepper } from '@mui/material'
import { Content, PageTitle } from '@data-c/ui'
import { memo, useState } from 'react'
import UploadFile from './components/UploadFile'
import Mapeamento from './components/Mapeamento'
import { ImportarExcelInterface } from 'hooks/queries/useImportarEntidade'
import Preview from './components/Preview'
import Resultado from './components/Resultado'
import { useNavigate } from 'react-router-dom'

const steps = ['Upload', 'Mapeamento', 'Prévia', 'Resultado']

const MemoizedPreview = memo(Preview)

export default function ImportarEntidade() {
  const navigate = useNavigate()
  const [activeStep, setActiveStep] = useState<{
    step: number
    excelProps?: ImportarExcelInterface
  }>({ step: 0 })

  function handleComplete() {
    setActiveStep({
      step: 0,
      excelProps: undefined,
    })
  }

  function handleUploadCompleted(excelProps: ImportarExcelInterface) {
    setActiveStep({
      step: 1,
      excelProps: excelProps,
    })
  }

  function handleMapCompleted(excelProps: ImportarExcelInterface) {
    setActiveStep({
      step: 2,
      excelProps: excelProps,
    })
  }

  function handleImportComplete(excelProps: ImportarExcelInterface) {
    setActiveStep({
      step: 3,
      excelProps: excelProps,
    })
  }

  function handleBack() {
    setActiveStep((prev) => ({
      ...prev,
      step: prev.step - 1,
    }))
  }

  return (
    <Container>
      <PageTitle
        onBack={() => navigate(-1)}
        toolbarProps={{ sx: { minHeight: 45 } }}
        title="Importar"
      ></PageTitle>
      <Content>
        <Grid container spacing={2}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Stepper nonLinear activeStep={activeStep.step} alternativeLabel>
              {steps.map((label, index) => (
                <Step completed={activeStep.step > index} key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>

          {activeStep.step === 0 && (
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <UploadFile onUploadCompleted={handleUploadCompleted} />
            </Grid>
          )}

          {activeStep.step === 1 && (
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Mapeamento
                onMapCompleted={handleMapCompleted}
                onBack={handleBack}
                excelProps={activeStep.excelProps}
              />
            </Grid>
          )}

          {activeStep.step === 2 && (
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <MemoizedPreview
                excelProps={
                  activeStep.excelProps || ({} as ImportarExcelInterface)
                }
                onBack={handleBack}
                onImportCompleted={handleImportComplete}
              />
            </Grid>
          )}

          {activeStep.step === 3 && (
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Resultado
                excelProps={
                  activeStep.excelProps || ({} as ImportarExcelInterface)
                }
                onConfirm={() => handleComplete()}
              />
            </Grid>
          )}
        </Grid>
      </Content>
    </Container>
  )
}
