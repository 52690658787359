import { useFilterApi, useFilterData } from '@data-c/providers'
import { FilterContainer } from '@data-c/ui'
import { Stack, TextField } from '@mui/material'
import { UsuarioFilter, UsuarioFilter1 } from 'hooks/queries/useUsuario'

export default function Filter() {
  const { onChangeFilterValue } = useFilterApi()
  const { filterValues } = useFilterData<UsuarioFilter, UsuarioFilter1>()

  return (
    <FilterContainer
      title="Pesquisar"
      triggerButtonProps={{ variant: 'outlined' }}
      renderLabel={(filterLabel: string, filterValue: any) => {
        return `${filterLabel} - ${filterValue}`
      }}
    >
      <Stack spacing={2}>
        <TextField
          autoFocus
          name="email"
          value={filterValues?.email?.value}
          label={filterValues?.email?.label}
          onChange={onChangeFilterValue}
        />
        <TextField
          name="nome"
          value={filterValues?.nome?.value}
          label={filterValues?.nome?.label}
          onChange={onChangeFilterValue}
        />
      </Stack>
    </FilterContainer>
  )
}
