import { AxiosResponse } from 'axios'
import { useState } from 'react'
import api from 'services/api'

interface FileUploadProgress {
  file: File
  progress: number
  isUploading: boolean
  error: string | null
}

export default function useMultipleUpload() {
  const [uploads, setUploads] = useState<FileUploadProgress[]>([])

  const initializeUpload = (file: File) => ({
    file,
    progress: 0,
    isUploading: true,
    error: null,
  })

  const updateUploadProgress = (file: File, progress: number) => {
    setUploads((prev) =>
      prev.map((upload) =>
        upload.file === file ? { ...upload, progress } : upload,
      ),
    )
  }

  const markUploadComplete = (file: File) => {
    setUploads((prev) =>
      prev.map((upload) =>
        upload.file === file ? { ...upload, isUploading: false } : upload,
      ),
    )
  }

  const markUploadError = (file: File, error: string) => {
    setUploads((prev) =>
      prev.map((upload) =>
        upload.file === file
          ? { ...upload, isUploading: false, error }
          : upload,
      ),
    )
  }

  async function uploadFiles<T>(url: string, attrName: string, files: File[]) {
    const initializedUploads = files.map(initializeUpload)
    setUploads(initializedUploads)

    const uploadPromises = files.map(async (file) => {
      const formData = new FormData()
      formData.append(attrName, file)

      try {
        const response = await api.post<any, AxiosResponse<T>>(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent
            const percentCompleted = Math.round((loaded * 100) / (total || 1))
            updateUploadProgress(file, percentCompleted)
          },
        })
        markUploadComplete(file)
        return response
      } catch (err) {
        markUploadError(file, 'Erro ao carregar arquivo')
      }
    })

    await Promise.all(uploadPromises)
  }

  return { uploads, uploadFiles }
}
