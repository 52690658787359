import { Box,  Switch } from '@mui/material'
import CardConfig from 'components/CardConfig'
import { useRoleContext } from 'components/Contexts/RoleContext'

export default function FatorPrecificacao() {
  const { role, changeRolePermission } = useRoleContext()
  const { fator_precificacao } = role?.permissoes || {}

  return (
    <Box sx={{ mt: 2 }}>      
        <CardConfig
          title="Acesso (Ver)"
          description={
            <div>
              Define o nível de acesso que o usuário possui para poder ver os
              registros (as regras se aplicam aos relatórios)
            </div>
          }
          component={
            <Switch
              name="fator_precificacao.read"
              checked={fator_precificacao?.read || ''}
              onChange={changeRolePermission}
              disabled={Boolean(role?.fixa)}
            />
          }
        />
        <CardConfig
          title="Acesso (Criar)"
          description={
            <div>
              Define o nível de acesso que o usuário possui para poder criar o
              registro
            </div>
          }
          component={
            <Switch
              name="fator_precificacao.create"
              checked={fator_precificacao?.create || ''}
              onChange={changeRolePermission}
              disabled={Boolean(role?.fixa)}
            />
          }
        />
        <CardConfig
          title="Acesso (Alterar)"
          description={
            <div>
              Define o nível de acesso que o usuário possui para poder alterar
              os registros
            </div>
          }
          component={
            <Switch
              name="fator_precificacao.alter"
              checked={fator_precificacao?.alter || ''}
              onChange={changeRolePermission}
              disabled={Boolean(role?.fixa)}
            />
          }
        />
        <CardConfig
          title="Acesso (Deletar)"
          description={
            <div>
              Define o nível de acesso que o usuário possui para poder deletar
              os registros
            </div>
          }
          component={
            <Switch
              name="fator_precificacao.delete"
              checked={fator_precificacao?.delete || ''}
              onChange={changeRolePermission}
              disabled={Boolean(role?.fixa)}
            />
          }
        />      
    </Box>
  )
}
