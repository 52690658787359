import { useState } from 'react'
import { Transporter, TransporterProps } from '@data-c/ui'
import { PedidoModel, useQueryObterPedidos } from 'hooks/queries/usePedidos'
import PedidoTable from './components/Table'

type PedidoTransporterProps = {} & Omit<
  TransporterProps<PedidoModel>,
  'table' | 'form' | 'onSearch' | 'renderValue' | 'options'
>

export default function PedidoTransporter(props: PedidoTransporterProps) {
  const { value, ...rest } = props
  const [query, setQuery] = useState<string | null>()
  // const { useQuery } = usePedidos()

  const { data, isLoading } = useQueryObterPedidos({
    queryParams: {
      plainQuery: query || null,
    },
    pagination: {
      page: 1,
      pageSize: 100,
    },
  })

  function handleSearch(q: string | null) {
    setQuery(q)
  }

  return (
    <Transporter
      onSearch={handleSearch}
      options={data?.data || []}
      isLoading={isLoading}
      table={PedidoTable}
      label="Negociação"
      renderValue={(value) => {
        return `${value?.codigo || ''} --- ${
          value?.vendaAsBrazilianDate || ''
        } --- ${value?.representante?.nome || ''} --- ${
          value?.cliente?.nome || ''
        }`
      }}
      value={value}
      {...rest}
    />
  )
}
