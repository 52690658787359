import { Stack } from '@mui/material'

import { MotivosDePerdaModel } from 'hooks/queries/useMotivosDePerda'
import { PedidoModel, SituacaoPedidoEnum } from 'hooks/queries/usePedidos'
import MotivosDePerdaTransporter from 'components/Transporters/MotivosDePerdaTransporter'
import React from 'react'
import TextField from 'components/Inputs/TextField'

interface MotivoPerdaFormProps<T> {
  validationProps: (filderName: string) => {
    helperText: string | false
    error: boolean
  }
  data: T
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  setData: React.Dispatch<React.SetStateAction<T>>
}

export default function MotivoPerdaForm(
  props: MotivoPerdaFormProps<PedidoModel>,
) {
  const { validationProps, data, handleChange, setData } = props

  return (
    <Stack spacing={2}>
      <MotivosDePerdaTransporter
        name="motivoPerdaUuid"
        value={data?.motivoPerda || null}
        required
        onChange={(value: MotivosDePerdaModel | null) => {
          setData({
            ...data,
            motivoPerda: value || null,
            motivoPerdaUuid: value?.uuid || '',
            situacao: SituacaoPedidoEnum.NEGOCIACAO_PERDIDA,
          })
        }}
        {...validationProps('motivoPerdaUuid')}
      />
      <TextField
        required={Boolean(data?.motivoPerda?.informarObservacao)}
        name="observacaoMotivoPerda"
        label="Observação"
        multiline
        rows={4}
        value={data?.observacaoMotivoPerda || ''}
        onChange={handleChange}
        {...validationProps('observacaoMotivoPerda')}
      />
    </Stack>
  )
}
