import { MenuItem, TextField, TextFieldProps } from '@mui/material'

export enum UsaCondicaoPagamentoOptions {
  EXIBE_CONDICOES_ATIVAS = 1,
  EXIBE_CONDICOES_ATRELADAS_VENDEDOR = 2,
  EXIBE_CONDICOES_ATRELADAS_CLIENTE_CASO_NAO_HAJA_CONDICAO_ATRELADA_CLIENTE_UTILIZA_MODO_2 = 3,
  EXIBE_CONDICAO_VENDEDOR = 4,
  EXIBE_CONDICAO_CLIENTE_CASO_NAO_HAJA_CONDICAO_NO_CLIENTE_EXIBE_A_DO_VENDEDOR = 5,
}

export default function DropDownUsaCondicaoPagamento(props: TextFieldProps) {
  return (
    <TextField select {...props}>
      <MenuItem value={UsaCondicaoPagamentoOptions.EXIBE_CONDICOES_ATIVAS}>
        Exibe as condições de pagamentos ativas
      </MenuItem>
      <MenuItem
        value={UsaCondicaoPagamentoOptions.EXIBE_CONDICOES_ATRELADAS_VENDEDOR}
      >
        Exibe as condições de pagamentos atreladas ao vendedor
      </MenuItem>
      <MenuItem
        value={
          UsaCondicaoPagamentoOptions.EXIBE_CONDICOES_ATRELADAS_CLIENTE_CASO_NAO_HAJA_CONDICAO_ATRELADA_CLIENTE_UTILIZA_MODO_2
        }
      >
        Exibe as condições de pagamentos atreladas ao cliente, quando não houver
        irá exibir as condições de pagamentos atreladas ao vendedor
      </MenuItem>
      <MenuItem value={UsaCondicaoPagamentoOptions.EXIBE_CONDICAO_VENDEDOR}>
        Exibe a condição de pagamento do vendedor
      </MenuItem>
      <MenuItem
        value={
          UsaCondicaoPagamentoOptions.EXIBE_CONDICAO_CLIENTE_CASO_NAO_HAJA_CONDICAO_NO_CLIENTE_EXIBE_A_DO_VENDEDOR
        }
      >
        Exibe a condição de pagamento do cliente, quando não houver irá exibir a
        condição de pagamento do vendedor
      </MenuItem>
    </TextField>
  )
}
