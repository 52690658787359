import { useMemo, useCallback } from 'react'

import {
  CurrencyCellStyle,
  DataTable,
  Flag,
  OptionStyles,
  DataTableOptions as Options,
} from '@data-c/ui'
import {
  TransportableDataTableProps,
  findData,
  formatCurrency,
} from '@data-c/hooks'

import { MUIDataTableColumnDef } from 'mui-datatables'
import {
  CondicaoPagamentoModel,
  useQueryObterCondicaoPagamentoPorRegra,
} from 'hooks/queries/useCondicaoPagamento'

interface TableProps
  extends TransportableDataTableProps<CondicaoPagamentoModel> {
  clienteUuid?: string
  vendedorUuid?: string
}

export default function Table(props: TableProps) {
  const { clienteUuid, vendedorUuid, onTransport, enableTransporter, query } =
    props

  const { data, isLoading, isFetching, error } =
    useQueryObterCondicaoPagamentoPorRegra({
      plainQuery: query,
      clienteUuid,
      vendedorUuid,
      ativo: true,
    })

  const condicoesDePagamento = useMemo(() => {
    return data?.map((item: CondicaoPagamentoModel) => {
      return {
        ...item,
        fatorDesbloqueio: formatCurrency(item?.fatorDesbloqueio || 0),
        fator: formatCurrency(item?.fator || ''),
        descontoMax:
          (item?.descontoMax || -1) >= 0
            ? formatCurrency(item?.descontoMax || '')
            : '',
      }
    })
  }, [data])

  const handleClickItem = useCallback(
    (event: 'transport', data: CondicaoPagamentoModel) => {
      if (event === 'transport') {
        onTransport && onTransport(data)
      }
    },
    [onTransport],
  )

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'codigo',
        label: 'Código',
        options: {
          setCellProps() {
            return { width: '96px' }
          },
        },
      },
      {
        name: 'nome',
        label: 'Nome',
      },
      {
        name: 'fatorDesbloqueio',
        label: 'Fator Desbloqueio',
        options: {
          setCellProps: CurrencyCellStyle,
        },
      },
      {
        name: 'fator',
        label: 'Fator (%)',
        options: {
          setCellProps: CurrencyCellStyle,
        },
      },
      {
        name: 'descontoMax',
        label: 'Desconto Max (%)',
        options: {
          setCellProps: CurrencyCellStyle,
        },
      },
      {
        name: 'ativo',
        label: 'Ativo',
        options: {
          setCellProps: () => ({
            style: {
              width: '60px',
              textAlign: 'center',
            },
          }),
          customBodyRender: (value) => {
            return <Flag isFlagged={value === 1} type="success" />
          },
        },
      },
      {
        name: 'uuid',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          setCellProps: OptionStyles,
          customBodyRender: (value) => {
            const _data = findData(data || [], value, 'uuid')
            return (
              <Options
                displayTransporterButton={enableTransporter}
                displayDeleteButton={false}
                displayUpdateButton={false}
                value={_data}
                onClick={handleClickItem}
              />
            )
          },
        },
      },
    ],
    [findData, handleClickItem, enableTransporter],
  )

  return (
    <DataTable
      error={error?.message}
      columns={columns}
      data={condicoesDePagamento || []}
      isLoading={isLoading}
      isFetching={isFetching}
    />
  )
}
