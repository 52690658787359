import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import useCrud from 'hooks/useCrud'
import { AxiosError } from 'axios'
import api from 'services/api'
import { CondicaoPagamentoModel } from './useCondicaoPagamento'
import {
  ErrorInterface,
  HttpResponseInterface,
  PaginationProps,
} from '@data-c/hooks'
import useNotification from 'hooks/useNotifications'
import { FilterOptions } from '@data-c/providers'

export enum AtivoEnum {
  TODOS = 'TODOS',
  ATIVOS = 'ATIVOS',
  INATIVOS = 'INATIVOS',
}

export interface LinkCondicaoForma {
  uuid: string
  codigo: number | null
  condicaoPagamento: CondicaoPagamentoModel
  formaPagamento: FormaPagamentoModel
}

export interface FormaPagamentoModel {
  uuid: string
  codigo: number | null
  nome: string
  ativo?: boolean
}

interface QueryParamsInterface {
  plainQuery?: string | null
  clienteUuid?: string
  formaPagamentoUuid?: string
  pagination?: PaginationProps
  ativo?: boolean
}

export interface FormaPagamentoFilter {
  nome?: FilterOptions<string>
  codigo?: FilterOptions<string>
  ativo?: FilterOptions<string>
}

export interface FormaPagamentoFilter1 {
  nome?: string
  codigo?: string
  ativo?: boolean
}

export const filters: FormaPagamentoFilter = {
  nome: {
    label: 'Nome',
  },
  codigo: {
    label: 'Código',
  },
  ativo: {
    label: 'Ativo',
    value: AtivoEnum.ATIVOS,
  },
}

export async function obterFormaPagamentoPorRegra(
  params: QueryParamsInterface,
): Promise<FormaPagamentoModel[]> {
  const response = await api.get<FormaPagamentoModel[]>(
    'vendas/forma-pagamento/forma-pagamento-por-regra',
    {
      params,
    },
  )
  return response.data
}

export function useQueryObterFormaPagamentoPorRegra(
  params: QueryParamsInterface,
) {
  return useQuery<FormaPagamentoModel[], AxiosError>(
    ['FORMADEPAGAMENTO', params],
    () => {
      return obterFormaPagamentoPorRegra(params)
    },
  )
}

export function useQueryObterCondicoesNaoVinculadas(
  params: QueryParamsInterface,
) {
  return useQuery<
    HttpResponseInterface<CondicaoPagamentoModel>,
    AxiosError<ErrorInterface, ErrorInterface>
  >(['FORMA_PGTO_CONDICOES', params], () => {
    return obterCondicoesNaoVinculadas(params)
  })
}

export async function obterCondicoesNaoVinculadas(
  params: QueryParamsInterface,
): Promise<HttpResponseInterface<CondicaoPagamentoModel>> {
  const { pagination: _pagination, formaPagamentoUuid, plainQuery } = params

  const response = await api.get(
    `administrador/forma-pagamento/${formaPagamentoUuid}/condicoes-nao-vinculadas`,
    {
      params: { plainQuery },
      headers: {
        'DC-Page': _pagination?.page,
        'DC-PageSize': _pagination?.pageSize,
      },
    },
  )

  const { data, meta: pagination } = response.data
  return { data, pagination }
}
interface VinculoCondicaoInputProps {
  formaPagamentoUuid: string
  idsSelecionados: Array<string>
}

export async function vincularCondicoes(
  formaPagamentoUuid: string,
  idsSelecionados: Array<string>,
): Promise<CondicaoPagamentoModel> {
  const response = await api.put(
    `administrador/forma-pagamento/${formaPagamentoUuid}/vincular-condicoes`,
    idsSelecionados,
  )
  return response.data
}

export function useVincularCondicoes() {
  const notifications = useNotification()
  const queryClient = useQueryClient()
  return useMutation<
    CondicaoPagamentoModel,
    AxiosError,
    VinculoCondicaoInputProps
  >(
    (data: VinculoCondicaoInputProps) =>
      vincularCondicoes(data.formaPagamentoUuid, data.idsSelecionados),
    {
      onSuccess(_: CondicaoPagamentoModel) {
        notifications.notifySuccess(`Condições vinculadas com sucesso`)
        queryClient.invalidateQueries(['FORMA_PGTO_CONDICOES'])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export async function obterCondicoesVinculadas(formaPagamentoUuid: string) {
  const response = await api.get(
    `administrador/forma-pagamento/${formaPagamentoUuid}/condicoes`,
  )
  return response.data
}

export function useQueryCondicoesVinculadas(formaPagamentoUuid: string) {
  return useQuery<Array<LinkCondicaoForma>, AxiosError>(
    ['FORMA_PGTO_CONDICOES', formaPagamentoUuid],
    () => obterCondicoesVinculadas(formaPagamentoUuid),
  )
}

export async function desvincularCondicoes(
  formaPagamento: string,
  ids: Array<string>,
) {
  await api.put(
    `/administrador/forma-pagamento/${formaPagamento}/desvincular-condicoes`,
    ids,
  )
}

export function useDesvincularCondicoes() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  return useMutation<void, AxiosError, VinculoCondicaoInputProps>(
    (data: VinculoCondicaoInputProps) =>
      desvincularCondicoes(data.formaPagamentoUuid, data.idsSelecionados),
    {
      onSuccess() {
        notifications.notifySuccess(`Condições desvinculadas com sucesso`)
        queryClient.invalidateQueries(['FORMA_PGTO_CONDICOES'])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export default function useFormaPagamento() {
  return {
    ...useCrud<FormaPagamentoModel, AxiosError>(
      'administrador/forma-pagamento',
      'Forma de Pagamento',
      'female',
    ),
    useQueryObterCondicoesNaoVinculadas,
    useQueryCondicoesVinculadas,
    useVincularCondicoes,
    useDesvincularCondicoes,
  }
}
