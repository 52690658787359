import { List, Drawer as MuiDrawer } from '@mui/material'
import { CSSObject, styled, Theme } from '@mui/material/styles'
import React, { ReactNode } from 'react'
import { MenuItemProps } from './MenuItem'
import { useLocation, useNavigate } from 'react-router-dom'

export interface MenuRootProps {
  children: ReactNode
  open: boolean
  toggleMenu: () => void
}

const openedMixin = (theme: Theme): CSSObject => ({
  width: theme?.mixins?.menu?.width || 64,
  background: '#FFFFFF',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
  background: '#FFFFFF',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  // width: `calc(${theme.spacing(8)} + 1px)`,
  width: 0,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(12)} + 1px)`,
  },
})

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: theme?.mixins?.menu?.width || 64,
  // boxShadow: '2px 3px 20px -16px rgb(32 32 36 / 30%)',
  borderRightStyle: 'none',
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))

const Offset = styled('div')(({ theme }) => theme.mixins.toolbar)

function cloneMenuItems(
  childrenProp: React.ReactNode,
  pathName: string,
  open: boolean,
  navigate: (link: string) => void,
): React.ReactNode {
  return React.Children.map(childrenProp, (child) => {
    if (!React.isValidElement<MenuItemProps>(child)) {
      return child
    }

    const splittedLink = child.props.link?.split('/') || []
    const linkName = splittedLink[1]
    // Clonar recursivamente os subitens (children)
    const newChildren = child.props?.children
      ? cloneMenuItems(child.props.children, pathName, open, navigate)
      : null

    return React.cloneElement(child, {
      open: open,
      selected: pathName === linkName,
      onClick: (link: string) => {
        navigate(link)
      }, // Corrigido para chamar navigate com o link do child
      children: newChildren,
    })
  })
}

export default function MenuRoot(props: MenuRootProps) {
  const { open, toggleMenu, children: childrenProp } = props
  const navigate = useNavigate()
  const location = useLocation()

  const splittedPath = location.pathname.split('/')
  const pathName = splittedPath[1]

  const newChildren = cloneMenuItems(childrenProp, pathName, open, navigate)

  return (
    <Drawer variant="permanent" open={open} onClose={toggleMenu}>
      <Offset />
      <List>{newChildren}</List>
    </Drawer>
  )
}
