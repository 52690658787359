import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { PageTitle } from '@data-c/ui'
import { FormProvider } from '@data-c/providers'

import { PedidoModel, useQueryObterPedidoPorId } from 'hooks/queries/usePedidos'

import { Badge, Box, Container, LinearProgress, Tab, Tabs } from '@mui/material'

import Loading from 'components/Displays/Loading'

import TabPanel from 'components/TabPanel'
import TabAtividades from 'pages/Pedido/components/TabAtividades'
import TabNegociacao from './components/TabNegociacao'
import useTabs from 'hooks/useTabs'
import TabItensPerdidos from './components/TabItensPerdidos'
import TabComentarios from './components/TabComentarios'
import { SyntheticEvent, useEffect } from 'react'
import TabSnapshot from './components/TabSnapshot'
import TabPedidosRelacionados from './components/TabPedidosRelacionados'
import Content from 'components/Layouts/Content'
import FunilVendasEtapas from './components/FunilVendaEtapas'
import { EtapaModel } from 'hooks/queries/useFunilEtapa'
import { useUpdatePedido } from 'hooks/queries/usePedidos'

export default function Pedido() {
  const { id } = useParams()
  const [searchParams] = useSearchParams()
  if (!id) {
    throw new Error('Parâmetro id não informado')
  }

  const { mutateAsync: updatePedido, isLoading: isSaving } = useUpdatePedido()

  const {
    data: pedido,
    isLoading,
    isFetching,
  } = useQueryObterPedidoPorId(id as string)

  const qtdItensPerdidos = pedido?.itensPerdidos?.length || 0
  const qtdComentarios = pedido?.comentarios?.length || 0
  const navigate = useNavigate()
  const { handleChange, value, setValue } = useTabs()

  useEffect(() => {
    if (searchParams.get('tab') === 'comentarios') {
      setValue(2)
    }
  }, [searchParams])

  useEffect(() => {
    setValue(0)
  }, [id])

  if (isLoading)
    return (
      <Container>
        <Loading isLoading={isLoading} />
      </Container>
    )

  function handleChangeTab(_: SyntheticEvent, newValue: number) {
    handleChange(_, newValue)
  }

  async function handleAlterarEtapa(etapa: EtapaModel) {
    if (pedido) {
      pedido.funilVendaEtapaUuid = etapa.uuid
      try {
        await updatePedido(pedido)
      } catch (err) {}
    }
  }

  return (
    <Container>
      <FormProvider>
        <PageTitle
          title={`Negociação ${pedido?.codigo}`}
          onBack={() => navigate(-1)}
        >
          <Tabs
            value={value}
            onChange={handleChangeTab}
            aria-label="tabs negociações"
          >
            <Tab label="Negociação" value={0} />
            <Tab label="Atividades" value={1} />
            <Tab
              value={2}
              label={
                <Box sx={{ position: 'relative' }}>
                  Comentários
                  {qtdComentarios > 0 && (
                    <Badge
                      sx={{ position: 'absolute', top: '-2px', right: '-6px' }}
                      variant="dot"
                      color="primary"
                    />
                  )}
                </Box>
              }
            />
            {qtdItensPerdidos > 0 && <Tab label="Itens Perdidos" value={3} />}
            {pedido?.snapshot && <Tab label="Snapshot" value={4} />}
            {Array.isArray(pedido?.pedidosRelacionados) &&
              pedido.pedidosRelacionados.length > 0 && (
                <Tab label="Neg. Relacionadas" value={5} />
              )}
          </Tabs>
        </PageTitle>

        {isLoading || (isFetching && <LinearProgress />)}

        <FunilVendasEtapas
          funilVendaUuid={pedido?.funilVendaEtapa?.funilVendaUuid || ''}
          selected={pedido?.funilVendaEtapa as EtapaModel}
          onClick={handleAlterarEtapa}
          isSaving={isSaving}
        />

        <Content>
          <TabPanel value={value} index={0}>
            <TabNegociacao
              pedido={pedido}
              isLoading={isLoading}
              isFetching={isFetching}
            />
          </TabPanel>

          <TabPanel value={value} index={1}>
            <TabAtividades pedido={pedido} />
          </TabPanel>

          <TabPanel value={value} index={2}>
            <TabComentarios pedido={pedido || ({} as PedidoModel)} />
          </TabPanel>

          {qtdItensPerdidos > 0 && (
            <TabPanel value={value} index={3}>
              <TabItensPerdidos pedido={pedido as PedidoModel} />
            </TabPanel>
          )}

          {pedido?.snapshot && (
            <TabPanel value={value} index={4}>
              <TabSnapshot snapshot={pedido.snapshot} />
            </TabPanel>
          )}

          {Array.isArray(pedido?.pedidosRelacionados) &&
            pedido.pedidosRelacionados.length > 0 && (
              <TabPanel value={value} index={5}>
                <TabPedidosRelacionados
                  pedidosRelacionados={pedido?.pedidosRelacionados || []}
                />
              </TabPanel>
            )}
        </Content>
      </FormProvider>
    </Container>
  )
}
