import { useDialog, useValidations } from '@data-c/hooks'
import { Button, ButtonContainer, Dialog } from '@data-c/ui'
import { Add, Close, Delete, Edit, Save } from '@mui/icons-material'
import { Autocomplete, IconButton, InputAdornment, Stack } from '@mui/material'
import TextField from 'components/Inputs/TextField'
import Surface from 'components/Surface'
import usePainelDesempenho from 'hooks/queries/usePainelDesempenho'
import { useEffect, useState } from 'react'

interface PainelSelectorProps {
  painelSelecionadoUuid: string
  onChangePainelSelecionadoUuid: (painelSelecionadoUuid: string | null) => void
  onCreatePainel: () => void
  openPopover: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export default function PainelSelector(props: PainelSelectorProps) {
  const {
    painelSelecionadoUuid,
    onChangePainelSelecionadoUuid,
    onCreatePainel,
    openPopover,
  } = props

  const {
    useQueryObterPaineisDesempenho,
    useUpdatePainelDesempenho,
    useDeletePainelDesempenho,
  } = usePainelDesempenho()
  const { data: paineis } = useQueryObterPaineisDesempenho()
  const { mutateAsync: updatePainel } = useUpdatePainelDesempenho()
  const { mutateAsync: deletePainel, isLoading: isDeleting } =
    useDeletePainelDesempenho()

  const {
    closeDialog: closeDialogDelete,
    isOpen: isOpenDialogDelete,
    openDialog: openDialogDelete,
  } = useDialog()
  const { validationProps } = useValidations()

  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [panelName, setPanelName] = useState<string | null>(null)

  const hasPanel = Array.isArray(paineis) && paineis.length > 0

  useEffect(() => {
    if (!painelSelecionadoUuid && hasPanel) {
      onChangePainelSelecionadoUuid(paineis[0]?.uuid || '')
    }
  }, [paineis])

  async function handleUpdatePainel() {
    const painel = paineis?.find((d) => d.uuid === painelSelecionadoUuid)
    setIsEditing(false)
    if (painelSelecionadoUuid && isEditing && painel?.nome !== panelName) {
      const response = await updatePainel({
        nome: panelName || '',
        uuid: painelSelecionadoUuid,
      })
      if (response) onChangePainelSelecionadoUuid(response.uuid || '')
    }
  }

  function handleClickEdit() {
    setIsEditing(true)
    const painel = paineis?.find((d) => d.uuid === painelSelecionadoUuid)
    setPanelName(painel?.nome || '')
  }

  async function handleDeletePainel() {
    if (painelSelecionadoUuid) {
      await deletePainel(painelSelecionadoUuid)
      onChangePainelSelecionadoUuid(null)
      closeDialogDelete()
    }
  }

  return (
    <>
      <Surface sx={{ width: '300px', ml: 1 }}>
        {isEditing ? (
          <TextField
            label="Nome do Painel"
            name="nome"
            required
            onChange={(e) => setPanelName(e.target.value)}
            value={panelName}
            {...validationProps('nome')}
            onBlur={handleUpdatePainel}
            onKeyDown={(e) => e.key === 'Enter' && handleUpdatePainel()}
            autoFocus
            InputProps={{
              endAdornment: (
                <>
                  <IconButton
                    color="primary"
                    onClick={() => setIsEditing(!isEditing)}
                  >
                    <Close />
                  </IconButton>
                  <IconButton color="primary" onClick={onCreatePainel}>
                    <Save />
                  </IconButton>
                </>
              ),
            }}
          />
        ) : (
          <Autocomplete
            options={paineis || []}
            getOptionLabel={(option) => option.nome}
            value={
              paineis?.find((p) => p.uuid === painelSelecionadoUuid) || null
            }
            onChange={(_, newValue) => {
              if (newValue) {
                onChangePainelSelecionadoUuid(newValue.uuid || '')
                setPanelName(newValue.nome || '')
              }
            }}
            clearIcon={null}
            renderInput={(params) => (
              <TextField
                {...params}
                autoFocus={isEditing}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      <InputAdornment position="end">
                        <Stack direction="row" spacing={0} sx={{ mr: -4 }}>
                          <IconButton
                            color="primary"
                            onClick={(e) => {
                              openPopover(e)
                            }}
                          >
                            <Add />
                          </IconButton>
                          <IconButton color="primary" onClick={handleClickEdit}>
                            <Edit />
                          </IconButton>
                          <IconButton
                            color="primary"
                            onClick={() => openDialogDelete()}
                          >
                            <Delete />
                          </IconButton>
                        </Stack>
                      </InputAdornment>
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        )}
      </Surface>

      <Dialog
        open={isOpenDialogDelete}
        type="error"
        title="Excluir Painel Permanentemente?"
        maxWidth="xs"
        onClose={() => closeDialogDelete()}
        actions={
          <ButtonContainer>
            <Button
              onClick={(e) => {
                e.stopPropagation()
                closeDialogDelete()
              }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              color="error"
              isLoading={isDeleting}
              onClick={(e) => {
                e.stopPropagation()
                handleDeletePainel()
              }}
            >
              Excluir
            </Button>
          </ButtonContainer>
        }
      >
        Você não poderá recuperar este painel após excluí-lo!
      </Dialog>
    </>
  )
}
