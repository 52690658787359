import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import * as Sentry from '@sentry/react'

import Hotjar from '@hotjar/browser'

if (import.meta.env.VITE_ENV === 'production') {
  const siteId = 3771438
  const hotjarVersion = 6
  Hotjar.init(siteId, hotjarVersion)

  Sentry.init({
    dsn: 'https://aa05d340bfd44b46ae9a9293a59e7af6@o282171.ingest.sentry.io/4505262836482048',
    beforeSend: (event) => {
      if ((import.meta.env.VITE_ENV || 'development') === 'development') {
        return null
      }
      return event
    },
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          'localhost',
          /^https:\/\/forcadevendas\.com\.br/,
        ],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
