import { useEffect, useMemo, useState } from 'react'

import { CheckCircle, Close } from '@mui/icons-material'
import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
} from '@mui/material'

import { CurrencyTextField } from '@data-c/ui'

import useTabelaPreco, {
  ItemTabelaPrecoModel,
  useVincularProdutosComPreco,
} from 'hooks/queries/useTabelaPreco'
import { useFormApi } from '@data-c/providers'

interface InteractiveCellProps {
  rowIndex: number
  value: string
  produtos: ItemTabelaPrecoModel[]
  tabelaPrecoUuid: string
  tipoOperacao?: 'vinculado' | 'naoVinculado'
  tipoValor: 'preco' | 'comissao'
}

export default function InteractiveCell(props: InteractiveCellProps) {
  const {
    rowIndex,
    value,
    produtos,
    tabelaPrecoUuid,
    tipoOperacao,
    tipoValor,
  } = props

  const [showTextField, setShowTextField] = useState<number>(-1)
  const [inputValue, setInputValue] = useState<number | string>(-1)

  const parsedValue = useMemo(() => {
    if (value) {
      return parseFloat(value.replace(/\./g, '').replace(',', '.'))
    }
    return ''
  }, [value])

  useEffect(() => {
    setInputValue(parsedValue)
  }, [parsedValue])

  const { toggleSubmit } = useFormApi()
  const { useUpdateProdutosVinculados } = useTabelaPreco()
  const { mutateAsync: updateProdutoVinculado, isLoading } =
    useUpdateProdutosVinculados()
  const { mutateAsync: vincularProdutosComPreco } =
    useVincularProdutosComPreco()

  async function handleClickVincularProdutos() {
    if (tabelaPrecoUuid && inputValue !== 0) {
      toggleSubmit(true)
      try {
        await vincularProdutosComPreco({
          tabelaPrecoUuid,
          produtoUuid: [produtos[rowIndex]?.uuid!],
          preco: inputValue.toString(),
        })
      } finally {
        toggleSubmit(false)
      }
    }

    setShowTextField(-1)
    setInputValue(0)
  }

  async function handleUpdatePercentualComissaoProdutoVinculado() {
    const produtoSelecionado = [
      {
        produtoUuid: produtos[rowIndex]?.produto?.uuid || '',
        uuid: produtos[rowIndex]?.uuid || '',
        percentualComissao: inputValue,
      },
    ]

    await updateProdutoVinculado({
      item: produtoSelecionado,
      tabelaPrecoUuid,
    })

    setShowTextField(-1)
  }

  async function handleUpdatePrecoProdutoVinculado() {
    const produtoSelecionado = [
      {
        produtoUuid: produtos[rowIndex]?.produto?.uuid || '',
        uuid: produtos[rowIndex]?.uuid || '',
        preco: inputValue as number,
      },
    ]

    await updateProdutoVinculado({
      item: produtoSelecionado,
      tabelaPrecoUuid,
    })

    setShowTextField(-1)
  }

  function handleKeyUp(e: React.KeyboardEvent<HTMLDivElement>) {
    if (e.key === 'Enter') {
      tipoValor === 'comissao'
        ? handleUpdatePercentualComissaoProdutoVinculado()
        : tipoOperacao === 'vinculado'
        ? handleUpdatePrecoProdutoVinculado()
        : handleClickVincularProdutos()
    }
  }

  function handleRestaurarValorOriginal() {
    setInputValue(parsedValue)
  }

  const valueNaoFoiModificado = inputValue === parsedValue

  return (
    <Box
      onMouseEnter={() => setShowTextField(rowIndex)}
      onMouseLeave={() => setShowTextField(-1)}
    >
      {showTextField === rowIndex ? (
        <CurrencyTextField
          value={inputValue || ''}
          onChange={(_, value) => {
            setInputValue(value)
          }}
          autoFocus
          onKeyUp={handleKeyUp}
          sx={{ '& .MuiInputBase-input': { paddingY: '4px' } }}
          inputProps={{ maxLength: 11 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {isLoading ? (
                    <CircularProgress size={16} />
                  ) : (
                    <>
                      <IconButton>
                        <Close
                          color="error"
                          onClick={handleRestaurarValorOriginal}
                        />
                      </IconButton>
                      <IconButton
                        disabled={valueNaoFoiModificado}
                        onClick={
                          tipoValor === 'comissao'
                            ? handleUpdatePercentualComissaoProdutoVinculado
                            : tipoOperacao === 'vinculado'
                            ? handleUpdatePrecoProdutoVinculado
                            : handleClickVincularProdutos
                        }
                      >
                        <CheckCircle
                          color={valueNaoFoiModificado ? 'disabled' : 'primary'}
                        />
                      </IconButton>
                    </>
                  )}
                </Box>
              </InputAdornment>
            ),
          }}
        />
      ) : (
        <span>{value}</span>
      )}
    </Box>
  )
}
