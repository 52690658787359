import { Box, Stack, Typography, useTheme } from '@mui/material'
import Surface from 'components/Surface'
import { PedidoModel, SituacaoPedidoEnum } from 'hooks/queries/usePedidos'
import { forwardRef, useEffect, useMemo, useRef, useState } from 'react'
import { useBoardContext } from '../BoardContext'
import invariant from 'tiny-invariant'
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine'
import {
  draggable,
  dropTargetForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import {
  attachClosestEdge,
  Edge,
  extractClosestEdge,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge'
import { dropTargetForExternal } from '@atlaskit/pragmatic-drag-and-drop/external/adapter'
import StatusAtividade from 'components/Displays/StatusAtividade'
type State =
  | { type: 'idle' }
  | { type: 'preview'; container: HTMLElement; rect: DOMRect }
  | { type: 'dragging' }

const idleState: State = { type: 'idle' }
const draggingState: State = { type: 'dragging' }

export interface FunilKanbanCardProps {
  negociacao: PedidoModel
  onClick?: (negociacao: PedidoModel) => void
}

export interface CardProps {
  negociacao: PedidoModel
  closestEdge: Edge | null
  state: State
  onClick?: (negociacao: PedidoModel) => void
}

const Card = forwardRef<HTMLDivElement, CardProps>(function Card(props, ref) {
  const { negociacao, onClick } = props
  const theme = useTheme()

  let cardBackgroundColor = ''

  switch (negociacao.situacao as SituacaoPedidoEnum) {
    case SituacaoPedidoEnum.ANALISE:
    case SituacaoPedidoEnum.LIBERADO:
    case SituacaoPedidoEnum.FECHADO:
      cardBackgroundColor = theme.palette.success.dark
      break
    case SituacaoPedidoEnum.NEGOCIACAO_PERDIDA:
      cardBackgroundColor = theme.palette.error.dark
      break
    default:
      cardBackgroundColor = 'white'
  }

  const statusAtividade = useMemo(() => {
    const atividade = negociacao.atividades?.find((a) => a.concluido === false)
    const temAtividade =
      Array.isArray(negociacao.atividades) && negociacao.atividades.length > 0
    if (!temAtividade) return 'SEM_ATIVIDADE'

    return atividade?.status || 'CONCLUIDA'
  }, [negociacao])

  return (
    <Box sx={{ cursor: 'grab' }} ref={ref}>
      <Surface
        elevation={1}
        sx={{ p: 1, borderLeft: `7px solid ${cardBackgroundColor}` }}
        onClick={() => {
          if (onClick) {
            onClick(negociacao)
          }
        }}
      >
        <Typography variant="body2" fontSize="10pt" fontWeight="bold">
          {negociacao?.codigo}
        </Typography>
        <Typography variant="body2" fontSize="8pt">
          {negociacao?.representante?.nome}
        </Typography>
        <Typography variant="body1" fontSize="10pt">
          {negociacao?.cliente?.nome}
        </Typography>
        <Stack direction="row" justifyContent="space-between">
          <Typography
            variant="body1"
            fontSize="8pt"
            fontWeight="bolder"
            color={theme.palette.grey[500]}
          >
            R$ {negociacao?.totalLiquidoAsBrazilianCurrency}
          </Typography>
          <StatusAtividade status={statusAtividade} />
        </Stack>
      </Surface>
      {/* {closestEdge && (
        <DropIndicator edge={closestEdge} gap={token('space.100', '0')} />
      )} */}
    </Box>
  )
})

export default function FunilKanbanCard(props: FunilKanbanCardProps) {
  const { negociacao, onClick } = props
  const ref = useRef<HTMLDivElement | null>(null)
  const [closestEdge, setClosestEdge] = useState<Edge | null>(null)
  const [state, setState] = useState<State>(idleState)
  const { instanceId, registerCard } = useBoardContext()

  useEffect(() => {
    invariant(ref.current)
    return registerCard({
      cardId: negociacao?.uuid || '',
      entry: {
        element: ref.current,
      },
    })
  }, [registerCard, negociacao])

  useEffect(() => {
    const element = ref.current
    invariant(element)
    return combine(
      draggable({
        element: element,
        getInitialData: () => ({
          type: 'card',
          itemId: negociacao.uuid,
          instanceId,
        }),
        onDragStart: () => setState(draggingState),
        onDrop: () => setState(idleState),
      }),
      dropTargetForExternal({
        element: element,
      }),
      dropTargetForElements({
        element: element,
        canDrop: ({ source }) => {
          return (
            source.data.instanceId === instanceId && source.data.type === 'card'
          )
        },
        getIsSticky: () => true,
        getData: ({ input, element }) => {
          const data = { type: 'card', itemId: negociacao.uuid }
          return attachClosestEdge(data, {
            input,
            element,
            allowedEdges: ['top', 'bottom'],
          })
        },
        onDragEnter: (args) => {
          if (args.source.data.itemId !== negociacao.uuid) {
            setClosestEdge(extractClosestEdge(args.self.data))
          }
        },
        onDrag: (args) => {
          if (args.source.data.itemId !== negociacao.uuid) {
            setClosestEdge(extractClosestEdge(args.self.data))
          }
        },
        onDragLeave: () => {
          setClosestEdge(null)
        },
        onDrop: () => {
          setClosestEdge(null)
        },
      }),
    )
  }, [instanceId, negociacao])

  return (
    // <Fragment>
    <Card
      state={state}
      closestEdge={closestEdge}
      negociacao={negociacao}
      ref={ref}
      onClick={onClick}
    />
    // </Fragment>
  )
}
