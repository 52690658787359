import { SvgIcon, SvgIconProps } from '@mui/material'

export default function EmailIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        id="vector"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          fill="currentColor"
          d="M20,4L4,4c-1.1,0 -1.99,0.9 -1.99,2L2,18c0,1.1 0.9,2 2,2h16c1.1,0 2,-0.9 2,-2L22,6c0,-1.1 -0.9,-2 -2,-2zM20,8l-8,5 -8,-5L4,6l8,5 8,-5v2z"
          id="path_0"
        />
      </svg>
    </SvgIcon>
  )
}
