import { useFilterApi, useFilterData } from '@data-c/providers'
import { FilterContainer } from '@data-c/ui'
import {
  CrmAtividadesFilters,
  CrmAtividadesFilters1,
  TipoAtividade,
  situacaoAtividadeMap,
  tipoAtividadeMap,
} from 'hooks/queries/useCrmAtividades'
import {
  TextField,
  Stack,
  MenuItem,
  IconButton,
  Button,
  useTheme,
} from '@mui/material'
import DropDownCrmSituacao from 'components/Inputs/DropDownCrmSituacao'
import TimeVendasTransporter from 'components/Transporters/TimeVendasTranporter'
import ClientesTransporter from 'components/Transporters/ClientesTransporter'
import { useUserContext } from 'components/Contexts/UserContext'
import PedidoTransporter from 'components/Transporters/PedidosTransporter'
import { useEffect } from 'react'
import useDeviceDetect from 'hooks/useDeviceDetect'
import { FilterList } from '@mui/icons-material'
import { DateTime } from 'luxon'
import DateRangePicker, { DateRange } from 'components/Inputs/DateRangePicker'

export default function Filter() {
  const { onChangeFilterValue, changeFilterValue, openFilter } = useFilterApi()
  const { user, temPermissao } = useUserContext()
  const { filterValues, isOpen } = useFilterData<
    CrmAtividadesFilters,
    CrmAtividadesFilters1
  >()
  const { isWebView } = useDeviceDetect()
  const theme = useTheme()
  const permissaoParaLerClientes = temPermissao('clientes.read') || 'pessoal'

  useEffect(() => {
    if (permissaoParaLerClientes === 'pessoal' && isOpen) {
      changeFilterValue('responsavel', user)
    }
  }, [permissaoParaLerClientes, isOpen])
  return (
    <FilterContainer
      disableds={['tipoAtividade', 'dateRange', 'situacao']}
      title="Pesquisar por Atividades"
      triggerButton={
        isWebView ? (
          <IconButton
            size="medium"
            onClick={openFilter}
            sx={{
              color: theme.palette.text.primary,
            }}
          >
            <FilterList fontSize="inherit" />
          </IconButton>
        ) : (
          <Button variant="outlined" onClick={openFilter}>
            Pesquisar
          </Button>
        )
      }
      triggerButtonProps={{ variant: 'outlined' }}
      renderLabel={(filterLabel: string, filterValue: any) => {
        if (
          filterLabel === 'Cliente / Lead' ||
          filterLabel === 'Atribuído para'
        ) {
          return `${filterLabel} - ${filterValue?.nome || ''}`
        }

        if (filterLabel === 'Negociação') {
          return `${filterLabel} - ${filterValue?.codigo || ''}`
        }

        if (filterLabel === 'Tipo de Atividade') {
          return `${filterLabel} - ${tipoAtividadeMap[filterValue]}`
        }

        if (filterLabel === 'Período') {
          const dateRange = filterValue as DateRange
          const formattedFirstDate = DateTime.fromFormat(
            dateRange?.firstDate || '',
            'yyyy-MM-dd',
          ).toFormat('dd/MM/yyyy')

          const formattedSecondDate = DateTime.fromFormat(
            dateRange?.secondDate || '',
            'yyyy-MM-dd',
          ).toFormat('dd/MM/yyyy')

          if (dateRange.tipo === 'day') {
            return `${filterLabel} - ${formattedFirstDate}`
          }

          return `${filterLabel} - de ${formattedFirstDate} até ${formattedSecondDate}`
        }

        if (filterLabel === 'Situação') {
          return `${filterLabel} - ${situacaoAtividadeMap[filterValue]}`
        }

        return `${filterLabel} - ${filterValue}`
      }}
    >
      <Stack spacing={2}>
        <DateRangePicker
          onChange={(value) => {
            changeFilterValue('dateRange', value)
          }}
          value={filterValues?.dateRange?.value}
        />
        <TextField
          name="assunto"
          onChange={onChangeFilterValue}
          value={filterValues?.assunto?.value || ''}
          label={filterValues?.assunto?.label}
        />
        <TextField
          name="tipoAtividade"
          onChange={onChangeFilterValue}
          value={filterValues?.tipoAtividade?.value || ''}
          label={filterValues?.tipoAtividade?.label}
          select
        >
          <MenuItem value={TipoAtividade.TODAS}>Todas</MenuItem>
          <MenuItem value={TipoAtividade.LIGAR}>Ligação</MenuItem>
          <MenuItem value={TipoAtividade.REUNIAO}>Reunião</MenuItem>
          <MenuItem value={TipoAtividade.TAREFA}>Tarefa</MenuItem>
          <MenuItem value={TipoAtividade.PRAZO}>Prazo</MenuItem>
          <MenuItem value={TipoAtividade.EMAIL}>E-mail</MenuItem>
          <MenuItem value={TipoAtividade.ALMOCO}>Evento</MenuItem>
        </TextField>
        <DropDownCrmSituacao
          name="situacao"
          value={filterValues?.situacao?.value}
          label={filterValues?.situacao?.label}
          onChange={onChangeFilterValue}
        />

        <PedidoTransporter
          name="pedidoUuid"
          value={filterValues?.pedido?.value || null}
          label={filterValues?.pedido?.label}
          onChange={(pedido) => {
            changeFilterValue('pedido', pedido)
          }}
          InputLabelProps={{ shrink: true }}
        />

        <TimeVendasTransporter
          name="vendedorUuid"
          disabled={temPermissao('clientes.read') === 'pessoal'}
          label={filterValues?.responsavel?.label}
          value={filterValues?.responsavel?.value || null}
          onChange={(responsavel) => {
            changeFilterValue('responsavel', responsavel)
          }}
          InputLabelProps={{ shrink: true }}
        />
        <ClientesTransporter
          label={filterValues?.lead?.label}
          name="lead"
          value={filterValues?.lead?.value || null}
          vendedorUuid={
            permissaoParaLerClientes === 'pessoal' ? user?.uuid : undefined
          }
          onChange={(lead) => {
            changeFilterValue('lead', lead)
          }}
          InputLabelProps={{ shrink: true }}
        />
      </Stack>
    </FilterContainer>
  )
}
