import { Id, ToastContent, TypeOptions, toast } from 'react-toastify'
import axios, { AxiosError } from 'axios'
import ErrorInterface from 'interfaces/ErrorInterface'

export default function useNotification() {
  const notifyLoading = (message: string): Id => {
    return toast.loading(message)
  }

  const updateNotify = (id: Id, type: TypeOptions, render: ToastContent) => {
    return toast.update(id, {
      type,
      render: render,
      isLoading: false,
      autoClose: 3000,
      closeButton: true,
    })
  }

  const notifySuccess = (message: string | ToastContent) => {
    toast.success(message)
  }

  const notifyInfo = (message: string) => {
    toast.info(message)
  }

  const notifyWarning = (message: string) => {
    toast.warning(message)
  }

  const notifyError = (message: string) => {
    toast.error(message)
  }

  const notifyException = (e: any) => {
    if (axios.isAxiosError(e)) {
      axiosError(e)
    } else {
      toast.error(e.message)
    }
  }

  // const notifyAction = (message: string, action: SnackbarAction) => {
  //   enqueueSnackbar(message, { variant: 'default', action })
  // }

  function axiosError(e: any) {
    const axiosError = e as AxiosError
    const defaultMessage = 'Ocorreu um erro ao tentar processar a requisição'

    const mappedStatusErrorsMessages: { [key: number]: string } = {
      // 403: 'Suas credenciais não estão corretas',
    }

    const status: number = axiosError.request.status as number

    if (mappedStatusErrorsMessages[status]) {
      toast.error(mappedStatusErrorsMessages[status])
      return
    }

    // if (import.meta.env.VITE_ENV === 'development') {
    //   toast.error(
    //     e.response ? e.response?.data?.message || e.message : e.message,
    //   )
    //   return
    // }

    if (e.response?.status >= 400 && e.response?.status <= 500) {
      toast.error(e.response?.data?.message || defaultMessage)
      return
    }

    toast.error(defaultMessage)
  }

  function extractAxiosError(e: unknown): string | undefined {
    if (!e) return undefined

    const defaultMessage = 'Ocorreu um erro ao tentar processar a requisição'
    if (axios.isAxiosError(e)) {
      const axiosError = e as AxiosError<ErrorInterface>
      const statusCode = axiosError.response?.status || 0
      if (statusCode >= 400 && statusCode <= 500) {
        return axiosError.response?.data?.message || defaultMessage
      }
    }
    return defaultMessage
  }

  function extractAxiosErrorData(e: unknown):
    | {
        code: string
        message: string
      }
    | undefined {
    if (!e) return undefined

    const defaultMessage = {
      code: 'E_DEFAULT',
      message: 'Ocorreu um erro ao tentar processar a requisição',
    }
    if (axios.isAxiosError(e)) {
      const axiosError = e as AxiosError<ErrorInterface>
      const statusCode = axiosError.response?.status || 0
      if (statusCode >= 400 && statusCode <= 500) {
        return axiosError.response?.data || defaultMessage
      }
    }
    return defaultMessage
  }

  return {
    notifySuccess,
    notifyInfo,
    notifyWarning,
    notifyError,
    // notifyAction,
    notifyException,
    extractAxiosError,
    extractAxiosErrorData,
    notifyLoading,
    updateNotify,
  }
}
