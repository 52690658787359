import { MenuItem, TextField, TextFieldProps } from '@mui/material'

export enum TipoVariacaoEnum {
  NENHUMA = 'NENHUMA',
  NIVEL_1 = 'NIVEL_1',
  NIVEL_2 = 'NIVEL_2',
}

export default function DropDownTipoVariacao(props: TextFieldProps) {
  return (
    <TextField select {...props}>
      <MenuItem value={TipoVariacaoEnum.NENHUMA}>Sem variação</MenuItem>
      <MenuItem value={TipoVariacaoEnum.NIVEL_1}>1 variação</MenuItem>
      <MenuItem value={TipoVariacaoEnum.NIVEL_2}>2 variações</MenuItem>
    </TextField>
  )
}
