import { KeyboardEvent } from 'react'

export default function useKeys() {
  const SUPER_FINDER_KEYS = ['Control', 'Shift', 'f']
  // const OPEN_TRANSPORTER_KEYS = ['Control', 'p']
  let keysPressed: any = {}

  const isSuperFinderOpenKeys = (key: any) =>
    SUPER_FINDER_KEYS.indexOf(key) > -1

  const isOpenTransporterKeys = (event: KeyboardEvent) => {
    keysPressed[event.key] = true
    if (
      keysPressed['F1'] ||
      (keysPressed['Control'] && (keysPressed['p'] || keysPressed['P']))
    ) {
      event.preventDefault()
      return true
    }
  }

  const isEnterKey = (event: KeyboardEvent) => {
    // return event.key === 'Enter' || event.key === 'Tab' //----- Todo => Validar Uso do Tab para atribuir o Transporter
    return event.key === 'Enter'
  }

  const isOpenAdicionarKeys = (event: KeyboardEvent) => {
    keysPressed[event.key] = true
    if (keysPressed['Control'] && (keysPressed['a'] || keysPressed['A'])) {
      event.preventDefault()
      return true
    }
  }

  function isShortcutKeys(event: KeyboardEvent, keys: any) {
    keysPressed[event.key] = true

    let isCombined = true

    keys.forEach((k: any) => {
      if (!keysPressed[k]) isCombined = false
    })

    if (isCombined) {
      event.preventDefault()
      return true
    }
  }

  function clearKey(event: KeyboardEvent) {
    delete keysPressed[event.key]
  }

  return {
    isSuperFinderOpenKeys,
    isOpenTransporterKeys,
    isOpenAdicionarKeys,
    isShortcutKeys,
    isEnterKey,
    clearKey,
  }
}
