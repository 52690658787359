import { useState } from 'react'
import { Transporter, TransporterProps } from '@data-c/ui'

import {
  CondicaoPagamentoModel,
  useQueryObterCondicaoPagamentoPorRegra,
} from 'hooks/queries/useCondicaoPagamento'

import CondicaoPagamentoTable from './components/Table'

type CondicaoPagamentoTransporterProps = {
  clienteUuid?: string
  vendedorUuid?: string
} & Omit<
  TransporterProps<CondicaoPagamentoModel>,
  'table' | 'form' | 'onSearch' | 'renderValue' | 'options'
>

export default function CondicaoPagamentoTransporter(
  props: CondicaoPagamentoTransporterProps,
) {
  const { value, clienteUuid, vendedorUuid, ...rest } = props

  const [query, setQuery] = useState<string | null>()

  const { data, isLoading } = useQueryObterCondicaoPagamentoPorRegra({
    plainQuery: query,
    clienteUuid,
    vendedorUuid,
    ativo: true,
  })

  function handleSearch(q: string | null) {
    setQuery(q)
  }

  return (
    <Transporter
      onSearch={handleSearch}
      options={data || []}
      isLoading={isLoading}
      table={CondicaoPagamentoTable}
      label="Condição de Pagamento"
      tableProps={{
        clienteUuid,
        vendedorUuid,
      }}
      dialogProps={{ title: 'Condição de Pagamento', maxWidth: 'md' }}
      renderValue={(value) => {
        const dash = value?.codigo || value?.nome ? ' - ' : '  '
        return `${value?.codigo || ''}${dash}${value?.nome || ''}`
      }}
      value={value}
      {...rest}
    />
  )
}
