import { useState } from 'react'
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  TextField,
} from '@mui/material'

import { usePagination } from '@data-c/hooks'

import { AutoCompleteProps } from 'hooks/useAutocomplete'
import {
  GrupoProdutosModel,
  obterGrupoProdutos,
} from 'hooks/queries/useGrupoProdutos'
import useNotification from 'hooks/useNotifications'

export default function AutoCompleteGrupoProdutos(
  props: AutoCompleteProps<GrupoProdutosModel>,
) {
  const { onChange, value } = props

  const [options, setOptions] = useState<GrupoProdutosModel[]>([])
  const [isLoading, setLoading] = useState(false)

  const { pagination } = usePagination()
  const notifications = useNotification()

  async function handleObterGrupoProdutos() {
    if (options?.length > 0) return
    setLoading(true)
    try {
      const grupoProdutos = await obterGrupoProdutos({
        pagination,
        queryParams: { ativo: true },
      })

      setOptions(grupoProdutos?.data)
    } catch (error) {
      notifications.notifyException(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Autocomplete
      onOpen={handleObterGrupoProdutos}
      disablePortal
      options={options || []}
      onChange={onChange}
      getOptionLabel={(grupoProduto: GrupoProdutosModel) => {
        return `${grupoProduto.nome}`
      }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField {...params} value={value} label="Grupo de Produtos" />
      )}
      loadingText="Carregando..."
      loading={isLoading}
    />
  )
}
