import IconCadastro from './IconCadastro'
import IconCircle from './IconCircle'
import IconClientes from './IconClientes'
import IconDashboard from './IconDashboard'
import IconDown from './IconDown'
import IconExpandable from './IconExpandable'
import IconNegociacao from './IconNegociacao'

const Icon = {
  Circle: IconCircle,
  Dashboard: IconDashboard,
  Expandable: IconExpandable,
  Down: IconDown,
  Negociacao: IconNegociacao,
  Cadastro: IconCadastro,
  Clientes: IconClientes,
}

export default Icon
