import { Button, Stack } from '@mui/material'

interface UndoProps {
  closeToast?: () => {}
  onUndo: () => void
  message: string
}

export default function Undo(props: UndoProps) {
  const { closeToast, onUndo, message } = props
  return (
    <Stack direction="row" alignItems="row" justifyContent="space-between">
      <div>{message}</div>
      <Button
        fullWidth
        variant="text"
        color="error"
        sx={{ ml: 2 }}
        onClick={() => {
          if (closeToast) {
            closeToast()
          }
          onUndo()
        }}
      >
        Desfazer
      </Button>
    </Stack>
  )
}
