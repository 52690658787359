import { Box } from '@mui/material'
import CardConfig from 'components/CardConfig'
import { useRoleContext } from 'components/Contexts/RoleContext'
import DropDownAcessoNegociacoes from 'pages/Role/components/DropDownAcessoNegociacoes'

export default function Atividades() {
  const { role, changeRolePermission } = useRoleContext()
  const { atividades } = role?.permissoes || {}

  return (
    <Box>
      <CardConfig
        title="Acesso (Ver)"
        description={
          <div>
            Define o nível de acesso que o usuário possui para poder ver as
            Atividades (as regras se aplicam aos relatórios)
          </div>
        }
        component={
          <DropDownAcessoNegociacoes
            name="atividades.read"
            value={atividades?.read || ''}
            onChange={changeRolePermission}
            disabled={Boolean(role?.fixa)}
          />
        }
      />
      <CardConfig
        title="Acesso (Criar)"
        description={
          <div>
            Define o nível de acesso que o usuário possui para poder criar as
            Atividades
          </div>
        }
        component={
          <DropDownAcessoNegociacoes
            name="atividades.create"
            value={atividades?.create || ''}
            onChange={changeRolePermission}
            disabled={Boolean(role?.fixa)}
          />
        }
      />
      <CardConfig
        title="Acesso (Alterar)"
        description={
          <div>
            Define o nível de acesso que o usuário possui para poder alterar as
            Atividades
          </div>
        }
        component={
          <DropDownAcessoNegociacoes
            name="atividades.alter"
            value={atividades?.alter || ''}
            onChange={changeRolePermission}
            disabled={Boolean(role?.fixa)}
          />
        }
      />
      <CardConfig
        title="Acesso (Deletar)"
        description={
          <div>
            Define o nível de acesso que o usuário possui para poder deletar as
            Atividades
          </div>
        }
        component={
          <DropDownAcessoNegociacoes
            name="atividades.delete"
            value={atividades?.delete || ''}
            onChange={changeRolePermission}
            disabled={Boolean(role?.fixa)}
          />
        }
      />
    </Box>
  )
}
