import { useState } from 'react'

import { Transporter, TransporterProps } from '@data-c/ui'

import Table from 'pages/MotivosDePerda/components/Table'
import Form from 'pages/MotivosDePerda/components/Form'
import useMotivosDePerda, {
  MotivosDePerdaModel,
} from 'hooks/queries/useMotivosDePerda'
import { useUserContext } from 'components/Contexts/UserContext'

type MotivosDePerdaTransporterProps = {} & Omit<
  TransporterProps<MotivosDePerdaModel>,
  'table' | 'form' | 'onSearch' | 'renderValue' | 'options'
>

export default function MotivosDePerdaTransporter(
  props: MotivosDePerdaTransporterProps,
) {
  const { value, ...rest } = props

  const [query, setQuery] = useState<string | null>()
  const { user } = useUserContext()
  const { useQuery } = useMotivosDePerda()
  const { data, isLoading } = useQuery({
    queryParams: {
      plainQuery: query,
    },
    pagination: {
      page: 1,
      pageSize: 100,
    },
  })

  function handleSearch(q: string | null) {
    setQuery(q)
  }

  return (
    <Transporter
      dialogProps={{
        title: 'Motivo de Perda',
        maxWidth: 'md',
      }}
      onSearch={handleSearch}
      options={data?.data || []}
      isLoading={isLoading}
      table={Table}
      form={user?.isadmin ? Form : undefined}
      label="Motivo de Perda"
      renderValue={(value) => {
        return `${value?.descricao || ''}`
      }}
      value={value}
      {...rest}
    />
  )
}
