import { MenuItem, TextField, TextFieldProps } from '@mui/material'

export enum UsaTabelaPrecoOptions {
  EXIBE_TABELAS_ATIVAS = 1,
  EXIBE_TABELAS_ATRELADAS_VENDEDOR = 2,
  EXIBE_TABELAS_ATRELADAS_CLIENTE_CASO_NAO_HAJA_TABELA_ATRELADA_CLIENTE_UTILIZA_MODO_2 = 3,
  EXIBE_TABELA_VENDEDOR = 4,
  EXIBE_TABELA_CLIENTE_CASO_NAO_HAJA_TABELA_NO_CLIENTE_EXIBE_A_DO_VENDEDOR = 5,
}

export default function DropDownUsaTabelaPreco(props: TextFieldProps) {
  return (
    <TextField select {...props}>
      <MenuItem value={UsaTabelaPrecoOptions.EXIBE_TABELAS_ATIVAS}>
        Exibe as tabelas de preços ativas
      </MenuItem>
      <MenuItem value={UsaTabelaPrecoOptions.EXIBE_TABELAS_ATRELADAS_VENDEDOR}>
        Exibe as tabelas de preços atreladas ao vendedor
      </MenuItem>
      <MenuItem
        value={
          UsaTabelaPrecoOptions.EXIBE_TABELAS_ATRELADAS_CLIENTE_CASO_NAO_HAJA_TABELA_ATRELADA_CLIENTE_UTILIZA_MODO_2
        }
      >
        Exibe as tabelas de preços atreladas ao cliente, quando não houver irá
        exibir as tabelas de preços atreladas ao vendedor
      </MenuItem>
      <MenuItem value={UsaTabelaPrecoOptions.EXIBE_TABELA_VENDEDOR}>
        Exibe a tabela do vendedor
      </MenuItem>
      <MenuItem
        value={
          UsaTabelaPrecoOptions.EXIBE_TABELA_CLIENTE_CASO_NAO_HAJA_TABELA_NO_CLIENTE_EXIBE_A_DO_VENDEDOR
        }
      >
        Exibe a tabela do cliente, quando não houver irá exibir a tabela do
        vendedor.
      </MenuItem>
    </TextField>
  )
}
