import { FormProvider } from '@data-c/providers'
import { Button, ButtonContainer } from '@data-c/ui'

import Delete from 'components/Delete'

import useTabelaPreco from 'hooks/queries/useTabelaPreco'
import Table from './components/Table'
import Form from './components/Form'
import Surface from 'components/Surface'
import { usePopover } from '@data-c/hooks'

interface TabDescontoAgregadorProps {
  tabelaPrecoUuid?: string
}

export default function TabDescontoAgregado(props: TabDescontoAgregadorProps) {
  const { tabelaPrecoUuid } = props
  const popoverProps = usePopover()
  const { useRemoverDescontoAgregado } = useTabelaPreco()
  const { mutateAsync } = useRemoverDescontoAgregado()

  return (
    <FormProvider>
      <ButtonContainer sx={{ mb: 2 }}>
        <Button variant="contained" onClick={popoverProps.open}>
          Adicionar
        </Button>
        <Form tabelaPrecoUuid={tabelaPrecoUuid} {...popoverProps} />
      </ButtonContainer>
      <Surface>
        <Table tabelaPrecoUuid={tabelaPrecoUuid} />
      </Surface>
      <Delete onDelete={mutateAsync} />
    </FormProvider>
  )
}
