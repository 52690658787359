import { FormProvider } from '@data-c/providers'
import { ButtonContainer, Content, PageTitle } from '@data-c/ui'

import { Container } from '@mui/material'
import useGrupoProdutos from 'hooks/queries/useGrupoProdutos'
import Delete from 'components/Delete'

import Form from './components/Form'
import Table from './components/Table'
import HelpButton from 'components/HelpButton'

export default function GrupoProdutos() {
  const { useDelete } = useGrupoProdutos()
  const { mutateAsync } = useDelete()

  return (
    <Container>
      <FormProvider initialFormValues={{ ativo: true }}>
        <PageTitle title="Grupo de Produtos">
          <ButtonContainer>
            <Form />
            <HelpButton link="https://datacsistemashelp.zendesk.com/hc/pt-br/articles/27025236952340-Categoriza%C3%A7%C3%A3o-de-Produtos" />
          </ButtonContainer>
        </PageTitle>
        <Content>
          <Table />
          <Delete onDelete={mutateAsync} />
        </Content>
      </FormProvider>
    </Container>
  )
}
