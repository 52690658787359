import { Button, ButtonContainer, Dialog } from '@data-c/ui'

interface ConfirmationDialogProps {
  isOpen: boolean
  isLoading: boolean
  onClick: () => void
  onClose: () => void
}

export function ConfirmationDialog(props: ConfirmationDialogProps) {
  const { isOpen, isLoading, onClick, onClose } = props

  return (
    <Dialog
      open={isOpen}
      type="error"
      title="Desvincular os produtos selecionados?"
      maxWidth="xs"
      actions={
        <ButtonContainer>
          <Button onClick={onClose}>Cancelar</Button>
          <Button variant="contained" isLoading={isLoading} onClick={onClick}>
            Desvincular
          </Button>
        </ButtonContainer>
      }
    >
      Todos os produtos selecionados serão desvinculados e os preços perdidos.
      <br />
      Você pode vincular os produtos e definir novos preços quando desejar.
    </Dialog>
  )
}
