import { Box, Switch } from '@mui/material'
import CardConfig from 'components/CardConfig'
import { useRoleContext } from 'components/Contexts/RoleContext'

export default function FormaPagamento() {
  const { role, changeRolePermission } = useRoleContext()
  const { forma_pagamento } = role?.permissoes || {}

  return (
    <Box>
      <CardConfig
        title="Acesso (Ver)"
        description={
          <div>
            Define o nível de acesso que o usuário possui para poder ver os
            registros (as regras se aplicam aos relatórios)
          </div>
        }
        component={
          <Switch
            name="forma_pagamento.read"
            checked={forma_pagamento?.read || ''}
            onChange={changeRolePermission}
            disabled={Boolean(role?.fixa)}
          />
        }
      />
      <CardConfig
        title="Acesso (Criar)"
        description={
          <div>
            Define o nível de acesso que o usuário possui para poder criar o
            registro
          </div>
        }
        component={
          <Switch
            name="forma_pagamento.create"
            checked={forma_pagamento?.create || ''}
            onChange={changeRolePermission}
            disabled={Boolean(role?.fixa)}
          />
        }
      />
      <CardConfig
        title="Acesso (Alterar)"
        description={
          <div>
            Define o nível de acesso que o usuário possui para poder alterar os
            registros
          </div>
        }
        component={
          <Switch
            name="forma_pagamento.alter"
            checked={forma_pagamento?.alter || ''}
            onChange={changeRolePermission}
            disabled={Boolean(role?.fixa)}
          />
        }
      />
      <CardConfig
        title="Acesso (Deletar)"
        description={
          <div>
            Define o nível de acesso que o usuário possui para poder deletar os
            registros
          </div>
        }
        component={
          <Switch
            name="forma_pagamento.delete"
            checked={forma_pagamento?.delete || ''}
            onChange={changeRolePermission}
            disabled={Boolean(role?.fixa)}
          />
        }
      />
    </Box>
  )
}
