import { FilterOptions } from '@data-c/providers'
import useCrud, { generateEntityKey } from 'hooks/useCrud'
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import api from 'services/api'
import useNotification from 'hooks/useNotifications'
import ErrorInterface from 'interfaces/ErrorInterface'
import { HttpResponseInterface } from '@data-c/hooks'

const entityName = 'Condição de Pagamento'

export interface CondicaoPagamentoModel {
  uuid?: string
  id?: string
  empresa?: number
  origem?: string
  codigo?: string
  nome: string
  prazomedio?: number
  condpgtot?: string
  pfinanceiro?: number
  pcomissao?: number
  pdesconto?: number
  ativo: boolean
  fator?: number
  descontoMax?: number
  fatorDesbloqueio?: number
  ordenacao?: number
}

export interface CondicaoPagamentoFilters {
  codigo: FilterOptions<string>
  nome: FilterOptions<string>
  fatorDesbloqueio: FilterOptions<string>
  fator: FilterOptions<string>
  descontoMax: FilterOptions<string>
}

export interface CondicaoPagamentoFilters1 {
  codigo: string
  nome: string
  fatorDesbloqueio: string
  fator: string
  descontoMax: string
}

export const filters: CondicaoPagamentoFilters = {
  codigo: {
    label: 'Código',
  },
  nome: {
    label: 'Nome',
  },
  fatorDesbloqueio: {
    label: 'Fator Desbloqueio',
  },
  fator: {
    label: 'Fator (%)',
  },
  descontoMax: {
    label: 'Desconto Máximo (%)',
  },
}

interface QueryParamsInterface {
  plainQuery?: string | null
  clienteUuid?: string
  vendedorUuid?: string
  ativo?: boolean
}

export async function updateOrder(
  newItemsOrder: CondicaoPagamentoModel[],
): Promise<CondicaoPagamentoModel> {
  const response = await api.put(
    `/administrador/condicao-pagamento`,
    newItemsOrder,
  )

  return response.data
}

export function useUpdateOrder() {
  const notifications = useNotification()
  const queryClient = useQueryClient()
  return useMutation<
    CondicaoPagamentoModel,
    AxiosError,
    CondicaoPagamentoModel[]
  >((data: CondicaoPagamentoModel[]) => updateOrder(data), {
    onSuccess(_: CondicaoPagamentoModel) {
      notifications.notifySuccess(
        'Ordenação da condição de pagamento alterada com sucesso',
      )
      const entityKey = generateEntityKey(entityName)
      queryClient.invalidateQueries([entityKey])
    },
    onError(error) {
      notifications.notifyException(error)
    },
  })
}

export async function obterCondicaoPagamentoPorRegra(
  params: QueryParamsInterface,
): Promise<CondicaoPagamentoModel[]> {
  const response = await api.get<CondicaoPagamentoModel[]>(
    'vendas/condicao-pagamento/condicao-pagamento-por-regra',
    {
      params,
    },
  )
  return response.data
}

export async function obterCondicoes(
  params: QueryParamsInterface,
): Promise<CondicaoPagamentoModel[]> {
  const response = await api.get<HttpResponseInterface<CondicaoPagamentoModel>>(
    'administrador/condicao-pagamento',
    {
      params,
    },
  )
  return response.data.data
}

export function useQueryObterCondicaoPagamentoPorRegra(
  params: QueryParamsInterface,
) {
  return useQuery<
    CondicaoPagamentoModel[],
    AxiosError<ErrorInterface, ErrorInterface>
  >(['CONDICAODEPAGAMENTO', params], () => {
    if (params?.clienteUuid || params?.vendedorUuid) {
      return obterCondicaoPagamentoPorRegra(params)
    }

    return obterCondicoes(params)
  })
}

export default function useCondicaoPagamento() {
  return {
    ...useCrud<CondicaoPagamentoModel, AxiosError>(
      'administrador/condicao-pagamento',
      entityName,
      'female',
    ),
    useUpdateOrder,
  }
}
