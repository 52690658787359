import { Box, Paper, Typography, useMediaQuery, useTheme } from '@mui/material'
import Table from './components/Table'
import Form from './components/Form'
import { VendedorModel } from 'hooks/queries/useVendedores'

interface TabMetaVendasProps {
  vendedor: VendedorModel
}

export default function TabMetaVendas(props: TabMetaVendasProps) {
  const { vendedor } = props

  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Paper
      sx={{
        padding: 2,
        display: 'grid',
        gridTemplateColumns: matches ? '1fr' : '1fr 240px',
        gap: 2,
      }}
    >
      <Box sx={{ order: matches ? 1 : 0 }}>
        <Typography variant="body2">Histórico</Typography>
        <Table vendedor={vendedor} />
      </Box>
      <Box sx={{ mt: 2.5 }}>
        <Form vendedor={vendedor} />
      </Box>
    </Paper>
  )
}
