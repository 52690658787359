import AddFirst from 'components/Displays/AddFirst'
import Surface from 'components/Surface'
import useIndicador from 'hooks/queries/useIndicador'
import { useEffect, useMemo, useState } from 'react'
import { Layout, WidthProvider, Responsive } from 'react-grid-layout'
import { Box, LinearProgress } from '@mui/material'
import IndicadorCard from './components/IndicadorCard'
import usePopOver from 'hooks/usePopover'
import IndicadoresPopover from './components/IndicadoresPopover'
import _ from 'lodash'
import 'react-grid-layout/css/styles.css'

const layoutsPropsMap: Record<string, any> = {
  EVOLUCAO_VENDAS: {
    w: 7,
    h: 9,
    minW: 5,
    minH: 9,
  },
  META_VENDAS: {
    w: 2,
    h: 4,
    minW: 2,
    minH: 3,
  },
  VENDAS: {
    w: 2,
    h: 4,
    minW: 2,
    minH: 3,
  },
  TOP_CLIENTES: {
    w: 7,
    h: 12,
    minW: 6,
    minH: 12,
  },
  TOP_VENDEDORES: {
    w: 7,
    h: 12,
    minW: 6,
    minH: 12,
  },
}

const ResponsiveGridLayout = WidthProvider(Responsive)

interface PainelDesempenhoContent {
  painelUuid: string
  onSucessUpdatePropriedades: (isSucessUpdatePropriedades: boolean) => void
  setHasIndicadores: (hasIndicadores: boolean) => void
}

export default function PainelDesempenhoContent(
  props: PainelDesempenhoContent,
) {
  const { painelUuid, onSucessUpdatePropriedades, setHasIndicadores } = props

  const { isOpen, open, anchorEl, close } = usePopOver()
  const { useQueryObterIndicadores, useUpdatePropriedadesLayout } =
    useIndicador()
  const { data: indicadores, isLoading: isLoadingIndicadores } =
    useQueryObterIndicadores(painelUuid || '')
  const {
    mutateAsync: updatePropriedades,
    isSuccess: isSucessUpdatePropriedades,
  } = useUpdatePropriedadesLayout()

  const [isDraggable, setIsDraggable] = useState<boolean>(true)

  const hasIndicadores = Array.isArray(indicadores) && indicadores.length > 0

  useEffect(() => {
    onSucessUpdatePropriedades(isSucessUpdatePropriedades)
  }, [isSucessUpdatePropriedades])

  useEffect(() => {
    setHasIndicadores(hasIndicadores || false)

    const style = document.createElement('style')
    style.innerHTML = `
      .react-grid-item.react-grid-placeholder {
        background-color: #34aadc;
      }
    `
    document.head.appendChild(style)
    return () => {
      document.head.removeChild(style)
    }
  }, [hasIndicadores])

  const layouts = useMemo(() => {
    if (!hasIndicadores) return

    return {
      xl: indicadores?.map((d) => ({
        i: d.uuid || '',
        x: d.propriedadesLayout?.x || 0,
        y: d.propriedadesLayout?.y || 0,
        w: d.propriedadesLayout?.largura || layoutsPropsMap[d.nome].w,
        h: d.propriedadesLayout?.altura || layoutsPropsMap[d.nome].h,
        minW: layoutsPropsMap[d.nome].minW,
        minH: layoutsPropsMap[d.nome].minH,
      })),
      lg: indicadores?.map((d) => ({
        i: d.uuid || '',
        x: d.propriedadesLayout?.x || 0,
        y: d.propriedadesLayout?.y || 0,
        w: d.propriedadesLayout?.largura || layoutsPropsMap[d.nome].w,
        h: d.propriedadesLayout?.altura || layoutsPropsMap[d.nome].h,
        minW: layoutsPropsMap[d.nome].minW,
        minH: layoutsPropsMap[d.nome].minH,
      })),
      md: indicadores?.map((d) => ({
        i: d.uuid || '',
        x: d.propriedadesLayout?.x || 0,
        y: d.propriedadesLayout?.y || 0,
        w: d.propriedadesLayout?.largura || layoutsPropsMap[d.nome].w,
        h: d.propriedadesLayout?.altura || layoutsPropsMap[d.nome].h,
        minW: layoutsPropsMap[d.nome].minW,
        minH: layoutsPropsMap[d.nome].minH,
      })),
      sm: indicadores?.map((d) => ({
        i: d.uuid || '',
        x: d.propriedadesLayout?.x || 0,
        y: d.propriedadesLayout?.y || 0,
        w: d.propriedadesLayout?.largura || layoutsPropsMap[d.nome].w,
        h: d.propriedadesLayout?.altura || layoutsPropsMap[d.nome].h,
        minW: layoutsPropsMap[d.nome].minW,
        minH: layoutsPropsMap[d.nome].minH,
      })),
      xs: indicadores?.map((d) => ({
        i: d.uuid || '',
        x: d.propriedadesLayout?.x || 0,
        y: d.propriedadesLayout?.y || 0,
        w: d.propriedadesLayout?.largura || layoutsPropsMap[d.nome].w,
        h: d.propriedadesLayout?.altura || layoutsPropsMap[d.nome].h,
        minW: layoutsPropsMap[d.nome].minW,
        minH: layoutsPropsMap[d.nome].minH,
      })),
    }
  }, [indicadores])

  const handleLayoutChange = async (layout: Layout[]) => {
    const propriedadesFormatada = layout.map((l) => ({
      uuid: l.i,
      x: l.x,
      y: l.y,
      largura: l.w,
      altura: l.h,
    }))

    await updatePropriedades(propriedadesFormatada)
  }

  if (isLoadingIndicadores) return <LinearProgress />

  return (
    <Box>
      {!hasIndicadores && !isLoadingIndicadores ? (
        <Surface sx={{ mt: 1, p: 2 }}>
          <AddFirst
            isLoading={isLoadingIndicadores}
            buttonLabel="Adicionar meu primeiro indicador"
            primaryMessage="Adicione o seu primeiro indicador"
            secondaryMessage={
              <div style={{ textAlign: 'center' }}>
                Parece que você ainda não tem nenhum indicador. Clique no botão
                abaixo para criar o seu primeiro indicador e começar a
                personalizar seu painel de desempenho.
              </div>
            }
            onClick={(e) => {
              open(e)
            }}
          />
        </Surface>
      ) : (
        <ResponsiveGridLayout
          layouts={layouts}
          breakpoints={{ xl: 1536, lg: 1200, md: 900, sm: 600, xs: 0 }}
          cols={{ xl: 12, lg: 12, md: 12, sm: 12, xs: 12 }}
          rowHeight={50}
          onDragStop={handleLayoutChange}
          onResizeStop={handleLayoutChange}
          isDraggable={isDraggable}
          compactType="vertical"
        >
          {indicadores?.map((indicador) => (
            <div
              key={indicador.uuid}
              style={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: 'white',
                borderRadius: 8,
                cursor: 'grab',
                padding: 8,
              }}
            >
              <IndicadorCard
                indicador={indicador}
                setIsDraggable={setIsDraggable}
              />
            </div>
          ))}
        </ResponsiveGridLayout>
      )}
      <IndicadoresPopover
        painelUuid={painelUuid}
        close={close}
        isOpen={isOpen}
        anchorEl={anchorEl}
        shouldCloseOnCreate={true}
      />
    </Box>
  )
}
