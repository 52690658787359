import { useState } from 'react'
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  TextField,
} from '@mui/material'

import { usePagination } from '@data-c/hooks'

import { AutoCompleteProps } from 'hooks/useAutocomplete'
import {
  FamiliaProdutosModel,
  obterFamiliaProdutos,
} from 'hooks/queries/useFamiliaProdutos'
import useNotification from 'hooks/useNotifications'

export default function AutoCompleteFamiliaProdutos(
  props: AutoCompleteProps<FamiliaProdutosModel>,
) {
  const { onChange, value } = props
  const [options, setOptions] = useState<FamiliaProdutosModel[]>([])
  const [isLoading, setLoading] = useState(false)

  const { pagination } = usePagination()
  const notifications = useNotification()

  async function handleObterFamiliaProdutos() {
    if (options?.length > 0) return
    setLoading(true)
    try {
      const familiaProdutos = await obterFamiliaProdutos({
        pagination,
        queryParams: { ativo: true },
      })

      setOptions(familiaProdutos?.data)
    } catch (error) {
      notifications.notifyException(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Autocomplete
      onOpen={handleObterFamiliaProdutos}
      disablePortal
      options={options || []}
      onChange={onChange}
      getOptionLabel={(familiaProduto: FamiliaProdutosModel) => {
        return `${familiaProduto.nome}`
      }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField {...params} value={value} label="Família de Produtos" />
      )}
      loadingText="Carregando..."
      loading={isLoading}
    />
  )
}
