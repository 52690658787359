import { ChangeEvent } from 'react'
import { Box, ButtonProps, TextFieldProps } from '@mui/material'
import { CurrencyTextField, Button } from '@data-c/ui'

interface InternalButtonProps extends ButtonProps {
  label: string
}

type CurrencyTextButtonProps = {
  isLoading?: boolean
  onChange: (e: ChangeEvent<HTMLInputElement>, value: any) => void
  buttonProps: InternalButtonProps
} & Omit<TextFieldProps, 'onChange'>

export default function CurrencyTextButton(props: CurrencyTextButtonProps) {
  const { isLoading, buttonProps, ...rest } = props
  const { label, ...restButtonProps } = buttonProps
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        borderRadius: '4px',
      }}
    >
      <CurrencyTextField
        sx={{
          width: '300px',
          '& .MuiInputBase-input': { paddingY: '4px' },
          background: '#fff',
        }}
        inputProps={{ maxLength: 11 }}
        {...rest}
      />

      <Button
        sx={{
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          minWidth: '160px',
          marginLeft: '-158px',
        }}
        variant="contained"
        isLoading={isLoading}
        {...restButtonProps}
      >
        {label || 'Ok'}
      </Button>
    </Box>
  )
}
