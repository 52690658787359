import { FilterOptions } from '@data-c/providers'
import {
  useMutation,
  useQueryClient,
  useQuery as useTQuery,
} from '@tanstack/react-query'
import useCrud from 'hooks/useCrud'
import { AxiosError } from 'axios'
import { CidadeModel } from './useCidade'
import api from 'services/api'
import { ClientesModel } from './useClientes'
import { HttpResponseInterface, PaginationProps } from '@data-c/hooks'
import ErrorInterface from 'interfaces/ErrorInterface'
import useNotification from 'hooks/useNotifications'
import { SortProps } from 'interfaces/SortProps'
import { CondicaoPagamentoModel } from './useCondicaoPagamento'

export interface VendedorFilters {
  codigo: FilterOptions<string>
  cnpjcpf: FilterOptions<string>
  usual: FilterOptions<string>
}

export interface VendedorFilters1 {
  codigo: string
  cnpjcpf: string
  usual: string
}

export const filters: VendedorFilters = {
  codigo: {
    label: 'Código',
  },
  cnpjcpf: {
    label: 'CPF/CNPJ',
  },
  usual: {
    label: 'Nome',
  },
}

export interface VendedorModel {
  uuid?: string
  empresa: number
  origem: string
  id?: string
  codigo: number | null
  cnpjcpf: string
  nome: string
  usual: string
  endereco?: string
  numero?: string
  complemento?: string
  bairro?: string
  uf?: string
  cidade?: string
  cidadeModel?: CidadeModel
  cep?: string
  ddd?: string
  telefone?: string
  celular?: string
  contato?: string
  useremail: string | null
  isadmin: number
  isvendedor: number
  iscliente: number
  observacao?: string
  email?: string
  ativo?: string
  metaVenda?: number
  clientes?: ClientesModel[]
  telefoneAsText?: string
  celularAsText?: string
  dddcelular?: string
  condicaoPagamento?: CondicaoPagamentoModel | null
  condicaoPagamentoUuid?: string | null
}

interface QueryParamsInterface {
  plainQuery?: string | null
  vendedorUuid?: string
  pagination: PaginationProps
}

interface VinculoClientesInputProps {
  vendedorUuid: string
  clientesSelecionadosIds: Array<string>
}

interface LogMetaVendaModel {
  uuid: string
  data: string
  metaVenda: string
  vendedorUuid: string
  vendedor: VendedorModel
}

export async function obterVendedorPorId(id: string): Promise<VendedorModel> {
  const response = await api.get(`administrador/vendedores/${id}`)
  return response.data
}

export async function obterVendedorPorCnpjCpf(
  cpfcnpj: string,
): Promise<VendedorModel> {
  const response = await api.get<VendedorModel>(
    `/administrador/vendedores/cpfcnpj/${cpfcnpj}`,
    { attempts: 3 },
  )
  return response.data
}

export async function obterClientesVinculados(
  params: QueryParamsInterface,
  sort?: SortProps,
): Promise<HttpResponseInterface<ClientesModel>> {
  const { pagination: _pagination, vendedorUuid } = params

  const response = await api.get(
    `administrador/vendedores/${vendedorUuid}/clientes-vinculados`,
    {
      headers: {
        'DC-Page': _pagination.page,
        'DC-PageSize': _pagination.pageSize,
        'DC-SortName': sort?.column,
        'DC-SortDirection': sort?.direction,
      },
    },
  )

  const { data, meta: pagination } = response.data
  return { data, pagination }
}

export async function obterClientesNaoVinculados(
  params: QueryParamsInterface,
): Promise<HttpResponseInterface<ClientesModel>> {
  const { pagination: _pagination, vendedorUuid, plainQuery } = params

  const response = await api.get(
    `administrador/vendedores/${vendedorUuid}/clientes-nao-vinculados`,
    {
      params: { plainQuery },
      headers: {
        'DC-Page': _pagination.page,
        'DC-PageSize': _pagination.pageSize,
      },
    },
  )

  const { data, meta: pagination } = response.data
  return { data, pagination }
}

export async function obterLogMetaVendaPorVendedorId(
  params: QueryParamsInterface,
): Promise<HttpResponseInterface<LogMetaVendaModel>> {
  const { pagination: _pagination, vendedorUuid } = params

  const response = await api.get(
    `/administrador/vendedores/${vendedorUuid}/log-meta-venda`,
    {
      headers: {
        'DC-Page': _pagination.page,
        'DC-PageSize': _pagination.pageSize,
      },
    },
  )

  const { data, meta: pagination } = response.data
  return { data, pagination }
}

export async function vincularClientes(
  vendedorUuid: string,
  clientesSelecionadosIds: Array<string>,
): Promise<VendedorModel> {
  const response = await api.put(
    `/administrador/vendedores/${vendedorUuid}/vincular-clientes`,
    clientesSelecionadosIds,
  )
  return response.data
}

export async function desvincularClientes(
  vendedorUuid: string,
  clientesSelecionadosIds: Array<string>,
): Promise<VendedorModel> {
  const response = await api.put(
    `/administrador/vendedores/${vendedorUuid}/desvincular-clientes`,
    clientesSelecionadosIds,
  )

  return response.data
}

export async function atualizarMetaVenda(
  vendedorUuid: string,
  metaVenda: number,
): Promise<VendedorModel> {
  const response = await api.put(
    `/administrador/vendedores/${vendedorUuid}/meta-venda`,
    { metaVenda },
  )
  return response.data
}

export function useQueryObterVendedorPorId(id: string) {
  return useTQuery<VendedorModel>(['VENDEDOR', id], () => {
    return obterVendedorPorId(id)
  })
}

export function useQueryObterClientesVinculados(
  params: QueryParamsInterface,
  sort?: SortProps,
) {
  return useTQuery<
    HttpResponseInterface<ClientesModel>,
    AxiosError<ErrorInterface, ErrorInterface>
  >(['CLIENTES', params, sort], () => {
    return obterClientesVinculados(params, sort)
  })
}

export function useQueryObterClientesNaoVinculados(
  params: QueryParamsInterface,
) {
  return useTQuery<
    HttpResponseInterface<ClientesModel>,
    AxiosError<ErrorInterface, ErrorInterface>
  >(['CLIENTES', params], () => {
    return obterClientesNaoVinculados(params)
  })
}

export function useQueryObterLogMetaVendaPorVendedorId(
  params: QueryParamsInterface,
) {
  return useTQuery<
    HttpResponseInterface<LogMetaVendaModel>,
    AxiosError<ErrorInterface>
  >(['VENDEDOR', params], () => {
    return obterLogMetaVendaPorVendedorId(params)
  })
}

function flexPluralSingular<T>(data: T[]) {
  const dataLength = data.length
  return dataLength > 1 ? 's' : ''
}

export function useVincularClientes() {
  const notifications = useNotification()
  const queryClient = useQueryClient()
  return useMutation<VendedorModel, AxiosError, VinculoClientesInputProps>(
    (data: VinculoClientesInputProps) =>
      vincularClientes(data.vendedorUuid, data.clientesSelecionadosIds),
    {
      onSuccess(_: VendedorModel, data: VinculoClientesInputProps) {
        const flex = flexPluralSingular(data.clientesSelecionadosIds)

        notifications.notifySuccess(
          `Cliente${flex} vinculado${flex} com sucesso`,
        )
        queryClient.invalidateQueries(['CLIENTES'])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export function useDesvincularClientes() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  return useMutation<VendedorModel, AxiosError, VinculoClientesInputProps>(
    (data: VinculoClientesInputProps) =>
      desvincularClientes(data.vendedorUuid, data.clientesSelecionadosIds),
    {
      onSuccess(_: VendedorModel, data: VinculoClientesInputProps) {
        const flex = flexPluralSingular<string>(data.clientesSelecionadosIds)

        notifications.notifySuccess(
          `Cliente${flex} desvinculado${flex} com sucesso`,
        )
        queryClient.invalidateQueries(['CLIENTES'])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export function useAtualizarMetaVenda() {
  const notifications = useNotification()
  const queryClient = useQueryClient()
  return useMutation<
    VendedorModel,
    AxiosError,
    {
      vendedorUuid: string
      metaVenda: number
    }
  >((data) => atualizarMetaVenda(data.vendedorUuid, data.metaVenda), {
    onSuccess() {
      notifications.notifySuccess('Meta alterada com sucesso')
      queryClient.invalidateQueries(['VENDEDOR'])
    },
    onError(error) {
      notifications.notifyException(error)
    },
  })
}

export default function useVendedores() {
  return {
    ...useCrud<VendedorModel, AxiosError>(
      'administrador/vendedores',
      'Vendedor',
    ),
    obterVendedorPorCnpjCpf,
    useQueryObterClientesVinculados,
    useQueryObterClientesNaoVinculados,
    useVincularClientes,
    useDesvincularClientes,
    useAtualizarMetaVenda,
    useQueryObterLogMetaVendaPorVendedorId,
  }
}
