import React from 'react'
import { Box, IconButton } from '@mui/material'
import { Delete } from '@mui/icons-material'
import Surface from 'components/Surface'

interface Photo {
  uuid: string
  url: string
}

interface PhotosListProps {
  photos: Photo[]
  onRemove: (photoId: string) => void
}

const PhotosList: React.FC<PhotosListProps> = ({ photos, onRemove }) => {
  if (photos.length === 0) {
    return null
  }

  return (
    <Surface>
      <Box display="flex" flexWrap="wrap" gap={2} p={2}>
        {photos.map((photo) => (
          <Box
            key={photo.uuid}
            position="relative"
            width={100}
            height={100}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <img
              src={photo.url}
              alt={`Produto ${photo.uuid}`}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: 8,
              }}
            />
            <IconButton
              onClick={() => onRemove(photo.uuid)}
              sx={{
                position: 'absolute',
                top: 4,
                right: 4,
                backgroundColor: 'white',
                ':hover': { backgroundColor: 'red', color: 'white' },
              }}
            >
              <Delete fontSize="small" />
            </IconButton>
          </Box>
        ))}
      </Box>
    </Surface>
  )
}

export default PhotosList
