import { Surface } from '@data-c/ui'
import { FunilVendaModel } from 'hooks/queries/useFunilVenda'
import Form from 'pages/FunisDeVenda/components/Form'

interface TabCadastroProps {
  funilVenda: FunilVendaModel
}

export default function TabCadastro(props: TabCadastroProps) {
  const { funilVenda } = props

  return (
    <Surface sx={{ p: 2 }} elevation={1}>
      <Form view="plain" data={funilVenda || ({} as FunilVendaModel)} />
    </Surface>
  )
}
