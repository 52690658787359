import {
  useMutation,
  useQueryClient,
  useQuery as useTQuery,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'
import useNotification from 'hooks/useNotifications'
import api from 'services/api'
import {
  ErrorInterface,
  HttpResponseInterface,
  PaginationProps,
  SortProps,
} from '@data-c/hooks'

interface VincularEntidadesInputProps {
  pessoaUuid: string
  itensSelecionadosIds: Array<string>
  entidade?: string
  titulo?: string
}

interface QueryParamsInterface {
  plainQuery?: string | null
  pessoaUuid?: string
  entidade?: EntidadeEnum
  titulo?: string
  pagination: PaginationProps
}

export enum EntidadeEnum {
  CONDICAO_PAGAMENTO = 'CONDICAO_PAGAMENTO',
  FORMA_PAGAMENTO = 'FORMA_PAGAMENTO',
  TABELA_PRECO = 'TABELA_PRECO',
}

const invalidateQueriesMap: Record<string, string> = {
  CONDICAO_PAGAMENTO: 'CONDICOES-VINCULADAS',
  FORMA_PAGAMENTO: 'FORMAS-VINCULADAS',
  TABELA_PRECO: 'TABELAS-VINCULADAS',
}

export default function usePessoa() {
  function useVincularEntidades<T>(titulo: string) {
    const notifications = useNotification()
    const queryClient = useQueryClient()
    return useMutation<T, AxiosError, VincularEntidadesInputProps>(
      (data: VincularEntidadesInputProps) =>
        vincularEntidades<T>(
          data.pessoaUuid,
          data.itensSelecionadosIds,
          data.entidade || '',
        ),
      {
        onSuccess(_: T, variables: VincularEntidadesInputProps) {
          notifications.notifySuccess(`${titulo} vinculadas com sucesso`)
          if (variables.entidade) {
            queryClient.invalidateQueries([
              invalidateQueriesMap[variables.entidade],
            ])
          }
        },
        onError(error) {
          notifications.notifyException(error)
        },
      },
    )
  }

  async function vincularEntidades<T>(
    pessoaUuid: string,
    itensSelecionadosIds: Array<string>,
    entidade: string,
  ): Promise<T> {
    const response = await api.put(
      `/administrador/pessoa/${pessoaUuid}/vincular-entidades`,
      {
        itensSelecionadosIds,
        entidade,
      },
      { params: { pessoaUuid } },
    )
    return response.data
  }

  function useQueryObterEntidadesNaoVinculadas<T>(
    params: QueryParamsInterface,
  ) {
    return useTQuery<
      HttpResponseInterface<T>,
      AxiosError<ErrorInterface, ErrorInterface>
    >([invalidateQueriesMap[params.entidade || ''], params], () => {
      return obterEntidadesNaoVinculadas<T>(params)
    })
  }

  async function obterEntidadesNaoVinculadas<T>(
    params: QueryParamsInterface,
  ): Promise<HttpResponseInterface<T>> {
    const { pagination: _pagination, pessoaUuid, entidade, plainQuery } = params

    const response = await api.get(
      `administrador/pessoa/${pessoaUuid}/entidades-nao-vinculadas/${entidade}`,
      {
        params: { plainQuery },
        headers: {
          'DC-Page': _pagination.page,
          'DC-PageSize': _pagination.pageSize,
        },
      },
    )

    const { data, meta: pagination } = response.data
    return { data, pagination }
  }

  function useDesvincularEntidades<T>(titulo: string) {
    const notifications = useNotification()
    const queryClient = useQueryClient()
    return useMutation<T, AxiosError, VincularEntidadesInputProps>(
      (data: VincularEntidadesInputProps) =>
        desvincularEntidades(
          data.pessoaUuid,
          data.itensSelecionadosIds,
          data?.entidade || '',
        ),
      {
        onSuccess(_: T, variables: VincularEntidadesInputProps) {
          notifications.notifySuccess(`${titulo} desvinculadas com sucesso`)
          if (variables.entidade) {
            queryClient.invalidateQueries([
              invalidateQueriesMap[variables.entidade],
            ])
          }
        },
        onError(error) {
          notifications.notifyException(error)
        },
      },
    )
  }

  async function desvincularEntidades<T>(
    pessoaUuid: string,
    itensSelecionadosIds: Array<string>,
    entidade: string,
  ): Promise<T> {
    const response = await api.put(
      `/administrador/pessoa/${pessoaUuid}/desvincular-entidades`,
      {
        itensSelecionadosIds,
        entidade,
      },
      { params: { pessoaUuid } },
    )

    return response.data
  }

  function useQueryObterEntidadesVinculadas<T>(
    params: QueryParamsInterface,
    sort?: SortProps,
  ) {
    return useTQuery<
      HttpResponseInterface<T>,
      AxiosError<ErrorInterface, ErrorInterface>
    >([invalidateQueriesMap[params.entidade || ''], params, sort], () => {
      return obterEntidadesVinculadas<T>(params, sort)
    })
  }

  async function obterEntidadesVinculadas<T>(
    params: QueryParamsInterface,
    sort?: SortProps,
  ): Promise<HttpResponseInterface<T>> {
    const { pagination: _pagination, pessoaUuid, entidade } = params

    const response = await api.get(
      `administrador/pessoa/${pessoaUuid}/entidades-vinculadas/${entidade}`,
      {
        headers: {
          'DC-Page': _pagination.page,
          'DC-PageSize': _pagination.pageSize,
          'DC-SortName': sort?.column,
          'DC-SortDirection': sort?.direction,
        },
      },
    )

    const { data, meta: pagination } = response.data
    return { data, pagination }
  }

  return {
    useVincularEntidades,
    useQueryObterEntidadesNaoVinculadas,
    useDesvincularEntidades,
    useQueryObterEntidadesVinculadas,
  }
}
