import { PaperProps, Stack } from '@mui/material'
import Surface from 'components/Surface'
import { ReactNode, forwardRef, memo } from 'react'
export interface FunilKanbanProps extends PaperProps {
  children: ReactNode
  alignCenter?: boolean
}

const FunilKanban = forwardRef<HTMLDivElement, FunilKanbanProps>(
  (props: FunilKanbanProps, ref) => {
    const { children, alignCenter, ...rest } = props

    return (
      <Surface
        {...rest}
        elevation={2}
        sx={
          alignCenter
            ? {
                p: 1,
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center',
                flexGrow: 1,
                overflow: 'auto',
              }
            : {
                p: 1,
                display: 'flex',
                flexGrow: 1,
                overflow: 'auto',
              }
        }
      >
        <Stack id="stack-column" ref={ref} spacing={1} direction="row">
          {children}
        </Stack>
      </Surface>
    )
  },
)

export default memo(FunilKanban)
