import { FormProvider } from '@data-c/providers'
import { ButtonContainer } from '@data-c/ui'
import { MixProdutosModel } from 'hooks/queries/useMixProdutos'
import { Box } from '@mui/material'

import FormVincularProdutos from './components/FormVincularProdutos'
import TableProdutosVinculados from './components/TableProdutosVinculados'

interface TabProdutosVinculadosProps {
  mixProduto: MixProdutosModel
  isLoading?: boolean
  isFetching?: boolean
  error?: string
}

export default function TabProdutosVinculados(
  props: TabProdutosVinculadosProps,
) {
  const { mixProduto, isLoading, isFetching, error } = props

  return (
    <Box>
      <ButtonContainer sx={{ mb: 2 }}>
        <FormProvider>
          <FormVincularProdutos mixProduto={mixProduto} />
        </FormProvider>
      </ButtonContainer>

      <TableProdutosVinculados
        error={error}
        isLoading={isLoading}
        isFetching={isFetching}
        data={mixProduto.produtos || []}
        mixProdutoUuid={mixProduto.uuid}
      />
    </Box>
  )
}
