import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { TextFieldProps } from '@mui/material'

import _ from 'lodash'
import TextField from 'components/Inputs/TextField'

type AutoSaverTextFieldProps<EPT> = {
  attempts?: number
  extraParams?: EPT
  onChange: (e: React.ChangeEvent<HTMLInputElement>, extraParams?: EPT) => void
} & Omit<TextFieldProps, 'onChange'>

export default function AutoSaverTextField<EPT>(
  props: AutoSaverTextFieldProps<EPT>,
) {
  const { error, onChange, value, extraParams, ...rest } = props
  const [inputValue, setInputValue] = useState<unknown>(value || '')

  useEffect(() => {
    if (value) setInputValue(value)
  }, [value])

  const debounced = _.debounce((e, extraParams) => {
    if (onChange) onChange(e, extraParams)
  }, 1000)
  const debouncedSearch = useRef(debounced)

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    setInputValue(e.target.value)
    debouncedSearch.current(e, extraParams)
  }

  useEffect(() => {}, [])

  return (
    <TextField
      value={inputValue}
      onChange={handleChange}
      error={error}
      {...rest}
    />
  )
}

AutoSaverTextField.defaultProps = {
  attempts: 3,
}
