import { PlainQueryProps } from '@data-c/hooks'
import useCrud from 'hooks/useCrud'
import HttpRequestInterface from 'interfaces/HttpRequestInterface'
import HttpResponseInterface from 'interfaces/HttpResponseInterface'
import api from 'services/api'
import { AxiosError } from 'axios'
import CategoriaInterface from 'interfaces/CategoriaInterface'

export interface GrupoProdutosModel extends PlainQueryProps, CategoriaInterface {  
}

export default function useGrupoProdutos() {
  return useCrud<GrupoProdutosModel, AxiosError>(
    'administrador/grupo-produto',
    'Grupo de Produtos',
  )
}

export async function obterGrupoProdutos(
  params: HttpRequestInterface<GrupoProdutosModel>,
): Promise<HttpResponseInterface<GrupoProdutosModel>> {
  const { pagination: _pagination, queryParams } = params

  const response = await api.get('administrador/grupo-produto', {
    params: queryParams,
    headers: {
      'DC-Page': _pagination.page,
      'DC-PageSize': _pagination.pageSize,
    },
  })

  const { data, meta: pagination } = response.data

  return { data, pagination }
}
