import { FilterProvider, FormProvider } from '@data-c/providers'
import { ButtonContainer, PageTitle } from '@data-c/ui'
import useClientes, { filters } from 'hooks/queries/useClientes'

import { Button, Container } from '@mui/material'
// import novoIcon from 'assets/images/novo.svg'
import Filter from './components/Filter'
import Form from './components/Form'
import Table from './components/Table'
import Delete from 'components/Delete'
import { useUserContext } from 'components/Contexts/UserContext'
import Content from 'components/Layouts/Content'
import HelpButton from 'components/HelpButton'
import { useNavigate } from 'react-router-dom'

export default function Clientes() {
  const { temPermissao } = useUserContext()
  const navigate = useNavigate()
  const podeCriar = temPermissao('clientes.create') != 'sem_acesso'
  const { useDelete } = useClientes()
  const { mutateAsync } = useDelete()

  return (
    <Container>
      <FormProvider
        initialFormValues={{
          ativo: true,
          ie: 'ISENTO',
        }}
      >
        <FilterProvider
          filterValues={filters}
          storagePath="@fdv-filters-clientes"
        >
          <PageTitle title="Clientes">
            <ButtonContainer>
              <Filter />
              {podeCriar && (
                <>
                  <Button onClick={() => navigate('/importar')}>
                    {/* <img
                      style={{ marginRight: '4px' }}
                      src={novoIcon}
                      width="26px"
                    /> */}
                    Importar
                  </Button>
                  <Form />
                </>
              )}
              <HelpButton link="https://datacsistemashelp.zendesk.com/hc/pt-br/articles/28518211079316-Cadastro-de-Clientes" />
            </ButtonContainer>
          </PageTitle>
          <Content>
            <Table />
            <Delete onDelete={mutateAsync} />
          </Content>
        </FilterProvider>
      </FormProvider>
    </Container>
  )
}
