import { formatCurrency } from '@data-c/hooks'
import { DataTable, theme } from '@data-c/ui'
import { MUIDataTableColumnDef } from 'mui-datatables'
import { useMemo } from 'react'

export interface ABCTotal {
  codigo: string
  nome: string
  total: number
  quantidade?: number
  receitaAcumulada: number
  percentualAcumulado: number
  classificacao: 'A' | 'B' | 'C'
}

interface DataTableProps {
  data: ABCTotal[]
  isLoading?: boolean
  isFetching?: boolean
  error?: string
  exibeClassificacao?: boolean
  exibeQuantidade?: boolean
}

const classificacaoMap: Record<string, string> = {
  A: theme.palette.success.light,
  B: theme.palette.warning.light,
  C: theme.palette.error.light,
}

export default function ABCTable({
  data,
  isLoading,
  isFetching,
  error,
  exibeClassificacao,
  exibeQuantidade,
}: DataTableProps) {
  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'codigo',
        label: 'Código',
        options: {
          setCellProps() {
            return { width: '96px' }
          },
        },
      },
      {
        name: 'nome',
        label: 'Nome',
      },
      {
        name: 'quantidade',
        label: 'Quantidade',
        options: {
          display: exibeQuantidade,
        },
      },
      {
        name: 'total',
        label: `Total`,
        options: {
          setCellProps: () => ({
            style: {
              width: '126px',
              textAlign: 'right',
            },
          }),
        },
      },
      {
        name: 'receitaAcumulada',
        label: `Total Acumulado`,
        options: {
          setCellProps: () => ({
            style: {
              width: '126px',
              textAlign: 'right',
            },
          }),
        },
      },
      {
        name: 'percentualAcumulado',
        label: `% Acumulado`,
        options: {
          setCellProps: () => ({
            style: {
              width: '126px',
              textAlign: 'right',
            },
          }),
        },
      },
      {
        name: 'classificacao',
        label: `Classificação`,
        options: {
          display: exibeClassificacao,
          setCellProps: (cellValue: string) => ({
            style: {
              width: '126px',
              textAlign: 'center',
              backgroundColor: classificacaoMap[cellValue],
            },
          }),
        },
      },
    ],
    [exibeClassificacao],
  )

  const tableData = data.map((item) => ({
    codigo: item.codigo,
    nome: item.nome,
    total: formatCurrency(item.total),
    receitaAcumulada: formatCurrency(item.receitaAcumulada),
    percentualAcumulado: formatCurrency(item.percentualAcumulado),
    classificacao: item.classificacao,
    quantidade: parseInt(item.quantidade?.toString() || ''),
  }))

  return (
    <DataTable
      isLoading={isLoading}
      isFetching={isFetching}
      error={error}
      data={tableData}
      columns={columns}
    />
  )
}
