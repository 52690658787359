import { ButtonContainer } from '@data-c/ui'
import Surface from 'components/Surface'
import { FormaPagamentoModel } from 'hooks/queries/useFormaPagamento'
import Table from './components/Table'
import Form from './components/Form'
import { FormProvider } from '@data-c/providers'

export interface TabCondicoesPagamentoProps {
  formaPagamento: FormaPagamentoModel
}

export default function TabCondicoesPagamento(
  props: TabCondicoesPagamentoProps,
) {
  const { formaPagamento } = props
  return (
    <>
      <ButtonContainer sx={{ mb: 2 }}>
        <FormProvider>
          <Form formaPagamento={formaPagamento} />
        </FormProvider>
      </ButtonContainer>
      <Surface>
        <Table formaPagamento={formaPagamento} />
      </Surface>
    </>
  )
}
