import { ButtonContainer } from '@data-c/ui'
import { Box, Button } from '@mui/material'
import Delete from 'components/Delete'
import usePopOver from 'hooks/usePopover'
import Form from './components/Form'
import Table from './components/Table'
import useMixProdutos from 'hooks/queries/useMixProdutos'

export interface TabBonificacaoProps {
  mixProdutoUuid: string
}

export default function TabBonificacao(props: TabBonificacaoProps) {
  const { mixProdutoUuid } = props
  const popoverProps = usePopOver()
  const { useRemoverDescontos } = useMixProdutos()
  const { mutateAsync } = useRemoverDescontos('BONIFICACAO')

  return (
    <Box>
      <ButtonContainer sx={{ mb: 2 }}>
        <Button variant="contained" onClick={popoverProps.open}>
          Adicionar
        </Button>
        <Form mixProdutoUuid={mixProdutoUuid} {...popoverProps} />
      </ButtonContainer>

      <Table mixProdutoUuid={mixProdutoUuid} />

      <Delete onDelete={mutateAsync} />
    </Box>
  )
}
