import { MouseEvent, useState } from 'react'

import { FilterProvider, FormProvider } from '@data-c/providers'
import { Button, ButtonContainer, PageTitle } from '@data-c/ui'
import { usePagination } from '@data-c/hooks'

import Delete from 'components/Delete'
import DragAndDrop from 'pages/CondicaoPagamento/components/DragAndDrop'

import useCondicaoPagamento, {
  CondicaoPagamentoFilters1,
  CondicaoPagamentoModel,
  filters,
} from 'hooks/queries/useCondicaoPagamento'
import { Container, Popover, Typography } from '@mui/material'

import Filter from './components/Filter'
import Form from './components/Form'
import Table from './components/Table'
import Content from 'components/Layouts/Content'
import HelpButton from 'components/HelpButton'

export default function CondicaoPagamento() {
  const [openPopover, setOpenPopover] = useState<HTMLButtonElement | null>(null)
  const [appliedFilters, setFilters] = useState<CondicaoPagamentoFilters1>(
    {} as CondicaoPagamentoFilters1,
  )

  const { pagination } = usePagination()
  const { useDelete, useUpdateOrder, useQuery } = useCondicaoPagamento()
  const { mutateAsync: updateOrder, isLoading } = useUpdateOrder()
  const { mutateAsync: handleDelete } = useDelete()
  const { data } = useQuery({ pagination })

  function handleShowPopover(event: MouseEvent<HTMLButtonElement>) {
    setOpenPopover(event.currentTarget)
  }

  return (
    <Container>
      <FormProvider initialFormValues={{ ativo: true }}>
        <PageTitle title="Condição de Pagamento">
          <ButtonContainer>
            {data?.data && (
              <Button size="small" onClick={handleShowPopover}>
                Ordenar Exibição
              </Button>
            )}

            <FilterProvider filterValues={filters}>
              <Filter
                onApplyFilters={(_appliedFilters) => {
                  setFilters(_appliedFilters)
                }}
              />
            </FilterProvider>
            <Form />
            <HelpButton link="https://datacsistemashelp.zendesk.com/hc/pt-br/articles/27684312994452-Condi%C3%A7%C3%A3o-de-Pagamento" />
          </ButtonContainer>
        </PageTitle>

        <Content>
          <Table filters={appliedFilters} />
        </Content>

        <Popover
          open={!!openPopover}
          anchorEl={openPopover}
          onClose={() => setOpenPopover(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <DragAndDrop
            data={data?.data || []}
            isLoading={isLoading}
            onChange={(_newItemsOrder) => {
              updateOrder(_newItemsOrder)
            }}
            renderItem={(data: CondicaoPagamentoModel) => (
              <Typography>{data?.nome}</Typography>
            )}
          />
        </Popover>
        <Delete onDelete={handleDelete} />
      </FormProvider>
    </Container>
  )
}
