import { useFilterApi, useFilterData } from '@data-c/providers'
import { FilterContainer } from '@data-c/ui'
import { Autocomplete, CircularProgress, Stack, TextField } from '@mui/material'
import { useUserContext } from 'components/Contexts/UserContext'
import ClientesTransporter from 'components/Transporters/ClientesTransporter'
import TimeVendasTransporter from 'components/Transporters/TimeVendasTranporter'
import useEtiqueta from 'hooks/queries/useEtiqueta'
import { useEffect, useMemo } from 'react'

export default function Filter() {
  const { changeFilterValue } = useFilterApi()
  const { filterValues, isOpen } = useFilterData()
  const { temPermissao, user } = useUserContext()

  const { useQueryObterEtiquetas } = useEtiqueta()
  const { data: etiquetas, isLoading: isLoadingEtiquetas } =
    useQueryObterEtiquetas()

  const formattedEtiquetas = useMemo(() => {
    return etiquetas?.map((e) => {
      return {
        label: e.nome,
        value: e.uuid,
      }
    })
  }, [etiquetas])

  const permissaoParaLerNegociacoes =
    temPermissao('negociacoes.read') || 'pessoal'

  useEffect(() => {
    if (permissaoParaLerNegociacoes === 'pessoal') {
      changeFilterValue('representante', user)
    }
  }, [permissaoParaLerNegociacoes, isOpen])

  return (
    <FilterContainer
      title="Parâmetros"
      triggerButtonProps={{ variant: 'outlined' }}
      renderLabel={(filterLabel, filterValue) => {
        if (filterLabel === 'Vendedor') {
          return `${filterLabel} - ${filterValue?.nome || ''}`
        }

        if (filterLabel === 'Cliente') {
          return `${filterLabel} - ${filterValue?.nome || ''}`
        }

        if (filterLabel === 'Etiqueta') {
          const label = filterValue.length === 1 ? 'Etiqueta' : 'Etiquetas'
          let values = ''
          if (Array.isArray(filterValue)) {
            values = filterValue.map((f: any) => f.label).toString()
          }
          return `${label} - ${values}`
        }

        return `${filterLabel} - ${filterValue}`
      }}
    >
      <Stack spacing={1.5}>
        <TextField
          label="Código do Pedido"
          value={filterValues?.codigoPedido?.value || ''}
          onChange={(e) => changeFilterValue('codigoPedido', e.target.value)}
        />

        <TimeVendasTransporter
          disabled={temPermissao('clientes.read') === 'pessoal'}
          name="representanteUuid"
          label={filterValues?.representante?.label}
          value={filterValues?.representante?.value || null}
          onChange={(representante) => {
            changeFilterValue('representante', representante)
          }}
          InputLabelProps={{ shrink: true }}
        />

        <ClientesTransporter
          name="clienteUuid"
          label={filterValues?.cliente?.label}
          value={filterValues?.cliente?.value || null}
          vendedorUuid={filterValues?.representante?.value?.uuid || ''}
          onChange={(cliente) => {
            changeFilterValue('cliente', cliente)
          }}
          InputLabelProps={{ shrink: true }}
        />

        <Autocomplete
          multiple
          options={formattedEtiquetas || []}
          getOptionLabel={(option) => option?.label}
          value={filterValues?.etiquetaKeyValue?.value || []}
          disableCloseOnSelect
          onChange={(_: any, value: any) =>
            changeFilterValue('etiquetaKeyValue', value)
          }
          renderInput={(params) => <TextField {...params} label="Etiquetas" />}
          loading={isLoadingEtiquetas}
          loadingText={<CircularProgress />}
        />
      </Stack>
    </FilterContainer>
  )
}
