import { Paper } from '@mui/material'
import { MixProdutosModel } from 'hooks/queries/useMixProdutos'
import Form from 'pages/MixProdutos/components/Form'

interface TabCadastroMixProdutoProps {
  mixProduto: MixProdutosModel
}

export default function TabCadastroMixProduto(
  props: TabCadastroMixProdutoProps,
) {
  const { mixProduto } = props

  return (
    <Paper elevation={0} sx={{ p: 2 }}>
      <Form data={mixProduto} view="plain" />
    </Paper>
  )
}
