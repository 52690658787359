import {
  DataTable,
  OptionStyles,
  DataTableOptions as Options,
} from '@data-c/ui'
import { MUIDataTableColumnDef } from 'mui-datatables'
import useTimeVendas, { TimeVendasModel } from 'hooks/queries/useTimeVendas'

import { useFormApi } from '@data-c/providers'
import { useMemo, useCallback } from 'react'
import {
  findData,
  usePagination,
  TransportableDataTableProps,
} from '@data-c/hooks'

export default function Table(
  props: TransportableDataTableProps<TimeVendasModel>,
) {
  const { query, onTransport, enableTransporter } = props
  const { pagination, changePageSize, changePage } = usePagination()
  const { useQuery } = useTimeVendas()
  const { data, isLoading, isFetching, error } = useQuery({
    pagination,
    queryParams: {
      ativo: true,
      plainQuery: query,
    },
  })
  const { openForm, openConfirm } = useFormApi()

  const handleClickItem = useCallback(
    (event: 'edit' | 'delete' | 'transport', data: TimeVendasModel) => {
      switch (event) {
        case 'edit':
          openForm(data)
          break
        case 'delete':
          openConfirm(data)
          break
        case 'transport':
          onTransport && onTransport(data)
          break
      }
    },
    [openForm, openConfirm],
  )

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'codigo',
        label: 'Código',
        options: {
          setCellProps: () => ({
            style: {
              width: '96px',
            },
          }),
        },
      },
      {
        name: 'nome',
        label: 'Nome',
      },
      {
        name: 'uuid',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          setCellProps: OptionStyles,
          customBodyRender: (value) => {
            const _data = findData<TimeVendasModel>(
              data?.data || [],
              value,
              'uuid',
            )
            return (
              <Options
                displayTransporterButton={enableTransporter}
                displayDeleteButton={!enableTransporter}
                displayUpdateButton={!enableTransporter}
                value={_data}
                onClick={handleClickItem}
              />
            )
          },
        },
      },
    ],
    [data?.data, handleClickItem],
  )

  return (
    <DataTable
      error={error?.message}
      columns={columns}
      data={data?.data || []}
      pagination={data?.pagination}
      isLoading={isLoading}
      isFetching={isFetching}
      changePage={changePage}
      changePageSize={changePageSize}
      options={{
        rowsPerPageOptions: [15, 50, 100],
        rowsPerPage: 15,
      }}
    />
  )
}
