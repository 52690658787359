import { MenuItem, TextField, TextFieldProps } from '@mui/material'

export enum Acesso {
  PESSOAL = 'pessoal',
  TOTAL = 'total',
  ROTA = 'rota',
  SUPERVISOR = 'supervisor',
  SEM_ACESSO = 'sem_acesso',
}

export default function DropDownAcessoNegociacoes(props: TextFieldProps) {
  return (
    <TextField type="text" select {...props}>
      <MenuItem value={Acesso.PESSOAL}>Carteira Própria</MenuItem>
      <MenuItem value={Acesso.TOTAL}>Carteira da Empresa</MenuItem>
      <MenuItem value={Acesso.SUPERVISOR}>Supervisor de Vendas</MenuItem> 
      <MenuItem value={Acesso.SEM_ACESSO}>Sem Permissão</MenuItem>      
      {/* {/* <MenuItem value={Acesso.ROTA}>Região/Rota</MenuItem> */}      
    </TextField>
  )
}
