import { FilterProvider, FormProvider } from '@data-c/providers'
import { ButtonContainer, PageTitle } from '@data-c/ui'

import useCrmAtividades, { filters } from 'hooks/queries/useCrmAtividades'
import { Container } from '@mui/material'

import Form from './components/Form'
import Table from './components/Table'
import Filter from './components/Filter'
import Delete from 'components/Delete'
import HelpButton from 'components/HelpButton'
import Content from 'components/Layouts/Content'

export default function CrmAtividades() {
  const { mutateAsync: handleDelete } = useCrmAtividades().useDelete()
  return (
    <Container>
      <FormProvider>
        <FilterProvider
          storagePath="@fdv-filters-atividades"
          filterValues={filters}
        >
          <PageTitle title="Atividades">
            <ButtonContainer>
              <Filter />
              <Form />
              <HelpButton link="https://ember-health-873.notion.site/Manuten-o-de-Atividades-ac6fc0d250784c4e9b385643c213f6df?pvs=4" />
            </ButtonContainer>
          </PageTitle>
          <Content>
            <Table />
          </Content>
        </FilterProvider>
        <Delete onDelete={handleDelete} />
      </FormProvider>
    </Container>
  )
}
