import { FormProvider } from '@data-c/providers'
import { ButtonContainer } from '@data-c/ui'
import usePopOver from 'hooks/usePopover'
import { Box, Button } from '@mui/material'
import Delete from 'components/Delete'

import Form from './Form'
import Table from './Table'
import useMixProdutos from 'hooks/queries/useMixProdutos'

interface TabDescontosProps {
  mixProdutoUuid: string
}

export default function TabDesontos(props: TabDescontosProps) {
  const { mixProdutoUuid } = props
  const { useRemoverDescontos } = useMixProdutos()
  const popoverProps = usePopOver()
  const { mutateAsync } = useRemoverDescontos('DESCONTO')

  return (
    <Box>
      <FormProvider>
        <ButtonContainer sx={{ mb: 2 }}>
          <Button variant="contained" onClick={popoverProps.open}>
            Adicionar
          </Button>
          <Form mixProdutoUuid={mixProdutoUuid} {...popoverProps} />
        </ButtonContainer>
        <Table mixProdutoUuid={mixProdutoUuid} />
        <Delete onDelete={mutateAsync} />
      </FormProvider>
    </Box>
  )
}
