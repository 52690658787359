import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { AutoCompleteProps } from 'hooks/useAutocomplete'
import { SyntheticEvent, useEffect, useState } from 'react'

export interface Unit {
  label: string
  value: string
}

const units: Unit[] = [
  // Medidas de Massa (Peso)
  { label: 'Grama (g)', value: 'g' },
  { label: 'Kilograma (kg)', value: 'kg' },
  { label: 'Tonelada (t)', value: 't' },
  { label: 'Miligramas (mg)', value: 'mg' },
  //   { label: 'Microgramas (µg)', value: 'µg' },

  // Medidas de Volume
  { label: 'Litro (L)', value: 'L' },
  //   { label: 'Mililitro (mL)', value: 'mL' },
  //   { label: 'Centímetro cúbico (cm³)', value: 'cm³' },
  //   { label: 'Metro cúbico (m³)', value: 'm³' },
  { label: 'Barril', value: 'barril' },

  // Medidas de Comprimento
  { label: 'Metro (m)', value: 'm' },
  { label: 'Centímetro (cm)', value: 'cm' },
  { label: 'Milímetro (mm)', value: 'mm' },
  { label: 'Polegada (in)', value: 'in' },
  { label: 'Pé (ft)', value: 'ft' },
  //   { label: 'Jarda (yd)', value: 'yd' },
  //   { label: 'Milha (mi)', value: 'mi' },

  // Medidas de Área
  { label: 'Metro quadrado (m²)', value: 'm²' },
  { label: 'Centímetro quadrado (cm²)', value: 'cm²' },
  //   { label: 'Hectare (ha)', value: 'ha' },
  //   { label: 'Acre', value: 'acre' },

  // Medidas de Tempo
  //   { label: 'Segundo (s)', value: 's' },
  //   { label: 'Minuto (min)', value: 'min' },
  //   { label: 'Hora (h)', value: 'h' },
  //   { label: 'Dia', value: 'dia' },
  //   { label: 'Semana', value: 'semana' },
  //   { label: 'Mês', value: 'mes' },
  //   { label: 'Ano', value: 'ano' },

  // Medidas de Quantidade
  { label: 'Unidade (un)', value: 'un' },
  { label: 'Dúzia (12 unidades)', value: 'dúzia' },
  { label: 'Centena (100 unidades)', value: 'centena' },
  { label: 'Milhar (1000 unidades)', value: 'milhar' },

  // Medidas Específicas de Produtos
  { label: 'Caixa', value: 'caixa' },
  { label: 'Pacote', value: 'pacote' },
  { label: 'Saco', value: 'saco' },
  { label: 'Lata', value: 'lata' },
  { label: 'Galão', value: 'galão' },
  { label: 'Fardo', value: 'fardo' },
  { label: 'Bobina', value: 'bobina' },
  { label: 'Rolo', value: 'rolo' },
  { label: 'Pallet', value: 'pallet' },
  { label: 'Contêiner', value: 'contêiner' },

  // Medidas Agrícolas
  //   { label: 'Alqueire', value: 'alqueire' },
  { label: 'Saco (usado para grãos)', value: 'saco_grãos' },

  // Medidas de Energia
  //   { label: 'Joule (J)', value: 'J' },
  //   { label: 'Caloria (cal)', value: 'cal' },
  //   { label: 'Kilowatt-hora (kWh)', value: 'kWh' },

  // Outras Medidas
  //   { label: 'Carat (usado para pedras preciosas)', value: 'carat' },
  //   { label: 'Ampola (para líquidos medicinais)', value: 'ampola' },
  //   {
  //     label: 'Litro por minuto (L/min) (para fluxos de líquidos)',
  //     value: 'L/min',
  //   },
  //   { label: 'Tonelada por quilômetro (para transporte)', value: 'ton_km' },
]

const AutocompleteUnidades = (props: AutoCompleteProps<string>) => {
  const { onChange, value } = props
  const [unitValue, setUinitValue] = useState<Unit | null | undefined>(
    findUnit(value),
  )

  function findUnit(index?: string | null) {
    return units.find((u) => u.value === index)
  }

  useEffect(() => {
    setUinitValue(findUnit(value))
  }, [value, setUinitValue])
  return (
    <Autocomplete
      options={units}
      getOptionLabel={(option) => {
        return option.label
      }}
      value={unitValue}
      onChange={(event: SyntheticEvent, newValue: Unit | null) => {
        if (onChange) onChange(event, newValue?.value || null)
      }}
      renderInput={(params) => (
        <TextField {...params} label="Unidade" variant="outlined" fullWidth />
      )}
    />
  )
}

export default AutocompleteUnidades
