import { useMemo, useCallback } from 'react'
import {
  DataTable,
  DataTableOptions as Options,
  CurrencyCellStyle,
} from '@data-c/ui'
import { MUIDataTableColumnDef } from 'mui-datatables'
import { useQueryObterBonificacaoPorId } from 'hooks/queries/useMixProdutos'
import { findData } from '@data-c/hooks'
import { useFormApi } from '@data-c/providers'

export interface TableProps {
  mixProdutoUuid: string
  bonificacaoUuid: string
}

export default function Table(props: TableProps) {
  const { mixProdutoUuid, bonificacaoUuid } = props
  const { data, isLoading, isFetching } = useQueryObterBonificacaoPorId(
    mixProdutoUuid,
    bonificacaoUuid,
  )
  const { openConfirm } = useFormApi()
  const handleClickItem = useCallback((event: 'delete', data: any) => {
    switch (event) {
      case 'delete':
        openConfirm({
          regraUuid: bonificacaoUuid,
          produtoUuid: data.uuid,
        })
        break
    }
  }, [])

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'codigo',
        label: 'Código',
      },
      {
        name: 'nome',
        label: 'Nome',
      },
      {
        name: 'quantidadeBonificacao',
        label: 'Quantidade',
        options: {
          setCellProps: CurrencyCellStyle,
        },
      },
      {
        name: 'uuid',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          setCellProps: () => ({
            style: {
              width: '50px',
              textAlign: 'center',
            },
          }),
          customBodyRender: (value) => {
            const _data = findData(data?.bonificacoes || [], value)
            return (
              <Options
                displayDeleteButton={true}
                displayUpdateButton={false}
                value={_data}
                onClick={handleClickItem}
              />
            )
          },
        },
      },
    ],
    [findData, handleClickItem, data],
  )

  return (
    <DataTable
      columns={columns}
      isLoading={isLoading || isFetching}
      data={data?.bonificacoes || []}
    />
  )
}
