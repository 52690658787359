import { Button } from '@data-c/ui'
import { Alarm } from '@mui/icons-material'
import {
  Box,
  Collapse,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
} from '@mui/material'
import {
  AppNotificationsModel,
  LembreteDataEnum,
  LembreteTipoEnum,
  useQueryLembretesPorAtividade,
} from 'hooks/queries/useAppNotifications'

import { useCallback, useEffect, useMemo, useState } from 'react'
import FormItem from '../FormItem'

export interface LembreteNotificacaoProps {
  entidadeUuid: string
  notificacoes: Array<AppNotificationsModel>
}

export default function LembreteNotificacao(props: LembreteNotificacaoProps) {
  const { entidadeUuid, notificacoes: _notificacoes } = props
  const [isOpen, setOpen] = useState<boolean>(false)
  const [notificacoesLembrete, setNotificacoesLembrete] = useState<
    Array<AppNotificationsModel>
  >([])

  const { data: notificacoes, isLoading } =
    useQueryLembretesPorAtividade(entidadeUuid)

  const notificacaoPadrao = {
    entidadeUuid,
    lembreteTempo: 15,
    lembreteTipo: LembreteTipoEnum.MINUTOS,
    lembreteData: LembreteDataEnum.INICIO,
  } as AppNotificationsModel

  useEffect(() => {
    if (notificacoes) {
      //setNotificacoesLembrete(notificacoes)

      // const notificacoesComLembrete = notificacoes.filter((n) => {
      //   return !n.enviarEm
      // })

      if (notificacoes.length === 0) {
        setNotificacoesLembrete([notificacaoPadrao])
      } else {
        setNotificacoesLembrete(notificacoes)
      }
    }
  }, [notificacoes])

  function handleClickAbrirFecharCollapse() {
    setOpen(!isOpen)
  }

  function handleClickAdicionarNovoLembrete() {
    setNotificacoesLembrete([...notificacoesLembrete, { ...notificacaoPadrao }])
  }

  function handleCancel(index: number) {
    setNotificacoesLembrete((oldState) =>
      oldState.filter((_, i) => i !== index),
    )
  }

  function handleChange(notificacao: AppNotificationsModel, index: number) {
    setNotificacoesLembrete((oldState) =>
      oldState.map((v, i) => (i === index ? notificacao : v)),
    )
  }

  const hasNewItem = useMemo(() => {
    if (notificacoesLembrete) {
      const itemIndex = notificacoesLembrete.findIndex((n) => !n.uuid)
      return itemIndex > -1
    }

    return false
  }, [notificacoesLembrete])

  // const countLembretes = useMemo(() => {
  //   if (notificacoes) {
  //     if (notificacoes.length === 0) return 'Nenhum lembrete definido'

  //     if (notificacoes.length === 1) {
  //       return `Você será lembrado ${notificacoes[0].lembrarEmAsRelative}`
  //     }

  //     return `Você tem ${notificacoes.length} lembretes`
  //   }
  //   return 'Nenhum lembrete definido'
  // }, [notificacoes])

  const showCancelButton = useCallback(
    (notificacao: AppNotificationsModel) => {
      return !(
        hasNewItem &&
        notificacoesLembrete.length === 1 &&
        !Boolean(notificacao?.uuid)
      )
    },
    [hasNewItem, notificacoesLembrete],
  )

  if (isLoading)
    return (
      <Typography color="primary" variant="body2">
        Carregando lembretes...
      </Typography>
    )

  return (
    <>
      <MenuItem onClick={handleClickAbrirFecharCollapse}>
        <ListItemIcon>
          <Alarm color="primary" />
        </ListItemIcon>
        <ListItemText disableTypography>
          <Typography color="primary" variant="body2">
            Quero ser lembrado
          </Typography>
        </ListItemText>

        {/* <Typography variant="body2">{countLembretes}</Typography> */}
      </MenuItem>

      <Collapse in={isOpen}>
        <Box sx={{ m: 2 }}>
          {notificacoesLembrete.map((notificacao, index) => (
            <Box key={index} sx={{ mb: 1 }}>
              <FormItem
                showCancelButton={showCancelButton(notificacao)}
                notificacao={notificacao}
                index={index}
                onCancel={(i) => {
                  handleCancel(i)
                }}
                onChange={(v, i) => {
                  handleChange(v, i)
                }}
              />
            </Box>
          ))}
          {!hasNewItem && (
            <Button variant="text" onClick={handleClickAdicionarNovoLembrete}>
              Adicionar novo lembrete
            </Button>
          )}
        </Box>
      </Collapse>
    </>
  )
}
