import {
  PlainQueryProps,
  HttpRequestInterface,
  HttpResponseInterface,
} from '@data-c/hooks'
import useCrud from 'hooks/useCrud'
import api from 'services/api'
import { AxiosError } from 'axios'
import CategoriaInterface from 'interfaces/CategoriaInterface'

export interface ClasseProdutosModel
  extends PlainQueryProps,
    CategoriaInterface {
  id?: string
  uuid?: string
  origem?: string
  empresa?: number
  codigo?: number
  nome?: string
  ativo?: boolean
}

export default function useClasseProdutos() {
  return useCrud<ClasseProdutosModel, AxiosError>(
    'administrador/classe-produto',
    'Classe de Produtos',
    'female',
  )
}

export async function obterClasseProdutos(
  params: HttpRequestInterface<ClasseProdutosModel>,
): Promise<HttpResponseInterface<ClasseProdutosModel>> {
  const { pagination: _pagination, queryParams } = params

  const response = await api.get('administrador/classe-produto', {
    params: queryParams,
    headers: {
      'DC-Page': _pagination.page,
      'DC-PageSize': _pagination.pageSize,
    },
  })

  const { data, meta: pagination } = response.data

  return { data, pagination }
}
