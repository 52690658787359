import EmailIcon from 'components/Icons/EmailIcon'
import EventoIcon from 'components/Icons/EventoIcon'
import LigacaoIcon from 'components/Icons/LigacaoIcon'
import PrazoIcon from 'components/Icons/PrazoIcon'
import ReuniaoIcon from 'components/Icons/ReuniaoIcon'
import TarefaIcon from 'components/Icons/TarefaIcon'
import { TipoAtividade } from 'hooks/queries/useCrmAtividades'

export interface TipoAtividadeIconProps {
  tipoAtividade: TipoAtividade
}

const mappedIcons = {
  [TipoAtividade.TODAS]: <LigacaoIcon />,
  [TipoAtividade.LIGAR]: <LigacaoIcon />,
  [TipoAtividade.EMAIL]: <EmailIcon />,
  [TipoAtividade.ALMOCO]: <EventoIcon />,
  [TipoAtividade.PRAZO]: <PrazoIcon />,
  [TipoAtividade.REUNIAO]: <ReuniaoIcon />,
  [TipoAtividade.TAREFA]: <TarefaIcon />,
}

export default function TipoAtividadeIcon(props: TipoAtividadeIconProps) {
  return <>{mappedIcons[props.tipoAtividade]}</>
}
