import api from 'services/api'
import { useQuery } from '@tanstack/react-query'
import ErrorInterface from 'interfaces/ErrorInterface'
import { AxiosError } from 'axios'
import { SortProps } from 'interfaces/SortProps'

export interface RelatorioFichaClienteModel {
  codigo: string
  nome: string
  ultimaCompra: string
  maiorPreco: number
  menorPreco: number
  ultimoPreco: number
  quantidadeItensComprados: number
  quantidadeRecorrenciaCompra: number
}

export interface RelatorioFichaClienteFilters {
  clienteUuid: string
  dataInicio: string | null
}

export async function obterRelatorioFichaCliente(
  params: RelatorioFichaClienteFilters,
  sort?: SortProps,
): Promise<Array<RelatorioFichaClienteModel>> {
  const response = await api.get<Array<RelatorioFichaClienteModel>>(
    `relatorio/ficha-cliente`,
    {
      params: params,
      headers: {
        'DC-SortName': sort?.column,
        'DC-SortDirection': sort?.direction,
      },
    },
  )
  return response.data
}

export function useQueryObterRelatorioFichaCliente(
  params: RelatorioFichaClienteFilters,
  sort?: SortProps,
) {
  return useQuery<
    Array<RelatorioFichaClienteModel>,
    AxiosError<ErrorInterface>
  >(['RELATORIO_FICHA_CLIENTE', params, sort], () => {
    return obterRelatorioFichaCliente(params, sort)
  })
}

export default function useRelatorioFichaCliente() {
  return {
    useQueryObterRelatorioFichaCliente,
  }
}
