import { SyntheticEvent, useState, useRef } from 'react'
import { AutocompleteInputChangeReason } from '@mui/material'
import _ from 'lodash'

export interface AutoCompleteProps<T> {
  name?: string
  label?: string
  value?: T | null
  required?: boolean
  helperText?: string
  error?: boolean
  onChange?: (event: SyntheticEvent, value: T | null) => void
}

interface InternalAutoCompleteProps<T, TParams = unknown> {
  search: (query: string, params: any) => Promise<Array<T>>
  params?: TParams
  options?: Array<T>
  initialOptions?: Array<T>
}

export default function useAutocomplete<T, TParams = unknown>(
  props: InternalAutoCompleteProps<T, TParams>,
) {
  const { search, params } = props
  const [isLoading, setLoading] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>('')

  const [options, setOptions] = useState<Array<T>>([])

  const setDefaultOptions = (defaultOptions: Array<T>) => {
    setOptions(defaultOptions)
  }

  const callSearch = async (value: string, params: TParams) => {
    setLoading(true)
    const data = await search(value, params)
    setLoading(false)
    if (data) {
      setOptions(data)
    }
  }

  const debounced = _.debounce(callSearch, 400)
  const debouncedSearch = useRef(debounced)

  async function onInputChange(
    _: React.SyntheticEvent | null,
    value: string,
    __?: AutocompleteInputChangeReason,
  ) {
    if (!value) {
      setInputValue('')
      return []
    }
    setInputValue(value)
    await debouncedSearch.current(value, params as TParams)
  }
  return {
    options,
    isLoading,
    inputValue,
    setDefaultOptions,
    onInputChange,
    setOptions,
  }
}
