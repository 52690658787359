import { ButtonContainer } from '@data-c/ui'
import { memo, useState } from 'react'
import {
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material'

import Surface from 'components/Surface'
import { Groups, Hail } from '@mui/icons-material'
import TableClientes from './components/TableClientes'
import TableVendedores from './components/TableVendedores'

function Table() {
  const [view, setView] = useState<'analitico' | 'sintetico'>('analitico')

  return (
    <>
      <Surface sx={{ mt: 2, p: 2 }}>
        <ButtonContainer sx={{ mb: 1 }}>
          <ToggleButtonGroup
            size="small"
            value={view}
            exclusive
            onChange={(_, value) => {
              setView(value)
            }}
            aria-label="text alignment"
          >
            <ToggleButton
              size="small"
              value="analitico"
              aria-label="left aligned"
              color="primary"
            >
              <Stack direction="row" spacing={1}>
                <Groups />
                <Typography variant="button">Clientes</Typography>
              </Stack>
            </ToggleButton>
            <ToggleButton value="sintetico" color="primary">
              <Stack direction="row" spacing={1}>
                <Hail /> <Typography variant="button">Vendedor</Typography>
              </Stack>
            </ToggleButton>
          </ToggleButtonGroup>
        </ButtonContainer>

        {view === 'analitico' && <TableClientes />}
        {view === 'sintetico' && <TableVendedores />}
      </Surface>
    </>
  )
}

export default memo(Table)
