import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { Container, Tab, Tabs } from '@mui/material'

import { FormProvider } from '@data-c/providers'
import { PageTitle } from '@data-c/ui'

import {
  VendedorModel,
  useQueryObterVendedorPorId,
} from 'hooks/queries/useVendedores'
import useTabs from 'hooks/useTabs'

import Loading from 'components/Displays/Loading'
import TabPanel from 'components/TabPanel'

import TabCadastroVendedor from './components/TabCadastroVendedor'
import TabClientes from './components/TabClientes'
import TabMetaVendas from './components/TabMetaVendas'
import { useEffect } from 'react'
import Content from 'components/Layouts/Content'
import TabTabelaDePrecos from './components/TabTabelaDePrecos'
import TabFormaDePagamento from './components/TabFormaDePagamento'
import TabCondicaoDePagamento from './components/TabCondicaoDePagamento'

export default function Vendedor() {
  const { id: vendedorUuid } = useParams()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { value, handleChange, setValue } = useTabs()
  const { data: vendedor, isLoading } = useQueryObterVendedorPorId(
    vendedorUuid as string,
  )

  useEffect(() => {
    if (searchParams.get('tab') === 'meta-de-vendas') {
      setValue(2)
    }
  }, [searchParams])

  if (isLoading)
    return (
      <Container>
        <Loading />
      </Container>
    )

  return (
    <Container>
      <FormProvider>
        <PageTitle title={`${vendedor?.nome}`} onBack={() => navigate(-1)}>
          <Tabs value={value} onChange={handleChange} aria-label="tabs cliente">
            <Tab label="Cadastro" value={0} />
            <Tab label="Clientes" value={1} />
            <Tab label="Meta de vendas" value={2} />
            <Tab label="Tabela de Preços" value={3} />
            <Tab label="Forma Pagamento" value={4} />
            <Tab label="Condição Pagamento" value={5} />
          </Tabs>
        </PageTitle>
        <Content>
          <TabPanel value={value} index={0}>
            <TabCadastroVendedor vendedor={vendedor || ({} as VendedorModel)} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <TabClientes vendedorUuid={vendedor?.uuid || ''} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <TabMetaVendas vendedor={vendedor || ({} as VendedorModel)} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <TabTabelaDePrecos pessoaUuid={vendedor?.uuid || ''} />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <TabFormaDePagamento pessoaUuid={vendedor?.uuid || ''} />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <TabCondicaoDePagamento pessoaUuid={vendedor?.uuid || ''} />
          </TabPanel>
        </Content>
      </FormProvider>
    </Container>
  )
}
