import { Grid, TextField } from '@mui/material'

import useFatorPrecificacao, {
  FatorPrecificacaoModel,
} from 'hooks/queries/useFatorPrecificacao'
import useValidations from 'hooks/useValidations'
import * as yup from 'yup'
import { Checkbox, CurrencyTextField, FormContainer } from '@data-c/ui'
import { useFormApi, useFormData } from '@data-c/providers'

export default function Form() {
  const { onChangeFormValue, closeForm, toggleSubmit } =
    useFormApi<FatorPrecificacaoModel>()

  const { formValues: data } = useFormData<FatorPrecificacaoModel>()

  const schema = yup.object().shape({
    descricao: yup.string().required('Informe a descrição'),
    fator: yup.string().required('Informe um fator'),
    pcomissao: yup.string().required('Informe um fator'),
  })

  const { setValidationErrors, validationProps } = useValidations()
  const { useSubmit } = useFatorPrecificacao()
  const { mutateAsync } = useSubmit()

  function handleSubmitForm() {
    setValidationErrors(null)
    schema
      .validate(data, { abortEarly: false })
      .then(async () => {
        toggleSubmit(true)
        await mutateAsync(data)
        closeForm()
      })
      .catch((err) => {
        setValidationErrors(err)
      })
      .finally(() => toggleSubmit(false))
  }

  return (
    <FormContainer
      dialogProps={{ title: 'Fator de Precificação' }}
      onSubmitForm={handleSubmitForm}
    >
      <Grid container spacing={2}>
        <Grid item xl={2} lg={2} md={2} sm={3} xs={12}>
          <TextField
            name="codigo"
            label="Código"
            value={data?.codigo || ''}
            onChange={onChangeFormValue}
            disabled
          />
        </Grid>
        <Grid item xl={10} lg={10} md={10} sm={12} xs={12}>
          <TextField
            name="descricao"
            label="Descrição"
            required
            value={data?.descricao || ''}
            onChange={onChangeFormValue}
            inputProps={{ maxLength: 40 }}
            {...validationProps('descricao')}
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            name="observacao"
            label="Observação"
            value={data?.observacao || ''}
            onChange={onChangeFormValue}
            inputProps={{ maxLength: 100 }}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={8} xs={8}>
          <CurrencyTextField
            name="pcomissao"
            label="Comissão(%)"
            required
            value={data?.pcomissao || ''}
            onChange={onChangeFormValue}
            {...validationProps('pcomissao')}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={8} xs={8}>
          <CurrencyTextField
            name="fator"
            label="Fator"
            required
            value={data?.fator || ''}
            onChange={onChangeFormValue}
            {...validationProps('fator')}
          />
        </Grid>
        <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
          <Checkbox
            label="Ativo"
            name="ativo"
            onChange={onChangeFormValue}
            checked={Boolean(data?.ativo)}
          />
        </Grid>
      </Grid>
    </FormContainer>
  )
}

Form.defaultProps = {
  data: {},
}
