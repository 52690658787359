import { ButtonContainer, PageTitle } from '@data-c/ui'
import { Container } from '@mui/material'
import { FilterProvider } from '@data-c/providers'
import { filters } from 'hooks/queries/useRelatorioPrevisaoEntrega'
import Content from 'components/Layouts/Content'
import Filter from './components/Filter'
import Table from './components/Table'

export default function PrevisaoEntrega() {
  return (
    <Container>
      <FilterProvider filterValues={filters}>
        <PageTitle title="Previsão de Entrega">
          <ButtonContainer>
            <Filter />
          </ButtonContainer>
        </PageTitle>
        <Content>
          <Table />
        </Content>
      </FilterProvider>
    </Container>
  )
}
