import { useDialog } from '@data-c/hooks'
import { Button, ButtonContainer, Dialog, Editable } from '@data-c/ui'
import { Add, ArrowDownward, ArrowUpward, Delete } from '@mui/icons-material'
import { Box, BoxProps, Stack } from '@mui/material'
import { EtapaModel } from 'hooks/queries/useFunilEtapa'
import { useEffect, useRef, useState } from 'react'
import { draggable } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import invariant from 'tiny-invariant'
export interface EtapaProps {
  containerProps?: BoxProps
  etapa: EtapaModel
  onChange?: (etapa: EtapaModel) => void
  onAdd: (position: number) => void
  onDelete: (etapa: EtapaModel) => void
  onChangePosition: (etapa: EtapaModel, direction: 'up' | 'down') => void
  isDeleting?: boolean
  isNew?: boolean
  hideUp?: boolean
  hideDown?: boolean
}

export default function Etapa(props: EtapaProps) {
  const {
    containerProps,
    etapa,
    onChange,
    onChangePosition,
    onAdd,
    onDelete,
    isDeleting,
    isNew,
    hideUp,
    hideDown,
  } = props
  const [isHovering, setHovering] = useState<boolean>(false)
  const {
    isOpen: isOpenDialog,
    openDialog,
    closeDialog,
    data: etapaParaExcluir,
  } = useDialog<EtapaModel>()

  const ref = useRef(null)

  useEffect(() => {
    const el = ref.current
    invariant(el)

    return draggable({
      element: el,
    })
  }, [])

  return (
    <Box
      ref={ref}
      {...containerProps}
      onMouseEnter={() => {
        setHovering(true)
      }}
      onMouseLeave={() => {
        setHovering(false)
      }}
    >
      <Box
        sx={{
          //   background: '#444',
          width: '100%',
          height: '100px',
          textAlign: 'center',
          position: 'absolute',
          bottom: '0px',
          color: '#ffffff',
        }}
      >
        <Box
          sx={{
            height: '100px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
            p: 2,
          }}
        >
          <Editable
            isEditing={isNew}
            textFieldProps={{
              sx: {
                maxWidth: '300px',
              },
              inputProps: {
                style: { color: '#fff' },
              },
            }}
            typographyProps={{
              variant: 'h4',
            }}
            value={etapa.nome}
            onChange={(value) => {
              if (onChange) {
                onChange({
                  ...etapa,
                  nome: value || '',
                })
              }
            }}
          />
        </Box>
      </Box>
      {isHovering && (
        <>
          <Box
            sx={{
              textAlign: 'center',
              position: 'absolute',
              bottom: '16px',
              right: '0px',
              mr: 2,
            }}
          >
            <Stack
              direction="row"
              spacing={1}
              justifyContent="center"
              alignItems="center"
              alignContent="center"
            >
              <Box>
                <Button
                  isLoading={isDeleting}
                  color="error"
                  variant="contained"
                  onClick={() => {
                    openDialog(etapa)
                  }}
                >
                  <Delete />
                </Button>
              </Box>
              <Box>
                <Stack spacing={1}>
                  {!hideUp && (
                    <Button
                      variant="text"
                      onClick={() => {
                        onChangePosition(etapa, 'up')
                      }}
                    >
                      <ArrowUpward sx={{ color: 'white' }} />
                    </Button>
                  )}
                  {!hideDown && (
                    <Button
                      variant="text"
                      onClick={() => {
                        onChangePosition(etapa, 'down')
                      }}
                    >
                      <ArrowDownward sx={{ color: 'white' }} />
                    </Button>
                  )}
                </Stack>
              </Box>
            </Stack>
          </Box>
          <Box
            sx={{
              width: '100%',
              textAlign: 'center',
              position: 'absolute',
              top: '-116px',
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                onAdd(etapa.posicao)
              }}
            >
              <Add />
            </Button>
          </Box>
          <Box
            sx={{
              width: '100%',
              textAlign: 'center',
              position: 'absolute',
              top: '-12px',
            }}
          >
            <Button
              sx={{ zIndex: 999 }}
              variant="contained"
              color="primary"
              onClick={() => {
                onAdd(etapa.posicao + 1)
              }}
            >
              <Add />
            </Button>
          </Box>
        </>
      )}

      <Dialog
        open={isOpenDialog}
        type="error"
        title="Excluir etapa permanentemente?"
        maxWidth="xs"
        actions={
          <ButtonContainer>
            <Button
              onClick={(e) => {
                e.stopPropagation()
                closeDialog()
              }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              color="error"
              isLoading={isDeleting}
              onClick={(e) => {
                e.stopPropagation()
                if (etapaParaExcluir) onDelete(etapaParaExcluir)
              }}
            >
              Excluir
            </Button>
          </ButtonContainer>
        }
      >
        Você não poderá recuperar esta etapa após excluí-la!
      </Dialog>
    </Box>
  )
}
