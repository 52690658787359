import { useEffect } from 'react'
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  TextField,
} from '@mui/material'

import useApi from 'hooks/useApi'
import useAutocomplete, { AutoCompleteProps } from 'hooks/useAutocomplete'
import { CidadeModel } from 'hooks/queries/useCidade'

interface AutoCompleteCidadeProps extends AutoCompleteProps<CidadeModel> {
  uf: string | null
  disabled?: boolean
}

interface QueryParams {
  uf?: string | null
}

export default function AutoCompleteCidade(props: AutoCompleteCidadeProps) {
  const {
    onChange,
    value,
    uf,
    name,
    label,
    required,
    helperText,
    error,
    disabled,
  } = props
  const { get } = useApi()

  const { options, onInputChange, inputValue, isLoading, setOptions } =
    useAutocomplete<CidadeModel, QueryParams>({
      search: async (cidadeNome: string, params: QueryParams) => {
        const response = await get(`administrador/cidades`, {
          params: { nome: cidadeNome, uf: params.uf },
        })
        if (response) {
          return response.data.data
        }
      },
      params: { uf: uf },
    })

  useEffect(() => {
    async function getCityByUF(_uf: string | null) {
      if (!_uf) return
      try {
        const response = await get(`administrador/cidades`, {
          params: { uf: _uf },
        })

        if (!response) return
        setOptions(response.data.data)
      } catch (error) {
        // console.log(error)
      }
    }

    getCityByUF(uf)
    //eslint-disable-next-line
  }, [uf])

  return (
    <Autocomplete
      disabled={disabled}
      options={options}
      value={value || null}
      onChange={onChange}
      getOptionLabel={(cidade: CidadeModel) => {
        return `${cidade.nome}`
      }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          name={name}
          label={label}
          required={required}
          helperText={helperText}
          error={error}
          {...params}
        />
      )}
      onInputChange={onInputChange}
      loading={isLoading}
      loadingText={'Carregando'}
      inputValue={inputValue}
      isOptionEqualToValue={(option, value) => {
        return option.uuid === value.uuid
      }}
    />
  )
}
