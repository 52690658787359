import { Switch } from '@mui/material'
import CardConfig from 'components/CardConfig'
import { useRoleContext } from 'components/Contexts/RoleContext'

export default function CRMVisibilidade() {
  const { role, changeRolePermission } = useRoleContext()
  const { crm, atividades, motivos_perda } = role?.permissoes || {}

  return (
    <CardConfig
      title="CRM"
      description="Define se o usuário tem permissão para acessar o menu CRM"
      collapse={Boolean(crm?.visibilidade)}
      component={
        <Switch
          name="crm.visibilidade"
          checked={Boolean(crm?.visibilidade)}
          onChange={changeRolePermission}
          disabled={Boolean(role?.fixa)}
        />
      }
    >
      {/* <CardConfig
        title="Leads"
        description="Define se o usuário tem permissão para acessar o menu Leads"
        disabledPadding
        component={
          <Switch
            name="leads.visibilidade"
            checked={Boolean(leads?.visibilidade)}
            disabled={Boolean(role?.fixa)}
            onChange={changeRolePermission}
          />
        }
      /> */}
      <CardConfig
        title="Atividades"
        description="Define se o usuário tem permissão para acessar o menu Atividades"
        disabledPadding
        component={
          <Switch
            name="atividades.visibilidade"
            checked={Boolean(atividades?.visibilidade)}
            disabled={Boolean(role?.fixa)}
            onChange={changeRolePermission}
          />
        }
      />
      <CardConfig
        title="Motivos de Perda"
        description="Define se o usuário tem permissão para acessar o menu Motivos de Perda"
        disabledPadding
        component={
          <Switch
            name="motivos_perda.visibilidade"
            checked={Boolean(motivos_perda?.visibilidade)}
            disabled={Boolean(role?.fixa)}
            onChange={changeRolePermission}
          />
        }
      />
    </CardConfig>
  )
}
