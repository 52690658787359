import { useEffect, useState } from 'react'
import { Accept, useDropzone } from 'react-dropzone'
import PropTypes from 'prop-types'
import { Alert, Box, LinearProgress, Typography } from '@mui/material'
import { Backup as UploadIcon } from '@mui/icons-material'
import { theme } from '@data-c/ui'

interface DragDropFileProps {
  maxSize?: number
  maxFiles?: number
  acceptFiles?: Accept
  width?: number
  height?: number
  uploadMessage?: string
  onDropped: (files: File[]) => void
  disabled?: boolean
  title?: string
  isLoading?: boolean
  progress?: number
  error?: string
}

const DragDropFile = (props: DragDropFileProps) => {
  const {
    maxSize,
    maxFiles,
    acceptFiles,
    width,
    height,
    uploadMessage,
    onDropped,
    disabled,
    title,
    isLoading,
    progress,
    error,
  } = props
  const [messageError, setMessageError] = useState<string>()

  useEffect(() => {
    setMessageError(error)
  }, [error])

  const onDrop = (files: File[]) => {
    setMessageError('')
    onDropped(files)
  }

  const onDropRejected = (files: any[]) => {
    files.forEach((file) => {
      file.errors.forEach((error: any) => {
        if (error.code === 'file-too-large')
          setMessageError(
            'Não foi possível inserir, tamanho máximo de arquivos atingido',
          )
        if (error.code === 'too-many-files')
          setMessageError(
            'Não foi possível inserir, limite de arquivos atingido',
          )
        if (error.code === 'file-invalid-type')
          setMessageError('Não foi possível inserir, tipo de arquivo inválido')
      })
    })
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    maxSize: maxSize,
    maxFiles: maxFiles,
    accept: acceptFiles,
    onDrop,
    onDropRejected,
    disabled,
  })

  return (
    <>
      <Box
        sx={{
          border: disabled
            ? `2px dashed ${theme.palette.action.disabled}`
            : '2px dashed #ccc',
          borderRadius: theme.shape.borderRadius,
          height: height,
          width: width,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
          cursor: disabled ? 'auto' : 'pointer',
          backgroundColor: isDragActive
            ? theme.palette.background.default
            : '#ffffffff',
        }}
        {...getRootProps()}
        title={title}
      >
        <input {...getInputProps()} />

        <UploadIcon
          sx={{
            fontSize: '30pt',
            color: disabled ? theme.palette.action.disabled : '#ccc',
          }}
        />
        <Typography
          sx={{
            fontSize: '13pt',
            fontWeight: 'bolder',
            color: disabled ? theme.palette.action.disabled : '#666',
          }}
        >
          {uploadMessage}
        </Typography>

        {/* {isDragActive && <div>Arraste aqui</div>}
        {isDragReject && <div>Deu um errinho</div>} */}
      </Box>
      {messageError && (
        <Alert sx={{ mt: 2 }} severity="error">
          {messageError}
        </Alert>
      )}
      {isLoading && (
        <Box sx={{ mt: 2 }}>
          <LinearProgress
            variant={(progress || 0) > 0 ? 'determinate' : 'indeterminate'}
            value={progress || 0}
          />
        </Box>
      )}
    </>
  )
}

DragDropFile.defaultProps = {
  maxSize: 10485760,
  maxFiles: 1,
  width: '100%',
  height: '100%',
  uploadMessage: 'Clique ou solte o arquivo aqui para fazer o upload',
  disabled: false,
  title: '',
  isLoading: false,
}

DragDropFile.propTypes = {
  maxSize: PropTypes.number,
  maxFiles: PropTypes.number,
  acceptFiles: PropTypes.arrayOf(PropTypes.string),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  uploadMessage: PropTypes.string,
  onDropped: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  isLoading: PropTypes.bool,
}

export default DragDropFile
