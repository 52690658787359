import {
  Alert,
  Chip,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material'

import * as yup from 'yup'
import { Email as EmailIcon } from '@mui/icons-material'

import { useDialog, useForm } from '@data-c/hooks'
import { Button, ButtonContainer, Dialog } from '@data-c/ui'
import useValidations from 'hooks/useValidations'
import { PedidoModel } from 'hooks/queries/usePedidos'
import { useEffect, useState } from 'react'
import axios from 'axios'
import useNotification from 'hooks/useNotifications'

interface FormModel {
  emails: Array<string>
  email: string
}

export interface EnviarEmailProps {
  pedido: PedidoModel
}

const schema = yup.object().shape({
  email: yup
    .string()
    .email('Informe um e-mail válido')
    .required('Informe um e-mail válido'),
})

export default function EnviarEmail(props: EnviarEmailProps) {
  const { pedido } = props
  const [isSubmitting, setSubmitting] = useState<boolean>()
  const notifications = useNotification()
  const { isOpen, openDialog, closeDialog } = useDialog()
  const { data, setData, handleChange } = useForm<FormModel>({
    emails: [],
    email: '',
  })
  const { setValidationErrors, validationProps } = useValidations()

  useEffect(() => {
    const { cliente } = pedido

    if (cliente?.email) {
      const splitedEmails = cliente?.email.split(',')
      setData({
        email: '',
        emails: splitedEmails,
      })
    }
  }, [pedido, isOpen])

  function handleKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
    setValidationErrors(null)
    if (e.key === 'Enter') {
      schema
        .validate({ email: data.email }, { abortEarly: false })
        .then(async () => {
          if (!data.emails.includes(data.email)) {
            const emails = [...data.emails, data.email]
            setData({
              email: '',
              emails,
            })
          }
        })
        .catch((err) => {
          setValidationErrors(err)
        })
    }
  }

  function handleDelete(mail: string) {
    const newMails = data.emails.filter((m) => m != mail)
    setData({
      ...data,
      emails: newMails,
    })
  }

  async function handleEnviarEmail() {
    let url = 'http://localhost:8888/index.php'

    if ((import.meta.env.VITE_ENV || 'development') === 'production') {
      url = 'https://fdv.datac.com.br/index.php'
    }

    const chave = `${pedido.empresa}-${pedido?.representante?.codigo}`
    const uuidPedido = pedido.uuid
    const emails = data.emails.toString()

    const api = axios.create({
      baseURL: url,
    })
    setSubmitting(true)
    try {
      const response = await api.get('', {
        params: {
          r: 'api/email/enviar-copia-pedido',
          'access-token': chave,
          uuid: uuidPedido,
          emails,
        },
      })
      notifications.notifySuccess('O email foi enviado com sucesso')
      closeDialog()
    } catch (e: any) {
      notifications.notifyException(e)
    } finally {
      setSubmitting(false)
    }

    // String url = UrlHelper.buildUrl(context) + "?r=api/email/enviar-copia-pedido&access-token="+chave+"&uuid=" + uuidPedido + "&emails="+email;
  }

  return (
    <>
      <MenuItem onClick={() => openDialog()}>
        <ListItemIcon>
          <EmailIcon />
        </ListItemIcon>
        <ListItemText>Enviar por e-mail</ListItemText>
      </MenuItem>

      <Dialog
        title="Enviar cópia do pedido por e-mail"
        open={isOpen}
        onClose={() => {
          closeDialog()
          close()
        }}
        actions={
          <ButtonContainer>
            <Button
              onClick={() => {
                closeDialog()
                close()
              }}
              color="error"
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              disabled={data.emails.length === 0}
              isLoading={isSubmitting}
              onClick={handleEnviarEmail}
            >
              Enviar
            </Button>
          </ButtonContainer>
        }
      >
        <Stack spacing={2}>
          <TextField
            autoFocus
            required
            placeholder="Digite o e-mail e pressione ENTER para adicionar"
            name="email"
            label="E-mail"
            value={data.email}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            {...validationProps('email')}
          />

          {data.emails.length > 0 && (
            <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">
              {data.emails.map((mail) => (
                <Chip
                  key={mail}
                  label={mail}
                  onDelete={() => {
                    handleDelete(mail)
                  }}
                />
              ))}
            </Stack>
          )}

          {data.emails.length === 0 && (
            <Alert severity="warning" icon={false}>
              Informe ao menos uma conta de e-mail par aenviar a cópia do pedido
            </Alert>
          )}
        </Stack>
      </Dialog>
    </>
  )
}
