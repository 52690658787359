import CnpjCpfTextField from 'components/CnpjCpfTextField'
import { onlyNumbers } from '@data-c/hooks'
import { useFilterApi, useFilterData } from '@data-c/providers'
import { FilterContainer } from '@data-c/ui'
import {
  ChavesDeAcessoFilters,
  ChavesDeAcessoFilters1,
} from 'hooks/queries/useChavesDeAcesso'
import FilterProps from 'interfaces/FilterProps'
import { Stack, TextField } from '@mui/material'

export default function Filter({
  onApplyFilters,
}: FilterProps<ChavesDeAcessoFilters1>) {
  const { onChangeFilterValue, changeFilterValue } = useFilterApi()
  const { filterValues } = useFilterData<
    ChavesDeAcessoFilters,
    ChavesDeAcessoFilters1
  >()
  return (
    <FilterContainer
      title="Pesquisar por vendedor"
      triggerButtonProps={{ variant: 'outlined' }}
      onApplyFilters={(f) => onApplyFilters(f)}
    >
      <Stack spacing={1.5}>
        <TextField
          name="codigo"
          value={filterValues?.codigo?.value}
          label={filterValues?.codigo?.label}
          onChange={onChangeFilterValue}
        />
        <TextField
          name="nome"
          value={filterValues?.nome?.value}
          label={filterValues?.nome?.label}
          onChange={onChangeFilterValue}
        />
        <CnpjCpfTextField
          name="cnpjcpf"
          value={filterValues?.cnpjcpf?.value || ''}
          onChange={(e) => {
            const cnpjcpf = onlyNumbers(e.target.value)
            changeFilterValue('cnpjcpf', cnpjcpf)
          }}
          inputProps={{
            label: `${filterValues?.cnpjcpf?.label}`,
          }}
        />
      </Stack>
    </FilterContainer>
  )
}
