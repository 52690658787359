import {
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  styled,
} from '@mui/material'
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  DateView,
  PickersDay,
  PickersDayProps,
} from '@mui/x-date-pickers'
import DatePicker from 'components/DatePicker'
import { DateTime, DateTimeUnit } from 'luxon'
import { useEffect, useMemo, useState } from 'react'
import './styles.css'
export interface DateRange {
  tipo: string
  firstDate: string | null
  secondDate: string | null
}

// ---- SELECT WEEK

interface CustomPickerDayProps extends PickersDayProps<DateTime> {
  isSelected: boolean
  isHovered: boolean
  isToday: boolean
}

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isHovered',
})<CustomPickerDayProps>(
  ({ theme, isSelected, isHovered, isToday, day }) =>
    () => {
      return {
        borderRadius: 0,
        '& .MuiPickersDay-today': {
          backgroundColor: '#f52',
          borderRadius: '50%',
        },
        // border: 'none',
        ...(isToday &&
          !isHovered &&
          !isSelected && {
            borderRadius: '50%',
            '& .MuiPickersDay-today': {
              padding: '0px',
            },
          }),
        ...(isSelected && {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          '&:hover, &:focus': {
            backgroundColor: theme.palette.primary.main,
          },
        }),
        ...(isHovered && {
          backgroundColor: theme.palette.primary[theme.palette.mode],
          '&:hover, &:focus': {
            backgroundColor: theme.palette.primary[theme.palette.mode],
          },
        }),
        ...(day.weekday === 1 && {
          borderTopLeftRadius: '50%',
          borderBottomLeftRadius: '50%',
        }),
        ...(day.weekday === 7 && {
          borderTopRightRadius: '50%',
          borderBottomRightRadius: '50%',
        }),
      }
    },
) as React.ComponentType<CustomPickerDayProps>

const isInSameWeek = (dayA: DateTime, dayB: DateTime | null | undefined) => {
  if (dayB == null) {
    return false
  }

  return dayA.weekNumber === dayB.weekNumber
}

function Day(
  props: PickersDayProps<DateTime> & {
    selectedDay?: DateTime | null
    hoveredDay?: DateTime | null
  },
) {
  const { day, selectedDay, hoveredDay, ...other } = props

  const isToday = useMemo(() => {
    return day.day === DateTime.now().day
  }, [])

  return (
    <CustomPickersDay
      {...other}
      day={day}
      isToday={isToday}
      sx={{ px: 2.5 }}
      disableMargin
      selected={false}
      isSelected={isInSameWeek(day, selectedDay)}
      isHovered={isInSameWeek(day, hoveredDay)}
    />
  )
}

// !---- SELECT WEEK
interface DateRangePickerProps {
  value?: DateRange
  onChange?: (value: DateRange) => void
  renderFieldSet?: boolean
}

export default function DateRangePicker(props: DateRangePickerProps) {
  const { value, onChange, renderFieldSet } = props
  const [hoveredDay, setHoveredDay] = useState<DateTime | null>(null)
  const [dates, setDates] = useState<DateRange>({
    tipo: 'month',
    firstDate: null,
    secondDate: null,
  })

  useEffect(() => {
    if (value) {
      setDates(value)
    }
  }, [value])

  function handleBackToPrevious() {
    const inicio = dates.firstDate
      ? DateTime.fromFormat(dates.firstDate, 'yyyy-MM-dd')
      : DateTime.now().startOf('day')

    const _inicio = inicio.minus({
      [dates.tipo]: 1,
    })

    definirPeriodo(dates.tipo, _inicio.toFormat('yyyy-MM-dd'))
  }
  function handleGoToNext() {
    const inicio = dates.firstDate
      ? DateTime.fromFormat(dates.firstDate, 'yyyy-MM-dd')
      : DateTime.now().startOf('day')

    const _inicio = inicio.plus({
      [dates.tipo]: 1,
    })

    definirPeriodo(dates.tipo, _inicio.toFormat('yyyy-MM-dd'))
  }

  function definirPeriodoPorTipo(
    tipo: string,
    inicio: DateTime,
    dateTimeUnit: DateTimeUnit,
  ) {
    let _inicio = inicio.startOf(dateTimeUnit).toFormat('yyyy-MM-dd')
    let _fim = inicio.endOf(dateTimeUnit).toFormat('yyyy-MM-dd')

    const newDates = {
      tipo,
      firstDate: _inicio,
      secondDate: _fim,
    }

    setDates(newDates)
    if (onChange) {
      onChange(newDates)
    }
  }

  function definirPeriodo(tipo: string, inicio?: string | null) {
    if (tipo === 'livre') {
      setDates({
        ...dates,
        tipo: 'livre',
      })
      if (onChange)
        onChange({
          ...dates,
          tipo: 'livre',
        })
      return
    }

    const _inicio = inicio
      ? DateTime.fromFormat(inicio, 'yyyy-MM-dd')
      : DateTime.now()
    if (tipo === 'month') definirPeriodoPorTipo(tipo, _inicio, 'month')
    if (tipo === 'day') definirPeriodoPorTipo(tipo, _inicio, 'day')
    if (tipo === 'week') definirPeriodoPorTipo(tipo, _inicio, 'week')
    // if (_periodo === 'bimestral') definirPeriodoBimeste(_inicio)
    if (tipo === 'quarter') definirPeriodoPorTipo(tipo, _inicio, 'quarter')
    // if (_periodo === 'semestral') definirPeriodoSemestre(_periodo, _inicio)
    if (tipo === 'year') definirPeriodoPorTipo(tipo, _inicio, 'year')
  }

  function handleChangeFirstDate(date: string | null) {
    if (dates.tipo === 'livre' && date) {
      const newDates = {
        ...dates,
        firstDate: date,
      }

      setDates(newDates)
      if (onChange) {
        onChange(newDates)
      }
      return
    }

    if (date) definirPeriodo(dates.tipo, date)
  }
  function handleChangeSecondDate(date: string | null) {
    if (dates.tipo === 'livre' && date) {
      const newDates = {
        ...dates,
        secondDate: date,
      }

      setDates(newDates)
      if (onChange) {
        onChange(newDates)
      }
      return
    }

    if (date) definirPeriodo(dates.tipo, date)
  }

  const views: DateView[] | undefined = useMemo(() => {
    if (dates.tipo === 'month' || dates.tipo === 'quarter') return ['month']
    if (dates.tipo === 'day') return ['day']
    if (dates.tipo === 'year') return ['year']
    return undefined
  }, [dates.tipo])

  if (renderFieldSet) {
    return (
      <fieldset
        style={{
          border: '1px solid #cfcfcf',
          borderRadius: '4px',
        }}
      >
        <legend
          style={{
            color: '#808080',
            fontSize: '0.7rem',
            paddingLeft: '4px',
            paddingRight: '8px',
          }}
        >
          Período
        </legend>
        <Stack spacing={2}>
          <TextField
            value={dates.tipo}
            onChange={(e) => {
              const _periodo = e.target.value
              definirPeriodo(_periodo, dates?.firstDate || null)
            }}
            select
            fullWidth
            size="small"
            variant="outlined"
            InputProps={{
              startAdornment: dates?.tipo !== 'livre' && (
                <InputAdornment position="start">
                  <IconButton size="small" onClick={handleBackToPrevious}>
                    <ArrowLeftIcon fontSize="small" />
                  </IconButton>
                  <IconButton size="small" onClick={handleGoToNext}>
                    <ArrowRightIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          >
            <MenuItem value="livre">Livre</MenuItem>
            <MenuItem value="month">Mensal</MenuItem>
            <MenuItem value="day">Diário</MenuItem>
            <MenuItem value="week">Semanal</MenuItem>
            {/* <MenuItem value="bimestral">Bimestral</MenuItem> */}
            <MenuItem value="quarter">Trimestral</MenuItem>
            {/* <MenuItem value="semestral">Semestral</MenuItem> */}
            <MenuItem value="year">Anual</MenuItem>
          </TextField>

          <Stack direction="row" spacing={1}>
            <DatePicker
              label="Início"
              onChange={handleChangeFirstDate}
              value={dates.firstDate}
              showDaysOutsideCurrentMonth
              slots={dates.tipo === 'week' ? { day: Day } : undefined}
              slotProps={
                dates.tipo === 'week'
                  ? {
                      day: (ownerState) =>
                        ({
                          selectedDay: dates?.firstDate
                            ? DateTime.fromFormat(
                                dates.firstDate,
                                'yyyy-MM-dd',
                              ).startOf('week')
                            : DateTime.now(),
                          hoveredDay,
                          onPointerEnter: () => setHoveredDay(ownerState.day),
                          onPointerLeave: () => setHoveredDay(null),
                        } as any),
                    }
                  : undefined
              }
              views={views}
              // selectedSections={'weekDay'}
              // displayWeekNumber={dates.tipo === 'week'}
            />
            {dates.tipo !== 'day' && (
              <DatePicker
                disabled={dates.tipo !== 'livre' || !Boolean(dates?.firstDate)}
                minDate={
                  dates?.firstDate
                    ? DateTime.fromFormat(dates.firstDate, 'yyyy-MM-dd')
                    : undefined
                }
                showDaysOutsideCurrentMonth
                label="Fim"
                onChange={handleChangeSecondDate}
                value={dates.secondDate}
              />
            )}
          </Stack>
        </Stack>
      </fieldset>
    )
  }

  return (
    <Stack spacing={2}>
      <TextField
        value={dates.tipo}
        onChange={(e) => {
          const _periodo = e.target.value
          definirPeriodo(_periodo, dates?.firstDate || null)
        }}
        select
        fullWidth
        size="small"
        variant="outlined"
        InputProps={{
          startAdornment: dates?.tipo !== 'livre' && (
            <InputAdornment position="start">
              <IconButton size="small" onClick={handleBackToPrevious}>
                <ArrowLeftIcon fontSize="small" />
              </IconButton>
              <IconButton size="small" onClick={handleGoToNext}>
                <ArrowRightIcon fontSize="small" />
              </IconButton>
            </InputAdornment>
          ),
        }}
      >
        <MenuItem value="livre">Livre</MenuItem>
        <MenuItem value="month">Mensal</MenuItem>
        <MenuItem value="day">Diário</MenuItem>
        <MenuItem value="week">Semanal</MenuItem>
        {/* <MenuItem value="bimestral">Bimestral</MenuItem> */}
        <MenuItem value="quarter">Trimestral</MenuItem>
        {/* <MenuItem value="semestral">Semestral</MenuItem> */}
        <MenuItem value="year">Anual</MenuItem>
      </TextField>

      <Stack direction="row" spacing={1}>
        <DatePicker
          label="Início"
          onChange={handleChangeFirstDate}
          value={dates.firstDate}
          showDaysOutsideCurrentMonth
          slots={dates.tipo === 'week' ? { day: Day } : undefined}
          slotProps={
            dates.tipo === 'week'
              ? {
                  day: (ownerState) =>
                    ({
                      selectedDay: dates?.firstDate
                        ? DateTime.fromFormat(
                            dates.firstDate,
                            'yyyy-MM-dd',
                          ).startOf('week')
                        : DateTime.now(),
                      hoveredDay,
                      onPointerEnter: () => setHoveredDay(ownerState.day),
                      onPointerLeave: () => setHoveredDay(null),
                    } as any),
                }
              : undefined
          }
          views={views}
          // selectedSections={'weekDay'}
          // displayWeekNumber={dates.tipo === 'week'}
        />
        {dates.tipo !== 'day' && (
          <DatePicker
            disabled={dates.tipo !== 'livre' || !Boolean(dates?.firstDate)}
            minDate={
              dates?.firstDate
                ? DateTime.fromFormat(dates.firstDate, 'yyyy-MM-dd')
                : undefined
            }
            showDaysOutsideCurrentMonth
            label="Fim"
            onChange={(date) => {
              setDates({ ...dates, secondDate: date })
            }}
            value={dates.secondDate}
          />
        )}
      </Stack>
    </Stack>
  )
}

DateRangePicker.defaultProps = {
  renderFieldSet: true,
}
