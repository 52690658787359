export default function useCalculos() {
  function calcularPorcentagem(progressValue: number, targetValue: number) {
    const porcentagem =
      Math.min(Math.round((progressValue / targetValue) * 10000) / 100, 100) ||
      0
    return parseFloat(porcentagem.toFixed(2))
  }

  return {
    calcularPorcentagem,
  }
}
