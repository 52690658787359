import api from 'services/api'
import { useQuery as useTQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import queryClient from 'services/query-client'
import { generateEntityKey } from 'hooks/useCrud'
export interface UsuarioModel {
  uuid: string
  cnpjcpf: string
  email: string
  codigo: string
  nome: string
  isadmin: boolean
  isvendedor: boolean
  migracaoMio: boolean
  empresaModel: {
    mioEmpresaUuid: string
  }
}

export async function sinalizarUsuarioMigrado(
  empresa: number,
  codigo: string,
): Promise<UsuarioModel> {
  const response = await api.put<UsuarioModel>(
    `/migrate-account/${empresa}/usuario/${codigo}`,
  )

  queryClient.invalidateQueries([generateEntityKey('Cliente')])
  return response.data
}

// export function useSinalizarUsuarioMigrado(
//   empresa: number,
//   codigo: string,
// ) {
//   return useTQuery<UsuarioModel, AxiosError>(
//     ['USUARIOS', empresa, codigo],
//     () => {
//       return sinalizar(empresa, codigo)
//     },
//     {
//       staleTime: 0,
//     },
//   )
// }

export async function obterUsuarioPorEmpresaCodigo(
  empresa: number,
  codigo: string,
): Promise<UsuarioModel> {
  const response = await api.get<UsuarioModel>(
    `/migrate-account/${empresa}/usuario/${codigo}`,
  )
  return response.data
}

export function useObterUsuarioPorEmpresaCodigo(
  empresa: number,
  codigo: string,
) {
  return useTQuery<UsuarioModel, AxiosError>(
    ['USUARIOS', empresa, codigo],
    () => {
      return obterUsuarioPorEmpresaCodigo(empresa, codigo)
    },
    {
      staleTime: 0,
    },
  )
}

// export default function useMigrateAccount() {
//     ret
// }
