import { ButtonContainer } from '@data-c/ui'
import { Box } from '@mui/material'
import Form from './Form'
import Filter from './Filter'
import { FilterProvider } from '@data-c/providers'
import { filtersDuplicatas } from 'hooks/queries/useClientes'
import { useUserContext } from 'components/Contexts/UserContext'
import DuplicatasTable from 'components/Tables/DuplicatasTable'

interface TabDuplicatasProps {
  pessoaUuid: string
}

export default function TabDuplicatas(props: TabDuplicatasProps) {
  const { temPermissao } = useUserContext()
  return (
    <Box>
      <FilterProvider filterValues={filtersDuplicatas}>
        <ButtonContainer sx={{ mb: 2 }}>
          <Filter />
          {temPermissao('clientes.liberar_inadimplencia_cliente') && (
            <Form pessoaUuid={props.pessoaUuid} />
          )}
        </ButtonContainer>
        <DuplicatasTable pessoaUuid={props.pessoaUuid} />
      </FilterProvider>
    </Box>
  )
}
