import {
  Box,
  CircularProgress,
  ClickAwayListener,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Popper,
} from '@mui/material'
import { ButtonContainer, Dialog, Button } from '@data-c/ui'

import {
  PedidoModel,
  SituacaoPedidoEnum,
  useDeleteItemPedido,
  useSubmitCancelarSolicitacaoCotacao,
  useSubmitSolicitarCotacao,
  useSubmitAnalisarCotacao,
  useSubmitConcluirCotacao,
  useSubmitReabrirCotacao,
  useSubmitFecharNegociacao,
} from 'hooks/queries/usePedidos'
import { useQuery } from 'hooks/queries/useConfiguracoes'

import { HeaderPedido } from './components/HeaderPedido'
import ItensPedido from './components/ItensPedido'
import Form from './components/Form'
import Delete from 'components/Delete'
import MarcarComoPerdidaForm from './components/MarcarComoPerdidaForm'
import useUsuario, { UsuarioModel } from 'hooks/queries/useUsuario'
import usePopOver from 'hooks/usePopover'
import {
  Cancel,
  CheckCircle,
  Handshake,
  KeyboardArrowDown,
  LocalOfferOutlined,
  PriceChange,
  RestartAlt,
  Troubleshoot,
} from '@mui/icons-material'
import { useUserContext } from 'components/Contexts/UserContext'
import EnviarEmail from './components/EnviarEmail'
import { useDialog } from '@data-c/hooks'
import useNotification from 'hooks/useNotifications'
import EtiquetasPedido from './components/EtiquetasPedido'

interface TabNegociacaoProps {
  pedido?: PedidoModel
  isLoading: boolean
  isFetching: boolean
}

export default function TabNegociacao(props: TabNegociacaoProps) {
  const { pedido, isLoading, isFetching } = props
  const { mutateAsync: handleDelete } = useDeleteItemPedido()
  const { data: configuracoes } = useQuery()
  const { useQuery: useObterUsuario } = useUsuario()
  const { data: usuarioLogado } = useObterUsuario()
  const { anchorEl, open, isOpen, close } = usePopOver()
  const {
    anchorEl: anchorElEtiqueta,
    open: openEtiqueta,
    isOpen: isOpenEtiqueta,
    close: closeEtiqueta,
  } = usePopOver()
  const { user, temPermissao } = useUserContext()
  const { extractAxiosErrorData } = useNotification()
  const {
    // data: dataDialog,
    openDialog: openDialog,
    isOpen: isOpenDialog,
    closeDialog: closeDialog,
  } = useDialog()
  const {
    data: dataDialogError,
    openDialog: openDialogError,
    isOpen: isOpenDialogError,
    closeDialog: closeDialogError,
  } = useDialog<string>()
  if (!pedido) {
    return <div>Pedido não encontrado</div>
  }

  const podeEditarPedido = temPermissao('negociacoes.alter') != 'sem_acesso'

  const {
    mutateAsync: solicitarCotacao,
    isLoading: isSubmittingSolicitarCotacao,
  } = useSubmitSolicitarCotacao()

  const {
    mutateAsync: fecharNegociacao,
    isLoading: isSubmittingFecharNegociacao,
  } = useSubmitFecharNegociacao()

  const {
    mutateAsync: analisarCotacao,
    isLoading: isSubmittingAnalisarCotacao,
  } = useSubmitAnalisarCotacao()

  const { mutateAsync: aprovarCotacao, isLoading: isSubmittingAprovarCotacao } =
    useSubmitConcluirCotacao()

  const { mutateAsync: reabrirCotacao, isLoading: isSubmittingReabrirCotacao } =
    useSubmitReabrirCotacao()

  const {
    mutateAsync: cancelarSolicitacaoCotacao,
    isLoading: isSubmittingCancelarSolicitacao,
  } = useSubmitCancelarSolicitacaoCotacao()

  async function solicitarCotacaoAsync() {
    if (!pedido) return
    await solicitarCotacao(pedido)
    close()
  }

  async function analisarCotacaoAsync() {
    if (!pedido) return
    await analisarCotacao(pedido)
    close()
  }

  async function fecharNegociacaoAsync() {
    if (!pedido) return
    try {
      await fecharNegociacao(pedido)
      close()
      closeDialog()
    } catch (error) {
      const errorData = extractAxiosErrorData(error)
      if (errorData?.code === 'E_LIMITE_ESTOURADO') {
        close()
        closeDialog()
        openDialogError(errorData.message)
        return
      }
    }
  }

  async function aprovarCotacaoAsync() {
    if (!pedido) return
    await aprovarCotacao(pedido)
    close()
  }

  async function reabrirCotacaoAsync() {
    if (!pedido) return
    await reabrirCotacao(pedido)
    close()
  }

  async function cancelarSolicitacaoCotacaoAsync() {
    if (!pedido) return
    await cancelarSolicitacaoCotacao(pedido)
    close()
  }

  function podeSolicitarCotacao() {
    const situacoesPermitidas = [
      SituacaoPedidoEnum.WEB,
      SituacaoPedidoEnum.MOBILE,
    ]
    if (!pedido) return false
    return (
      !user?.isadmin &&
      situacoesPermitidas.includes(pedido.situacao as SituacaoPedidoEnum) &&
      podeEditarPedido
    )
  }

  function podeCancelarSolicitacaoCotacao() {
    const situacoesPermitidas = [SituacaoPedidoEnum.COTACAO_SOLICITACAO]
    if (!pedido) return false
    return (
      !user?.isadmin &&
      situacoesPermitidas.includes(pedido.situacao as SituacaoPedidoEnum) &&
      podeEditarPedido
    )
  }

  function podeAnalisarCotacao() {
    const situacoesPermitidas = [
      SituacaoPedidoEnum.COTACAO_SOLICITACAO,
      SituacaoPedidoEnum.WEB,
    ]
    if (!pedido) return false
    return (
      user?.isadmin &&
      situacoesPermitidas.includes(pedido.situacao as SituacaoPedidoEnum) &&
      podeEditarPedido
    )
  }

  function podeAprovarCotacao() {
    const situacoesPermitidas = [SituacaoPedidoEnum.COTACAO_ANALISE]
    if (!pedido) return false
    return (
      user?.isadmin &&
      situacoesPermitidas.includes(pedido.situacao as SituacaoPedidoEnum) &&
      podeEditarPedido
    )
  }

  function podeReabrirCotacao() {
    const situacoesPermitidas = [SituacaoPedidoEnum.COTACAO_APROVADA]
    if (!pedido) return false
    return (
      situacoesPermitidas.includes(pedido.situacao as SituacaoPedidoEnum) &&
      podeEditarPedido
    )
  }

  function podeMarcarPerdida() {
    const situacoesPermitidas = [
      SituacaoPedidoEnum.MOBILE,
      SituacaoPedidoEnum.WEB,
      SituacaoPedidoEnum.COTACAO_ANALISE,
      SituacaoPedidoEnum.COTACAO_APROVADA,
      SituacaoPedidoEnum.COTACAO_SOLICITACAO,
    ]
    if (!pedido) return false
    return (
      situacoesPermitidas.includes(pedido.situacao as SituacaoPedidoEnum) &&
      podeEditarPedido
    )
  }

  function podeFecharNegociacao() {
    const situacoesPermitidas = [
      SituacaoPedidoEnum.COTACAO_APROVADA,
      SituacaoPedidoEnum.WEB,
      SituacaoPedidoEnum.MOBILE,
    ]
    if (!pedido) return false
    return (
      situacoesPermitidas.includes(pedido.situacao as SituacaoPedidoEnum) &&
      podeEditarPedido
    )
  }

  function desabilitarAcoes() {
    const situacoesDesabilitadas = [
      SituacaoPedidoEnum.FECHADO,
      SituacaoPedidoEnum.CANCELADO,
      SituacaoPedidoEnum.LIBERADO,
    ]
    if (!pedido) return false
    return situacoesDesabilitadas.includes(
      pedido.situacao as SituacaoPedidoEnum,
    )
  }

  return (
    <Box>
      <ButtonContainer sx={{ mb: 2, marginRight: 0 }}>
        <ClickAwayListener
          onClickAway={() => {
            close()
          }}
        >
          <div>
            <ButtonContainer>
              {temPermissao('etiquetas.visibilidade') && (
                <Button
                  onClick={openEtiqueta}
                  startIcon={<LocalOfferOutlined />}
                >
                  Etiquetas
                </Button>
              )}
              <Button
                variant="contained"
                disableElevation
                onClick={open}
                endIcon={<KeyboardArrowDown />}
              >
                Opções
              </Button>
            </ButtonContainer>
            <Popper placement="bottom-end" anchorEl={anchorEl} open={isOpen}>
              <Paper
                sx={{ minWidth: 200, maxWidth: '100%', pt: 1, pb: 1 }}
                elevation={2}
              >
                <EnviarEmail pedido={pedido} />
                {!desabilitarAcoes() && <Divider />}

                {podeSolicitarCotacao() && (
                  <MenuItem onClick={solicitarCotacaoAsync}>
                    <ListItemIcon>
                      <PriceChange />
                    </ListItemIcon>
                    <ListItemText>Solicitar cotação</ListItemText>
                    {isSubmittingSolicitarCotacao && (
                      <Box>
                        <CircularProgress color="primary" size={18} />
                      </Box>
                    )}
                  </MenuItem>
                )}
                {podeAnalisarCotacao() && (
                  <MenuItem onClick={analisarCotacaoAsync}>
                    <ListItemIcon>
                      <Troubleshoot />
                    </ListItemIcon>
                    <ListItemText>Analisar Cotação</ListItemText>
                    {isSubmittingAnalisarCotacao && (
                      <Box>
                        <CircularProgress color="primary" size={18} />
                      </Box>
                    )}
                  </MenuItem>
                )}
                {podeCancelarSolicitacaoCotacao() && (
                  <MenuItem onClick={cancelarSolicitacaoCotacaoAsync}>
                    <ListItemIcon>
                      <Cancel />
                    </ListItemIcon>
                    <ListItemText>Cancelar Cotação</ListItemText>
                    {isSubmittingCancelarSolicitacao && (
                      <Box>
                        <CircularProgress color="primary" size={18} />
                      </Box>
                    )}
                  </MenuItem>
                )}
                {podeReabrirCotacao() && (
                  <MenuItem onClick={reabrirCotacaoAsync}>
                    <ListItemIcon>
                      <RestartAlt />
                    </ListItemIcon>
                    <ListItemText>Reabrir Cotação</ListItemText>
                    {isSubmittingReabrirCotacao && (
                      <Box>
                        <CircularProgress color="primary" size={18} />
                      </Box>
                    )}
                  </MenuItem>
                )}
                {podeAprovarCotacao() && (
                  <MenuItem onClick={aprovarCotacaoAsync}>
                    <ListItemIcon>
                      <CheckCircle />
                    </ListItemIcon>
                    <ListItemText>Aprovar Cotação</ListItemText>
                    {isSubmittingAprovarCotacao && (
                      <Box>
                        <CircularProgress color="primary" size={18} />
                      </Box>
                    )}
                  </MenuItem>
                )}
                {podeMarcarPerdida() && (
                  <MarcarComoPerdidaForm close={close} pedido={pedido} />
                )}
                {podeFecharNegociacao() && (
                  <MenuItem
                    onClick={() => {
                      openDialog(pedido)
                    }}
                  >
                    <ListItemIcon>
                      <Handshake />
                    </ListItemIcon>
                    <ListItemText>Fechar negócio</ListItemText>
                    {isSubmittingFecharNegociacao && (
                      <Box>
                        <CircularProgress color="primary" size={18} />
                      </Box>
                    )}
                  </MenuItem>
                )}
              </Paper>
            </Popper>
            <EtiquetasPedido
              pedidoUuid={pedido.uuid || ''}
              isOpen={isOpenEtiqueta}
              anchorEl={anchorElEtiqueta}
              onClose={closeEtiqueta}
            />
          </div>
        </ClickAwayListener>
      </ButtonContainer>
      <HeaderPedido pedido={pedido} configuracoes={configuracoes} />
      <Paper sx={{ padding: 2, mb: 1 }} elevation={0}>
        <ItensPedido
          pedido={pedido}
          usuarioLogado={usuarioLogado || ({} as UsuarioModel)}
          isLoading={isLoading}
          isFetching={isFetching}
          configuracoes={configuracoes}
        />
      </Paper>
      {podeEditarPedido && (
        <Form
          usuarioLogado={usuarioLogado || ({} as UsuarioModel)}
          pedido={pedido}
          onAfterSubmit={() => {}}
          configuracoes={configuracoes}
        />
      )}

      <Delete onDelete={handleDelete} />

      <Dialog
        title={`Fechar Negociação?`}
        maxWidth="xs"
        type="error"
        open={isOpenDialog}
        onClose={closeDialog}
        dialogActionsProps={{
          confirmLabel: 'Sim',
          isLoadingConfirmButton: isSubmittingFecharNegociacao,
        }}
        onConfirm={() => {
          fecharNegociacaoAsync()
        }}
        // actions={
        //   <Button variant="contained" onClick={closeDialog}>
        //     Voltar
        //   </Button>
        // }
      >
        Não será possível alterar a negociação depois de fechá-la.
      </Dialog>

      <Dialog
        title={`Limite de Crédito Excedido`}
        maxWidth="xs"
        type="error"
        open={isOpenDialogError}
        onClose={closeDialogError}
        dialogActionsProps={{
          confirmLabel: 'Ok',
        }}
        onConfirm={() => {
          closeDialogError()
        }}
      >
        {dataDialogError}
      </Dialog>
    </Box>
  )
}
