import { useState } from 'react'
import { PlainQueryProps } from '@data-c/hooks'
import { Transporter, TransporterProps } from '@data-c/ui'
import useClasseProdutos, {
  ClasseProdutosModel,
} from 'hooks/queries/useClasseProdutos'
import ClasseProdutoForm from 'pages/ClasseProdutos/components/Form'
import ClasseProdutoTable from 'pages/ClasseProdutos/components/Table'

type ClasseProdutoTransporterProps = {} & Omit<
  TransporterProps<ClasseProdutosModel>,
  'table' | 'form' | 'onSearch' | 'renderValue' | 'options'
>

export default function ClasseProdutoTransporter(
  props: ClasseProdutoTransporterProps,
) {
  const { value, ...rest } = props
  const [query, setQuery] = useState<string | null>()
  const { useQuery } = useClasseProdutos()

  const { data, isLoading } = useQuery<PlainQueryProps>({
    queryParams: {
      plainQuery: query,
    },
    pagination: {
      page: 1,
      pageSize: 100,
    },
  })

  function handleSearch(q: string | null) {
    setQuery(q)
  }

  return (
    <Transporter
      onSearch={handleSearch}
      options={data?.data || []}
      isLoading={isLoading}
      table={ClasseProdutoTable}
      label="Classe"
      dialogProps={{ title: 'Classe' }}
      renderValue={(value) => `${value?.codigo || ''} - ${value.nome}`}
      form={ClasseProdutoForm}
      value={value}
      {...rest}
    />
  )
}
