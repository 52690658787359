import { Container } from '@mui/material'

import { PageTitle } from '@data-c/ui'
import Error from 'components/Displays/Error'
import Loading from 'components/Displays/Loading'

import { useQuery } from 'hooks/queries/useConfiguracoes'

import Form from './components/Form'

export default function Configuracoes() {
  const { data, isLoading, error } = useQuery()

  return (
    <Container>
      <PageTitle title="Configurações">
        {/* <SearchTextField
          onSearch={() => {}}
          placeholder="Pesquisar por configurações"
        /> */}
      </PageTitle>

      {isLoading && <Loading />}
      {Boolean(error) && <Error />}
      {!isLoading && Boolean(!error) && <Form data={data} />}
    </Container>
  )
}
