import { Box, BoxProps, styled, useTheme } from '@mui/material'
import Loading from 'components/Displays/Loading'
import useFunilVendaEtapa, { EtapaModel } from 'hooks/queries/useFunilEtapa'
import { useEffect, useState } from 'react'

interface WoodenDirectionalSignProps extends BoxProps {
  isSelected?: boolean
}

const WoodenDirectionalSign = styled(Box)<WoodenDirectionalSignProps>(
  (props) => {
    const theme = useTheme()
    return {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1,
      paddingLeft: '16px',
      paddingRight: '16px',
      fontSize: '11pt',
      clipPath:
        'polygon(calc(100% - 6px) 0, 100% 50%, calc(100% - 6px) 100%, 2px 100%, 8px 50%, 2px 0)',
      backgroundColor: theme.palette.grey[400],
      ...(Boolean(props.isSelected) && {
        backgroundColor: theme.palette.success.main,
        color: 'white',
        fontWeight: 'bolder',
      }),
      '&:first-of-type': {
        borderTopLeftRadius: '4px',
        borderBottomLeftRadius: '4px',
        clipPath:
          'polygon(0 0, calc(100% - 6px) 0, 100% 50%, calc(100% - 6px) 100%, 0 100%);',
      },
      '&:last-of-type': {
        borderTopRightRadius: '4px',
        borderBottomRightRadius: '4px',
        clipPath: 'polygon(100% 0,100% 100%,2px 100%,8px 50%,2px 0)',
      },
      '&:hover': {
        cursor: 'pointer',
      },
    }
  },
)

export interface FunilVendasEtapasProps {
  funilVendaUuid: string
  selected: EtapaModel
  isSaving?: boolean
  onClick?: (etapa: EtapaModel) => void
}

export default function FunilVendasEtapas(props: FunilVendasEtapasProps) {
  const { funilVendaUuid, selected, onClick, isSaving } = props
  const { useQuery } = useFunilVendaEtapa(funilVendaUuid)
  const [etapaClicada, setEtapaClicada] = useState<string | undefined>()

  useEffect(() => {
    if (!isSaving) setEtapaClicada(undefined)
  }, [isSaving])

  const { data, isLoading } = useQuery({
    pagination: {
      page: 1,
      pageSize: 999,
    },
  })

  function handleClick(e: EtapaModel) {
    setEtapaClicada(e.uuid)
    if (onClick) onClick(e)
  }

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexGrow: 1,
        direction: 'column',
      }}
    >
      {data?.data?.map((e) => {
        const estaSalvando = etapaClicada === e.uuid && isSaving
        return (
          <WoodenDirectionalSign
            isSelected={e.posicao <= selected?.posicao}
            onClick={() => {
              handleClick(e)
            }}
          >
            <Loading
              isLoading={estaSalvando || isLoading}
              progressProps={{
                size: '12px',
              }}
            />
            {e.nome}
          </WoodenDirectionalSign>
        )
      })}
    </Box>
  )
}
