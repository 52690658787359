import { Container } from '@mui/material'

import { PageTitle } from '@data-c/ui'
import { FilterProvider } from '@data-c/providers'

import { filters } from 'hooks/queries/useRelatorioMetaVendas'

import Table from './components/Table'
import Filter from './components/Filter'
import Content from 'components/Layouts/Content'

export default function MetasVendas() {
  return (
    <Container>
      <FilterProvider filterValues={filters}>
        <PageTitle title="Metas de Vendas">
          <Filter />
        </PageTitle>
        <Content>
          <Table />
        </Content>
      </FilterProvider>
    </Container>
  )
}
