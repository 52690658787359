import { useCallback, useEffect, useMemo, useState } from 'react'

import { Box, Grid } from '@mui/material'
import { formatToCPFOrCNPJ } from 'brazilian-values'
import { MUIDataTableColumnDef } from 'mui-datatables'

import {
  DataTable,
  Flag,
  OptionStyles,
  DataTableOptions as Options,
  SearchTextField,
} from '@data-c/ui'
import { findData, usePagination } from '@data-c/hooks'

import useVendedores, { VendedorModel } from 'hooks/queries/useVendedores'
import useChavesDeAcesso from 'hooks/queries/useChavesDeAcesso'
import Loading from 'components/Displays/Loading'

interface TableVendedoresSemChaveProps {
  onClose: () => void
}

export default function TableVendedoresSemChave(
  props: TableVendedoresSemChaveProps,
) {
  const { onClose } = props

  const [query, setQuery] = useState('')
  const [vendedoresSemChave, setVendedoresSemChave] = useState<VendedorModel[]>(
    [],
  )

  const { changePageSize, changePage, pagination } = usePagination()
  const { useQuery: useQueryChaveDeAcesso, useSubmit } = useChavesDeAcesso()
  const { data: chavesDeAcesso } = useQueryChaveDeAcesso({ pagination })
  const { mutateAsync, isLoading: isSubmitting } = useSubmit()
  const { useQuery: useQueryVendedores } = useVendedores()

  const {
    data: vendedores,
    isLoading,
    isFetching,
    error,
  } = useQueryVendedores({
    pagination,
    queryParams: { usual: query },
  })

  useEffect(() => {
    const idsVendedoresComChave = chavesDeAcesso?.data.map(
      (item) => item?.vendedor?.uuid,
    )

    const _vendedoresSemChave =
      vendedores?.data.filter(
        (vendedor) =>
          !idsVendedoresComChave?.includes(vendedor?.uuid) && vendedor?.ativo,
      ) || []

    setVendedoresSemChave(_vendedoresSemChave)
  }, [chavesDeAcesso, vendedores])

  const formattedData = useMemo(
    () =>
      vendedoresSemChave.map((item) => {
        return {
          ...item,
          cnpjcpf: item.cnpjcpf ? formatToCPFOrCNPJ(item.cnpjcpf) : '',
        }
      }),
    [vendedoresSemChave],
  )

  const handleClickItem = useCallback(
    async (event: 'key', data: VendedorModel) => {
      switch (event) {
        case 'key':
          await mutateAsync({ vendedorUuid: data.uuid || '' })
          onClose()
          break
      }
    },
    [mutateAsync, onClose],
  )

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'codigo',
        label: 'Código',
        options: {
          setCellProps: () => ({
            style: {
              width: '96px',
            },
          }),
        },
      },
      {
        name: 'cnpjcpf',
        label: 'CNPJ/CPF',
      },

      {
        name: 'usual',
        label: 'Nome',
      },
      {
        name: 'ativo',
        label: 'Ativo',
        options: {
          setCellProps: () => ({
            style: {
              width: '60px',
              textAlign: 'center',
            },
          }),
          customBodyRender: (value) => {
            return <Flag isFlagged={value === 1} type="success" />
          },
        },
      },
      {
        name: 'uuid',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          setCellProps: OptionStyles,
          customBodyRender: (value) => {
            const _data = findData<VendedorModel>(vendedores?.data || [], value)
            return (
              <Options
                displayUpdateButton={false}
                displayDeleteButton={false}
                extraOptions={[
                  {
                    id: 'key',
                    icon: 'key',
                    iconButtonProps: {
                      color: 'primary',
                    },
                    visible: true,
                  },
                ]}
                value={_data}
                onClick={handleClickItem}
              />
            )
          },
        },
      },
    ],
    [vendedores, handleClickItem],
  )

  function handleSearch(_query: string) {
    setQuery(_query)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <SearchTextField
          placeholder="Pesquisar vendedores"
          onSearch={handleSearch}
        />
      </Grid>

      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Box sx={{ position: 'relative' }}>
          {isSubmitting && <Loading />}
          <DataTable
            error={error?.message}
            isLoading={isLoading}
            isFetching={isFetching}
            columns={columns}
            data={formattedData || []}
            pagination={vendedores?.pagination}
            changePage={changePage}
            changePageSize={changePageSize}
            options={{
              rowsPerPageOptions: [15, 50, 100],
              rowsPerPage: 15,
            }}
          />
        </Box>
      </Grid>
    </Grid>
  )
}
