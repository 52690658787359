import { CurrencyCellStyle, DataTable } from '@data-c/ui'
import { ItemPerdidoModel } from 'hooks/queries/usePedidos'
import { MUIDataTableColumnDef } from 'mui-datatables'

export interface TableProps {
  itensPerdidos: Array<ItemPerdidoModel>
}
export default function Table(props: TableProps) {
  const { itensPerdidos } = props

  const data = itensPerdidos.map((i) => ({
    ...i,
    codigoProduto: i.produto.codigo,
    nomeProduto: i.produto.nome,
  }))

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'codigoProduto',
      label: 'Código',
      options: {
        setCellProps: () => ({
          style: {
            width: '96px',
          },
        }),
      },
    },
    {
      name: 'nomeProduto',
      label: 'Nome',
    },
    {
      name: 'dataAsBrazilianDate',
      label: 'Data da perda',
      options: {
        setCellProps: () => ({
          style: {
            width: '100px',
          },
        }),
      },
    },
    {
      name: 'motivoPerdaAsText',
      label: 'Motivo',
    },
    {
      name: 'observacaoMotivoPerda',
      label: 'Observação',
    },
    {
      name: 'quantidade',
      label: 'Quantidade',
      options: {
        setCellProps: () => ({
          style: {
            width: '96px',
          },
        }),
      },
    },
    {
      name: 'precoAsBrazilianCurrency',
      label: 'Preço de Tabela',
      options: {
        setCellProps: CurrencyCellStyle,
      },
    },
    {
      name: 'precoUnitarioAsBrazilianCurrency',
      label: 'Preço de Venda',
      options: {
        setCellProps: CurrencyCellStyle,
      },
    },

    //   {
    //     name: 'uuid',
    //     label: 'Ações',
    //     options: {
    //       filter: true,
    //       sort: false,
    //       empty: true,
    //       setCellProps: OptionStyles,
    //       customBodyRender: (value) => {
    //         const _data = findData<CrmAtividadesModel>(data || [], value)
    //         return (
    //           <Options
    //             displayUpdateButton={true}
    //             value={_data}
    //             onClick={handleClickItem}
    //           />
    //         )
    //       },
    //     },
    //   },
  ]

  return (
    <DataTable
      //   error={error}
      columns={columns}
      data={data || []}
      //   isLoading={isLoading}
      //   isFetching={isFetching}
    />
  )
}
