import { Box, useTheme } from '@mui/material'
import CompComentarios from 'components/Comentarios'
import { useSearchParams } from 'react-router-dom'

// export interface ComentariosProps {
//   atividadeUuid?: string
//   pepdidoUuid?: string
// }

export default function Comentarios() {
  const [searchParams] = useSearchParams()
  const theme = useTheme()

  const pedidoUuid = searchParams.get('pedidoUuid') || undefined
  const atividadeUuid = searchParams.get('atividadeUuid') || undefined

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.paper,
        p: 2,
      }}
    >
      <CompComentarios pedidoUuid={pedidoUuid} atividadeUuid={atividadeUuid} />
    </Box>
  )
}
