import { useEffect, useMemo, useState } from 'react'

import { Grid } from '@mui/material'
import { MUIDataTableColumnDef } from 'mui-datatables'

import { DataTable, Flag, FormContainer } from '@data-c/ui'
import { usePagination } from '@data-c/hooks'
import { useFormApi, useFormData } from '@data-c/providers'

import SearchTextField from 'components/SearchTextField'
import useFunilVenda from 'hooks/queries/useFunilVenda'

interface FormProps {
  funilVendaUuid: string
}

function temVendedoresSelecionados(
  indexVendedoresSelecionados: Array<string | undefined>,
): boolean {
  return indexVendedoresSelecionados.length > 0
}

export default function Form(props: FormProps) {
  const { funilVendaUuid } = props

  const [query, setQuery] = useState('')
  const [indexVendedoresSelecionados, setIndexVendedoresSelecionados] =
    useState<number[]>([])

  const { isOpen } = useFormData()
  // const { toggleSubmit, closeForm } = useFormApi()
  const { pagination, changePage, changePageSize } = usePagination()
  const { useQueryObterVendedoresNaoVinculados, useVincularVendedores } =
    useFunilVenda()
  const {
    data: vendedoresNaoVinculados,
    isLoading,
    isFetching,
    error,
  } = useQueryObterVendedoresNaoVinculados({
    plainQuery: query,
    funilVendaUuid,
    pagination,
  })
  const { mutateAsync: vincularVendedores } = useVincularVendedores()
  const { toggleSubmit, closeForm } = useFormApi<FormProps>()

  useEffect(() => {
    if (isOpen === false) {
      setIndexVendedoresSelecionados([])
    }
  }, [isOpen])

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'codigo',
        label: 'Código',
        options: {
          setCellProps() {
            return { width: '96px' }
          },
        },
      },
      {
        name: 'cnpjcpf',
        label: 'CNPJ/CPF',
      },
      {
        name: 'usual',
        label: 'Nome',
      },
      {
        name: 'ativo',
        label: 'Ativo',
        options: {
          setCellProps: () => ({
            style: {
              width: '60px',
              textAlign: 'center',
            },
          }),
          customBodyRender: (value) => {
            return <Flag isFlagged={value === 1} type="success" />
          },
        },
      },
    ],
    [],
  )

  function handleSearch(_query: string) {
    setQuery(_query)
  }

  async function handleClickVincularVendedores() {
    const vendedoresSelecionadosIds =
      indexVendedoresSelecionados.map(
        (index) => vendedoresNaoVinculados?.data[index]?.uuid || '',
      ) || []

    if (
      funilVendaUuid &&
      temVendedoresSelecionados(vendedoresSelecionadosIds)
    ) {
      toggleSubmit(true)
      try {
        await vincularVendedores({
          funilVendaUuid,
          vendedoresSelecionadosIds,
        })
        closeForm()
      } finally {
        toggleSubmit(false)
      }
    }
  }

  return (
    <FormContainer
      onSubmitForm={handleClickVincularVendedores}
      triggerButtonLabel="Vincular"
      confirmButtonLabel="Vincular"
      dialogProps={{ title: 'Vendedores', maxWidth: 'md' }}
    >
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <SearchTextField
            placeholder="Pesquisar vendedores"
            onSearch={handleSearch}
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <DataTable
            error={error?.message}
            isLoading={isLoading}
            isFetching={isFetching}
            columns={columns}
            data={vendedoresNaoVinculados?.data || []}
            changePage={changePage}
            changePageSize={changePageSize}
            pagination={vendedoresNaoVinculados?.pagination}
            options={{
              rowsPerPageOptions: [15, 50, 100],
              rowsPerPage: 15,
              selectableRows: 'multiple',
              selectableRowsOnClick: true,
              rowsSelected: indexVendedoresSelecionados,
              onRowSelectionChange: (_, __, rowsSelecteds: any) =>
                setIndexVendedoresSelecionados(rowsSelecteds),
              selectToolbarPlacement: 'none',
            }}
          />
        </Grid>
      </Grid>
    </FormContainer>
  )
}
