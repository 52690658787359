import { PlainQueryProps } from '@data-c/hooks'
import useCrud, { generateEntityKey } from 'hooks/useCrud'
// import HttpRequestInterface from 'interfaces/HttpRequestInterface'
// import HttpResponseInterface from 'interfaces/HttpResponseInterface'
// import api from 'services/api'
import { AxiosError } from 'axios'
import { useMutation } from '@tanstack/react-query'
import useNotification from 'hooks/useNotifications'
import queryClient from 'services/query-client'
import api from 'services/api'

const entityKey = generateEntityKey('Permissão')

interface RoleItem extends Record<string, any> {}

export interface RoleModel extends PlainQueryProps {
  id?: string
  uuid?: string
  origem?: string
  nome?: string
  descricao?: number
  permissoes?: Record<string, RoleItem>
  fixa?: string
}

async function copy(roleUuid: string) {
  await api.post(`/administrador/role/${roleUuid}/copy`)
}

export function useCopy() {
  const notifications = useNotification()
  return useMutation((roleUuid: string) => copy(roleUuid), {
    onSuccess() {
      queryClient.invalidateQueries([entityKey])
    },
    onError(error) {
      notifications.notifyException(error)
    },
  })
}

export default function useRole() {
  return {
    useCopy,
    ...useCrud<RoleModel, AxiosError>(
      'administrador/role',
      'Permissão',
      'female',
    ),
  }
}

// export async function obterGrupoProdutos(
//   params: HttpRequestInterface<RoleModel>,
// ): Promise<HttpResponseInterface<RoleModel>> {
//   const { pagination: _pagination, queryParams } = params

//   const response = await api.get('administrador/grupo-produto', {
//     params: queryParams,
//     headers: {
//       'DC-Page': _pagination.page,
//       'DC-PageSize': _pagination.pageSize,
//     },
//   })

//   const { data, meta: pagination } = response.data

//   return { data, pagination }
// }
