import { useState } from 'react'

import cepApi from 'services/cep-api'
import { CidadeModel, obterCidadesPorUf } from './queries/useCidade'
import { normalizeText, onlyNumbers } from '@data-c/hooks'

function encontrarCidadePorNome(
  cidades: Array<CidadeModel>,
  nomeCidade: string,
): CidadeModel | undefined {
  const normalizedNomeCidade = normalizeText(nomeCidade).toLowerCase()

  return cidades.find(
    (cidade) =>
      normalizeText(cidade.nome).toLowerCase() === normalizedNomeCidade,
  )
}

export interface ApiCepModel {
  cep: string
  logradouro: string
  complemento?: string
  bairro: string
  localidade: string
  uf: string
  ibge: string
  ddd: string
}

export interface CepModel {
  cep: string
  endereco: string
  complemento?: string
  bairro: string
  cidadeModel?: CidadeModel
}

export async function consultarCepNaApiViaCep(
  cep: string,
): Promise<ApiCepModel> {
  const response = await cepApi.get<ApiCepModel>(`/${cep}/json`)
  return response.data
}

export async function consultarCep(cep: string) {
  const data = await consultarCepNaApiViaCep(onlyNumbers(cep) as string)
  const cidades = await obterCidadesPorUf(data.uf)
  const cidade = encontrarCidadePorNome(cidades, data.localidade)
  return {
    cep,
    endereco: data?.logradouro,
    bairro: data?.bairro,
    complemento: data?.complemento,
    cidadeModel: cidade,
  }
}

export default function useConsultaCep() {
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState<null | string>(null)

  async function _consultarCep(cep: string): Promise<CepModel | undefined> {
    try {
      setLoading(true)
      return consultarCep(cep)
    } catch (err) {
      setError('Não foi possível carregar o cep')
    } finally {
      setLoading(false)
    }
  }

  return {
    consultarCep: _consultarCep,
    isLoading,
    error,
  }
}
