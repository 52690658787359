import { Paper } from '@mui/material'
import { ClientesModel } from 'hooks/queries/useClientes'
import Form from 'pages/Clientes/components/Form'

interface TabCadastroClienteProps {
  cliente: ClientesModel
}

export default function TabCadastroCliente(props: TabCadastroClienteProps) {
  const { cliente } = props

  return (
    <Paper sx={{ p: 2 }} elevation={0}>
      <Form view="plain" data={cliente} />
    </Paper>
  )
}
