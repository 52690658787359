import { ButtonContainer, PageTitle } from '@data-c/ui'
import { Container, Tab, Tabs } from '@mui/material'
import TabChart from './components/TabChart'
import { FilterProvider } from '@data-c/providers'
import { filters } from 'hooks/queries/useRelatorioOportunidadesPerdidas'
import Filter from './components/Filter'
import useTabs from 'hooks/useTabs'
import TabPanel from 'components/TabPanel'
import TabDetalhado from './components/TabDetalhado'
import Content from 'components/Layouts/Content'

export default function NegociacoesPerdidas() {
  const { value: tabValue, handleChange } = useTabs()

  return (
    <Container>
      <FilterProvider
        filterValues={filters}
        storagePath="@fdv:relatorio-perdas"
      >
        <PageTitle title="Oportunidades Perdidas">
          <Filter />
        </PageTitle>

        <ButtonContainer sx={{ m: 2 }}>
          <Tabs value={tabValue} onChange={handleChange}>
            <Tab label="Por motivo" value={0} />
          </Tabs>

          <Tabs value={tabValue} onChange={handleChange}>
            <Tab label="Detalhado" value={2} />
          </Tabs>
        </ButtonContainer>

        <Content>
          <TabPanel value={tabValue} index={0}>
            <TabChart />
          </TabPanel>

          <TabPanel value={tabValue} index={2}>
            <TabDetalhado />
          </TabPanel>
        </Content>
      </FilterProvider>
    </Container>
  )
}
