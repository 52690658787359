import { IconButton, IconButtonProps } from '@mui/material'
import { Menu as MenuIcon } from '@mui/icons-material'

export interface HeaderToggleMenuProps extends IconButtonProps {}

export default function HeaderToggleMenu(props: HeaderToggleMenuProps) {
  const { ...iconButtonProps } = props
  return (
    <IconButton {...iconButtonProps}>
      <MenuIcon />
    </IconButton>
  )
}
