import { Box, Button, Stack, Typography } from '@mui/material'
import unauthorizedImage from 'assets/images/unauthorized.svg'
import appLogo from 'assets/images/logo.png'
import useDeviceDetect from 'hooks/useDeviceDetect'
import WebAppInterface from 'interfaces/WebAppInterface'
import { useNavigate } from 'react-router-dom'

declare const android: WebAppInterface
export default function Unauthorized() {
  const { isWebView } = useDeviceDetect()
  const navigate = useNavigate()
  function handleVoltar() {
    android.back()
  }

  function handleLogin() {
    // if (isWebView) {
    //   android.login()
    //   return
    // }
    navigate('/login')
  }

  return (
    <Box
      sx={{
        height: `100vh`,
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#fff',
      }}
    >
      <img src={appLogo} alt="Logo App Força de Vendas" />
      <img src={unauthorizedImage} width="300" />
      <Stack spacing={2} sx={{ m: 2 }}>
        <Typography sx={{ textAlign: 'center' }} variant="body2">
          Parece que você não tem permissão para acessar esta página ou a sua
          sessão expirou.
        </Typography>

        {isWebView && (
          <Button size="medium" variant="outlined" onClick={handleVoltar}>
            Voltar
          </Button>
        )}

        <Button size="medium" variant="contained" onClick={handleLogin}>
          Fazer Login
        </Button>
      </Stack>
    </Box>
  )
}
