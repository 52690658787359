import {
  ConfiguracoesModel,
  obterConfiguracoes,
} from 'hooks/queries/useConfiguracoes'
import { UsuarioModel, obterUsuarioLogado } from 'hooks/queries/useUsuario'
import React, { useContext, useState } from 'react'

const DEFAULT_VALUE = {
  user: {} as UsuarioModel,
  configuracoes: {} as ConfiguracoesModel,
  isLoading: false,
  setUser: async () => Promise,
  setLoading: async () => Promise,
  loadUser: async () => {},
  loadConfiguracoes: async () => {},
  temPermissao: (_: string) => {},
}

export interface UserProviderProps {
  isLoading: boolean
  user: UsuarioModel | null
  configuracoes: ConfiguracoesModel | null
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  setUser: React.Dispatch<React.SetStateAction<UsuarioModel | null>>
  loadUser: () => Promise<void>
  loadConfiguracoes: () => Promise<void>
  temPermissao: (_: string) => any
}

export const UserContext = React.createContext<UserProviderProps>(DEFAULT_VALUE)

export default function UserProvider(props: any) {
  const { children } = props
  const [user, setUser] = useState<UsuarioModel | null>(null)
  const [configuracoes, setConfiguracoes] = useState<ConfiguracoesModel | null>(
    null,
  )
  const [isLoading, setLoading] = useState<boolean>(false)

  function temPermissao(roleKey: string) {
    const name = roleKey.split('.')
    const n1 = name[0]
    const n2 = name[1]
    let permissoes = user?.role?.permissoes

    if (
      permissoes &&
      permissoes.hasOwnProperty(n1) &&
      permissoes[n1].hasOwnProperty(n2)
    ) {
      return permissoes[n1][n2]
    }

    return false
  }

  async function loadConfiguracoes() {
    try {
      const response = await obterConfiguracoes()
      setConfiguracoes(response)
    } catch (err) {}
  }

  async function loadUser() {
    try {
      setLoading(true)
      const response = await obterUsuarioLogado()
      setUser(response)
    } catch (err) {
      // console.log('ERROU', JSON.stringify(err))
    } finally {
      setLoading(false)
    }
  }

  return (
    <UserContext.Provider
      value={{
        isLoading,
        setLoading,
        user,
        setUser,
        temPermissao,
        loadUser,
        loadConfiguracoes,
        configuracoes,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}
export function useUserContext() {
  return useContext<UserProviderProps>(UserContext)
}
